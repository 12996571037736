import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function greetingReducer(state = initialState.greeting, action) {
     switch (action.type) {
        case types.GET_QUOTE_SUCCESS:
            return {
                ...state,
                quoteText: action.quote
            }
        case types.GET_GREETING_SUCCESS:
            return {
                ...state,
                text: action.greeting.text,
                timeOfDay: action.greeting.timeOfDay
            }    
        default:
            return state;
    }
};