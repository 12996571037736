import React from 'react'

function EmptyView({text, buttonText, onButtonClick, redirectTo}) {
    return (         
        <div className="list-row--empty">
            <img src={require('../../images/picto-empty.png')} alt="" />
            <div className="list-row--empty__text" style={{textAlign: 'center'}}> 
                {text}
            </div>
            {
                buttonText ?
                    onButtonClick ?
                        <a href="#" className="list-row--empty__button" onClick={onButtonClick}>{buttonText} <span>+</span></a>
                        : 
                        <a href={redirectTo} className="list-row--empty__button" target="_blank" rel="noopener noreferer">{buttonText} <span>+</span></a> 
                    : null
            }
        </div>
    )
}

export default EmptyView;