import React, {useState, useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import ToastrService from '../../services/toastrService';
import * as qs from 'query-string';
import swal from 'sweetalert';
import isEmail from 'validator/lib/isEmail';
import * as urlUtils from '../../utils/urlUtils';
import urls from '../../constants/urls.js';
import SweetAlertExternalLoginInput from './SweetAlertExternalLoginInput';
//import { useTraceUpdate } from '../../utils/customHooks';

//React.memo(props => {
const ExternalLogin = props => {
    const isSignup = props.isSignup;
    const loginSignup = isSignup ? 'signup' : 'login';
    const [externalLogins, setExternalLogins] = useState([]);
    const mounted = useRef(true);

    useEffect(() => {
        const loadingExternalLogin = checkForExternalCallback();
        if(!loadingExternalLogin) {
            getExternalLogins();
        }
        return () => mounted.current = true;
    }, []);

    const externalLogin = (event, provider) => {
        event.preventDefault();
        let login;
        if(externalLogins.length) {
            login = externalLogins.find(l => l.Name === provider);
        }
        if(login) {
            //save the referrer before we redirect so we have it when we return
            urlUtils.saveReferrer();            
            window.location = `${urls.BASE_URL}${login.Url}`;
        }        
    }

    const getExternalLogins = () => {
        if(!externalLogins.length) {
            //**add the full path here with the querystring encoded? returnUrl = '/Account/Login' */
            const params = qs.parse(props.location.search);
            let originalReturnUrl = null;
            if(params && params.returnUrl && !urlUtils.isExternalUrl(params.returnUrl)) {
                originalReturnUrl = params.returnUrl;
            }
            const returnUrl = `${window.location.href}${originalReturnUrl ? encodeURIComponent(`?returnUrl=${originalReturnUrl}`): ''}`;
            props.actions.getExternalLoginsList(returnUrl)
                .then(logins => {
                    if(!props.user) {
                        //we've already redirected if the user is set
                        if(mounted.current) {
                            setExternalLogins(logins);
                        }
                    }
                })
                .catch(error => {
                });
        }
    }
    
    const registerExternalAccount = (email, marketingOptIn) => {
        const toastrService = new ToastrService();
        props.actions.registerExternal(email, marketingOptIn)
            .then(data => {
                if(!data) {
                    toastrService.showError(`Social ${loginSignup} failed`, 'Sorry, something went wrong');
                    getExternalLogins();
                }
            })
            .catch(err => {
                toastrService.showError(`Social ${loginSignup} failed`, 'Sorry, something went wrong');
                getExternalLogins();
            });
    }
    
    const checkForExternalCallback = () => {
        let loadingExternalLogin = false;    
        const toastrService = new ToastrService();
        const hash = props.location.hash;    
    
        if(hash) {
            const params = qs.parse(hash.substr(1));      
            let accessTokenCookie = document.cookie
                .split('; ')
                .find(row => row.startsWith('AccessToken'));   
            let accessToken = accessTokenCookie ? accessTokenCookie.split('=')[1] : (params.access_token ? params.access_token : null);
            let refreshTokenCookie = document.cookie
                .split('; ')
                .find(row => row.startsWith('RefreshToken'));   
            let refreshToken = refreshTokenCookie ? refreshTokenCookie.split('=')[1] : (params.refresh_token ? params.refresh_token : null);
            let expiresAtCookie = document.cookie
                .split('; ')
                .find(row => row.startsWith('ExpiresAt'));   
            let expires = expiresAtCookie ? decodeURIComponent(expiresAtCookie.split('=')[1]) : (params.expires_at ? params.expires_at : null);

            if(accessToken) {
                loadingExternalLogin = true;
                //delete the token details from the cookie now we've read it (it's set to expire in 5 mins but let's be extra safe)
                document.cookie = 'AccessToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 UTC;';
                document.cookie = 'RefreshToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 UTC;';
                document.cookie = 'Expires=; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 UTC;';
                props.actions.loginSuccess(accessToken, refreshToken, expires);
                props.actions.getUserInfo()
                    .then(userInfo => {
                        if(userInfo) {
                            if(userInfo.HasRegistered) {                                
                                props.actions.getUser(true, true);
                            }
                            else {
                                const promise = isSignup ? Promise.resolve() : new Promise((resolve, reject) => {
                                    return swal('It looks like you don\'t have an account, would you like to create one?', {
                                        buttons: [true, 'Create Account']
                                    }).then(value => {
                                        if(value) {
                                            resolve(value);
                                        }
                                        else {
                                            getExternalLogins();
                                            if(mounted.current) {
                                                props.setLoadingExternalLogin(false);
                                            }
                                            reject();
                                        }
                                    })
                                });
                                promise.then(response => {
                                    /*if(userInfo.Email) {
                                        registerExternalAccount(userInfo.Email, loginSignup, externalLogins, setExternalLogins, props);
                                    }
                                    else {*/
                                        const wrapper = document.createElement('div');
                                        ReactDOM.render(<SweetAlertExternalLoginInput email={userInfo.Email} />, wrapper);
                                        const el = wrapper.firstChild;
                                        return swal(`Please ${userInfo.Email ? 'confirm' : 'enter'} your details`, {
                                            content: el,
                                            buttons: {
                                                cancel: true,                                                 
                                                confirm: {
                                                    value: {email: userInfo.Email || '', marketingOptIn: false},
                                                }
                                            }
                                        })
                                        .then(result => {
                                            console.log(result);
                                            const {email, marketingOptIn} = result;
                                            if(email) {
                                                if(isEmail(email)) {
                                                    registerExternalAccount(email, marketingOptIn);
                                                }
                                                else {
                                                    toastrService.showError(`Social ${loginSignup} failed`, 'The email address entered doesn\'t look quite right');
                                                    getExternalLogins();
                                                }
                                            }
                                            else {
                                                toastrService.showError(`Social ${loginSignup} failed`, 'We need your email address to complete the sign up');
                                                getExternalLogins();
                                            }
                                        });
                                    //}
                                })
                                .catch(error => {
                                    //do nothing
                                    getExternalLogins();
                                })                                                                 
                                if(mounted.current) {
                                    props.setLoadingExternalLogin(false);
                                }
                            }
                        }
                        else {
                            toastrService.showError(`Social ${loginSignup} failed`, `Sorry, it looks like your ${loginSignup} attempt may have timed out, please try again`);
                            if(mounted.current) {
                                props.setLoadingExternalLogin(false);
                            }
                            getExternalLogins();
                        }
                    })
                    .catch(error => {
                        if(mounted.current) {
                            props.setLoadingExternalLogin(false);
                            getExternalLogins();
                        }
                    });
            }
            else if(params.error) {
                toastrService.showError(`Social ${loginSignup} failed`, `${params.error === 'access_denied' ? 'Access denied,' : 'Sorry, something went wrong,'} unable to complete ${loginSignup}`);
            }
        }   
        props.setLoadingExternalLogin(loadingExternalLogin);       
        return loadingExternalLogin;
    }    

    return (
        <React.Fragment>
            <p className="form-text text-center text-muted">{isSignup ? 'Sign up' : 'Log in'} with your external account</p>
            <div className="btn-group btn-group--social">
                <button type="button" className="soc-btn soc-btn--fb" onClick={(e) => externalLogin(e, 'Facebook')}>
                    <svg id="Bold" enableBackground="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                        <path d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z"/>
                    </svg>
                </button>
                <button type="button" className="apple-btn soc-btn" onClick={(e) => externalLogin(e, 'Apple')}>
                    <svg width="56px" height="56px" viewBox="0 0 56 56" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g id="Black-Logo-Square" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <rect rx="50" ry="50" id="Rectangle" fill="#FFFFFF" x="6" y="6" width="44" height="44"></rect>
                            <path d="M28.2226562,20.3846154 C29.0546875,20.3846154 30.0976562,19.8048315 30.71875,19.0317864 C31.28125,18.3312142 31.6914062,17.352829 31.6914062,16.3744437 C31.6914062,16.2415766 31.6796875,16.1087095 31.65625,16 C30.7304687,16.0362365 29.6171875,16.640178 28.9492187,17.4494596 C28.421875,18.06548 27.9414062,19.0317864 27.9414062,20.0222505 C27.9414062,20.1671964 27.9648438,20.3121424 27.9765625,20.3604577 C28.0351562,20.3725366 28.1289062,20.3846154 28.2226562,20.3846154 Z M25.2929688,35 C26.4296875,35 26.9335938,34.214876 28.3515625,34.214876 C29.7929688,34.214876 30.109375,34.9758423 31.375,34.9758423 C32.6171875,34.9758423 33.4492188,33.792117 34.234375,32.6325493 C35.1132812,31.3038779 35.4765625,29.9993643 35.5,29.9389701 C35.4179688,29.9148125 33.0390625,28.9122695 33.0390625,26.0979021 C33.0390625,23.6579784 34.9140625,22.5588048 35.0195312,22.474253 C33.7773438,20.6382708 31.890625,20.5899555 31.375,20.5899555 C29.9804688,20.5899555 28.84375,21.4596313 28.1289062,21.4596313 C27.3554688,21.4596313 26.3359375,20.6382708 25.1289062,20.6382708 C22.8320312,20.6382708 20.5,22.5950413 20.5,26.2911634 C20.5,28.5861411 21.3671875,31.013986 22.4335938,32.5842339 C23.3476562,33.9129053 24.1445312,35 25.2929688,35 Z" id="" fill="#000000" fillRule="nonzero"></path>
                        </g>
                    </svg>
                </button>
                <button type="button" className="soc-btn soc-btn--twitter" onClick={(e) => externalLogin(e, 'Twitter')}>                                 
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" style={{enableBackground: 'new 0 0 512 512'}} xmlSpace="preserve">
                        <g>
                            <g>
                                <path d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016
                                    c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992
                                    c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056
                                    c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152
                                    c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792
                                    c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44
                                    C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568
                                    C480.224,136.96,497.728,118.496,512,97.248z"/>
                            </g>
                        </g>
                    </svg>
                </button>
            </div>
        </React.Fragment>
    )
}

export default ExternalLogin;