import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as accountActions from '../../actions/accountActions';
import * as artistActions from '../../actions/artistActions';
import '../../styles/style-ui.scss';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { withModalContext } from '../../services/modalService';
import ToastrService from '../../services/toastrService';
import * as qs from 'query-string';
import swal from 'sweetalert';
import urls from '../../constants/urls.js';
import { checkSubscription } from '../../utils/artistUtils';
import SocialSummaryCard from './SocialSummaryCard';
import SweetAlertSelectExternalAccount from './SweetAlertSelectExternalAccount';
import SocialProfileInputModal from './SocialProfileInputModal';
import ArtistNameHeader from '../common/ArtistNameHeader';
import { socialPageHeaders } from '../../constants/appMessages';
import _ from 'lodash';

function SocialPage(props) {   

    const activeProfile = props.user.activeProfile;
    const [externalLogins, setExternalLogins] = useState([]);
    const mounted = useRef(true);
    const [loading, setLoading] = useState(false);
    const [connecting, setConnecting] = useState(false);
    const [disconnecting, setDisconnecting] = useState(false);
    const headerText = useRef(_.sample(socialPageHeaders)).current;

    useEffect(() => {
        checkForExternalCallback();
        return () => mounted.current = true;
    }, []);

    const getExternalLogins = () => {
        if(!externalLogins.length) {
            setLoading(true);
            const returnUrl = window.location.href;
            return props.actions.getExternalLoginsInsightsList(returnUrl)
                .then(logins => {
                    if(mounted.current) {
                        setExternalLogins(logins);
                        setLoading(false);
                    }
                    return logins;
                })
                .catch(error => {
                    setLoading(false);
                    return null;
                });
        }
    }

    const checkForExternalCallback = () => {
        const toastrService = new ToastrService();
        const hash = props.location.hash;    
    
        if(hash) {
            const params = qs.parse(hash.substr(1));  
            const provider = params.provider;
            debugger;
            let accessTokenCookie = document.cookie
                .split('; ')
                .find(row => row.startsWith('AccessToken'));
            let accessToken = accessTokenCookie ? accessTokenCookie.split('=')[1] : (params.access_token ? params.access_token : null);
            if(accessToken) {
                //delete it from the cookie now we've read it (it's set to expire in 5 mins but let's be extra safe)
                document.cookie = 'AccessToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 UTC;';
                if(provider === 'Facebook' || provider === 'Instagram') {
                    const setProfileAction = provider === 'Facebook' ? props.actions.setFacebookProfile : props.actions.setInstagramProfile;
                    props.actions.getFacebookAccounts(accessToken)
                        .then(accounts => {
                            if(accounts.length === 1) {
                                const account = accounts[0];
                                swal(`Do you want to connect this profile to ${provider === 'Instagram' ? ' the Instagram account linked to ' : ''}Facebook account '${account.Name}'? ${activeProfile.ArtistType === 'Band' ? 'The account stats will be available to all members of this band.' : ''}`, {
                                    buttons: [true, 'Connect']
                                }).then(value => {
                                    if(value) {
                                        setConnecting(true);                                        
                                        setProfileAction(activeProfile.ID, account.AccessToken)
                                            .then(_ => setConnecting(false))
                                        //check the error, maybe the user didn't select the correct permissions so we can show a better error
                                            .catch(error => {
                                                setConnecting(false);
                                                toastrService.showError('', 'Sorry, something went wrong!');
                                            });
                                    }
                                })
                            }
                            else if(accounts.length > 1) {
                                //the user will need to select the account
                                const wrapper = document.createElement('div');
                                    ReactDOM.render(<SweetAlertSelectExternalAccount accounts={accounts} artistType={activeProfile.ArtistType} />, wrapper);
                                    const el = wrapper.firstChild;   
                                    return swal(`Please select the ${provider === 'Facebook' ? 'page you want to connect to' : 'Facebook page your Instagram account is linked to'} `, {
                                        content: el,
                                        buttons: {
                                            cancel: true,                                                 
                                            confirm: {
                                                value: { account: '' },
                                            }
                                        }
                                    })
                                    .then(account => {
                                        if(account) {
                                            setConnecting(true);
                                            setProfileAction(activeProfile.ID, account.AccessToken)
                                                .then(_ => setConnecting(false))
                                                //check the error, maybe the user didn't select the correct permissions so we can show a better error
                                                .catch(error => {
                                                    setConnecting(false);
                                                    toastrService.showError('', 'Sorry, something went wrong!');
                                                });
                                        }
                                        else {
                                            toastrService.showError('', 'Sorry, we didn\'t get a Facebook page to connect to');
                                        }
                                    });
                            }
                            else {
                                toastrService.showError('', 'Sorry, we didn\'t get a Facebook page to connect to');
                            }
                        })
                        .catch(error => {
                            toastrService.showError('', 'Sorry, something went wrong!');
                        });
                }
                else if(provider === 'Twitter') {
                    swal(`Do you want to connect this profile to your Twitter account? ${activeProfile.ArtistType === 'Band' ? 'The account stats will be available to all members of this band.' : ''}`, {
                        buttons: [true, 'Connect']
                    }).then(value => {
                        if(value) {
                            setConnecting(true);
                            props.actions.setTwitterProfile(activeProfile.ID, accessToken)
                                .then(_ => setConnecting(false))
                                .catch(error => {
                                    setConnecting(false);
                                    //this could potentially be caused by the access token timing out, it's only valid for 10 mins
                                    toastrService.showError('', 'Sorry, something went wrong!');
                                });
                        }
                    })
                }              
            }
            else if(params.error) {
                toastrService.showError(`Social connect failed`, params.error === 'access_denied' ? 'Access has been denied' : params.error);
            }
        }       
    }  

    const connect = (event, provider) => {
        event.preventDefault();
        const toastrService = new ToastrService();
        checkSubscription(activeProfile, () => {
            let infoPromise;
            if(provider === 'Instagram') {
                const message = <span style={{ color: "rgba(0,0,0,.65)"}}>To connect to Instagram, you need to have a professional Instagram account which is linked to a Facebook page you're an admin for.<br />Find out how to connect your Instagram account to a Facebook page at <a href="https://help.instagram.com/399237934150902" target="_blank">https://help.instagram.com/399237934150902</a></span>
                let wrapper = document.createElement('div');
                ReactDOM.render(message, wrapper);
                infoPromise = swal({
                    title: 'Here\'s how it works',
                    content: wrapper.firstChild,
                    buttons: true,
                    dangerMode: false,
                    buttons: ['Cancel', 'I\'m all set!'],
                })
            }
            else {
                infoPromise = Promise.resolve(true);
            }
            if(provider === 'Facebook' || provider === 'Twitter' || provider === 'Instagram') {
                infoPromise.then(result => {
                    debugger;
                    if(result) {
                        return getExternalLogins()
                            .then(externalLogins => {
                                let login;
                                if(externalLogins.length) {
                                    login = externalLogins.find(l => l.Name === provider);
                                }
                                if(login) {
                                    window.location = `${urls.BASE_URL}${login.Url}`;
                                }  
                            })
                            .catch(error => {
                                 toastrService.showError('', 'Sorry, something went wrong!');
                            })   
                    }                   
                })
            }
            else {                
                let hintText;
                let inputName;
                if(provider === 'YouTube') {
                    inputName = 'Channel ID';
                    hintText = <span style={{color: '#78778E', fontSize: '0.9em'}}>This is the part of your channel url at https://www.youtube.com/channel/<span style={{color: '#E4662A'}}>[Channel ID]</span></span>;   
                }
                else if(provider === 'SoundCloud') {
                    inputName = 'SoundCloud Username';
                    hintText = <span style={{color: '#78778E', fontSize: '0.9em'}}>This is the part of your profile url at https://www.soundcloud.com/<span style={{color: '#E4662A'}}>[Username]</span></span>;   
                }
                else if(provider === 'LoudUp') {
                    inputName = 'LoudUp Username';
                    hintText = <span style={{color: '#78778E', fontSize: '0.9em'}}>This is the part of your profile url at https://www.loudup.com/<span style={{color: '#E4662A'}}>[Username]</span></span>;   
                }
                const onSelected = (input) => {
                    let action;
                    if(provider === 'YouTube') {
                        action = props.actions.setYouTubeProfile;
                    } 
                    else if(provider === 'SoundCloud') {
                        action = props.actions.setSoundCloudProfile;
                    } 
                    else if(provider === 'LoudUp') {
                        action = props.actions.setLoudUpProfile;
                    }                    
                    setConnecting(true);  
                    action(activeProfile.ID, input).then(_ => setConnecting(false))
                        .catch(error => {
                            setConnecting(false);
                            if(error.statusCode === 404) {
                                let error = `Sorry, looks like the ${inputName} entered is invalid!`;
                                if(provider === 'LoudUp') {
                                    error += ' Make sure you aren\'t using a fan profile.'
                                }
                                toastrService.showError('', error);
                            }
                            else {
                                toastrService.showError('', 'Sorry, something went wrong!');
                            }
                        });
                } 
                const socialProfileInputModal = <SocialProfileInputModal inputName={inputName} onSelected={onSelected} hintText={hintText} />;
                props.showModal(() => socialProfileInputModal, { isOpen: true })
            }
        }, 'connect a social profile');    
    }

    const disconnect = (event, provider) => {
        event.preventDefault();
        swal({
            title: 'Are you sure?',
            text: 'Once disconnected, any insights loaded will be deleted',
            buttons: true,
            dangerMode: true,
        })
        .then((willDisconnect) => {
            if (willDisconnect) {
                setDisconnecting(true);
                props.actions.clearSocialProfile(activeProfile.ID, provider)
                    .then(_ => {
                        setDisconnecting(false);
                    })
                    .catch(error => {
                        setDisconnecting(false);
                    })
            }
        }); 
    }

    const socialKeys = activeProfile.Social ? Object.keys(activeProfile.Social) : [];

    return (        
        <div className="home">
            <Header />
            <main className="content">
                <div className="container">
                    <ArtistNameHeader user={props.user} greeting={`Hey ${props.user.FirstName}, ${headerText}`} />  
                    {
                        !activeProfile.Social || !socialKeys.length || !socialKeys.find(key => activeProfile.Social[key]) ?
                            <p className="text-muted pb-3 text-center">Connect to your social profiles to view insights for each platform</p>
                            :
                            null
                    }
                    <div className="content-body">
                        {/*Use the external login flag somehow so when the user tries to connect, we check if we're loading */}
                        <div className="stats stats--social">
                        {
                            ['Facebook', 'Twitter', 'Instagram'].map(platform =>
                                <SocialSummaryCard key={platform} platform={platform} socialProfile={activeProfile.Social[platform]} connect={(event) => connect(event, platform)} disconnect={(event) => disconnect(event, platform)} />    
                            )
                        }
                        </div>
                        <div className="stats stats--social">
                        {
                            ['YouTube', 'SoundCloud', 'LoudUp'].map(platform =>
                                <SocialSummaryCard key={platform} platform={platform} socialProfile={activeProfile.Social[platform]} connect={(event) => connect(event, platform)} disconnect={(event) => disconnect(event, platform)} />    
                            )
                        }       
                        </div>
                    </div>
                </div>
            </main>
            {
                loading || connecting || disconnecting ?
                    <div className="modal-window__loader modal-window__loader-task modal-window__loader-login active">
                        <img src={require('../../images/loader.svg')} alt="loading" />
                        {loading ? 'Loading...' : (connecting ? 'Connecting...' : 'Disconnecting...')}
                    </div>
                    : null
            } 
            <Footer />
        </div>
    );
}

SocialPage.propTypes = {
    //myProp: PropTypes.string.isRequired
};


function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user
    };
}


function mapDispatchToProps(dispatch) {
    return {
         actions: bindActionCreators(Object.assign({}, accountActions, artistActions), dispatch)
    };
}

export default withModalContext(connect(mapStateToProps, mapDispatchToProps)(SocialPage));