import React, { useState, useEffect, useRef } from 'react';
import ToastrService from '../../services/toastrService';
import AudioRecorder from 'audio-recorder-polyfill'
import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder'
import AnalyticsService from '../../services/analyticsService';
AudioRecorder.encoder = mpegEncoder;
AudioRecorder.prototype.mimeType = 'audio/mpeg';
window.MediaRecorder = AudioRecorder;

function RecordAudioModal({closeRecordModal, createAudioItem}) {

    const [recording, setRecording] = useState(false);
    const [secondsRecorded, setSecondsRecorded] = useState(0);

    useEffect(() => {
        window.$(document).on('click', '', closeModal);
        return () => {
            window.$(document).off('click', '', closeModal);
        }
    }, []);

    useEffect(() => {
        let interval;
        if(recording) {
            let currentSecs = 0;
            setSecondsRecorded(currentSecs);
            interval = setInterval(() => {
                currentSecs++;
                setSecondsRecorded(currentSecs);
                if(currentSecs >= 180) {
                    clearInterval(interval);
                    toggleRecording();
                }
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [recording])

    const mediaRecorderRef = useRef();

    useEffect(() => {
        AnalyticsService.logEvent('Record Audio Modal Launched');
    }, []);

    const toggleRecording = (event) => {     
        let mediaRecorder = mediaRecorderRef.current;
        let chunks = [];

        if(!recording) {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices.getUserMedia({ audio: true })
                .then((stream) => {
                    mediaRecorder = new MediaRecorder(stream);
                    mediaRecorderRef.current = mediaRecorder;                  
                    mediaRecorder.addEventListener('dataavailable', e => {
                        chunks.push(e.data);
                    });                  
                    mediaRecorder.start();
                    setRecording(true);                    

                    mediaRecorder.addEventListener('stop', (e) => {                        
                        //send the chunks back to parent
                        setRecording(false);
                        closeRecordModal();
                        if(chunks.length) {
                            //const encodedData = btoa([].reduce.call(new Uint8Array(chunks), (p,c) => p + String.fromCharCode(c), ''));                           
                            createAudioItem(event, chunks[0]);                        
                            chunks = [];
                        }
                    });
                })
                .catch((err) => {
                    const toastrService = new ToastrService();
                    toastrService.showError('', 'An error occured: ' + err.message);
                });
            } 
            else {
                const toastrService = new ToastrService();
                toastrService.showError('', 'It looks like your browser doesn\'t support recording');
            }
        }
        else {
            mediaRecorder.stop();    
            mediaRecorder.stream.getTracks().forEach(i => i.stop());        
        }
    }

    const closeModal = (e) => { 
        const modalContent = window.$('.record-audio-modal__content');
        if (!modalContent.is(e.target) && modalContent.has(e.target).length === 0) {
            closeRecordModal();
        }    
    }

    const mins = Math.floor(secondsRecorded / 60).toString().padStart(2, '0');
    const secs = (secondsRecorded % 60).toString().padStart(2, '0');  
    return (
        <div className="record-audio-modal active">
            <div className="record-audio-modal__content">
                <div className="close" onClick={closeRecordModal}>+</div>
                <div className="record-audio-modal__title">
                    RECORD AUDIO
                </div>
                <div className={`record-audio-modal__icon ${recording ? 'active' : ''}`}>
                    <img src={require('../../images/mic.png')} alt="" className="" />
                </div>
                <div className={`record-audio-modal__text ${recording ? 'active' : ''}`}>
                    <span className={recording ? 'active' : 'no-active'}>{recording ? 'Recording...' : 'Press start to record'}</span>
                </div>
                <div className="record-audio-modal__time">
                    {mins}:{secs}/03:00
                </div>
                <button className={`btn btn-brand btn-record ${recording ? 'active' : ''}`} onClick={toggleRecording}>
                    <span className="start">START</span>
                    <span className="finish">FINISH</span>            
                </button>
            </div>
        </div>
    )
}

export default RecordAudioModal;