import React, { useState, useEffect, useRef } from 'react';
import { getResourceDescription } from '../../utils/resourceUtils';
import EmptyView from '../common/EmptyView';
import TaskModal from '../tasks/TaskModal';
import EventModal from '../events/EventModal';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as taskActions from '../../actions/taskActions';
import * as eventActions from '../../actions/eventActions';
import swal from 'sweetalert';
import { checkSubscription } from '../../utils/artistUtils';

function ManageLinksModal({user, resource, actions, showModal}) {
    const activeProfile = user.activeProfile;
    const [deletingLink, setDeletingLink] = useState(false);
    const items = activeProfile.Tasks.filter(t => t.ResourceIDs.includes(resource.ID))
        .map(t => ({ ID: t.ID, Type: 'Task', Name: t.Name }))
        .concat(activeProfile.Events
            .filter(e => e.ResourceIDs.includes(resource.ID))
            .map(e => ({ ID: e.ID, Type: 'Event', Name: e.Name })))
        .sort((a, b) => a.Name - b.Name);
        
    const deleteResourceLink = (event, item) => {
        event.preventDefault();
        event.stopPropagation();
        checkSubscription(activeProfile, () => {
            swal({
                title: 'Please confirm',
                text: 'Are you sure you want to delete this link?',
                buttons: true,
                dangerMode: true,
            })
            .then(async (willDelete) => {
                if (willDelete) {
                    setDeletingLink(true);
                    const action = item.Type === 'Task' ? actions.deleteTaskResourceLink : actions.deleteEventResourceLink;
                    action(activeProfile.ID, item.ID, resource.ID)
                        .then(result => setDeletingLink(false))
                        .catch(error => setDeletingLink(false));
                }
            })
        }, 'delete this link');        
    }

    const showItem = (event, item) => {
        event.preventDefault();
        if(item.Type === 'Task') {
            const taskModal = <TaskModal taskID={item.ID} viewMode={true} />
            showModal(() => taskModal, { isOpen: true })
        }
        else {
            const eventModal = <EventModal eventID={item.ID} viewMode={true} />
            showModal(() => eventModal, { isOpen: true })
        }
    }

    const resourceDescription = getResourceDescription(resource);

    return (  
        <div className="modal-dialog modal-lg modal-dialog--add-link">
             {
                deletingLink ?
                    <div className="modal-window__loader modal-window__loader-task">
                        <img src={require('../../images/loader.svg')} alt="" />
                        Deleting Link
                    </div>
                    : null
            }      
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">Manage links</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i className="mikeicon-x" />
                    </button>
                </div>
                <div className="modal-body">
                    <div className="manage-link__title">{resourceDescription.substring(0, 1).toUpperCase() + resourceDescription.substring(1)} - {resource.Name}</div>
                    {
                        items.length ?
                            items.map(item => 
                                <a key={item.ID} href="#" onClick={(event) => showItem(event, item)} className="list-row list-row-link task-links-item">
                                    <div className="links-item__title">
                                        <p>{item.Type} - {item.Name}</p>
                                    </div>
                                    <div className="list-links--delete" onClick={(event) => deleteResourceLink(event, item)}>
                                        <img src={require('../../images/icon-goals-delete.png')} alt="" className="" /> 
                                    </div>
                                </a>
                            )
                            :
                            <EmptyView text="There are no tasks or events linked to this item" />
                    }                       
                </div> 
            </div>
        </div>      
    );
}

function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //actions will now be available under props.actions
        actions: bindActionCreators(Object.assign({}, taskActions, eventActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageLinksModal);