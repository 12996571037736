import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import serviceMiddleware from '../middleware/serviceMiddleware';
import tokenRefreshMiddleware from '../middleware/tokenRefreshMiddleware';
import localStorageMiddleware from '../middleware/localStorageMiddleware';
import createRootReducer from '../reducers';

export const history = createBrowserHistory();

export default function configureStore(initialState) {
    const windowIfDefined = typeof window === 'undefined' ? null : window;
    const composeEnhancers = (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
    const myServiceMiddleware = serviceMiddleware();
    const myTokenRefreshMiddleware = tokenRefreshMiddleware();
    const myLocalStorageMiddleware = localStorageMiddleware();
    const store = createStore(
        createRootReducer(history),
        initialState,
        composeEnhancers(
            applyMiddleware(myTokenRefreshMiddleware, thunk, reduxImmutableStateInvariant(), routerMiddleware(history), myServiceMiddleware, myLocalStorageMiddleware)
        ));

    // Enable Webpack hot module replacement for reducers
    if (module.hot) {
        module.hot.accept('../reducers', () => {
            store.replaceReducer(createRootReducer(history));
        });
    }

    return store;
}