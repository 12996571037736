import React, { useEffect, useRef } from 'react';
import { formatNumber } from '../../utils/financeUtils';
import _ from 'lodash';
import Chart from 'chart.js';
import { pluralise } from '../../utils/textUtils';

function SocialMetricCard({title, legendColour, chartColour, chartData, previousChartData, cumulative, period}) {
    const chartRef = useRef();
    const chartDataKeys = Object.keys(chartData);
    let current = 0;
    let previous = 0;
    let chartTimeUnit = 'day';

    if(chartDataKeys.length) {
        let earliestItem;
        let latestItem;
        chartDataKeys.forEach(key => {
            const dataItem = chartData[key];
            if(!earliestItem || dataItem.x.isBefore(earliestItem.x)) {
                earliestItem = dataItem;
            }
            if(!latestItem || dataItem.x.isAfter(latestItem.x)) {
                latestItem = dataItem;
            }
            if(cumulative) {
                current += dataItem.y;
            }
        });
        if(!cumulative) {
            current = latestItem.y;
        }
        previous = earliestItem.y;

        if(earliestItem && latestItem) {
            //if our data spans over a month, use month, if it's over a week, use week, else use day
            const daysDiff = latestItem.x.diff(earliestItem.x, 'day');
            if(daysDiff > 30) {
                chartTimeUnit = 'month';                
            } 
            else if(daysDiff > 7) {
                chartTimeUnit = 'week';
            }
            else {
                chartTimeUnit = 'day';
            }
        }
    }

    let difference = 0;
    let differenceAbs = 0;
    let percentageDifference = 0;

    if(cumulative) {
        //if the data is cumulative, the current will be the sum of how much we added during this period. Calculate the total for the previous period 
        //and compare them to calculate the difference
        if(previousChartData) {
            const chartPreviousDataKeys = Object.keys(previousChartData);
            let previousTotal = 0;
            if(chartPreviousDataKeys.length) {
                chartPreviousDataKeys.forEach(key => {
                    const dataItem = previousChartData[key];
                    previousTotal += dataItem.y;
                })
                difference = current - previousTotal;
                differenceAbs = Math.abs(difference);
                if(current && previousTotal) {
                    percentageDifference = _.round(((current - previousTotal) / previousTotal) * 100, 1);
                }
                else if (!previousTotal) {
                    //we have a value for this period but previously it was zero, set the difference to 100%
                    percentageDifference = 100;
                }
                else if(!current) {
                    //we have a value for previous period but now it's zero, set the difference to -100%
                    percentageDifference = -100;
                }
            }
        }
    }
    else {
        //if the data isn't cumulative (it's the total), show the difference as the difference between the value at the start of the period and now
        if(previous != null) { //use a single equals so we can also match to undefined
            difference = current - previous;
            differenceAbs = Math.abs(difference);
            //calculate the percentage change during this period
            if(current && previous) {
                percentageDifference = _.round((difference / previous) * 100, 1);
            }
            else if (!previous) {
                //we have a value for the end of this period but at the start it was zero, set the difference to 100%
                percentageDifference = 100;
            }
            else if(!current) {
                //we have a value for the start of this period but now it's zero, set the difference to -100%
                percentageDifference = -100;
            }
        }        
    }
    
    const divClassName = `social-page__item-changes ${(difference < 0 && !cumulative) || percentageDifference < 0  ? 'social-page__item-changes--red' : ''}`;
    const imageSrc = difference < 0 ? require('../../images/triangle-red.svg') : require('../../images/triangle.svg');
    const imageClassName = `top-green-arrow ${difference < 0 ? 'top-red-arrow' : ''}`;

    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');      
        const labels = chartDataKeys.reverse();
        const data = Object.values(chartData).reverse();
        const chart = new Chart(ctx, {
            type: 'line',
            // The data for our dataset
            data: {
                labels: labels,
                datasets: [{
                    backgroundColor: false,
                    label: title,
                    lineTension: 0,
                    borderColor: chartColour,
                    data: data,
                    pointRadius: 1,
                    pointStyle: 'circle',
                    pointBorderColor: chartColour,
                    pointBackgroundColor: chartColour,
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: chartColour,
                    pointHoverBorderColor: "#aaaaaa",
                    pointHoverBorderWidth: 1,
                    pointHitRadius: 5
                }]
            },        
            options: {
                legend: {
                    display: false,
                    labels: {
                        usePointStyle: true
                    }
                },
                scales: {
                    xAxes: [{
                        type: 'time',
                        distribution: 'linear',
                        time: {
                            unit: chartTimeUnit
                        }
                    }],
                    yAxes: [{
                        type: 'linear',
                        ticks: {
                            precision: 0
                        }
                    }]
                },
                tooltips: {
                    callbacks: {
                        title: (tooltipItem, data) => {
                            return labels[tooltipItem[0].index];
                        },
                        label: (tooltipItem, data) => {
                            return tooltipItem.value;
                        }
                    }
                }
            }        
        });
        return () => {
            if(chart) { 
                chart.destroy();
            }
        }         
    }, [chartColour, chartData, chartTimeUnit]);

    return (
        <div className="social-page__item">
            <div className="social-page__item-content">
                <div className="social-page__item-header">
                    {`${!cumulative ? 'TOTAL ' :'NEW '}${title}`}
                </div>
                <div className="social-page__item-value-wrapper">
                    <div className="social-page__item-value">
                        {formatNumber(current)}
                        {
                            difference ?
                                <div className={divClassName}>
                                    {
                                        /*if it's not cumulative and the percent difference is zero, we don't want to show an arrow */
                                        !cumulative || percentageDifference ?
                                            <img src={imageSrc} alt="" className={imageClassName} /> 
                                            : null
                                    }
                                      
                                    {
                                        !cumulative ?
                                            <React.Fragment>
                                                {`${formatNumber(differenceAbs)} ${difference > 0 ? 'new' : 'lost'} ${pluralise(_.trimEnd(title.toLowerCase(), 's'), differenceAbs)}`}
                                                <div className="post-stat-tooltipe">
                                                    {`${percentageDifference ? (`${Math.abs(percentageDifference.toLocaleString())}% ${percentageDifference > 0 ? 'increase' : 'decrease'}`) : 'No change'} during the last ${period}`}
                                                </div>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                {`${percentageDifference ? (`${Math.abs(percentageDifference).toLocaleString()}%`) : 'No change'}`}                                          
                                                <div className="post-stat-tooltipe">
                                                    {`${percentageDifference ? (`${Math.abs(percentageDifference).toLocaleString()}% ${percentageDifference > 0 ? 'increase' : 'decrease'}`) : 'No change'} compared to the previous ${period}`}
                                                </div>
                                            </React.Fragment>
                                    }
                                    
                                </div>
                                : null
                        }                       
                    </div>
                </div>
                <div className="social-page__item-legend">
                    <div className={`legend-icon legend-icon--${legendColour}`} />
                    <div className="legend-title">
                        {title.toUpperCase()}
                    </div>
                </div>
                <canvas ref={chartRef} />                            
            </div>
        </div>
    )
}

export default SocialMetricCard;