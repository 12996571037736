export const timeOfDays = [{
        name: 'morning',
        isCurrent: (hour) => hour >= 4 && hour < 12
    }, {
        name: 'afternoon',
        isCurrent: (hour) => hour >= 12 && hour < 17
    }, {
        name: 'evening',
        isCurrent: (hour) => hour >= 17 && hour < 21
    }, {
        name: 'night',
        isCurrent: (hour) => hour >= 21 || hour < 4
}];

export const welcomeGreetings = {
    morning: 'Good morning',
    afternoon: 'Good afternoon',
    evening: 'Good evening',
    night: 'Hey'
};

export const messageTags = [{
        name: 'earlyMorning',
        isCurrent: (hour) => hour >= 4 && hour < 9
    }, {
        name: 'morning',
        isCurrent: (hour) => hour >= 4 && hour < 12
    }, {
        name: 'afternoon',
        isCurrent: (hour) => hour >= 12 && hour < 17
    }, {
        name: 'lateAfternoon',
        isCurrent: (hour) => hour >= 15 && hour < 17
    }, {
        name: 'evening',
        isCurrent: (hour) => hour >= 17 && hour < 21
    }, {
        name: 'night',
        isCurrent: (hour) => hour >= 21 || hour < 4
    }, {
        name: 'lateNight',
        isCurrent: (hour) => hour >= 23 || hour < 4
}];


export const welcomeMessages = {
    earlyMorning: [
        'Rise and shine! Here\'s a warm cup of inspiration to fuel your morning:',
        'Rise and shine! Here\'s your daily dose of inspiration:'
    ],
    morning: [
        'With a new day comes new inspiration!',
        'Here\'s some motivation to start your day off right:',
        'Here\'s some inspiration to kick off your day in style:'
    ],
    afternoon: [
        'Need some inspiration? Here\'s an afternoon snack:',
        'You already crushed the morning, but you\'re just getting started.<br />Here\'s some inspiration to keep you going:',
        'Here\'s a little rocket fuel to propel you through the afternoon:',
        'Need an energy boost? Here\'s a shot of inspiration:'
    ],
    lateAfternoon: [
        'Need an afternoon pick-me-up? Here\'s some inspiration:',
        'Here\'s some inspiration to tide you over \'til dinner:',
    ],
    evening: [
        'Here\'s some inspiration to get you through the home stretch:',
        'You\'ve already made it this far, finish the day strong.<br />Here\'s some inspiration to get you there:',
        'Here\'s some motivation to carry you through the final stretch:',
        'Need some inspiration? Here\'s a log to throw on the fire:',
        'Finishing up a project? Here\'s some inspiration to power you through:',
        'You\'re almost through the day! Here\'s some motivation to get you to the finish line:'
    ],
    night: [
        'Still at it? Don\'t forget your work will be right where you left it tomorrow.',
        'Ready to wind things down? Relaxation is a great way to decompress from the day',        
        'Stuck in the middle of a project? Don\'t worry, we\'ll save your place for you.',
    ],
    lateNight: [
        'Stuck on an idea that\'s keeping you up? Sleeping on it might give you the fresh perspective you need.',
        'Burning the midnight oil? Here\'s some inspiration to fuel the flame:',
        'Working on something that just can\'t wait till tomorrow?<br />Here\'s some motivation to power you through the night:',        
        'Burning the midnight oil? It\'s ok to turn off the light and pick back up tomorrow.',        
        'Pulling a late one? Night owls need their rest too!'
    ]
}

export const taskCompletedSuccessMessages = [
  'Job well done!',
  'Keep at it!',
  'Great job!',
  'Nice one!',
  'Rock on!',
  'Rock \'n roll!',
  'You\'re killing it!',
  'Well played!',
  'Nicely done!',
  'Hell of a job!',
  'Damn good job!',
  'Solid work!',
  'Couldn\'t have done it better myself',
  'I\'m impressed!',
  'You make it look easy!'  
];

export const taskCompletedMilestoneMessage = [{
    Count: 1,
    Messages: ['1st task complete. Now rinse and repeat!'],
}, {
    Count: 5,
    Messages: ['Congrats on completing 5 tasks! That wasn\'t so bad, was it?'],
}, {
    Count: 10,
    Messages: ['Congrats on completing 10 tasks! Keep the momentum going!'],
}, {
    Count: 25,
    Messages: ['Congrats on completing 25 tasks! I can tell you\'re in this for the long haul.'],
}, {
    Count: 50,
    Messages: ['Congratulations on completing 50 tasks! Next stop, 100.'],
}, {
    Count: 100,
    Messages: ['Congratulations on completing 100 tasks! You\'re practically unstoppable now!'],
}, {
    Count: 150,
    Messages: ['Congrats on completing 150 tasks! You made that look easy!'],
}, {
    Count: 200,
    Messages: ['You\'ve now completed 200 tasks. Hey, you\'re not messing around!'],
}, {
    Count: 250,
    Messages: ['Congratulations on completing 250 tasks! You are invincible.'],
}, {
    Count: 500,
    Messages: ['You\'ve now completed 500 tasks! Tell us what your secret is?'],
} ];


//Here's a healthy serving of inspiration. Just don't forget your actual dinner.

export const noPendingTasksMessages = ['No pending tasks at the moment', 'You don\'t have any pending tasks, but why not set some new ones?'];
export const noCompletedTasksMessages = ['You haven\'t completed any tasks. Take some action to start moving towards your goals.', 'You haven\'t completed any tasks. Take some action now to experience the satisfaction that comes with a job well done!']
export const noUpcomingEventsMessages = ['No upcoming events scheduled. Keep an eye out for new opportunities!', 'You don\'t have any upcoming events, but now\'s a great time to line up some new ones.'];
export const noTasksDueTodayMessages = ['You\'re all caught up! No tasks due today.', 'You don\'t have any tasks due today, but why not set some new ones?'];
export const noProjectsMessages = ['You don\'t have any projects at the moment. Get in there and start some new ones!', 'You don\'t have any active projects right now, but why not take on some new ones?'];
export const noActiveGoalsMessages = ['You don\'t have any active goals. Get in there and create some new ones!', 'You don\'t have any active goals, but it\'s always a good idea to set some. "Future you" will thank you!']
export const noCompletedGoalsMessages = ['You don\'t have any completed goals. One by one gets it all done!', 'You don\'t have any completed goals. Start by checking off a smaller task and build from there.', 'You don\'t have any completed goals, but one action step right now puts you on the path to achieving them.', 'You don\'t have any completed goals. Check a smaller task off the list now and I promise you\'ll feel better after!' ,'You don\'t have any completed goals. Keep on top of your tasks to start inching closer!']
export const noUpcomingGigsMessages = ['No gigs scheduled. Time to start pounding the pavement!'];
export const noUpcomingRehearsalsMessages = ['No rehearsals scheduled, but don\'t forget to get some solo practice time.'];
export const noUpcomingMeetingsMessages = ['No meetings scheduled, but now\'s a great time to get something on the books.'];
export const noUpcomingOtherEventsMessages = ['You don\'t have anything scheduled. Keep an eye out for new opportunities!'];
export const noPastEventsMessages = ['You don\'t have any past events, but the future is bright!'];

export const projectsPageHeaders = ['here are your projects at a glance:', 'here are all the exciting projects you\'re working on:'];
export const goalsActivePageHeaders = ['here are your current goals. Keep at it!', 'here are your current goals. You got this!']
export const goalsCompletedPageHeaders = ['here are your completed goals:']
export const eventsUpcomingPageHeaders = ['here are your upcoming events:'];
export const eventsPastPageHeaders = ['here are your past events:'];
export const financePageHeaders = ['here is a list of your income and expenses:'];
export const socialPageHeaders = ['here are your social profiles:'];
export const tasksPendingPageHeaders = ['here are your pending tasks:'];
export const tasksCompletedPageHeaders = ['here are your completed tasks:'];
export const vaultPageHeaders = ['welcome, you have now entered the Vault!'];

