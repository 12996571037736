import _ from 'lodash';

export function formatNumber(number) {
    if(number != null) {
        if(Number.isInteger(number)) {
            return _.round(number, 2).toLocaleString();
        }
        else {
            return _.round(number, 2).toFixed(2).toLocaleString();
        }
    }
    else {
        return '-';
    }
}

export function formatBalance(artist, date) {
    const currentBalance = getBalanceForDate(artist, date);
    if (currentBalance < 10000) {
        return formatNumber(currentBalance);
    }
    else {
        if (currentBalance < 1000000) {
            return _.round((currentBalance / 1000), 1) + 'k';
        }
        else {
            return _.round((currentBalance / 1000000), 1) + 'm';
        }
    }
}

export function getBalanceForDate(artist, date) {
    const balances = artist.Finances.Balances;
    let startingBalance = 0;
    let balanceStartDate = null;
    let balanceTimestamp = null;
    if (balances.length > 0) {
        //get the nearest balance item to the date
        const nearestBalances = balances.filter(b => b.Date <= date).sort((a, b) => {
            //sort by date descending 
            let diff = b.Date - a.Date;
            if(diff === 0) {
                //if the dates are equal, sort by timestamp descending
                diff = b.Timestamp - a.Timestamp;
            }
            return diff;
        })
        if (nearestBalances.length) {
            const balanceItem = nearestBalances[0];
            startingBalance = balanceItem.Amount;
            balanceStartDate = new Date(balanceItem.Date); //clone the date as otherwise, when we call set hours, it mutates the original balance object in state
            balanceStartDate.setHours(0, 0, 0, 0);
            balanceTimestamp = balanceItem.Timestamp;
        }
    }
    const incomeToAdd = artist.Finances.Income.filter(i => (!balanceStartDate || i.Date >= balanceStartDate) && (!balanceTimestamp || i.Timestamp >= balanceTimestamp) && i.Date <= date);
    incomeToAdd.forEach(income => startingBalance += income.Amount);
    const expensesToAdd = artist.Finances.Expenses.filter(e => (!balanceStartDate || e.Date >= balanceStartDate) && (!balanceTimestamp || e.Timestamp >= balanceTimestamp) && e.Date <= date)
    expensesToAdd.forEach(expense => startingBalance -= expense.Amount);
    return startingBalance;
}

export function getFinanceTypeIcon(itemType, type) {
    if(itemType === 'Income') {
        switch(type) {
            case 'MerchSales':
                return require('../images/icon-finances-merchandise.png');
            case 'MusicSales':
                return require('../images/icon-finances-music.png');
            case 'Royalties':
                return require('../images/icon-finances-royalty.png');
            case 'GigIncome':
                return require('../images/icon-finances-tickets.png');
            case 'Crowdfunding':
                return require('../images/icon-finances-crowdfunding.png');
            case 'Donation':
                return require('../images/icon-finances-donation.png');
            case 'SessionWork':
                return require('../images/icon-finances-session-work.png');
            case 'Tips':
                return require('../images/icon-finances-tips.png');
            case 'Other':
                default:
                return require('../images/icon-finances-other.png');
        }
    }
    else if(itemType === 'Expense' || itemType === 'Expenses') {
        switch(type) {
            case 'VenueBooking':
                return require('../images/icon-finances-venue.png');
            case 'FoodDrink':
                return require('../images/icon-finances-drinks.png');
            case 'Accommodation':
                return require('../images/icon-finances-accommodation.png');
            case 'Distribution':
                return require('../images/icon-finances-distribution.png');
            case 'Equipment':
                return require('../images/icon-finances-equipment.png');
            case 'LegalFees':
                return require('../images/icon-finances-legalfees.png');
            case 'Marketing':
                return require('../images/icon-finances-marketing.png');
            case 'Merch':
                return require('../images/icon-finances-merchandise2.png');
            case 'MixingMastering':
                return require('../images/icon-finances-mixing.png');
            case 'Payout':
                return require('../images/icon-finances-payout.png');
            case 'PhotoVideo':
                return require('../images/icon-finances-photovideo.png');
            case 'PR':
                return require('../images/icon-finances-pr.png');
            case 'Promotion':
                return require('../images/icon-finances-promotion.png');
            case 'SessionMusicians':
                return require('../images/icon-finances-session-musicians.png');
            case 'Stationary':
                return require('../images/icon-finances-studiotime.png');
            case 'StudioTime':
                return require('../images/icon-finances-promotion.png');
            case 'Styling':
                return require('../images/icon-finances-styling.png');
            case 'Transportation':
                return require('../images/icon-finances-transportation.png');
            case 'Other':
            default:
                return require('../images/icon-finances-other2.png');
        }
    }	
    else {
        return require('../images/icon-finances-other.png');
    }
}