import ProjectService from '../services/projectService';
import ToastrService from '../services/toastrService';
import * as types from './actionTypes';
import * as processUtils from '../utils/processUtils';
import * as actionUtils from '../utils/actionUtils';
import { normaliseProject } from '../utils/normaliseUtils';
import { deleteArrayItem } from '../reducers/reducerUtils';

export function addProject(artistID, project) {
    return function(dispatch, getState) {
        const toastrService = new ToastrService();
        return ProjectService.createProject(artistID, project).then(project => {
            normaliseProject(project);
            dispatch({ type: types.PROJECT_CREATE_SUCCESS, payload: { artistID, project } });
            toastrService.showSuccess('', 'Project has been created!')
            return project;
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function editProject(artistID, project) {
    return function(dispatch, getState) {
        const toastrService = new ToastrService();
        return ProjectService.editProject(artistID, project).then(async project => {            
            normaliseProject(project);
            dispatch({ type: types.PROJECT_UPDATE_SUCCESS, payload: { artistID, project } });
            toastrService.showSuccess('', 'Project has been updated!');
        }).catch(error => {
            if(error.statusCode === 409) {
                toastrService.showError('', 'The project has already been updated elsewhere, please refresh the page to reload it and try again');
            }
            else {
                actionUtils.handleError(error);
            }
        });
    };
}

export function deleteProject(artistID, projectID, deleteTasks) {
    return function(dispatch) {
        const toastrService = new ToastrService();
        return ProjectService.deleteProject(artistID, projectID, deleteTasks).then(_ => {
            dispatch({ type: types.PROJECT_DELETE_SUCCESS, payload: { artistID, projectID, deleteTasks } });
            toastrService.showSuccess('', 'Project has been deleted!');
        }).catch(error => {
            actionUtils.handleError(error);
        });
    };
}