import moment from 'moment';
import * as dateUtils from './dateUtils';

export function getDueDateText(task) {
    const momentDueDate = moment(task.DueDate).startOf('day');
    const momentToday = moment().startOf('day');
    const daysDiff = momentDueDate.diff(momentToday, 'days');
    let dueDateText;
    if (daysDiff > 7) {
        dueDateText = dateUtils.getShortDate(momentDueDate.toDate());
    }
    else {
        if (daysDiff < 0) {
            dueDateText = task.Status === 'Pending' ? "OVERDUE" : dateUtils.getShortDate(momentDueDate.toDate());
        }
        else if (daysDiff === 0) {
            dueDateText = 'TODAY';
        }
        else if (daysDiff === 1) {
            dueDateText = 'TOMORROW';
        }
        else {
            dueDateText = `IN ${daysDiff} DAYS`;
        }
    }
    return { dueDateText, daysDiff };
}