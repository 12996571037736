import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as resourceActions from '../../actions/resourceActions';
import FinaliseSave from './FinaliseSave';
import { useFormInput } from '../../utils/customHooks';
import ToastrService from '../../services/toastrService';
import { getShortDate } from '../../utils/dateUtils';
import NoteViewContent from './NoteViewContent';
import { checkSubscription } from '../../utils/artistUtils';
import AnalyticsService from '../../services/analyticsService';

function NoteModal({item, user, actions, allTags, viewMode = false}) {
    const activeProfile = user.activeProfile;
    const [savingNote, setSavingNote] = useState(false);
    const [proceed, setProceed] = useState(false);
    const [text, setText] = useState(item ? item.Text : '');
    const [currentDictation, setCurrentDictation] = useState('');
    const [dictating, setDictating] = useState(false);
    const [isViewMode, setIsViewMode] = useState(viewMode);
    const isEdit = item && !isViewMode;
    const speechSupported = 'webkitSpeechRecognition' in window;

    const noteRef = useRef();
    const finaliseRef = useRef();
    const textRef = useRef();

    useEffect(() => {
        if(textRef.current) {
            setTimeout(() => {
                textRef.current.focus();                
            }, 500);
        }
    }, [isEdit]);

    useEffect(() => {
        if(proceed) { 
            window.$(noteRef.current).slideUp();
            window.$(finaliseRef.current).slideDown();
        }
        else {            
            window.$(noteRef.current).slideDown();
            window.$(finaliseRef.current).slideUp();
        }
    }, [proceed]);

    const speechRecognitionRef = useRef();

    useEffect(() => {
        let eventName = isEdit ? 'Edit Vault Note Launched' : 'Add Vault Note Launched';
        AnalyticsService.logEvent(eventName);
    }, []);

    const finaliseCancelled = (event) => {
        if(event) {
            event.preventDefault();
        }
        setProceed(false);
    }

    const validate = () => {
        const toastrService = new ToastrService();
        if(!text) {
            toastrService.showError('', 'Looks like you forgot to enter the text');
            return false;
        }
        return true;
    }

    const getItem = (event) => {
         return {
            ID: item ? item.ID : null,
            Type: 'Text',
            Text: text,
            Version: item ? item.Version : 0
        }
    }

    const editNote = () => {
        if(isViewMode) {
            setIsViewMode(false);
        }
        else {
            if(validate()) {
                checkSubscription(activeProfile, () => setProceed(true), 'edit a note');
            }
        }
    }

    const startDictation = () => {
        if(speechSupported) {
            setDictating(true);
            if(speechSupported && !speechRecognitionRef.current) {
                initialiseRecognition();
            }
            speechRecognitionRef.current.onresult = (event) => {
                let newDictation = '';
                for (let i = 0; i < event.results.length; i++) { //event.resultIndex
                    newDictation += event.results[i][0].transcript;
                    console.log(i + ' - ' + event.results[i][0].transcript);
                }   
                setCurrentDictation(currentDictation + newDictation);
            };   
            speechRecognitionRef.current.onend = () => {
                debugger;
                setCurrentDictation('');
            } 
            speechRecognitionRef.current.start();
        }
    }

    const initialiseRecognition = () => {
        const recognition = new window.webkitSpeechRecognition();
        recognition.continuous = true;
        recognition.interimResults = true;          

        recognition.onerror = (evt) => {
            const toastrService = new ToastrService();
            if (evt.error === 'not-allowed') {
                toastrService.showError('', 'Dictation failed, it looks like we don\'t have the required permissions');
            } 
            else {
                toastrService.showError('', `Sorry, something went wrong - ${evt.error}`);
            }
        }            
        speechRecognitionRef.current = recognition;
    }

    const stopDictation = () => {        
        setDictating(false);
        setText(text + currentDictation);
        speechRecognitionRef.current.stop();
        AnalyticsService.logEvent('Completed Dictation');
    }

    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

    return ( 
        <div className="modal-dialog modal-lg modal-dialog--view-task">
            {
                savingNote ?
                    <div className="modal-window__loader modal-window__loader-task">
                        <img src={require('../../images/loader.svg')} alt="" />
                        {                            
                            isEdit ? 
                                'Saving note' 
                                : 
                                'Creating note'
                        }
                    </div>
                    : null
            }            
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">{isEdit ? 'Edit Note' : (isViewMode ? 'View Note' : 'New Note')}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i className="mikeicon-x" />
                    </button>
                </div>
                <div className="modal-body">
                    <div className="note-content">
                        <form className="form form--lined form--inputbg">
                            <div className="note-description-wrapper" ref={noteRef}>
                                {
                                    isViewMode ?
                                        <NoteViewContent item={item} />
                                        :
                                        <div className="form-group">
                                            <label>Note text</label>
                                            <textarea className="form-control form-area" placeholder="Start typing your note..." value={text + (dictating ? currentDictation : '')} onChange={(event) => setText(event.target.value)} ref={textRef} />
                                            <button type="button" className="btn bth--transparent dictate-note" onMouseDown={startDictation} onMouseUp={stopDictation} disabled={!speechSupported}>
                                                <span className="active">SPEAK NOW...</span>
                                                <span className="no-active">DICTATE NOTE</span>
                                                {
                                                    speechSupported ? 
                                                        <img src={require('../../images/vault-icon-record-small.png')} alt="" />
                                                        : 
                                                        <img className="no-active" src={require('../../images/mute.svg')} alt="" />
                                                }                                               
                                            </button>
                                            {
                                                !speechSupported ?
                                                    <div class="dictate-note__prompt">
                                                        {`Sorry, your browser doesn't support speech recognition${!isChrome ? ', please try using Google Chrome': ''}`}
                                                    </div>
                                                    : null
                                            }
                                        </div>   
                                }
                                <div className="form-group text-center mt-4 modal-buttons">
                                    <button type="button" className="btn btn-outline btn-wide m-1" data-dismiss="modal">Cancel</button>
                                    <button type="button" className="btn btn-primary btn-wide m-1 add-note-button" onClick={editNote}>{isViewMode ? 'Edit Note' : (item ? 'Save Note' : 'Create Note')}</button>
                                </div>
                            </div>
                            <FinaliseSave 
                                onCancel={finaliseCancelled} 
                                finaliseRef={finaliseRef}
                                user={user}
                                name={item ? item.Name : ''}
                                isPublic={item ? item.Visibility === 'Public' : true}
                                itemTags={item ? item.Tags : []}
                                allTags={allTags}
                                actions={actions}
                                getItem={getItem}
                                setSaving={setSavingNote} />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user
    };
}


function mapDispatchToProps(dispatch) {
    return {
        //actions will now be available under props.actions
        actions: bindActionCreators(Object.assign({}, resourceActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NoteModal);