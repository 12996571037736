import React from 'react';
import PropTypes from 'prop-types';
import Loading from './Loading';

const PageLoading = () => { 
    return (
        <div className="loading">
            <div className="loader">
                <div className="ball one"><div className="inner"></div></div>
                <div className="ball two"><div className="inner"></div></div>
                <div className="ball three"><div className="inner"></div></div>
                <div className="ball four"><div className="inner"></div></div>
                <div className="ball five"><div className="inner"></div></div>
                <div className="ball six"><div className="inner"></div></div>
                <div className="ball center"><div className="inner"></div></div>
            </div>
            <div className="loading-text">
                Loading...
            </div>
        </div>
    );
};

export default PageLoading;
