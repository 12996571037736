import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as accountActions from '../../actions/accountActions';
import * as goalActions from '../../actions/goalActions';
import '../../styles/style-ui.scss';
import Header from '../common/Header';
import * as goalUtils from '../../utils/goalUtils';
import { goalTypes } from '../../constants/types';
import Footer from '../common/Footer';
import * as currencyActions from '../../actions/currencyActions';
import { withModalContext } from '../../services/modalService';
import GoalModal from './GoalModal';
import EmptyView from '../common/EmptyView';
import swal from 'sweetalert';
import { pluralise } from '../../utils/textUtils';
import moment from 'moment';
import { checkSubscription } from '../../utils/artistUtils';
import ArtistNameHeader from '../common/ArtistNameHeader';
import { goalsActivePageHeaders, goalsCompletedPageHeaders, noActiveGoalsMessages, noCompletedGoalsMessages } from '../../constants/appMessages';
import _ from 'lodash';

function GoalsPage(props) {   

    const [selectedTab, setSelectedTab] = useState('Active');    
    const headerActiveText = useRef(_.sample(goalsActivePageHeaders)).current;
    const headerCompletedText = useRef(_.sample(goalsCompletedPageHeaders)).current;
    const headerText = selectedTab === 'Active' ? headerActiveText : headerCompletedText;
    const noActiveGoalsMessage = useRef(_.sample(noActiveGoalsMessages)).current;
    const noCompletedGoalsMessage = useRef(_.sample(noCompletedGoalsMessages)).current;
    const emptyText = selectedTab === 'Active' ? noActiveGoalsMessage : noCompletedGoalsMessage;

    useEffect(() => {
        if(!props.currencies.length) {
            props.actions.getCurrencies();
        }
    }, []);

    const updateSelectedTab = (tab) => {
        setSelectedTab(tab);
    }

    //initialise goals
    const activeProfile = props.user.activeProfile;
    const goals = activeProfile.Goals.map(g => {
        const goal = {...g};
        goalUtils.addGoalCalculatedFields(goal);
        return goal;
    })
    .filter(goal => selectedTab === 'Active' ? goal.Percentage < 100 : goal.Percentage >= 100)
    .sort((a, b) => selectedTab === 'Active' ? a.TargetDate - b.TargetDate : b.TargetDate - a.TargetDate);

    const currency = props.currencies.find(c => c.ID === activeProfile.DefaultCurrencyID);

    const getActiveClass = (tabName) => {
        if(selectedTab === tabName) {
            return 'active';
        }
        return '';
    }

    const createNewGoal = (event) => {
        event.preventDefault();
        event.stopPropagation();
        checkSubscription(activeProfile, () => {
            const goalModal = <GoalModal />
            props.showModal(() => goalModal, { isOpen: true })
        }, 'create a goal');        
    }

    const editGoal = (event, goal) => {
        event.preventDefault();
        event.stopPropagation();
        checkSubscription(activeProfile, () => {
            const goalModal = <GoalModal goal={goal} />
            props.showModal(() => goalModal, { isOpen: true })
        }, 'edit a goal');        
    }

    const deleteGoal = (event, goal) => {
        event.preventDefault();        
        event.stopPropagation();
        checkSubscription(activeProfile, () => {
            swal({
                title: 'Are you sure?',
                text: 'Once deleted, you will not be able to recover this goal!',
                buttons: true,
                dangerMode: true,
              })
              .then((willDelete) => {
                if (willDelete) {
                    props.actions.deleteGoal(activeProfile.ID, goal.ID);
                }
            });
        }, 'delete a goal');        
    }

    const viewGoal = (event, goal) => {
        event.preventDefault();
        const goalModal = <GoalModal goal={goal} viewMode={true} />
        props.showModal(() => goalModal, { isOpen: true })
    }

    const getGoalIcon = (goal) => {
        if(goal.Type === 'TwitterFollowers') {
            return require('../../images/icon-goals-twitter.png');
        }
        else if(goal.Type === 'FacebookLikes') {
            return require('../../images/icon-goals-like.png');
        }
        else if(goal.Type === 'InstagramFollowers') {
            return require('../../images/icon-goals-instagram.png');
        }
        else if(goal.Type === 'MailingList') {
            return require('../../images/icon-goals-email.png');
        }
        else if(goal.Type === 'YouTubeViews') {
            return require('../../images/icon-goals-youtube.png');
        }
        else if(goal.Type === 'Gigs') {
            return require('../../images/icon-goals-gig.png');
        }
        else if(goal.Type === 'Profit') {
            return require('../../images/icon-goals-profit.png');
        }
        else if(goal.Type === 'SoundCloudPlays') {
            return require('../../images/icon-goals-soundcloud.png');
        }
        else if(goal.Type === 'LoudUpConnections') {
            return require('../../images/icon-goals-connections.png');
        }
        return null;
    }

    const getProgressText = (goal) => {
        if (goal.Percentage >= 100) {
            return 'KILLED IT!';
        }
        else {
            const daysDiff = moment().diff(moment(goal.Timestamp), 'days');
            if (daysDiff < 1) {
                return 'EARLY DAYS...';
            }
            else if (goal.OnTrack) {
                return 'KILLING IT!';
            }
            else{
                return 'SLACKING OFF...';
            }
        }
    }

    return (        
        <div className="home">
            <Header />
            <main className="content">
                <div className="container">
                    <ArtistNameHeader user={props.user} greeting={`Hey ${props.user.FirstName}, ${headerText}`} />
                    <div className="content-body">
                        <div className="tabs-header">
                            <div className="actions float-right">
                                <a href="#" className="btn-text btn-new" onClick={createNewGoal}>
                                    <i className="mikeicon-pluscircle"></i>
                                    New Goal
                                </a>                                
                            </div>

                            <ul className="nav nav-tabs nav-tabs-simple nav-tabs-tasks" role="tablist">
                                <li className="nav-item">
                                    <a className={`nav-link ${getActiveClass('Active')}`} id="active-tab" href="#" onClick={(e => updateSelectedTab('Active'))} role="tab" aria-controls="active" aria-selected={selectedTab === 'Active'}>Active<span className="d-none d-lg-inline"> Goals</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${getActiveClass('Completed')}`} id="completed-tab" href="#" onClick={(e => updateSelectedTab('Completed'))} role="tab" aria-controls="completed" aria-selected={selectedTab === 'Completed'}>Completed<span className="d-none d-lg-inline"> Goals</span></a>
                                </li>
                            </ul>

                        </div>
                        
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="pending" role="tabpanel" aria-labelledby="pending-tab">
                                <div className="list goals-list">                                   
                                    <div className="list-body">
                                        {
                                            goals.length ?
                                                goals.map(goal => {
                                                    return <div key={goal.ID} className="list-row" onClick={(e) => viewGoal(e, goal)}>
                                                        <div className="list-col">
                                                            <p className="goal__item"><img src={getGoalIcon(goal)} alt="goal icon" />{(goalTypes[goal.Type] ? goalTypes[goal.Type].DisplayText : null) || ''}</p>
                                                            <div className="progress-wrapper">
                                                                <div className="progress-target">
                                                                    {getProgressText(goal)}
                                                                </div>
                                                                <div className="progress mt-2">                                                            
                                                                    <div className={goal.OnTrack ? 'progress-bar--green': 'progress-bar'} role="progressbar" style={{width: goal.Percentage + '%'}} aria-valuenow={goal.Percentage} aria-valuemin="0" aria-valuemax="100"><span>{goal.Current}</span></div>
                                                                </div>
                                                            </div>
                                                            <div className="goal__item-info">
                                                                <div className="target-number">
                                                                    <div className="target-number__title">
                                                                        TARGET<br />
                                                                        NUMBER
                                                                    </div>
                                                                    <div className="target-number__value">
                                                                        {
                                                                            goal.Type === 'Profit' && currency ?
                                                                                <span className="target-number__cur"> {currency.Symbol}</span>
                                                                                :null
                                                                        }                                                                        
                                                                        {goal.Target}
                                                                    </div>
                                                                </div>
                                                                {
                                                                    selectedTab === 'Active' ?
                                                                        <div className="target__days">
                                                                            <div className="target__days-title">
                                                                                Days <br />
                                                                                Left
                                                                            </div>
                                                                            <div className="target__days-value-wrapper">
                                                                                <div className="target__days-value">
                                                                                    {goal.DaysLeft}
                                                                                </div>
                                                                                <div className="target__days-text">
                                                                                    {pluralise('day', Math.abs(goal.DaysLeft))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="target__days">
                                                                            <div className="target__days-title" style={{ marginRight: 0 }}>
                                                                                <img src={require('../../images/icon_schedule_checkmark.png')} style={{ height: '18px', width: '18px', marginRight: '5px' }} />
                                                                               NICE WORK!
                                                                            </div>
                                                                        </div>
                                                                }                                                                
                                                            </div>
                                                        </div>

                                                        <div className="goals__menu">
                                                            <div className="goals__menu-item" onClick={(e) => editGoal(e, goal)}>
                                                                <img src={require('../../images/icon-goals-edit.png')} alt="Edit" onClick={(e) => editGoal(e, goal)} />
                                                            </div>
                                                            <div className="goals__menu-item delete-goal-item" onClick={(e) => deleteGoal(e, goal)}>
                                                                <img src={require('../../images/icon-goals-delete.png')} alt="Delete" onClick={(e) => deleteGoal(e, goal)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                                :
                                                <EmptyView text={emptyText} buttonText="Create goal" onButtonClick={createNewGoal} />
                                        }                                       
                                    </div>
                                </div>
                            </div>                           
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}

GoalsPage.propTypes = {
    //myProp: PropTypes.string.isRequired
};


function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user,
        currencies: state.currencies
    };
}


function mapDispatchToProps(dispatch) {
    return {
        //account actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, goalActions, currencyActions), dispatch)
    };
}

export default withModalContext(connect(mapStateToProps, mapDispatchToProps)(GoalsPage));