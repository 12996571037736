import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types';
import { useFormInput } from '../../utils/customHooks';
import ToastrService from '../../services/toastrService';
import moment from 'moment';
import 'select2/dist/js/select2';
import 'select2/dist/css/select2.min.css';
import { isValidDate, isBrowserDateTypeSupported } from '../../utils/dateUtils';
import { trim } from 'lodash';
import { checkSubscription } from '../../utils/artistUtils';
import AnalyticsService from '../../services/analyticsService';
/* global google */

function NewEditEvent({event, user, actions, setSavingEvent, googleMapsPromise, clone = false}) {
    const [type, setType] = useState(event ? event.Type : 'Gig');
    const activeProfile = user.activeProfile;
    const nameInput = useFormInput(event ? event.Name : '');
    const descriptionInput = useFormInput(event ? (event.Description || '') : '');
    const startDateInput = useFormInput(event ? event.StartDate.toISOString().split("T")[0] : '');
    const startTimeInput = useFormInput(event ? event.StartDate.toISOString().split("T")[1].substring(0, 5) : '');
    const endDateInput = useFormInput(event && event.EndDate ? event.EndDate.toISOString().split("T")[0] : '');
    const endTimeInput = useFormInput(event && event.EndDate ? event.EndDate.toISOString().split("T")[1].substring(0, 5) : '');
    const [location, setLocation] = useState(event ? event.Location : '');
    const [address, setAddress] = useState(event ? event.Address : '');
    //if it's a clone, don't copy over the cancelled status
    const [status, setStatus] = useState(event && (!clone || event.Status !== 'Cancelled') ? event.Status : '');
    const [longitude, setLongitude] = useState(event ? event.Longitude : null);
    const [latitude, setLatitude] = useState(event ? event.Latitude : null);
    const incomeInput = useFormInput(event ? event.Income : 0);    
    const noteInput = useFormInput(''); //we don't allow notes to be edited

    const getTypeClass = (t) => t === type ? 'active' : '';
    const statusRef = useRef();   
    const locationRef = useRef();
    const addressRef = useRef(); 
    const isEdit = event && !clone;

    useEffect(() => {
        if(clone) {
            const toastrService = new ToastrService();
            toastrService.showSuccess('', 'This event has been cloned from your existing event');
        }
        if(statusRef.current) {
            const statusControl = window.$(statusRef.current);
            statusControl.select2({
                theme : "classic" 
            })
            .on('select2:select', statusChanged);
            if(status) {
                statusControl.val(status).trigger('change');
            }
        }
    }, []);

    const mountedRef = useRef(true);

    const updateStartDate = (e) => {
        startDateInput.onChange(e);
        if(!endDateInput.value) {
            //set the same value on the end date
            endDateInput.onChange(e);
        }
    } 

    useEffect(() => {
        initialisePlacesAutoComplete();
        return () => mountedRef.current = false;
    }, []);

    useEffect(() => {
        let eventName = isEdit ? 'Edit Event Launched' : 'Add Event Launched';
        if(clone) {
            eventName += ' - cloning';
        }
        AnalyticsService.logEvent(eventName);
    }, []);

    const initialisePlacesAutoComplete = () => {
        const updateCallback = (placeName, address, latitude, longitude, fieldName) => {
            if(fieldName === 'Location') {
                setLocation(placeName);
            }
            setAddress(address);
            setLatitude(latitude);
            setLongitude(longitude);
        };   

        if(googleMapsPromise) {
            googleMapsPromise.then(_ => {
                const autocompleteLocation = new google.maps.places.Autocomplete(locationRef.current, {});
                google.maps.event.addListener(autocompleteLocation, 'place_changed', () => {
                    const place = autocompleteLocation.getPlace();
                    processPlace(place, 'Location', updateCallback);
                });
                const autocompleteAddress = new google.maps.places.Autocomplete(addressRef.current, {});
                google.maps.event.addListener(autocompleteAddress, 'place_changed', () => {
                    const place = autocompleteAddress.getPlace();
                    processPlace(place, 'Address', updateCallback);
                });
            });               
        }
    }

    const processPlace = (place, field, updateCallback) => {
        const componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            locality: 'long_name',
            postal_town: 'long_name',
            administrative_area_level_2: 'long_name',
            sublocality_level_1: 'long_name',
            administrative_area_level_1: 'long_name',
            country: 'long_name',
            postal_code: 'short_name'
        };
        let placeName = place.name;
        let placeStreetNumber;
        let placeRoute;
        let placeDistrict;
        let placeCity;
        let placeState;
        let placeCountry;
        let placePostalCode;
        for (let i = 0; i < place.address_components.length; i++) {
            let addressType = place.address_components[i].types[0];
            if (componentForm[addressType]) {
                let val = place.address_components[i][componentForm[addressType]];
                if (addressType === 'street_number') {
                    placeStreetNumber = val;
                }
                else if (addressType === 'route') {
                    placeRoute = val;
                }
                else if (addressType === 'sublocality_level_1' || addressType === 'administrative_area_level_2') {
                    placeDistrict = val.replace('City of London', 'London');
                }
                else if (addressType === 'locality' || addressType === 'postal_town') {
                    placeCity = val;
                }
                else if (addressType === 'administrative_area_level_1') {
                    placeState = val;
                }
                else if (addressType === 'country') {
                    placeCountry = val;
                }
                else if (addressType === 'postal_code') {
                    placePostalCode = val;
                }
            }
        }
        if (placeCity == undefined) {
            placeCity = placeDistrict;
        }
        let address = '';
        if (placeStreetNumber != undefined) {
            address = placeStreetNumber + ' ';
        }
        if (placeRoute != undefined) {
            address += placeRoute + ', ';
        }
        if (placeCity != undefined) {
            address += placeCity + ', ';
        }
        if (placeState != undefined) {
            address += placeState + ', ';
        }
        if (placeCountry != undefined) {
            address += placeCountry + ', ';
        }
        if (placePostalCode != undefined) {
            address += placePostalCode + ', ';
        }
        updateCallback(placeName, address.substring(0, address.length - 2), place.geometry.location.lat(), place.geometry.location.lng(), field);
    }

    const statusChanged = (event) => {
        const data = event.params.data;
        if(data) {     
            setStatus(data.id);
        }
    }
    const browserDateTypeSupported = isBrowserDateTypeSupported();
    
    const validateEvent = () => {
        const toastrService = new ToastrService();
        if(!trim(nameInput.value)) {
            toastrService.showError('', 'Looks like you forgot to enter the name');
            return false;
        }
        if(!startDateInput.value) {
            toastrService.showError('', 'Looks like you forgot to enter the start date');
            return false;
        }
        if(!startTimeInput.value) {
            toastrService.showError('', 'Looks like you forgot to enter the start time');
            return false;
        }
        if(!isValidDate(startDateInput.value)) {
            toastrService.showError('', `Looks like the start date isn't valid${!browserDateTypeSupported ? ', please enter the date in the format YYYY-MM-DD' : ''}`);
            return false;
        }
        if(!endDateInput.value) {
            toastrService.showError('', 'Looks like you forgot to enter the end date');
            return false;
        }
        if(!endTimeInput.value) {
            toastrService.showError('', 'Looks like you forgot to enter the end time');
            return false;
        }
        if(!isValidDate(endDateInput.value)) {
            toastrService.showError('', `Looks like the end date isn't valid${!browserDateTypeSupported ? ', please enter the date in the format YYYY-MM-DD' : ''}`);
            return false;
        }
        if(!type) {
            toastrService.showError('', 'Looks like you forgot to select the event type');
            return false;
        }
        const startDateMoment = moment(new Date(startDateInput.value + ' ' + startTimeInput.value));
        if(!isEdit && startDateMoment < moment(new Date()).startOf('day')) {
            toastrService.showError('', 'The start date cannot be a date in the past');
            return false;
        }        
        if(startDateMoment > moment(endDateInput.value + ' ' + endTimeInput.value)) {
            toastrService.showError('', 'The end date should be after the start date');
            return false;
        }
        if(!location) {
            toastrService.showError('', 'Looks like you forgot to enter the location');
            return false;
        }
        if(!status) {
            toastrService.showError('', 'Looks like you forgot to select the status');
            return false;
        }
        return true;
    }

    const getEvent = () => {
        return {
            ID: isEdit ? event.ID : null,
            Name: trim(nameInput.value),
            Description: trim(descriptionInput.value) || '',
            Location: trim(location) || '',
            Address: trim(address) || '',
            Longitude: longitude, 
            Latitude: latitude,
            Type: type,
            Timestamp: isEdit ? event.Timestamp : new Date(),
            StartDate:  new Date(startDateInput.value).toISOString().split('T')[0] + ' ' + startTimeInput.value,
            EndDate: new Date(endDateInput.value).toISOString().split('T')[0] + ' ' + endTimeInput.value, 
            Status: status,      
            Income: type === 'Gig' ? parseInt(incomeInput.value, 10) : 0, 
            Version: isEdit ? event.Version : 0,
            Notes: !isEdit && trim(noteInput.value) ? [{ Text: trim(noteInput.value), Timestamp: new Date(), UserID: user.ID }] : []
        };							
    }

    const createEvent = (e) => {
        e.preventDefault();
        checkSubscription(activeProfile, () => {
            if(validateEvent()) {
                setSavingEvent(true);
                const newEvent = getEvent();
                actions.addEvent(activeProfile.ID, newEvent)
                    .then(_ => {
                        window.$("#modal").modal("hide");
                        setSavingEvent(false);
                    })
                    .catch(error => {
                        //do nothing, the action will show the error
                        setSavingEvent(false);
                    });
            }     
        }, 'create an event');           
    }

    const updateEvent = (e) => {
        e.preventDefault();
        checkSubscription(activeProfile, () => {
            if(validateEvent()) {
                setSavingEvent(true);
                const updatedEvent = getEvent();
                actions.editEvent(activeProfile.ID, updatedEvent)
                    .then(_ => {
                        window.$("#modal").modal("hide");
                        setSavingEvent(false);
                    })
                    .catch(error => {
                        //do nothing, the action will show the error
                        setSavingEvent(false);
                    });
            }
        }, 'update an event');
        
    }

    return (        
        <div className={`modal-body  ${!browserDateTypeSupported ? 'no-date-support' : ''}`}>
            <form className="form form--lined form--inputbg modal-inner" onSubmit={isEdit ? updateEvent : createEvent}>
                <div className="form-group mb-2">
                    <label>Event name</label>
                    <input className="form-control" type="text" placeholder="Enter event name" {...nameInput} />
                </div>                        
                <div className="form-group mb-2">
                    <label>Event type</label>
                    <div className="event-type-tabs">
                        {
                            ['Gig', 'Rehearsal', 'Meeting', 'Other'].map(type =>
                                <div key={type} className={`event-tab ${getTypeClass(type)}`} onClick={() => setType(type)}>{type}</div>
                            )
                        }
                    </div>
                </div>                 
                <div className="form-group">
                    <label>Event description</label>
                    <textarea className="form-control form-area" placeholder="Enter event description..." {...descriptionInput}  />
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="form-group mb-2">
                            <label>START DATE</label>
                            <div className="datapicker-wrapper">
                                <input className="form-control form-date datepicker" type="date" id="datepicker3" placeholder={browserDateTypeSupported ? 'Select date' : 'yyyy-mm-dd'} value={startDateInput.value} onChange={updateStartDate} /> 
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group mb-2">
                            <label>Start time</label>
                            <div className="time-input__wrapper">
                                <input className="form-control" type="time" placeholder={browserDateTypeSupported ? '--:--' : 'HH:MM'} {...startTimeInput} /> 
                            </div>
                        </div>
                    </div>                    
                </div>
                <div className="row">
                    <div className="col-6">
                            <div className="form-group mb-2">
                            <label>END DATE</label>
                            <div className="datapicker-wrapper">
                                <input className="form-control form-date datepicker" type="date" id="datepicker3" placeholder={browserDateTypeSupported ? 'Select date' : 'yyyy-mm-dd'} {...endDateInput} /> 
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group mb-2">
                            <label>End time</label>
                            <div className="time-input__wrapper">
                                <input className="form-control" type="time" placeholder={browserDateTypeSupported ? '--:--' : 'HH:MM'}  {...endTimeInput} /> 
                            </div>
                        </div> 
                    </div>
                </div>
                <div className="form-group mb-2">
                    <label>Location</label>
                    <div className="location-wrapper">                     
                        <input className="form-control" type="text" ref={locationRef} placeholder="Enter event location" value={location} onChange={(e) => setLocation(e.target.value)} />
                    </div>
                </div>
                    <div className="form-group mb-2">
                    <label>ADDRESS</label>
                    <input className="form-control" type="text" ref={addressRef} placeholder="Enter event address" value={address} onChange={(e) => setAddress(e.target.value)} />
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <label>STATUS</label>
                            <select className="form-control" ref={statusRef}>
                                <option value="" disabled selected>Select status</option>
                                <option key="Tentative" value="Tentative">Tentative</option>
                                <option key="Confirmed" value="Confirmed">Confirmed</option>
                                {
                                    isEdit ? 
                                        <option key="Cancelled" value="Cancelled">Cancelled</option>
                                        : null
                                }
                                
                            </select>
                        </div>
                    </div>
                    {
                        type === 'Gig' ?
                            <div className="col-6">
                                <div className="form-group mb-2">
                                    <label>INCOME</label>
                                    <input className="form-control" type="text" placeholder="0" {...incomeInput} />
                                </div>
                            </div>
                            : null
                    }                    
                </div>
                {
                    !isEdit ?
                        <div className="form-group">
                            <label>Notes</label>
                            <textarea className="form-control form-area" placeholder="Add optional notes" {...noteInput} />
                        </div>
                        : null
                }                
                <div className="form-group text-center mt-4 modal-buttons">
                    <button type="button" className="btn btn-outline btn-wide m-1" data-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-primary btn-wide m-1">{isEdit ? 'Save Event' : 'Create Event'}</button>
                </div>
            </form>
        </div>
    )
}

export default NewEditEvent;