const urls = {
    BASE_URL: process.env.REACT_APP_API_ENV === 'PRODUCTION' ? 'https://www.mikeapp.co' : 'https://uat.mikeapp.co',//  'https://a36d76bb54d0.ngrok.io',// 'https://mikewebcore.azurewebsites.net', //
    FACEBOOK_URL: 'https://www.facebook.com/mikeappofficial',
    TWITTER_URL: 'https://www.twitter.com/mikeappofficial',
    API_URL: process.env.REACT_APP_API_ENV === 'PRODUCTION' ? 'https://www.mikeapp.co/api/v1.1' : 'https://uat.mikeapp.co/api/v1.1', //'http://localhost:61374/api/v1.1',// 'https://mikewebcore.azurewebsites.net/api/v1.0', //
    MARKETING_URLS: { }
};
//map the application urls to the correct marketing url so the home page redirect from the signup/login page works
//we could also use the base url here, but this is set to staging when running locally, any other reason?
urls.MARKETING_URLS['http://localhost:3000'] = 'http://localhost:61374';
urls.MARKETING_URLS['https://mikewebapp.azurewebsites.net'] = 'https://mikewebcore.azurewebsites.net';
urls.MARKETING_URLS['https://uatapp.mikeapp.co'] = 'https://uat.mikeapp.co';
urls.MARKETING_URLS['https://app.mikeapp.co'] = 'https://www.mikeapp.co';

export default urls;