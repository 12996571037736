import React from 'react';

function ArtistNameHeader({user, greeting = null, welcomeText = null, showInspiration = false, loadingQuote = false, quote = false, haveQuoteError = false, reloadInspiration}) {
    return (
        <div className="content-head">
            <h1 className="band-name">{user.activeProfile.DisplayName}</h1>
            {
               /* user.activeProfile.ArtistType === 'Band' ?
                    <p>{user.FirstName} {user.LastName}</p>
                    : null*/
            }             
            {
                greeting && <p className="home-text">
                    <span>{greeting}</span>
                    <br />
                    {welcomeText && <span dangerouslySetInnerHTML={{__html: welcomeText}} />}
                </p>
            }
            {
                showInspiration ?
                        <div className="inspiration-wrapper-home"> 
                            {
                                quote && <div className="inspiration-home"> 
                                    <span dangerouslySetInnerHTML={{__html: quote.Text}} />
                                    <div className="inspiration__author-home">
                                        - {quote.Author}
                                    </div>  
                                </div>   
                            }   
                            {
                                loadingQuote && <div className="inspiration__loader">    
                                    <div className="center">
                                        <ul>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>                                        
                                        </ul>
                                    </div>
                                </div>
                            }
                            {
                                haveQuoteError && <div className="inspiration-home"> 
                                    <span>Error loading inspiration</span>
                                    <div> <a href="#" onClick={reloadInspiration}>Reload</a></div>
                                </div>  
                            }
                        </div>                        
                    : null
            }                     
        </div>  
    )
}

export default ArtistNameHeader;