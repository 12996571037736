import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ModalProvider } from './common/ModalContext';
import ModalRoot from './common/ModalRoot';
import { isAuthenticated } from '../utils/userUtils';

function App(props) {  
  return (     
    <ModalProvider>
      <div className="App">
        {props.children}          
      </div>
      <ModalRoot />
    </ModalProvider>
  );
}

App.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  user: PropTypes.object,
 // actions: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    authenticated: isAuthenticated(state.account.user),
    user: state.account.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

//we have to use withRouter here to prevent blocked updates:
//https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));