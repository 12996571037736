import React from 'react';
import EmptyView from '../common/EmptyView';
import moment from 'moment';
import { getShortDate } from '../../utils/dateUtils';
import { formatNumber } from '../../utils/financeUtils';

function SocialPosts({ posts, startDate, title, platformUrl, getPostUrl, itemName='post', reachOrPlays='reach', reachText='Reach', reachDescription='', engagementDescription='commented, liked, shared, or clicked' }) {
    const dateText = startDate ? moment(startDate).format('Do MMMM YYYY') : null;
    return (
        <div className="social-page__item">
            <div className={`social-page__item-content ${!posts || !posts.length ? 'social-page__item-content--empty' : ''}`}>
                <div className="social-page__item-header">
                    {title} {dateText ? <span>since <span>{dateText}</span></span> : null}
                </div>
                {
                    posts && posts.length ?
                        <React.Fragment>
                            <div className="popular-post__row popular-post__row--header">
                                <div className="post-name">
                                    {itemName.toUpperCase()}
                                </div>
                                <div className="post-stat">
                                <div className="post-stat-item post-stat-item--date">Date</div>
                                    <div className="post-stat-item">
                                        <img src={reachOrPlays === 'reach' ? require('../../images/eye2.svg') : require('../../images/play.svg')} alt="" className="eye" />  
                                        <span>{reachText}</span>
                                        <div className="post-stat-tooltipe">
                                          {reachDescription || `The number of people who had your ${itemName} enter their screen`}
                                      </div> 
                                    </div>                                        
                                    <div className="post-stat-item">                                        
                                        <img src={require('../../images/pointer2.svg')} alt="" /> 
                                        Engagement
                                        <div className="post-stat-tooltipe">
                                          The number of times people have engaged with your {itemName} e.g. {engagementDescription} {itemName === 'post' ? 'on particular elements of ' : ' '}the {itemName}.
                                        </div> 
                                    </div> 
                                </div>                                        
                            </div>
                            {
                                posts.map((post, index) =>
                                    <div className="popular-post__row" key={post.ID}>
                                        <a href={getPostUrl(post)} className="post-name" target="_blank" rel="noreferer noopener">
                                            {`${index + 1}. ${post.Description || `No ${itemName} text`}`}
                                        </a>
                                        <div className="post-stat">
                                            <div className="post-date">
                                                {getShortDate(post.CreatedDate)}
                                            </div>
                                            <div className="post-stat-item post-stat-item--share">
                                                {formatNumber(post.Views)}
                                            </div>                                    
                                            <div className="post-stat-item post-stat-item--like">
                                                {formatNumber(post.Engagement)}
                                            </div> 
                                        </div>                                        
                                    </div>
                                )
                            }
                            </React.Fragment>
                        :
                        <EmptyView text={`You didn't have any ${itemName}s during this time period`} buttonText={`Create new ${itemName}`} redirectTo={platformUrl} />
                }
            </div>
        </div>
    )
}

export default SocialPosts;