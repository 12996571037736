import * as reducerUtils from '../reducerUtils';
import * as types from '../../actions/actionTypes';

export default function eventReduce(state, action) {
    const artistPredicate = (a, artistID) => a.ID === artistID;
    const eventPredicate = (e, eventID) => e.ID === eventID;

    switch(action.type) {            
        
        case types.EVENT_CREATE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const event = action.payload.event;
            const user = {...state.user};            
            user.Artists = reducerUtils.addNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Events', event);           
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }      
        case types.EVENT_UPDATE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const event = action.payload.event;
            const user = {...state.user};
            user.Artists = reducerUtils.updateNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Events', (e) => eventPredicate(e, event.ID), event);    
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }
        case types.EVENT_DELETE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const eventID = action.payload.eventID;
            const user = {...state.user};
            user.Artists = reducerUtils.deleteNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Events', (e) => eventPredicate(e, eventID));
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }      
        case types.EVENT_ATTENDANCE_UPDATE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const eventID = action.payload.eventID;
            const attendance = action.payload.attendance;
            const user = {...state.user};
            user.Artists = [...user.Artists];
            const artistIndex = user.Artists.findIndex(a => a.ID === artistID);
            const artist = {...user.Artists[artistIndex]};
            artist.Events = [...artist.Events];
            const eventIndex = artist.Events.findIndex(e => e.ID === eventID);
            const event = {...artist.Events[eventIndex]};
            const existingAttendanceIndex = event.Attendances.findIndex(a => a.UserID === attendance.UserID);
            event.Attendances = [...event.Attendances];
            if (existingAttendanceIndex >= 0) {
                event.Attendances[existingAttendanceIndex] = attendance;
            }
            else {                
                event.Attendances.push(attendance);
            }
            artist.Events[eventIndex] = event;
            user.Artists[artistIndex] = artist;
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artist.ID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }      
        case types.EVENT_NOTE_CREATE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const eventID = action.payload.eventID;
            const note = action.payload.note;
            const user = {...state.user};
            user.Artists = [...user.Artists];
            const artistIndex = user.Artists.findIndex(a => a.ID === artistID);
            const artist = {...user.Artists[artistIndex]};
            artist.Events = reducerUtils.addNestedArrayItem(artist.Events, (e) => eventPredicate(e, eventID), 'Notes', note);
            user.Artists[artistIndex] = artist;
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artist.ID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        } 
        case types.EVENT_NOTE_DELETE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const eventID = action.payload.eventID;
            const noteID = action.payload.noteID;
            const user = {...state.user};
            user.Artists = [...user.Artists];
            const artistIndex = user.Artists.findIndex(a => a.ID === artistID);
            const artist = {...user.Artists[artistIndex]};
            artist.Events = reducerUtils.deleteNestedArrayItem(artist.Events, (e) => eventPredicate(e, eventID), 'Notes', n => n.ID === noteID);
            user.Artists[artistIndex] = artist;
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artist.ID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }      
        default:
            return state;
    }
}