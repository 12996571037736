import { update } from "lodash";

export function normaliseTask(task) {
    task.DueDate = new Date(task.DueDate);
    task.CreatedDate = new Date(task.CreatedDate);
    if(task.CompletedDate) {
        task.CompletedDate = new Date(task.CompletedDate);
    }
};

export function normaliseEvent(event) {
    event.StartDate = new Date(event.StartDate);
    if(event.EndDate) {
        event.EndDate = new Date(event.EndDate);
    }
    event.Notes.forEach(note => normaliseNote(note));
};

export function normaliseBalance(balance) {
    balance.Date = new Date(balance.Date);
    balance.Timestamp = new Date(balance.Timestamp);
};

export function normaliseIncome(income) {
    income.Date = new Date(income.Date);
    income.Timestamp = new Date(income.Timestamp);
};

export function normaliseExpense(expense) {
    expense.Date = new Date(expense.Date);
    expense.Timestamp = new Date(expense.Timestamp);
};

export function normaliseBudgetIncome(budgetIncome) {
    budgetIncome.Date = new Date(budgetIncome.Date);
    budgetIncome.Timestamp = new Date(budgetIncome.Timestamp);
};

export function normaliseBudgetExpense(budgetExpense) {
    budgetExpense.Date = new Date(budgetExpense.Date);
    budgetExpense.Timestamp = new Date(budgetExpense.Timestamp);
};

export function normaliseInvitationCode(invitationCode) {
    invitationCode.ExpiryDate = new Date(invitationCode.ExpiryDate);
};

export function normaliseNote(note) {
    note.Timestamp = new Date(note.Timestamp);
}

export function normaliseGoal(goal) {
    goal.TargetDate = new Date(goal.TargetDate);
    goal.Timestamp = new Date(goal.Timestamp);
    if(goal.LastUpdated) {
        goal.LastUpdated = new Date(goal.LastUpdated);
    } 
    goal.History.forEach(goalHistory => goalHistory.Date = new Date(goalHistory.Date));
}

export function normaliseResource(resource) {
    resource.CreatedDate = new Date(resource.CreatedDate);
}

export function normaliseArtist(artist) {
    if(artist.ActivePayment) {
        artist.ActivePayment.ExpiryDate = new Date(artist.ActivePayment.ExpiryDate);
    }
    artist.Tasks.forEach(task => normaliseTask(task));
    artist.Events.forEach(event => normaliseEvent(event));
    artist.Goals.forEach(goal => normaliseGoal(goal));
    artist.Finances.Balances.forEach(balance => normaliseBalance(balance));
    artist.Finances.Income.forEach(income => normaliseIncome(income));
    artist.Finances.Expenses.forEach(expense => normaliseExpense(expense));
    artist.Finances.Budget.Income.forEach(budgetIncome => normaliseBudgetIncome(budgetIncome));
    artist.Finances.Budget.Expenses.forEach(budgetExpense => normaliseBudgetExpense(budgetExpense));
    artist.InvitationCodes.forEach(invitationCode => normaliseInvitationCode(invitationCode));
    artist.Resources.forEach(resource => normaliseResource(resource));
    return artist;
}

export function normaliseInsights(insights) {
    const keys = Object.keys(insights.Insights).sort((a, b) => new Date(a) - new Date(b));
    const normalisedInsights = {};
    keys.forEach(key => {
        const insightDate = new Date(key.split('T')[0])
        const insight = insights.Insights[key];
        normalisedInsights[insightDate] = {...insight, Date: insightDate, LastUpdated: new Date(insight.LastUpdated)};
    });
    insights.StartDate = new Date(insights.StartDate);
    insights.Insights = normalisedInsights;
    if(insights.PopularPosts) {
        insights.PopularPosts.forEach(post => normaliseSocialPost(post));
    }
    if(insights.LatestPosts) {
        insights.LatestPosts.forEach(post => normaliseSocialPost(post));
    }    
    if(insights.PopularVideos) {
        insights.PopularVideos.forEach(post => normaliseSocialPost(post));
    }
    if(insights.LatestVideos) {
        insights.LatestVideos.forEach(post => normaliseSocialPost(post));
    }   
    if(insights.PopularTracks) {
        insights.PopularTracks.forEach(post => normaliseSocialPost(post));
    }
    if(insights.LatestTracks) {
        insights.LatestTracks.forEach(post => normaliseSocialPost(post));
    }  
    if(insights.PopularTweets) {
        insights.PopularTweets.forEach(post => normaliseSocialPost(post));
    }
    if(insights.LatestTweets) {
        insights.LatestTweets.forEach(post => normaliseSocialPost(post));
    }    
    return insights;
}

export function normaliseSocialPost(post) {
    post.CreatedDate = new Date(post.CreatedDate);
}

export function normaliseProject(project) {
    project.CreatedDate = new Date(project.CreatedDate);
}