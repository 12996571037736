import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import { connect, useStore } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as financeActions from '../../actions/financeActions';
import * as currencyActions from '../../actions/currencyActions';
import { useFormInput, useFormCheckInput } from '../../utils/customHooks';
import { getMemberName, checkSubscription } from '../../utils/artistUtils';
import ToastrService from '../../services/toastrService';
import moment from 'moment';
import 'select2/dist/js/select2';
import 'select2/dist/css/select2.min.css'
import 'magnific-popup/dist/magnific-popup.css';
import { isValidDate, isBrowserDateTypeSupported } from '../../utils/dateUtils';
import { incomeTypes, expenseTypes } from '../../constants/types';
import { readImage, getBase64Url, getImageExtension } from '../../utils/fileUtils';
import { showImage } from '../../utils/widgetUtils';
import { getFinanceTypeIcon } from '../../utils/financeUtils';
import { trim } from 'lodash';
import AnalyticsService from '../../services/analyticsService';

function FinanceItemModal({item, user, actions, currencies, defaultItemType = 'Income', clone = false, viewMode = false}) {
    const activeProfile = user.activeProfile;
    const currency = currencies.find(c => c.ID === activeProfile.DefaultCurrencyID);
    const titleInput = useFormInput(item ? item.Title : '');
    const descriptionInput = useFormInput(item ? (item.Description || '') : '');
    const dateInput = useFormInput(item ? item.Date.toISOString().split("T")[0] : '');    
    const amountInput = useFormInput(item ? item.Amount : 0);
    const [itemType, setItemType] = useState(item ? item.ItemType : defaultItemType);
    const [isViewMode, setIsViewMode] = useState(viewMode);
    const [savingItem, setSavingItem] = useState(false);
    const [loadingReceipt, setLoadingReceipt] = useState(false);
    const [receiptIDs, setReceiptIDs] = useState(item && !clone ? item.ReceiptIDs : []);
    const [receiptData, setReceiptData] = useState();

    const [category, setCategory] = useState(item ? item.Type : '');
    const categoryRef = useRef();

    const categoryChanged = (event) => {
        const data = event.params.data;
        if(data) {                   
            setCategory(data.id);
        }
    }

    useEffect(() => {
        if(clone) {
            const toastrService = new ToastrService();
            toastrService.showSuccess('', 'This item has been cloned from your existing task');
        }
        if(!currencies.length) {
            actions.getCurrencies();
        }
    }, []);

    const isEdit = item && !clone && !isViewMode;

    useEffect(() => {
        if(categoryRef.current) {
            const categoryControl = window.$(categoryRef.current);            
            categoryControl.select2({
                theme : "classic" ,
                templateResult: formatState,
                templateSelection: formatState
            })
            .on('select2:select', categoryChanged);
            if(category) {
                categoryControl.val(category).trigger('change');
            }
        }
    }, [isEdit]);

    const formatState = (opt) => {
        if (!opt.id) {
            return opt.text;
        }               
        const optimage = window.$(opt.element).data('image'); 
        if(!optimage){
            return opt.text;
        } 
        else {                    
            const $opt = window.$(
                '<span><img src="' + optimage + '" width="23px" /> ' + opt.text + '</span>'
            );
            return $opt;
        }
    };

    const browserDateTypeSupported = isBrowserDateTypeSupported();

    useEffect(() => {
        let eventName = isEdit ? 'Edit Finance Item Launched' : 'Add Finance Item Launched';
        if(clone) {
            eventName += ' - cloning';
        }
        AnalyticsService.logEvent(eventName);
    }, []);

    const validateItem = () => {
        const toastrService = new ToastrService();
        if(!trim(titleInput.value)) {
            toastrService.showError('', 'Looks like you forgot to enter the title');
            return false;
        }
        if(!category) {
            toastrService.showError('', 'Looks like you forgot to select the category');
            return false;
        }
        if(!dateInput.value) {
            toastrService.showError('', 'Looks like you forgot to enter the date');
            return false;
        }
        if(!isValidDate(dateInput.value)) {
            toastrService.showError('', `Looks like the date isn't valid${!browserDateTypeSupported ? ', please enter the date in the format YYYY-MM-DD' : ''}`);
            return false;
        }
        const amount = parseFloat(amountInput.value);
        if(!amount > 0) {
            toastrService.showError('', 'Looks like you forgot to enter the amount');
            return false;
        }
        return true;
    }

    const getItem = () => {       
        const amount = parseFloat(amountInput.value);
        return {
            ID: isEdit ? item.ID : null,
            Title: trim(titleInput.value),
            Type: itemType === 'Income' ? category : null,
            Description: trim(descriptionInput.value) || '',
            Timestamp: isEdit ? item.Timestamp : new Date(),
            Date: new Date(dateInput.value).toISOString().split('T')[0] + 'T00:00:00+00:00', //set the date with no time or time zone applied
            Amount: amount,
            ReceiptIDs: receiptIDs,
            Breakdown: itemType === 'Expense' ? [ 
                {
                    Type : category,
                    Amount : parseFloat(amountInput.value)
                }
            ] : null,
            Version: isEdit ? item.Version : 0
        };			
    }

    const getUploadReceiptPromise = () => {
        return receiptData ? 
            new Promise((resolve, reject) => {
                const extension = getImageExtension(receiptData);
                actions.uploadReceipt(activeProfile.ID, `receipt.${extension}`, receiptData.split(',')[1], `${itemType} Receipt`)
                    .then(receipt => resolve(receipt.ID))
                    .catch(error => reject());
           })
           : Promise.resolve();         
    }

    const createItem = (event) => {
        event.preventDefault();
        checkSubscription(activeProfile, () => {
            if(validateItem()) {
                setSavingItem(true);
                const newItem = getItem();
                const receiptPromise = getUploadReceiptPromise();
                const action = itemType === 'Income' ? actions.createIncome : actions.createExpense;
                receiptPromise.then(receiptID => {
                    if(receiptID) {
                        newItem.ReceiptIDs = [receiptID];
                    }
                    action(activeProfile.ID, newItem)
                        .then(item => {
                            window.$("#modal").modal("hide");
                            setSavingItem(false);
                        })
                        .catch(error => {
                            //do nothing, the action will show the error
                            setSavingItem(false);
                        });
                })            
            }
        }, `create a new ${itemType.toLowerCase()} item`);                
    }

    const updateItem = (event) => {
        event.preventDefault();
        checkSubscription(activeProfile, () => {
            if(validateItem()) {
                setSavingItem(true);
                const updatedItem = getItem();
                const receiptPromise = getUploadReceiptPromise();
                if(item.ReceiptIDs.length && !receiptIDs.length) {
                    //we had a receipt but it's been removed, delete it
                    actions.deleteReceipt(activeProfile.ID, item.ReceiptIDs[0], false);
                }
                const action = itemType === 'Income' ? actions.editIncome : actions.editExpense;
                receiptPromise.then(receiptID => {
                    if(receiptID) {
                        updatedItem.ReceiptIDs = [receiptID];
                    }
                    action(activeProfile.ID, updatedItem)
                        .then(item => {
                            window.$("#modal").modal("hide");
                            setSavingItem(false);
                        })
                        .catch(error => {
                            //do nothing, the action will show the error
                            setSavingItem(false);
                        });
                })
            }
        }, `update this ${itemType.toLowerCase()} item`);        
    }

    const showReceipt = (event) => {
        event.preventDefault();
        const target = event.target;
        if(receiptData) {
            //for the initial upload, the image is already base64 encoded
            showImage(receiptData, target);
        }
        else if(receiptIDs.length) {
            setLoadingReceipt(true);
            actions.getReceipt(activeProfile.ID, receiptIDs[0])
                .then(receipt => {
                    setLoadingReceipt(false);
                    const imageSrc = getBase64Url(receipt.Data);
                    showImage(imageSrc, target);
                })
                .catch(error => setLoadingReceipt(false));
        }
    }

    const deleteReceipt = () => {
        setReceiptData(null);
        setReceiptIDs([]);
    }

    const onFileChange = (event) => {
        if(event.target.files.length) {
            readImage(event, (upload) => setReceiptData(upload.target.result), (error) => {
                const toastrService = new ToastrService();
                toastrService.showError('', error);
            }, 10);
        }
    }

    const types = itemType === 'Income' ? incomeTypes : expenseTypes;

    return (     
        <div className={`modal-dialog modal-lg ${!browserDateTypeSupported ? 'no-date-support' : ''}`}>
            {
                savingItem || loadingReceipt ?
                    <div className="modal-window__loader modal-window__loader-task">
                        <img src={require('../../images/loader.svg')} alt="" />
                        {
                            savingItem ?
                                isEdit ? `Saving ${itemType.toLowerCase()}` : `Creating ${itemType.toLowerCase()}`
                                :
                                'Loading receipt...'
                        }
                    </div>
                    : null
            }            
            <div className="modal-content modal-content--balance">
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">{isEdit ? `Edit ${itemType}` : (isViewMode ? `View ${itemType}` : `New ${itemType}`)}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i className="mikeicon-x" />
                    </button>
                </div>
                {
                    !isViewMode && !isEdit && !clone ?
                        <div className={`new-income__tabs-wrapper ${itemType === 'Expense' ? 'active' : ''}`} onClick={() => setItemType(itemType === 'Income' ? 'Expense' : 'Income')}>
                            <div className="new-income__switch">
                                <img src={require('../../images/switch-button-toggle.png')} alt="" />
                            </div>
                            <div className="new-income__tabs">
                                <div className="new-income__tab">Income</div>
                                <div className="new-income__tab">Expenses</div>
                            </div>
                        </div>
                        : null
                }                
                <div className="new-income__tab-content-wrapper">
                    <div className="new-income__tab-content">
                        <div className="modal-body">
                            <form className="form form--lined form--inputbg modal-inner" onSubmit={isEdit ? updateItem : (!isViewMode ? createItem : null)}>
                                <div className="form-group mb-2">
                                    <label>TITLE</label>
                                    <input className="form-control" type="text" placeholder="Enter title" {...titleInput} readOnly={isViewMode} />
                                </div>                            
                                <div className="form-group">
                                    <label>CATEGORY</label>
                                    {
                                        isViewMode ?
                                            <input className="form-control" type="text" value={types[category]} readOnly /> 
                                            :
                                            <select className="form-control select income-select" ref={categoryRef}>
                                                <option value="" disabled selected>Select category</option>
                                                {
                                                    Object.keys(types).map(type =>
                                                        <option key={type} value={type} data-image={getFinanceTypeIcon(itemType, type)}>{types[type]}</option>
                                                    )
                                                }
                                            </select>
                                    }                                     
                                </div>
                                <div className="form-group mb-2">
                                    <label>DATE</label>
                                    <div className="datapicker-wrapper" readOnly={isViewMode}>
                                        <input className="form-control form-date datepicker" type="date" placeholder={browserDateTypeSupported ? 'Select date' : 'yyyy-mm-dd'} {...dateInput} readOnly={isViewMode} /> 
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea className="form-control form-area" placeholder="Enter description..." {...descriptionInput} readOnly={isViewMode} />
                                </div>                            
                                <div className="form-group mb-2">
                                    <label>Amount ({currency ? currency.Symbol : ''})</label>
                                    <input className="form-control" type="number" min="0.01" step="any" placeholder="0" {...amountInput} readOnly={isViewMode} />
                                </div>
                                {
                                    receiptIDs.length || receiptData ?
                                        <div className="add-receipt">
                                            <a href={null} onClick={showReceipt}>
                                                <img src={require('../../images/icon-finances-addreceipt.png')} alt="" className="icon" />
                                                &nbsp;RECEIPT
                                            </a>
                                            {
                                                !isViewMode ? <div className="hide" onClick={deleteReceipt}>+</div> : null
                                            }
                                            
                                        </div>
                                        :
                                        !isViewMode ?
                                            <div className="form-group text-center mt-4 modal-buttons new-receipt__footer">
                                                <button type="button" className="btn btn-primary btn-wide m-1 new-income__btn" download>                                     
                                                    <input type="file" id="file" className="inputfile" accept="image/*" onChange={onFileChange} />
                                                    <label htmlFor="file">
                                                        <img src={require('../../images/icon-finances-addreceipt.png')} alt="" />
                                                        Add receipt
                                                    </label>                                   
                                                </button>
                                            </div>
                                            : null                                
                                }                                
                                <div className={`form-group text-center mt-4 modal-buttons ${isViewMode ? 'modal-buttons--view' : ''}`}>
                                    {
                                        isViewMode ? 
                                            <button type="button" className="btn btn-primary btn-wide m-1" onClick={() => checkSubscription(activeProfile, () => setIsViewMode(false), `edit this ${itemType.toLowerCase()} item`) }>Edit {itemType}</button>
                                            :
                                            <React.Fragment>
                                                <button type="button" className="btn btn-outline btn-wide m-1" data-dismiss="modal">Cancel</button>
                                                <button type="submit" className="btn btn-primary btn-wide m-1">{isEdit ? `Save ${itemType}` : `Create ${itemType}`}</button>
                                            </React.Fragment>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user,
        currencies: state.currencies
    };
}


function mapDispatchToProps(dispatch) {
    return {
        //actions will now be available under props.actions
        actions: bindActionCreators(Object.assign({}, financeActions, currencyActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FinanceItemModal);