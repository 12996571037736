import React, { useState, useRef, useEffect } from 'react';
import { incomeTypes, expenseTypes } from '../../constants/types';
import { getFinanceTypeIcon } from '../../utils/financeUtils';
import { useFormInput } from '../../utils/customHooks';
import { checkSubscription } from '../../utils/artistUtils';
import AnalyticsService from '../../services/analyticsService';

function BudgetModal({ user, currency, actions, budgetItemEditing, budgetSelectedTab, closeBudgetModal, budgetFilter }) {
    const activeProfile = user.activeProfile;
    const budgetTypes = budgetSelectedTab === 'Income' ? incomeTypes : expenseTypes;

    const availableBudgetTypes = budgetSelectedTab === 'Income' ? 
        Object.keys(budgetTypes).filter(t => (budgetItemEditing && t === budgetItemEditing.Type) || !activeProfile.Finances.Budget.Income.map(i => i.Type).includes(t))
        : Object.keys(budgetTypes).filter(t => (budgetItemEditing && t === budgetItemEditing.Type) || !activeProfile.Finances.Budget.Expenses.map(i => i.Type).includes(t))

    const [budgetType, setBudgetType] = useState(budgetItemEditing ? budgetItemEditing.Type : availableBudgetTypes[0]);
    const year = budgetFilter.toUpperCase().indexOf('YEAR') > -1;
    const budgetAmountInput = useFormInput(budgetItemEditing ? (year ? budgetItemEditing.Planned / 12 : budgetItemEditing.Planned).toFixed(2) : 0);
    const [saving, setSaving] = useState(false);
    const budgetTypeRef = useRef();

    useEffect(() => {
        if(budgetTypeRef.current) {
            const budgetTypeControl = window.$(budgetTypeRef.current);            
            budgetTypeControl.select2({
                theme : "classic" ,
                templateResult: formatState,
                templateSelection: formatState
            })
            .on('select2:select', budgetTypeChanged);
            if(budgetType) {
                budgetTypeControl.val(budgetType).trigger('change');
            }
        }
    }, []);

    const formatState = (opt) => {
        if (!opt.id) {
            return opt.text;
        }               
        const optimage = window.$(opt.element).data('image'); 
        if(!optimage){
            return opt.text;
        } 
        else {                    
            const $opt = window.$(
                '<span><img src="' + optimage + '" width="23px" /> ' + opt.text + '</span>'
            );
            return $opt;
        }
    };

    const budgetTypeChanged = (event) => {
        const data = event.params.data;
        if(data) {                   
            setBudgetType(data.id);
        }
    }

    useEffect(() => {
        window.$(document).on('click', '', closeModal);
        return () => {
            window.$(document).off('click', '', closeModal);
        }
    }, []);

    const closeModal = (e) => { 
        const modalContent = window.$('.budget-modal__content');
        if (!modalContent.is(e.target) && modalContent.has(e.target).length === 0) {
            closeBudgetModal();
        }
    }

    useEffect(() => {
        AnalyticsService.logEvent('Add/Edit Budget Launched');
    }, []);

    const saveBudgetItem = (event) => {
        event.preventDefault();
        checkSubscription(activeProfile, () => {
            let action;
            let budgetItem;
            if(budgetType) {
                if(budgetSelectedTab === 'Income') {
                    budgetItem = activeProfile.Finances.Budget.Income.find(i => i.Type == budgetType);
                    if(budgetItem) {
                        budgetItem = {...budgetItem, Amount: budgetAmountInput.value || 0};
                        action = actions.editBudgetIncome;
                    }
                    else {
                        budgetItem = { Amount: budgetAmountInput.value || '', Date: new Date(), Type: budgetType };
                        action = actions.createBudgetIncome;
                    }
                }
                else {
                    budgetItem = activeProfile.Finances.Budget.Expenses.find(e => e.Type == budgetType);
                    if(budgetItem) {
                        budgetItem = {...budgetItem, Amount: budgetAmountInput.value || 0};
                        action = actions.editBudgetExpense;
                    }
                    else {
                        budgetItem = { Amount: budgetAmountInput.value || '', Date: new Date(), Type: budgetType };
                        action = actions.createBudgetExpense;
                    }
                }
                setSaving(true);
                action(activeProfile.ID, budgetItem)
                    .then(response => {
                        setSaving(false);
                        closeBudgetModal()
                    })
                    .catch(error => setSaving(false));
            }
        }, 'save your budget');        
    }

    return (
        <div className={`budget-modal ${budgetItemEditing ? 'budget-modal--edit' : 'new-budget-modal'} active`}>
            <div className="budget-modal__content load">
                {
                    saving ?
                        <div className="modal-window__loader">
                            <img src={require('../../images/loader.svg')} alt='loading' />
                            { budgetItemEditing ? 'Saving budget...' : 'Creating budget' }
                        </div>
                        : null
                }   
                <div className="close" onClick={closeBudgetModal}>+</div>
                <div className="budget-modal__title">
                    {budgetItemEditing ? 'EDIT BUDGET' : 'NEW BUDGET'} 
                </div>
                <form className="form">
                    <div className="edit-budget-modal-title">
                        <img src={getFinanceTypeIcon(budgetSelectedTab, budgetType)} alt="" />       
                        {
                            budgetItemEditing ?
                                <div className="edit-budget-modal-text">
                                    Planned budget for <span>{budgetTypes[budgetType]}</span>
                                </div>
                                : null
                        }             
                    </div>
                    {
                        !budgetItemEditing ?  
                            <div className="form-group budget-modal-select">
                                <div className="budget-modal-select-text">
                                    {`Planned budget for`}
                                </div>
                                {
                                    <select id="white-select" className="form-control select income-select" ref={budgetTypeRef} disabled={budgetItemEditing}>      
                                        {
                                            availableBudgetTypes.map(type =>
                                                <option key={type} value={type} data-image={getFinanceTypeIcon(budgetSelectedTab, type)}>{budgetTypes[type]}</option>
                                            )
                                        }                                     
                                    </select>
                                }   
                            </div>
                            : null
                    }                    
                    <div className="budget-modal__input-wrapper">
                        <div className="budget-modal__current">
                            {currency ? currency.Symbol : ''}   
                        </div>
                        <input type="number" placeholder="0" {...budgetAmountInput} />
                        <div className="budget-modal__month">
                            (a month)
                        </div> 
                    </div>
                    <div className="budget-modal__footer">
                        <button className="btn" onClick={saveBudgetItem}>
                            Save
                        </button> 
                    </div>
                </form>
            </div>
        </div>
    )
}

export default BudgetModal;