export const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS';
export const GET_PRICING_SUCCESS = 'GET_PRICING_SUCCESS';

//quote actions
export const GET_QUOTE_SUCCESS = 'GET_QUOTE_SUCCESS';
export const GET_GREETING_SUCCESS = 'GET_GREETING_SUCCESS';

//account actions
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SET_USER_LOADING = 'SET_USER_LOADING';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const SCHEDULE_TOKEN_REFRESH = 'SCHEDULE_TOKEN_REFRESH';
export const TOKEN_REFRESHING = 'TOKEN_REFRESHING';
export const CANCEL_TOKEN_REFRESH = 'CANCEL_TOKEN_REFRESH'; //cancels the token refresh scheduled
export const TOKEN_REFRESHED = 'TOKEN_REFRESHED';
export const TOKEN_REFRESH_FAILED = 'TOKEN_REFRESH_FAILED';
export const SWITCH_ACTIVE_PROFILE = 'SWITCH_ACTIVE_PROFILE';
export const SET_REFRESH_TOKEN_TIMEOUT_HANDLE = 'SET_REFRESH_TOKEN_TIMEOUT_HANDLE';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';

//artist actions
export const ARTIST_CREATE_SUCCESS = 'ARTIST_CREATE_SUCCESS';
export const ARTIST_UPDATE_SUCCESS = 'ARTIST_UPDATE_SUCCESS';
export const ARTIST_INVITATION_CODE_CREATE_SUCCESS = 'ARTIST_INVITATION_CODE_CREATE_SUCCESS';

//task actions
export const TASK_CREATE_SUCCESS = 'TASK_CREATE_SUCCESS';
export const TASK_UPDATE_SUCCESS = 'TASK_UPDATE_SUCCESS';
export const TASK_DELETE_SUCCESS = 'TASK_DELETE_SUCCESS';

//event actions
export const EVENT_CREATE_SUCCESS = 'EVENT_CREATE_SUCCESS';
export const EVENT_UPDATE_SUCCESS = 'EVENT_UPDATE_SUCCESS';
export const EVENT_DELETE_SUCCESS = 'EVENT_DELETE_SUCCESS';
export const EVENT_ATTENDANCE_UPDATE_SUCCESS = 'EVENT_ATTENDANCE_UPDATE_SUCCESS';
export const EVENT_NOTE_CREATE_SUCCESS = 'EVENT_NOTE_CREATE_SUCCESS';
export const EVENT_NOTE_DELETE_SUCCESS = 'EVENT_NOTE_DELETE_SUCCESS';

//goal actions
export const GOAL_CREATE_SUCCESS = 'GOAL_CREATE_SUCCESS';
export const GOAL_UPDATE_SUCCESS = 'GOAL_UPDATE_SUCCESS';
export const GOAL_DELETE_SUCCESS = 'GOAL_DELETE_SUCCESS';

//finance actions
export const GET_RECEIPT_SUCCESS = 'GET_RECEIPT_SUCCESS';
export const UPLOAD_RECEIPT_SUCCESS = 'UPLOAD_RECEIPT_SUCCESS';
export const DELETE_RECEIPT_SUCCESS = 'DELETE_RECEIPT_SUCCESS';
export const EXPENSE_CREATE_SUCCESS = 'EXPENSE_CREATE_SUCCESS';
export const EXPENSE_UPDATE_SUCCESS = 'EXPENSE_UPDATE_SUCCESS';
export const EXPENSE_DELETE_SUCCESS = 'EXPENSE_DELETE_SUCCESS';
export const INCOME_CREATE_SUCCESS = 'INCOME_CREATE_SUCCESS';
export const INCOME_UPDATE_SUCCESS = 'INCOME_UPDATE_SUCCESS';
export const INCOME_DELETE_SUCCESS = 'INCOME_DELETE_SUCCESS';
export const BALANCE_CREATE_SUCCESS = 'BALANCE_CREATE_SUCCESS';
export const BALANCE_UPDATE_SUCCESS = 'BALANCE_UPDATE_SUCCESS';
export const BALANCE_DELETE_SUCCESS = 'BALANCE_DELETE_SUCCESS';
export const BUDGET_EXPENSE_CREATE_SUCCESS = 'BUDGET_EXPENSE_CREATE_SUCCESS';
export const BUDGET_EXPENSE_UPDATE_SUCCESS = 'BUDGET_EXPENSE_UPDATE_SUCCESS';
export const BUDGET_EXPENSE_DELETE_SUCCESS = 'BUDGET_EXPENSE_DELETE_SUCCESS';
export const BUDGET_INCOME_CREATE_SUCCESS = 'BUDGET_INCOME_CREATE_SUCCESS';
export const BUDGET_INCOME_UPDATE_SUCCESS = 'BUDGET_INCOME_UPDATE_SUCCESS';
export const BUDGET_INCOME_DELETE_SUCCESS = 'BUDGET_INCOME_DELETE_SUCCESS';

//resource actions
export const RESOURCE_CREATE_SUCCESS = 'RESOURCE_CREATE_SUCCESS';
export const RESOURCE_UPDATE_SUCCESS = 'RESOURCE_UPDATE_SUCCESS';
export const RESOURCE_DELETE_SUCCESS = 'RESOURCE_DELETE_SUCCESS';

//project actions
export const PROJECT_CREATE_SUCCESS = 'PROJECT_CREATE_SUCCESS';
export const PROJECT_UPDATE_SUCCESS = 'PROJECT_UPDATE_SUCCESS';
export const PROJECT_DELETE_SUCCESS = 'PROJECT_DELETE_SUCCESS';