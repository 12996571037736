import BaseService from './baseService';

class FinanceService {

    static getReceipt(artistID, receiptID) {
        return BaseService.getAndReturn(`/Artists/${artistID}/Finances/Receipts/${receiptID}`, BaseService.returnAssignedObject, 'Unable to get receipt', true);
    }

    static createExpense(artistID, expense) {
        return BaseService.postAndReturn(`/Artists/${artistID}/Finances/Expenses`, expense, BaseService.returnAssignedObject, 'Unable to create expense', true);
    }
    
    static createIncome(artistID, income) {
        return BaseService.postAndReturn(`/Artists/${artistID}/Finances/Income`, income, BaseService.returnAssignedObject, 'Unable to create income', true);
    }

    static createBalance(artistID, balance) {
        return BaseService.postAndReturn(`/Artists/${artistID}/Finances/Balances`, balance, BaseService.returnAssignedObject, 'Unable to create balance', true);
    }

    static createBudgetIncome(artistID, budgetIncome) {
        return BaseService.postAndReturn(`/Artists/${artistID}/Finances/Budget/Income`, budgetIncome, BaseService.returnAssignedObject, 'Unable to create budgeted income', true);
    }
    
    static createBudgetExpense(artistID, budgetExpense) {
        return BaseService.postAndReturn(`/Artists/${artistID}/Finances/Budget/Expenses`, budgetExpense, BaseService.returnAssignedObject, 'Unable to create budgeted expense', true);
    }

    static uploadReceipt(artistID, filename, dataString, description) {
        return BaseService.postAndReturn(`/Artists/${artistID}/Finances/Receipts`, { filename, dataString, description }, BaseService.returnAssignedObject, 'Unable to upload receipt', true);
    } 

    static exportTransactions(artistID, itemType, startDate, endDate, format, includeDescriptions) {
        const exportData = { itemType, startDate, endDate, format, includeDescriptions };
        return BaseService.postAndReturn(`/Artists/${artistID}/Finances/Export/Transactions`, exportData, BaseService.returnAssignedObject, 'Unable to export transactions', true);
    } 

    static exportBudget(artistID, itemType, budgetPeriod, format) {
        const exportData = { itemType, budgetPeriod, format };
        return BaseService.postAndReturn(`/Artists/${artistID}/Finances/Export/Budget`, exportData, BaseService.returnAssignedObject, 'Unable to export budget', true);
    }
    
    static editExpense(artistID, expense) {
        return BaseService.putAndReturn(`/Artists/${artistID}/Finances/Expenses/${expense.ID}`, expense, BaseService.returnAssignedObject, 'Unable to update expense', true);
    } 

    static editIncome(artistID, income) {
        return BaseService.putAndReturn(`/Artists/${artistID}/Finances/Income/${income.ID}`, income, BaseService.returnAssignedObject, 'Unable to update income', true);
    } 

    static editBalance(artistID, balance) {
        return BaseService.putAndReturn(`/Artists/${artistID}/Finances/Balances/${balance.ID}`, balance, BaseService.returnAssignedObject, 'Unable to update balance', true);
    } 

    static editBudgetIncome(artistID, budgetIncome) {
        return BaseService.putAndReturn(`/Artists/${artistID}/Finances/Budget/Income/${budgetIncome.ID}`, budgetIncome, BaseService.returnAssignedObject, 'Unable to update budgeted income', true);
    } 

    static editBudgetExpense(artistID, budgetExpense) {
        return BaseService.putAndReturn(`/Artists/${artistID}/Finances/Budget/Expenses/${budgetExpense.ID}`, budgetExpense, BaseService.returnAssignedObject, 'Unable to update budgeted expense', true);
    } 

    static deleteExpense(artistID, expenseID) {
        return BaseService.deleteAndReturn(`/Artists/${artistID}/Finances/Expenses/${expenseID}`, BaseService.returnAssignedObject, 'Unable to delete expense', true);
    }

    static deleteIncome(artistID, incomeID) {
        return BaseService.deleteAndReturn(`/Artists/${artistID}/Finances/Income/${incomeID}`, BaseService.returnAssignedObject, 'Unable to delete income', true);
    }

    static deleteBalance(artistID, balanceID) {
        return BaseService.deleteAndReturn(`/Artists/${artistID}/Finances/Balances/${balanceID}`, BaseService.returnAssignedObject, 'Unable to delete balance', true);
    }

    static deleteBudgetIncome(artistID, budgetIncomeID) {
        return BaseService.deleteAndReturn(`/Artists/${artistID}/Finances/Budget/Income/${budgetIncomeID}`, BaseService.returnAssignedObject, 'Unable to delete budgeted income', true);
    }

    static deleteBudgetExpense(artistID, budgetExpenseID) {
        return BaseService.deleteAndReturn(`/Artists/${artistID}/Finances/Budget/Expenses/${budgetExpenseID}`, BaseService.returnAssignedObject, 'Unable to delete budgeted expense', true);
    }

    static deleteReceipt(artistID, receiptID) {
        return BaseService.deleteAndReturn(`/Artists/${artistID}/Finances/Receipts/${receiptID}`, BaseService.returnAssignedObject, 'Unable to delete receipt', true);
    }
 }

export default FinanceService;