import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

function ScrollToTop({location, children}) {
  useEffect(
    () => {
      window.scrollTo(0, 0);
    },
    [location]
  );
  
  return children;
}
  
ScrollToTop.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired
};

export default withRouter(ScrollToTop);