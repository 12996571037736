/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import Header from '../account/Header.js';
import * as currencyActions from '../../actions/currencyActions';
import * as pricingActions from '../../actions/pricingActions';
import * as artistActions from '../../actions/artistActions';
import * as accountActions from '../../actions/accountActions';
import '../../styles/style-onboarding.scss';
import AnimateIn from '../common/AnimateIn.js';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import CardSection from './CardSection.js';
import ToastrService from '../../services/toastrService.js';
import moment from 'moment';
import { paymentCurrencies } from '../../constants/types';
import AnalyticsService from '../../services/analyticsService.js';

function CreateSubscriptionPage({user, currencies, actions, location, history}) {
    const activeProfile = user.activeProfile;
    const selectedPricingPlan = location.state && location.state.pricingPlan ? location.state.pricingPlan : null;
    const selectedCurrency = location.state && location.state.currency ? location.state.currency : null;

    const [currency, setCurrency] = useState(selectedCurrency);
    const [stripePricingPlans, setStripePricingPlans] = useState();
    const stripePricingPlan = stripePricingPlans ? stripePricingPlans.find(p => p.Nickname === selectedPricingPlan.ProductID && p.Currency === currency.Code) : null;

    const stripe = useStripe();
    const elements = useElements();
    const [processingPayment, setProcessingPayment] = useState(false);
    
    const canRender = stripe && elements && stripePricingPlan ? true : false; // we have to set true/false here as else the stripePricingPlan will be the result and it changes

    const subscribedRef = useRef(false);

    useEffect(() => {
        if(!currencies.length) {
            actions.getCurrencies();
        }        
        actions.getPricingPlansStripe()
            .then(pricingPlans => setStripePricingPlans(pricingPlans));
    }, []);

    const currencyRef = useRef();

    const currencyChanged = (event) => {
        const data = event.params.data;
        if(data) {            
            setCurrency(currencies.find(c => c.ID === data.id));
        }
    }

    useEffect(() => {
        if(currencyRef.current) {
            const currencyControl = window.$(currencyRef.current);
            currencyControl.select2({
                theme : "classic" 
            })
            .on('select2:select', currencyChanged);
            currencyControl.val(currency.ID).trigger('change');
        }
    }, [canRender]);   

    useEffect(() => {
        AnalyticsService.logEvent('Create Subscription Page Launched');
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements || !stripePricingPlan) {
            return;
        }
        const priceID = stripePricingPlan.ID;
        //start by creating them as a customer if the user doesn't have a customerID for this currency
        let customerID = user.StripeCustomerIDs ? user.StripeCustomerIDs[currency.Code] : null;
        setProcessingPayment(true);
        if(!customerID) {
            //call the API to get one
            customerID = await actions.createStripeCustomer(currency.Code);
        }
        if(!customerID) {
            setProcessingPayment(false);
            return;
        }
        const cardElement = elements.getElement(CardElement);
        // If a previous payment was attempted, get the latest invoice
        const latestInvoiceStatus = localStorage.getItem(
            'latestInvoiceStatus'
        );
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });
        if (error) {
            if(error.message) {
                const toastrService = new ToastrService();
                toastrService.showError('', error.message);
            }
            console.log('[createPaymentMethod error]', error);
            setProcessingPayment(false);
        } 
        else {
            console.log('[PaymentMethod]', paymentMethod);
            const paymentMethodID = paymentMethod.id;
            if (latestInvoiceStatus === 'requires_payment_method') {
                // Update the payment method and retry invoice payment
                const invoiceID = localStorage.getItem('latestInvoiceID');
                retryInvoiceWithNewPaymentMethod({
                    customerID,
                    paymentMethodID,
                    invoiceID,
                    priceID,
                });
            } 
            else {
                // call our API to create the subscription
                actions.createStripeSubscription(activeProfile.ID, customerID, paymentMethodID, priceID)    
                    // Some payment methods require a customer to be on session to complete the payment process. Check the status of the payment intent to handle these actions.
                    .then(handlePaymentThatRequiresCustomerAction)
                    // If attaching this card to a Customer object succeeds but attempts to charge the customer fail, you get a requires_payment_method error.
                    .then(handleRequiresPaymentMethod)
                    // No more actions required. Provision your service for the user.
                    .then(onSubscriptionComplete)
                    .catch((error) => {
                        const toastrService = new ToastrService();
                        toastrService.showError('', error.message);
                        setProcessingPayment(false);
                    });
            }
        }
    };

    function onSubscriptionComplete(result) {
        //this action will set the profile as active in state, show a toast, and then redirect to the home page. 
        //We don't need to update the back end as the webhook will update it if there was further action needed after the initial subscription action
        if (result.subscription.Status === 'active' || result.subscription.Status === 'trialing') {
            subscribedRef.current = true;
            //reload the artist so we can get the active subscription flag and latest payment details
            actions.reloadArtist(activeProfile.ID)
                .then(result => setProcessingPayment(false));
            AnalyticsService.logEvent('Subscription created');
        }
        else {
            setProcessingPayment(false);
        }
    }

    function retryInvoiceWithNewPaymentMethod({ customerID, paymentMethodID, invoiceID, priceID }) {
        setProcessingPayment(true);
        return (            
            actions.retryStripeSubscription(activeProfile.ID, customerID, paymentMethodID, invoiceID, priceID)           
            // If the card is declined, display an error to the user.
            .then((result) => { //check this, I don't think we ever get an error set here
                setProcessingPayment(false);
                if (result.error) {
                    // The card had an error when trying to attach it to a customer.
                    throw result;
                }
                return result;
            })           
            // Some payment methods require a customer to be on session to complete the payment process. Check the status of the payment intent to handle these actions.
            .then(handlePaymentThatRequiresCustomerAction)
            // No more actions required. Provision your service for the user.
            .then(onSubscriptionComplete)
            .catch((error) => {
                const toastrService = new ToastrService();
                toastrService.showError('', error.message);
            })
        );
    }

    function handlePaymentThatRequiresCustomerAction({ subscription, invoice, priceID, paymentMethodID, isRetry }) {
        if (subscription && subscription.Status === 'active') {
          // Subscription is active, no customer actions required.
          return { subscription, priceID, paymentMethodID };
        }
      
        let invoiceStatus = subscription.LatestInvoiceStatus;
      
        if (invoiceStatus === 'requires_action' || (isRetry === true && invoiceStatus === 'requires_payment_method')) {
            return stripe
                .confirmCardPayment(subscription.LatestInvoiceSecret, { 
                    payment_method: paymentMethodID,
                })
                .then((result) => {
                    if (result.error) {
                        // Start code flow to handle updating the payment details.
                        // The card was declined (i.e. insufficient funds, card has expired, etc).
                        throw result;
                    } 
                    else {
                        if (result.paymentIntent.status === 'succeeded') {
                            // Show a success message to your customer.
                            // There's a risk of the customer closing the window before the callback.
                            // We recommend setting up webhook endpoints later in this guide.
                            return {
                                priceID,
                                subscription,
                                invoice,
                                paymentMethodID,
                            };
                        }
                    }
                })
                .catch((error) => {              
                    const toastrService = new ToastrService();
                    toastrService.showError('', error.message);
                });
        } 
        else {
            // No customer action needed.
            return { subscription, priceID, paymentMethodID };
        }
    }

    function handleRequiresPaymentMethod({ subscription, paymentMethodID, priceID }) {
        if (subscription.Status === 'active') {
            // subscription is active, no customer actions required.
            return { subscription, priceID, paymentMethodID };
        } 
        else if (subscription.LatestInvoiceStatus === 'requires_payment_method') {
          // Using localStorage to manage the state of the retry here,
          // feel free to replace with what you prefer.
          // Store the latest invoice ID and status.
          localStorage.setItem('latestInvoiceID', subscription.LatestInvoiceID);
          localStorage.setItem('latestInvoiceStatus', subscription.LatestInvoiceStatus);
          throw { error: { message: 'Your card was declined.' } };
        } 
        else {
          return { subscription, priceID, paymentMethodID };
        }
      }

    if(!selectedPricingPlan && !selectedCurrency) {
        return <Redirect to="/subscription" />;
    } 

    if(user.activeProfile.HasActiveSubscription) {
        if(!subscribedRef.current) {
            //only show the toast if we haven't just subscribed
            const toastrService = new ToastrService();
            toastrService.showInfo('', 'You already have an active subscription for this profile');
        }
        return <Redirect to="/" />;
    }

    const trialEnd = stripePricingPlan ?  moment().add(stripePricingPlan.TrialDays, 'days').format('DD MMM YYYY') : '-';
    return (   
        <React.Fragment>
            {
                canRender ?
                    <main className="onboarding">
                        <button className="close-page" onClick={() => history.push('/subscription')} />
                        <div className="onboarding__content">
                            <Header className="page__branding" />
                            <AnimateIn className="onboarding__body">
                                <form className="form form--lined form--login" onSubmit={handleSubmit}>
                                    <h4 className="text-center">Enter your payment details</h4>                            
                                    <div className="form-group">
                                        <label>Select currency</label>
                                        <select className="form-control select" name="currency" ref={currencyRef} required value={currency ? currency.ID : ''}>
                                            <option value="" disabled>Select currency</option>
                                            {
                                                currencies.filter(c => paymentCurrencies.includes(c.Code)).map(c => 
                                                    <option key={c.ID} value={c.ID}>{c.Symbol} - {c.Description}</option>
                                                )
                                            }
                                        </select>
                                        <label className="mt-4">Card details</label>
                                        <CardSection />
                                        <div className="card-element__text">
                                            Card details and payments are securely stored and handled by <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">Stripe</a>
                                        </div>
                                        <p className="text-center mt-4 text-muted">
                                            You will not be charged for this subscription until {trialEnd}.
                                            You can cancel anytime within the trial period if you do not wish to continue with the subscription.
                                            After the trial, you will be charged {currency.Symbol}{stripePricingPlan.Value} on {selectedPricingPlan.Frequency === 'Annual' ? 'an annual' : 'a monthly'} basis.
                                        </p>
                                        <button className="btn btn-block btn--outline btn-transparent-white mt-3" disabled={!stripe || !stripePricingPlan}>Start subscription</button>
                                    </div>                       
                                    
                                </form>
                            </AnimateIn>
                        </div>
                    </main>  
                : 
                null
            }
            {
                !canRender || processingPayment ?
                    <div className="modal-window__loader modal-window__loader-task modal-window__loader-login active">
                        <img src={require('../../images/loader.svg')} alt="loading" />
                        {!canRender ? 'Loading...' : 'Processing Payment...'}
                    </div>
                    : null
            }            
        </React.Fragment>
    );   
}

CreateSubscriptionPage.propTypes = {
    actions: PropTypes.object
    //myProp: PropTypes.string.isRequired
};

//the subscription page should pass this down
function getPricingPlan(pricingPlans, artistType, frequency) {
    const pricingPlan = pricingPlans.find(p => p.ArtistType === artistType && p.Frequency === frequency);
    return pricingPlan;
}

function mapStateToProps(state, ownProps) {
    let pricingPlan = null;
    if(state.pricingPlans && state.pricingPlans.length > 0) {
        const artistType = state.account.user.activeProfile.ArtistType;
        pricingPlan = {
            Monthly: getPricingPlan(state.pricingPlans, artistType, 'Monthly'),
            Annual: getPricingPlan(state.pricingPlans, artistType, 'Annual')
        };
    }
    return { 
        user: state.account.user,
        userLoading: state.account.userLoading,
        currencies: state.currencies,
        pricingPlan: pricingPlan
    };
}


function mapDispatchToProps(dispatch) {
    return {
        //actions will now be available under props.actions
        actions: bindActionCreators(Object.assign({}, artistActions, pricingActions, currencyActions, accountActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSubscriptionPage);
