import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as resourceActions from '../../actions/resourceActions';
import { getFileSizeText, readPDF, getFileSizeTextFromBase64, getBase64Url } from '../../utils/fileUtils';
import FinaliseSave from './FinaliseSave';
import { getShortDate } from '../../utils/dateUtils';
import ToastrService from '../../services/toastrService';
import { showImage, showFile } from '../../utils/widgetUtils';
import { checkSubscription } from '../../utils/artistUtils';
import AnalyticsService from '../../services/analyticsService';

function FileModal({item, user, actions, allTags, viewMode = false}) {
    const activeProfile = user.activeProfile;
    const [savingFile, setSavingFile] = useState(false);
    const [loadingFile, setLoadingFile] = useState(false);
    const [file, setFile] = useState();
    const [fileData, setFileData] = useState();
    const [fileName, setFileName] = useState('');        
    const [fileSize, setFileSize] = useState(false);    
    const [proceed, setProceed] = useState(item && !viewMode);
    const isEdit = item && proceed;

    useEffect(() => {
        if(item && !file && !proceed) {
            setLoadingFile(true);
            actions.getFile(activeProfile.ID, item.ID)
                .then(file => {
                    setFile(file);
                    setFileName(file.Filename);
                    setLoadingFile(false);
                })
                .catch(error => setLoadingFile(false));
        }        
    }, [isEdit])

    useEffect(() => {
        if(file) {                 
            setFileSize(getFileSizeText(file.Length));
        }
    }, [file]);

    const noteRef = useRef();
    const finaliseRef = useRef();

    useEffect(() => {
        if(proceed) { 
            window.$(noteRef.current).slideUp();
            window.$(finaliseRef.current).slideDown();
        }
        else {            
            window.$(noteRef.current).slideDown();
            window.$(finaliseRef.current).slideUp();
        }
    }, [proceed])

    useEffect(() => {
        let eventName = isEdit ? 'Edit Vault File Launched' : 'Add Vault File Launched';
        AnalyticsService.logEvent(eventName);
    }, []);

    const finaliseCancelled = (event) => {
        if(event) {
            event.preventDefault();
        }
        setProceed(false);
    }

    const getItem = async (event) => {
        //if it's a new item, we'll have the fileData and we need to send the file name and data string, else we send the file id as we don't allow the file to be updated
        return {
            ID: item ? item.ID : null,
            Type: 'File',
            FileType: 'PDF',
            Version: item ? item.Version : 0,
            Filename: fileData ? fileName : null,
            DataString: fileData ? fileData.split(',')[1] : null,
            FileID: item ? item.FileID : null
        }
    }

    const onFileUploaded = (event) => {
        if(event.target.files.length) {     
            const file = event.target.files[0];   
            readPDF(event, (upload) => {
                setFileName(file.name)
                setFileSize(getFileSizeText(file.size));
                setFileData(upload.target.result)
            }, (error) => {
                const toastrService = new ToastrService();
                toastrService.showError('', error);
            }, 10);
        }
    }

    const getFileSource = () => {
        if(fileData) {
            //for the initial upload, the image is already base64 encoded
            return fileData;
        }
        else if(file) {
            return getBase64Url(file.Data);
        }
    }

    /*const openFile = (base64) => {
        //we can call this with the file source
        let pdfWindow = window.open('');
        pdfWindow.document.write(`<iframe width='100%' height='100%' src='${encodeURI(base64)}'></iframe>`);
    }*/

    const showItemFile = (event) => {
        event.preventDefault();
        const target = event.target;
        if(fileData) {
            //for the initial upload, the file is already base64 encoded
            showFile(fileData, target);
        }
        else if(file) {            
            const src =  `data:${file.MetaData.ContentType};base64,${file.Data}`;
            showFile(src, target);                
        }
    }

    return ( 
        <div className="modal-dialog modal-lg modal-dialog--view-task">
            {
                savingFile || loadingFile?
                    <div className="modal-window__loader modal-window__loader-task">
                        <img src={require('../../images/loader.svg')} alt="" />
                        {
                            loadingFile ? 
                                'Loading file...'
                                : 
                                isEdit ? 
                                    'Saving file' 
                                    : 
                                    'Creating file'
                        }
                    </div>
                    : null
            }            
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">{item && proceed ? 'Edit File' : (item ? 'View File' : 'New File')}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i className="mikeicon-x" />
                    </button>
                </div>
                <div className="modal-body">
                    <div className="note-content">
                        <form className="form form--lined form--inputbg">
                            <div className="note-description-wrapper" ref={noteRef}>
                                {
                                    !item ?
                                        !fileData ?
                                            <div className="form-group">
                                                <div className="browse-file__title browse-doc__title">Please select a PDF file to upload</div>
                                                <button type="button" className="btn btn-primary btn-wide m-1 new-income__btn browse-file__btn add-new-file" download="">                                     
                                                    <input type="file" name="file" id="file" className="inputfile" accept="application/pdf" onChange={onFileUploaded} />
                                                    <label htmlFor="file"> BROWSE FILE</label>                                   
                                                </button>
                                            </div>
                                            : null
                                        :
                                        <div className="note-view">
                                            <div className="note-view__header note-view__header--file">
                                                <div className="note-view__title">
                                                    {item.Name}
                                                </div>                                    
                                                <div className="note-view__date">
                                                    {getShortDate(item.CreatedDate)}
                                                </div>
                                            </div>
                                        </div>                                
                                }
                                {
                                    file || fileData ?
                                        <div className="note-image-view">
                                            <a className="browse-file__wrapper popup-file-link" onClick={showItemFile}>
                                            {/*<a className="browse-file__wrapper popup-file-link" href={getFileSource()} download={fileName}>*/}
                                                <img src={require('../../images/vault-file-pdf.png')} alt="" />
                                                <span className="note-image-view__title">
                                                    {fileName}
                                                </span>
                                                <span className="note-image-view__weight">
                                                    {fileSize}
                                                </span>
                                            </a>
                                        </div>        
                                        : null
                                }                                
                                <div className="form-group text-center mt-4 modal-buttons">
                                    <button type="button" className="btn btn-outline btn-wide m-1" data-dismiss="modal">Cancel</button>
                                    <button type="button" className="btn btn-primary btn-wide m-1 add-note-button" onClick={() => checkSubscription(activeProfile, () => setProceed(true), 'update this file')}>{item ? 'Edit' : 'Save File'}</button>
                                </div>
                            </div>
                            <FinaliseSave 
                                onCancel={finaliseCancelled} 
                                finaliseRef={finaliseRef}
                                user={user}
                                name={item ? item.Name : ''}
                                isPublic={item ? item.Visibility === 'Public' : true}
                                itemTags={item ? item.Tags : []}
                                allTags={allTags}
                                actions={actions}
                                getItem={getItem}
                                setSaving={setSavingFile} />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user
    };
}


function mapDispatchToProps(dispatch) {
    return {
        //actions will now be available under props.actions
        actions: bindActionCreators(Object.assign({}, resourceActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FileModal);