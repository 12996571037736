import * as types from '../actions/actionTypes';
import {saveState} from '../utils/localStorage';

export default function localStorageMiddleware() {
    return ({ dispatch, getState }) => next => action => {
        if (action.type === types.GET_USER_SUCCESS || action.type === types.TOKEN_REFRESHED || action.type === types.SWITCH_ACTIVE_PROFILE) {
            const result = next(action)
            const state = getState(); //this will now give us the new state after calling next
            const account = state.account;
            saveState({
                account: {
                    token: account.token,
                    tokenExpiry: account.tokenExpiry,
                    refreshToken: account.refreshToken,
                    activeProfileID: account.activeProfileID,
                    user: {
                        Email: account.user.Email //we need the email to be able to refresh the token 
                    }
                } 
            });
            return result;
        }  
        else if(action.type === types.LOGOUT) {
            saveState({});
        }
        return next(action);
    };
  }
