import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as accountActions from '../../actions/accountActions';
import Header from './Header';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';

function DeleteAccountPage({user, actions, showModal, history}) {
    const [deleting, setDeleting] = useState(false);
    

    const deleteAccount = (event) => {
        event.preventDefault();
        swal({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover your account!',
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                setDeleting(true);
                actions.deleteAccount()
                    .then(result => setDeleting(false))
                    .catch(error => setDeleting(false));
            }
        });
    }

    const hasBandWithMembers = user.Artists.find(a => a.ArtistType === 'Band' && a.Members.length) != null;
    const hasSubscription = user.Artists.find(a => a.HasActiveSubscription) != null;

    const getDeleteText = () => {

        let text = [<p style={{ textAlign: 'center' }}>Click the button below to delete your account.</p>] 
        if(hasSubscription) {
            text.push(<p style={{ textAlign: 'center' }}>**You will need to ensure that you have deleted your payment subscription/s as a separate step. Check your subscription at <Link to='/subscription/manage'>this link</Link>.</p>);
        }
        if(hasBandWithMembers) {
            text.push(<p style={{ textAlign: 'center' }}>**Any band members you share a profile with will still be able to access the data in that profile.</p>);
        }
        return text;
    }

    return (
        <React.Fragment>
            <main className="">
                <button className="close-page" onClick={() => history.push('/')} />
                <div className="container">
                    <Header className="page__branding" />
                    <div className="members">         
                        <div className="account-setting__content">
                            <div className="setting-form"> 
                                <h1 className="members__title">Delete Account</h1>
                                <form className="form form--lined form--inputbg" onSubmit={deleteAccount}>
                                    {getDeleteText()}
                                    <div className="form-group text-center mt-4 modal-buttons">
                                        <button type="submit" className="btn btn-primary btn-wide m-1">Delete account</button>
                                    </div>
                               </form>
                            </div>
                        </div>    
                    </div>
                </div>
            </main>
            {
                deleting ?
                    <div className="modal-window__loader modal-window__loader-task modal-window__loader-login active">
                        <img src={require('../../images/loader.svg')} alt="loading" />
                        Deleting account...                     
                    </div>
                    : null
            } 
        </React.Fragment>
    )
}

function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //actions will now be available under props.actions
        actions: bindActionCreators(Object.assign({}, accountActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccountPage);