import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as accountActions from '../../actions/accountActions';
import * as eventActions from '../../actions/eventActions';
import '../../styles/style-ui.scss';
import Header from '../common/Header';
//import * as eventUtils from '../../utils/eventUtils';
import * as artistUtils from '../../utils/artistUtils';
import Footer from '../common/Footer';
import ToastrService from '../../services/toastrService';
import moment from 'moment';
import { withModalContext } from '../../services/modalService';
import EventModal from './EventModal';
import EmptyView from '../common/EmptyView';
import swal from 'sweetalert';
import { getResourceDescription } from '../../utils/resourceUtils';
import ArtistNameHeader from '../common/ArtistNameHeader';
import { eventsUpcomingPageHeaders, eventsPastPageHeaders, noPastEventsMessages, noUpcomingEventsMessages, noUpcomingGigsMessages, noUpcomingMeetingsMessages, noUpcomingOtherEventsMessages, noUpcomingRehearsalsMessages } from '../../constants/appMessages';
import _ from 'lodash';

function EventsPage({user, actions, showModal, location, history}) {   

    const [selectedTab, setSelectedTab] = useState('Upcoming');
    const [filter, setFilter] = useState('All Events');
    const [type, setType] = useState('All');
    const [showMenuForEvent, setShowMenuForEvent] = useState();
    const activeProfile = user.activeProfile;
    const [updatingAttendance, setUpdatingAttendance] = useState(false);
    const userIsGoing = (userID, event) => event.Attendances.find(a => a.UserID === userID && a.Status === 'Attending') != null; //we can't use !== as it will fail for undefined
    const userIsNotGoing = (userID, event) => event.Attendances.find(a => a.UserID === userID && a.Status === 'NotAttending') != null;
    const types = ['All', 'Gig', 'Rehearsal', 'Meeting', 'Other'];
    const resource = location.state && location.state.resource ? location.state.resource : null;
    const [resourceToLink, setResourceToLink] = useState(resource);
    const [linkingResource, setLinkingResource] = useState(false);    
    let headerTextUpcoming = useRef(_.sample(eventsUpcomingPageHeaders)).current;
    let headerTextPast = useRef(_.sample(eventsPastPageHeaders)).current;
    let headerText = selectedTab === 'Upcoming' ? headerTextUpcoming: headerTextPast;

    const upcomingFilters = [{
        Name: 'All Events',
        Filter: (e) => true
    }, {
        Name: 'This week only',
        Filter: (e) => moment(e.StartDate) <= moment().endOf('week').endOf('day')
    }, {
        Name: 'This month only',
        Filter: (e) => moment(e.StartDate) <= moment().endOf('month').endOf('day')
    }];

    if(activeProfile.ArtistType === 'Band') {
        upcomingFilters.push({
            Name: 'Tentative events only',
            Filter: (e) => e.Status === 'Tentative'
        });
        upcomingFilters.push({
            Name: 'Confirmed events only',
            Filter: (e) => e.Status === 'Confirmed'
        })
    }

    const filters = {
        Upcoming: upcomingFilters,
        Past: [{
            Name: 'All Events',
            Filter: (e) => true
        }, {
            Name: 'Last 7 days',
            Filter: (e) => {
                const earliestDate = moment().startOf('day').add(-7, 'days')
                return moment(e.StartDate) >= earliestDate || (e.EndDate && moment(e.EndDate) >= earliestDate)
            } 
        }, {
            Name: 'Last 30 days',
            Filter: (e) => {
                const earliestDate = moment().startOf('day').add(-30, 'days')
                return moment(e.StartDate) >= earliestDate || (e.EndDate && moment(e.EndDate) >= earliestDate)
            } 
        }, {
            Name: 'Last 60 days',
            Filter: (e) => {
                const earliestDate = moment().startOf('day').add(-60, 'days')
                return moment(e.StartDate) >= earliestDate || (e.EndDate && moment(e.EndDate) >= earliestDate)
            } 
        }, {
            Name: 'Last 90 days',
            Filter: (e) => {
                const earliestDate = moment().startOf('day').add(-90, 'days')
                return moment(e.StartDate) >= earliestDate || (e.EndDate && moment(e.EndDate) >= earliestDate)
            } 
        }]
    }

    const updateFilter = (event, filter) => {
        event.preventDefault();
        setFilter(filter);
    }

    const updateSelectedTab = (tab) => {
        setSelectedTab(tab);
        setFilter('All Events');
        setShowMenuForEvent(null);
    }

    //initialise events
    
    const currentFilter = filters[selectedTab].find(f => f.Name === filter);
    const now = new Date();
    let events = activeProfile.Events.map(e => {
        return {...e, DateStatus: (e.StartDate >= now || (e.EndDate && e.EndDate >= now)) ? 'Upcoming' : 'Past'};
    });
    const haveUpcomingEvents = events.find(e => e.DateStatus === 'Upcoming');
    if(haveUpcomingEvents && selectedTab === 'Upcoming') {
        headerText = _.trimEnd(headerText, ':') + ', great job staying busy!';
    }
    events = events.filter(e => e.DateStatus === selectedTab && currentFilter.Filter(e) && (type === 'All' || e.Type === type));
    if(selectedTab === 'Upcoming') {
        events = events.sort((a, b) => a.StartDate - b.StartDate);
    }
    else {
        events = events.sort((a, b) => b.StartDate - a.StartDate);
    }
    const groupedEvents = {};
    events.forEach(event => {
        const dateKey = moment(event.StartDate).format('MMMM yyyy');
        if(!groupedEvents[dateKey]) {
            groupedEvents[dateKey] = [event];
        }
        else {
            groupedEvents[dateKey].push(event);
        }
    })

    const noUpcomingEventsMessage = useRef(_.sample(noUpcomingEventsMessages)).current;
    const noUpcomingGigsMessage = useRef(_.sample(noUpcomingGigsMessages)).current;
    const noUpcomingRehearsalsMessage = useRef(_.sample(noUpcomingRehearsalsMessages)).current;
    const noUpcomingMeetingsMessage = useRef(_.sample(noUpcomingMeetingsMessages)).current;
    const noUpcomingOtherEventsMessage = useRef(_.sample(noUpcomingOtherEventsMessages)).current;
    const noPastEventsMessage = useRef(_.sample(noPastEventsMessages)).current;

    const getActiveClass = (tabName) => {
        if(selectedTab === tabName) {
            return 'active';
        }
        return '';
    }

    const createNewEvent = (e) => {
        e.preventDefault();
        e.stopPropagation();
        artistUtils.checkSubscription(activeProfile, () => {
            const eventModal = <EventModal />
            showModal(() => eventModal, { isOpen: true })
        }, 'create an event');        
    }

    const editEvent = (e, event) => {
        e.preventDefault();
        e.stopPropagation();
        artistUtils.checkSubscription(activeProfile, () => {
            const eventModal = <EventModal eventID={event.ID} />
            showModal(() => eventModal, { isOpen: true })
        }, 'edit an event');        
    }

    const cloneEvent = (e, event) => {
        e.preventDefault();
        e.stopPropagation();
        artistUtils.checkSubscription(activeProfile, () => {
            const eventModal = <EventModal eventID={event.ID} clone={true} />
            showModal(() => eventModal, { isOpen: true })
        }, 'clone an event')        
    }

    const deleteEvent = (e, event) => {
        e.preventDefault();        
        e.stopPropagation();
        artistUtils.checkSubscription(activeProfile, () => {
            swal({
                title: 'Are you sure?',
                text: 'Once deleted, you will not be able to recover this event!',
                buttons: true,
                dangerMode: true,
              })
              .then((willDelete) => {
                if (willDelete) {
                    actions.deleteEvent(activeProfile.ID, event.ID);
                }
            });
        }, 'delete an event');        
    }

    const viewEvent = (e, event) => {
        e.preventDefault();
        const eventModal = <EventModal eventID={event.ID} viewMode={true} />
        showModal(() => eventModal, { isOpen: true })
    }

    useEffect(() => {
        window.$(document).on('click', '', closeDropDown);
        return () => {
            window.$(document).off('click', '', closeDropDown);
        }
    }, []);

    const closeDropDown = (e) => {
        const div = window.$('.list-row__menu');    
        if (!div.is(e.target) && div.has(e.target).length === 0) {
            div.removeClass('active');
        }
    }

    const eventMenuClicked = (e, event) => {
        e.preventDefault();
        e.stopPropagation();
        setShowMenuForEvent(showMenuForEvent !== event.ID ? event.ID : null)
    }

    const getEventClass = (event) => {
        if(event.Status === 'Tentative') {
            return 'events-item--orange';
        }
        else if(event.Status === 'Cancelled') {
            return 'events-item--cancel';
        }
        else {
            return '';
        }
    }

    const setAttendance = (e, status, event) => {
        e.preventDefault();
        e.stopPropagation();
        if(event.DateStatus === 'Upcoming') {
            artistUtils.checkSubscription(activeProfile, () => {
                if(!updatingAttendance) {
                    const existingAttendance = event.Attendances.find(a => a.UserID === user.ID);
                    let attendance;
                    if(existingAttendance) {
                        //use the exising attendance object so the version number will be correct. if the status matches the existing status, reset to Pending
                        attendance = {...existingAttendance, Status: existingAttendance.Status === status ? 'Pending' : status };
                    }
                    else {
                        attendance = { UserID: user.ID, Status: status };
                    }
                    setUpdatingAttendance(true);
                    actions.setEventAttendance(activeProfile.ID, event.ID, attendance)
                        .then(_ => setUpdatingAttendance(false))
                        .catch(error => setUpdatingAttendance(false));
                }
            }, 'update your attendance');
        }
        else {
            const toastrService = new ToastrService();
            toastrService.showInfo('', 'It looks like this event has already been and gone!');
        }        
    }

    const resourceDescription = resourceToLink ? getResourceDescription(resourceToLink) : null;

    const linkResource = (e, event) => {
        e.preventDefault();
        artistUtils.checkSubscription(activeProfile, () => {
            if(!event.ResourceIDs.includes(resourceToLink.ID)) {
                setLinkingResource(true);
                actions.linkEventResource(activeProfile.ID, event.ID, resourceToLink.ID)
                    .then(result => {
                        setLinkingResource(false);
                        history.push('/vault');
                    })
                    .catch(error => setLinkingResource(false));
            }
            else {
                const toastrService = new ToastrService();
                toastrService.showError('', `The ${resourceDescription} has already been linked to this event`);
            }
        }, `link the ${resourceDescription}`);       
    }

    const dateKeys = Object.keys(groupedEvents);
    const getEmptyText = () => {
        if(filter !== 'All Events' || (selectedTab === 'Past' && type !== 'All')) {
            return `You don't have any ${(type !== 'All' || filter !== 'All Events') ? 'matching' : selectedTab.toLowerCase()} events`
        }
        else {
            if(type === 'All') {
                return selectedTab === 'Upcoming' ? noUpcomingEventsMessage : noPastEventsMessage;
            }
            else if(type === 'Gig') {
                return noUpcomingGigsMessage;
            }
            else if(type === 'Rehearsal') {
                return noUpcomingRehearsalsMessage;
            }
            else if(type === 'Meeting') {
                return noUpcomingMeetingsMessage;
            }
            else if(type === 'Other') {
                return noUpcomingOtherEventsMessage;
            }
        }
        return '';
    }

    return (        
        <div className="home">
            <Header />
            <main className="content">
                {
                    resourceToLink ?
                        <div className="prompt">    
                            <div className="prompt__text">
                                Please select an event to link the {resourceDescription} to
                            </div>
                            <div className="prompt__close" onClick={() => setResourceToLink(null)}>+</div>
                        </div>
                    : null
                }
                <div className="container">
                    <ArtistNameHeader user={user} greeting={`Hey ${user.FirstName}, ${headerText}`} />
                    <div className="content-body">
                        <div className="tabs-header">
                            <div className="actions float-right">
                                {
                                    !resourceToLink ? 
                                        <a href="#" className="btn-text btn-new" onClick={createNewEvent}>
                                            <i className="mikeicon-pluscircle"></i>
                                            New Event
                                        </a>
                                        : null
                                }
                                <div className="dropdown ml-3">
                                    <a className="btn-text-simple dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className={`mikeicon-filter ${filter !== 'All Events' ? 'active' : ''}`}></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-animated dropdown-menu-right dropdown-menu--filter" aria-labelledby="filterDropdown">
                                        {
                                            filters[selectedTab].map(f => 
                                                <a key={`${selectedTab}-${f.Name}`} className={`dropdown-item ${filter === f.Name ? 'choosen': ''}`} href="#" onClick={(event) => updateFilter(event, f.Name)}>{f.Name}</a>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                            <ul className="nav nav-tabs nav-tabs-simple nav-tabs-tasks" role="tablist">
                                <li className="nav-item">
                                    <a className={`nav-link ${getActiveClass('Upcoming')}`} id="upcoming-tab" href="#" onClick={(e => updateSelectedTab('Upcoming'))} role="tab" aria-controls="upcoming" aria-selected={selectedTab === 'Upcoming'}>Upcoming<span className="d-none d-lg-inline"> Events</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${getActiveClass('Past')}`} id="past-tab" href="#" onClick={(e => updateSelectedTab('Past'))} role="tab" aria-controls="past" aria-selected={selectedTab === 'Past'}>Past<span className="d-none d-lg-inline"> Events</span></a>
                                </li>
                            </ul>
                        </div>
                        
                        <div className="tab-content">
                            <div className="event-page__tab-header">
                                <div className="event-type-tabs">
                                    {
                                        types.map(t => 
                                            <div key={t} className={`event-tab ${t === type ? 'active' : ''}`} onClick={() => setType(t)}>{t}</div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="tab-pane fade show active" id="upcoming" role="tabpanel" aria-labelledby="upcoming-tab">
                                {
                                    dateKeys.length ?
                                        dateKeys.map(dateKey => {                                                
                                            return <div key={dateKey} className="list">
                                                <div className="list-head events-tab__header">
                                                    <h3 className="m-0">
                                                        {dateKey}
                                                    </h3>
                                                </div>
                                                <div className="list-body events__list">
                                                    {
                                                        groupedEvents[dateKey].map(event => {
                                                            const startDateISOParts = event.StartDate.toISOString().split("T");
                                                            const startDate = new Date(startDateISOParts[0] + 'T' + startDateISOParts[1].substring(0, 5));
                                                            const startDateMoment = moment(startDate);
                                                            const going = userIsGoing(user.ID, event);
                                                            const notGoing = userIsNotGoing(user.ID, event);

                                                            return <a key={event.ID} href="#" className={`list-row list-row-center list-row-link ${getEventClass(event)}`} onClick={(e) => resourceToLink ? linkResource(e, event) : viewEvent(e, event)}>
                                                                {
                                                                    !resourceToLink ?
                                                                        <span className={`list-row__menu ${showMenuForEvent === event.ID ? 'active' : ''}`}>
                                                                            <span className="list-row__menu-btn" onClick={(e) => eventMenuClicked(e, event)}>
                                                                                <span></span>
                                                                                <span></span>
                                                                                <span></span>
                                                                            </span>
                                                                            <span className="list-row__menu-dropdown list-row__menu-dropdown--event">
                                                                                <span className="list-row__menu-dropdown-item list-row__menu--view" onClick={(e) => viewEvent(e, event)}>
                                                                                    <img src={require('../../images/eye.svg')} /> 
                                                                                    <span>View</span>
                                                                                </span>
                                                                                <span className="list-row__menu-dropdown-item list-row__menu--edit" onClick={(e) => editEvent(e, event)}>
                                                                                    <img src={require('../../images/icon-goals-edit.png')} />
                                                                                    <span>Edit</span>
                                                                                </span>  
                                                                                <span className="list-row__menu-dropdown-item list-row__menu--clone" onClick={(e) => cloneEvent(e, event)}>
                                                                                    <img src={require('../../images/sheet.svg')} />
                                                                                    <span>Clone</span>
                                                                                </span>
                                                                                <span className="list-row__menu-dropdown-item list-row__menu--delete" onClick={(e) => deleteEvent(e, event)}>
                                                                                    <img src={require('../../images/icon-goals-delete.png')} />
                                                                                    <span>Delete </span>                                                            
                                                                                </span> 
                                                                            </span>                             
                                                                        </span>
                                                                        : null
                                                                }
                                                                <div className="list-col list-col-100 events-tab__date-wrapper">
                                                                        <span className="date events-tab__date">
                                                                            <p className="date-day">{startDateMoment.format('DD')}</p>
                                                                            <p className="date-week-day">{startDateMoment.format('ddd').toUpperCase()}</p>
                                                                        </span>
                                                                        <div className="events-tab__time">
                                                                            {
                                                                                startDateMoment.format('h:mm A')
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="list-col list-col--event">
                                                                        <p>{event.Name}</p>
                                                                        <div className="events-tab__status-info__wrapper">
                                                                        {
                                                                            event.Attendances.map(a =>
                                                                                <div className={`events-tab__name-info ${user.ID === a.UserID ? 'active' : 'no-active'}`} style={{ marginRight: '5px' }}>
                                                                                    <div className="wrap">
                                                                                        <span>
                                                                                            {artistUtils.getMemberName(user, activeProfile.Members, a.UserID)}
                                                                                        </span>
                                                                                        {
                                                                                            a.Status === 'Attending' ? <img src={require('../../images/icon-schedule-tag-checkmark-active.png')} alt="" className="events-tab__active active" /> : null 
                                                                                        }
                                                                                        {                                                                                                
                                                                                            a.Status === 'NotAttending' ? <img src={require('../../images/icon-schedule-tag-x-inactive.png')} alt="" className="events-tab__inactive active" /> : null
                                                                                        }
                                                                                        {                                                                                                
                                                                                            a.Status === 'Pending' ? <img src={require('../../images/icon-schedule-tag-question.png')} alt="" className="events-tab__not-select__status active" /> : null
                                                                                        }
                                                                                    </div>                                                                                
                                                                                </div>
                                                                            )
                                                                        } 
                                                                        </div>                                                                           
                                                                        <div className="events-tab__location">
                                                                            <img src={require('../../images/icon-schedule-geo.png')} alt="" />
                                                                            {event.Location}
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        !resourceToLink && event.Status !== 'Cancelled' ?
                                                                            <div className="list-col list-col-100 list-col-center list-col-type event-tab__status-wrapper">
                                                                                <div className="event-tab__status">
                                                                                    <div className={`event-tab__no ${notGoing ? 'active' : 'no-active'}`} onClick={(e) => !updatingAttendance ? setAttendance(e, 'NotAttending', event) : null}>
                                                                                        <svg height="329pt" viewBox="0 0 329.26933 329" width="329pt"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/></svg>
                                                                                    </div>

                                                                                    <div className={`event-tab__yes ${going ? 'active' : 'no-active'}`} onClick={(e) => !updatingAttendance ? setAttendance(e, 'Attending', event) : null}>
                                                                                        <svg version="1.1" id="Capa_1" x="0px" y="0px"
                                                                                            viewBox="0 0 426.667 426.667" style={{enableBackground: 'new 0 0 426.667 426.667'}}>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <path d="M421.876,56.307c-6.548-6.78-17.352-6.968-24.132-0.42c-0.142,0.137-0.282,0.277-0.42,0.42L119.257,334.375
                                                                                                        l-90.334-90.334c-6.78-6.548-17.584-6.36-24.132,0.42c-6.388,6.614-6.388,17.099,0,23.713l102.4,102.4
                                                                                                        c6.665,6.663,17.468,6.663,24.132,0L421.456,80.44C428.236,73.891,428.424,63.087,421.876,56.307z"/>
                                                                                                </g>
                                                                                            </g>

                                                                                        </svg> 
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                    
                                                            </a>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        })
                                    :
                                    <EmptyView text={getEmptyText()} buttonText="Create event" onButtonClick={createNewEvent} />
                                }
                                    
                            </div>                           
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
            {
                linkingResource ?
                    <div className="modal-window__loader modal-window__loader-task modal-window__loader-sign-up active">
                        <img src={require('../../images/loader.svg')} alt="loading" />
                        Linking {resourceDescription} to event...
                    </div>
                    : null
            }   
        </div>
    );
}


EventsPage.propTypes = {
    //myProp: PropTypes.string.isRequired
};


function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user
    };
}


function mapDispatchToProps(dispatch) {
    return {
        //event actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, eventActions), dispatch)
    };
}

export default withModalContext(connect(mapStateToProps, mapDispatchToProps)(EventsPage));