import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';

function SweetAlertExternalLoginInput({label, email, ...rest}) {
    const [text, setText] = useState(email);
    const [marketingOptIn, setMarketingOptIn] = useState(false);
    
    useEffect(() => {
        if(email) {
            swal.setActionValue({ confirm: { email, marketingOptIn }});
        }
    }, [])

    const changeText = (e) => {
        const text = e.target.value;
        setText(text);
        swal.setActionValue({ confirm: { email: text, marketingOptIn }});
    }

    const changeMarketingOptIn = (e) => {
        const checked = e.target.checked;
        setMarketingOptIn(checked);
        swal.setActionValue({ confirm: { email: text, marketingOptIn: checked }});
    }

    return (
        <form className="form form-signup form-tightfields">
            <div className="form-group mb-3">
                <label htmlFor="alertInput">Email address</label>
                <input id="alertInput" name="alertInput" type="email" className="form-control" value={text} onChange={changeText} {...rest} />                
            </div>                 
            <div className="custom-control custom-checkbox mb-3 subscription-checkbox">
                <input type="checkbox" className="custom-control-input" id="marketingOptInExternal" checked={marketingOptIn} onChange={changeMarketingOptIn} />
                <label className="custom-control-label" style={{color: '#000'}} htmlFor="marketingOptInExternal">Yes, I would like to receive emails with updates and special offers</label>
            </div> 
            <div className="form-group text-center small">
                <p className="text-muted">By signing up, you are confirming that you have read and agree to the <a href="https://www.mikeapp.co/terms" rel="noopener noreferrer" target="_blank" className="btn-text">Terms of use</a> and <a href="https://www.mikeapp.co/privacy" target="_blank" rel="noopener noreferrer" className="btn-text">Privacy Policy</a></p>
            </div>
        </form>                                          
    );
}

SweetAlertExternalLoginInput.propTypes = {
};

export default SweetAlertExternalLoginInput;