/* eslint-disable react/jsx-no-bind */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import PageLoading from '../common/PageLoading.js';
import SidePromo from './SidePromo.js';
import Header from './Header.js';
import Footer from './Footer.js';
import * as accountActions from '../../actions/accountActions';
import * as userUtils from '../../utils/userUtils';
import '../../styles/style-signup.css';
import AnimateIn from '../common/AnimateIn.js';
import ExternalLogin from './ExternalLogin.js';
import { useFormInput, useFormCheckInput } from '../../utils/customHooks.js';
import urls from '../../constants/urls.js';

function SignupPage(props) {
    const [loadingExternalLogin, setLoadingExternalLogin] = useState(false); 
    const emailInput = useFormInput('');
    const passwordInput = useFormInput('');   
    const firstNameInput = useFormInput('');
    const lastNameInput = useFormInput('')
    const marketingInput = useFormCheckInput(false);
    const [isBusy, setIsBusy] = useState(false);

    const signup = (event) => {
        event.preventDefault();
        if(emailInput.value && passwordInput.value && firstNameInput.value && lastNameInput.value) {
            setIsBusy(true);
            props.actions.signup(emailInput.value, passwordInput.value, firstNameInput.value, lastNameInput.value, marketingInput.checked)
            .then(_ => setIsBusy(false))
            .catch(error => setIsBusy(false));
        }
    }

    return (
        userUtils.isAuthenticated(props.user) ?
            <Redirect to={userUtils.getNext(props.user)} />
            :
            !props.userLoading ?
                <React.Fragment>
                    <main className="login">
                        <SidePromo />
                        <div id="loginContent" className="login__content">
                            <Header className="login__branding" />
                            <AnimateIn className="login__form">
                                <div className="login__body">                                    
                                    <form className="form form--lined form--signup" onSubmit={signup}>
                                        <ExternalLogin isSignup={true} setLoadingExternalLogin={setLoadingExternalLogin} location={props.location} actions={props.actions} user={props.user} />
                                        <p className="form-text text-center text-muted text-or">
                                            <span>OR</span>
                                        </p>
                                        <p className="form-text text-center text-muted">Create account</p>
                                       <div className="form-group form-group--name">
                                            <div className="form-prefix">
                                                <i className="mikeicon-user form__picto" />
                                                <input className="form-control" type="text" required placeholder="First name" {...firstNameInput} />
                                            </div>
                                            <div className="form-prefix">
                                                <i className="mikeicon-user form__picto" />
                                                <input className="form-control" type="text" required placeholder="Last name" {...lastNameInput} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-prefix">
                                                <i className="mikeicon-email form__picto" />
                                                <input className="form-control" type="email" required placeholder="Email" {...emailInput} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-prefix">
                                                <i className="mikeicon-password form__picto" />
                                                <input className="form-control" type="password" required placeholder="Password" {...passwordInput} />
                                            </div>
                                        </div>
                                         <div className="custom-control custom-checkbox mb-3 subscription-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="subscription" {...marketingInput} />
                                            <label className="custom-control-label" htmlFor="subscription">Yes, I would like to receive emails with updates and special offers</label>
                                        </div>
                                        <div className="form-group text-center small">
                                            <p className="text-muted">By signing up, you are confirming that you have read and agree to the <a href="https://www.mikeapp.co/terms" rel="noopener noreferrer" target="_blank" className="btn-text">Terms of use</a> and <a href="https://www.mikeapp.co/privacy" target="_blank" rel="noopener noreferrer" className="btn-text">Privacy Policy</a></p>
                                        </div>
                                        <div className="form-group mt-5 form-group--create-account">
                                            <button type="submit" className="btn btn-block btn--outline btn-transparent-white">Create account</button>
                                        </div>
                                         <div className="see-pricing-wrapper">
                                            <a href={`${urls.BASE_URL}/#pricing`} className="see-pricing" target="_blank" rel="noopener noreferrer">
                                                SEE PRICING
                                            </a>
                                        </div>
                                        <div className="form-group text-center mt-4">
                                            <p className="text-muted small">Already have an account? <Link to="/login" className="btn-text btn-gotologin">Log in</Link></p>
                                        </div>
                                    </form>                                   
                                </div>
                            </AnimateIn>
                            <Footer />
                        </div>                                        
                    </main>
                    {
                        isBusy || loadingExternalLogin ?
                            <div className="modal-window__loader modal-window__loader-task modal-window__loader-sign-up active">
                                <img src={require('../../images/loader.svg')} alt="loading" />
                                Creating account...
                            </div>
                            : null
                    }    
                </React.Fragment>
                : 
                <div>
                    <PageLoading />
                </div> 
    );      
}

SignupPage.propTypes = {
    //myProp: PropTypes.string.isRequired
};


function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user,
        userLoading: state.account.userLoading, 
        signupError: state.account.signupError
    };
}


function mapDispatchToProps(dispatch) {
    return {
        //account actions will now be available under props.actions
        actions: bindActionCreators(Object.assign({}, accountActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupPage);
