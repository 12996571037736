/* eslint-disable react/jsx-no-bind */
import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import Header from '../account/Header.js';
import * as currencyActions from '../../actions/currencyActions';
import * as artistActions from '../../actions/artistActions';
import * as accountActions from '../../actions/accountActions';
import '../../styles/style-onboarding.scss';
import AnimateIn from '../common/AnimateIn.js';
import { useFormInput } from '../../utils/customHooks.js';
import 'select2/dist/js/select2';
import 'select2/dist/css/select2.min.css'

function ProfileSetupPage(props) {
    
    const isEditing = props.location.pathname === '/editprofile';
    const [profileType, setProfileType] = useState(isEditing ? props.user.activeProfile.ArtistType : 'Individual');
    const displayNameInput = useFormInput(isEditing ? props.user.activeProfile.DisplayName : '');
    const [defaultCurrencyID, setDefaultCurrencyID] = useState(isEditing ? props.user.activeProfile.DefaultCurrencyID : '');
    const [savingProfile, setSavingProfile] = useState(false);
    const [invitationCode1, setInvitationCode1] = useState('');
    const [invitationCode2, setInvitationCode2] = useState('');
    const [invitationCode3, setInvitationCode3] = useState('');
    const [invitationCode4, setInvitationCode4] = useState('');
    const [invitationCode5, setInvitationCode5] = useState('');
    const [invitationCode6, setInvitationCode6] = useState('');
    const [showInvitationCodeModal, setShowInvitationCodeModal] = useState(false);
    const [validatingInvitationCode, setValidatingInvitationCode] = useState(false);

    const invitationCodeInput1 = useRef();
    const invitationCodeInput2 = useRef();
    const invitationCodeInput3 = useRef();
    const invitationCodeInput4 = useRef();
    const invitationCodeInput5 = useRef();
    const invitationCodeInput6 = useRef();

    useEffect(() => {
        if(!props.currencies.length) {
            props.actions.getCurrencies();
        }
    }, []);

    const currencyRef = useRef();

    const currencyChanged = (event) => {
        const data = event.params.data;
        if(data) {            
            setDefaultCurrencyID(data.id);
        }
    }

    useEffect(() => {
        if(currencyRef.current) {
            const currencyControl = window.$(currencyRef.current);
            currencyControl.select2({
                theme : "classic" 
            })
            .on('select2:select', currencyChanged);
            if(defaultCurrencyID) {
                currencyControl.val(defaultCurrencyID).trigger('change');
            }
        }
    }, []);

    const createProfile = (event) => {
        event.preventDefault();
        if(displayNameInput.value && profileType && defaultCurrencyID) {
            setSavingProfile(true);
            props.actions.createArtist(displayNameInput.value, profileType, defaultCurrencyID)
                .then(result => setSavingProfile(false))
                .catch(error => setSavingProfile(false));
        }
    }

    const editProfile = (event) => {
        event.preventDefault();
        if(displayNameInput.value && defaultCurrencyID) {
            setSavingProfile(true);
            props.actions.updateArtist(props.user.activeProfile.ID, displayNameInput.value, defaultCurrencyID)
                .then(result => setSavingProfile(false))
                .catch(error => setSavingProfile(false));
        }
    }

    const getProfileTypeClass = (text) => {
        const className = `btn btn--outline flex-grow-1 ${profileType.toLowerCase() === text ? 'active' : ''}`;
        return className;
    }

    const close = () => {
        if(isEditing || props.user.Artists.length) {
            props.history.goBack();
        }
        else {
            props.actions.logout(false);
        }
    }

    useEffect(() => {
        window.$(document).on('click', '', closeModal);
        return () => {
            window.$(document).off('click', '', closeModal);
        }
    }, []);

    const closeModal = (e) => { 
        const modalContent = window.$('.modal-window__content');
        if (!modalContent.is(e.target) && modalContent.has(e.target).length === 0) {
          setShowInvitationCodeModal(false);
          //clear invitation code
          setInvitationCode1('');
          setInvitationCode2('');
          setInvitationCode3('');
          setInvitationCode4('');
          setInvitationCode5('');
          setInvitationCode6('');
        }
    }

    useEffect(() => {
        if(showInvitationCodeModal) {
            if(invitationCodeInput1.current) {
                invitationCodeInput1.current.focus();
            }
        }
    }, [showInvitationCodeModal]);

    const invitationCode = (invitationCode1 || '') + (invitationCode2 || '') + (invitationCode3 || '') + (invitationCode4 || '') + (invitationCode5 || '') + (invitationCode6 || '')
    const setInvitationCode = (e) => {
        const code = e.target.value;        
        setInvitationCode1(code[0] || '');
        setInvitationCode2(code[1] || '');
        setInvitationCode3(code[2] || '');
        setInvitationCode4(code[3] || '');
        setInvitationCode5(code[4] || '');
        setInvitationCode6(code[5] || '');
    }

    useEffect(() => {
        if(invitationCode.length === 6) {
            setValidatingInvitationCode(true);
            props.actions.redeemInvitationCode(invitationCode)
                .catch(error => {
                    setValidatingInvitationCode(false);
                    //clear invitation code
                    setInvitationCode1('');
                    setInvitationCode2('');
                    setInvitationCode3('');
                    setInvitationCode4('');
                    setInvitationCode5('');
                    setInvitationCode6('');
                    invitationCodeInput1.current.focus();
                });
        }
    }, [invitationCode1, invitationCode2, invitationCode3, invitationCode4, invitationCode5, invitationCode6]);

    const updateInvitationCode1 = (event) => {
        setInvitationCode1(event.target.value);
        if(event.target.value) {
            invitationCodeInput2.current.focus();
        }
    }

    const updateInvitationCode2 = (event) => {
        setInvitationCode2(event.target.value);
        if(event.target.value) {
            invitationCodeInput3.current.focus();
        }
    }

    const updateInvitationCode3 = (event) => {
        setInvitationCode3(event.target.value);
        if(event.target.value) {
            invitationCodeInput4.current.focus();
        }
    }

    const updateInvitationCode4 = (event) => {
        setInvitationCode4(event.target.value);
        if(event.target.value) {
            invitationCodeInput5.current.focus();
        }
    }

    const updateInvitationCode5 = (event) => {
        setInvitationCode5(event.target.value);
        if(event.target.value) {
            invitationCodeInput6.current.focus();
        }
    }

    const updateInvitationCode6 = (event) => {
        setInvitationCode6(event.target.value);
    }

    return (    
        <React.Fragment>     
            <main className="onboarding">
                <button className="close-page" onClick={close} />
                <div className="onboarding__content">
                    <Header className="page__branding" />
                    <AnimateIn className="onboarding__body add-account-wrapper">
                        <form className="form form--lined form--login" onSubmit={isEditing ? editProfile : createProfile}>
                            {
                                !isEditing ?
                                    <React.Fragment>
                                        <p className="form-text text-center text-muted mb-4">Please set up your profile</p>
                                        <div className="form-group">
                                            <div className="btn-group btn-group--type btn-group--switchsingle d-flex">
                                                <button type="button" className={getProfileTypeClass('individual')} onClick={(e) => setProfileType('Individual')}>
                                                    Individual
                                                </button>
                                                <button type="button" className={getProfileTypeClass('band')} onClick={(e) => setProfileType('Band')}>
                                                    Band
                                                </button>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    : null
                            }
                            
                            <div className="form-group">
                                <label>Name</label>
                                <input name="displayName" className="form-control" type="text" required {...displayNameInput} placeholder="Enter display name" />
                            </div>
                            <div className="form-group">
                                <label>Select default currency</label>
                                <select className="form-control select" name="defaultCurrencyID" ref={currencyRef} required value={defaultCurrencyID}>
                                    <option value="" disabled>Select currency</option>
                                    {
                                        props.currencies.map(currency => 
                                            <option key={currency.ID} value={currency.ID} >{currency.Symbol} - {currency.Description}</option>
                                        )
                                    }
                                </select>
                                <p className="form-text form-text--prompt">This is the currency you want to use for managing finances</p>
                            </div>
                            <div className="form-group form-group--buttons">
                                <button type="submit" className="btn btn-block btn-primary">{isEditing ? 'SAVE PROFILE' : (props.user.Artists.length ? 'CREATE ADDITIONAL PROFILE' : 'CREATE PROFILE')}</button>
                            </div>
                            {
                                profileType === 'Band' && !isEditing ?
                                    <React.Fragment>
                                        <div className="serarator-wrapper">
                                            <div className="serarator">
                                                or
                                            </div> 
                                        </div>                   
                                        <div className="form-group">
                                            <button type="button" className="btn btn-block btn-primary btn-primary--last" onClick={() => setShowInvitationCodeModal(true)}>My band is already using Mike</button>
                                        </div>
                                    </React.Fragment>
                                    : null
                            }
                        </form>
                    </AnimateIn>
                </div>
                <div className={`modal-window ${showInvitationCodeModal ? 'active' : ''}`}>
                    <div className="modal-window__content load">
                        {
                            validatingInvitationCode ?
                                <div className="modal-window__loader">
                                    <img src={require('../../images/loader.svg')} alt='loading' />
                                    Validating invitation code
                                </div>
                                : null
                        }                   
                        <div className="close" onClick={() => setShowInvitationCodeModal(false)}>+</div>
                        <div className="modal-window__title">
                            CONFIRMATION
                        </div>
                        <div className="modal-window__text">
                            Please enter your invitation code
                        </div>

                        <div className="modal-window__code-wrapper">
                            <input type="password" className={invitationCode1 ? 'active' : ''} maxLength="1" ref={invitationCodeInput1} value={invitationCode1} onChange={updateInvitationCode1} />            
                            <input type="password" className={invitationCode2 ? 'active' : ''} maxLength="1" ref={invitationCodeInput2} value={invitationCode2} onChange={updateInvitationCode2} />          
                            <input type="password" className={invitationCode3 ? 'active' : ''} maxLength="1" ref={invitationCodeInput3} value={invitationCode3} onChange={updateInvitationCode3} />  
                            <input type="password" className={invitationCode4 ? 'active' : ''} maxLength="1" ref={invitationCodeInput4} value={invitationCode4} onChange={updateInvitationCode4} />
                            <input type="password" className={invitationCode5 ? 'active' : ''} maxLength="1" ref={invitationCodeInput5} value={invitationCode5} onChange={updateInvitationCode5} />
                            <input type="password" className={invitationCode6 ? 'active' : ''} maxLength="1" ref={invitationCodeInput6} value={invitationCode6} onChange={updateInvitationCode6} />
                        </div>
                        <div className="modal-window__past" onClick={() => window.$('.modal-window__past-code').slideToggle()}>
                            [Paste Invitation Code]
                        </div>
                        <div className="modal-window__past-code">
                            <div className="form-group">
                                <input className="form-control" type="text" placeholder="Invitation Code" maxLength="6" value={invitationCode} onChange={setInvitationCode} /> 
                            </div>                            
                            <div className="modal-window__btn-wrapper">
                                <button className="btn" onClick={() => window.$('.modal-window__past-code').slideToggle()}>Cancel</button>
                            </div>
                        </div>                    
                    </div>
                </div>
            </main>    
            {
                savingProfile ?
                    <div className="modal-window__loader modal-window__loader-task modal-window__loader-login active">
                        <img src={require('../../images/loader.svg')} alt="loading" />
                    {isEditing ? 'Saving profile' : 'Creating profile'}
                    </div>
                    : null
            } 
        </React.Fragment>
    );       
}

ProfileSetupPage.propTypes = {
    actions: PropTypes.object,
    currencies: PropTypes.array
    //myProp: PropTypes.string.isRequired
};


function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user,
        userLoading: state.account.userLoading,
        currencies: state.currencies
    };
}


function mapDispatchToProps(dispatch) {
    return {
        //actions will now be available under props.actions
        actions: bindActionCreators(Object.assign({}, artistActions, currencyActions, accountActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSetupPage);
