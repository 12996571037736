import urls from '../constants/urls.js';

class HttpError extends Error {
    constructor(message, statusCode) {
      super(message);
      this.statusCode = statusCode;
    }
  }

class BaseService {
    constructor() {
        this.token = null;
        this.tokenExpiry = null;
        this.setToken = this.setToken.bind(this);
        this.getHeaders = this.getHeaders.bind(this);
        this.getData = this.getData.bind(this);
     }
     
    static getData(relativeUrl, additionalHeaders = {}) {    
        return fetch(urls.API_URL + relativeUrl, this.getHeaders(additionalHeaders))
            .then(response => {
                if(response.ok) {
                    return response.status !== 204 ? response.json() : '';
                }
                else {
                    if(response.status === 404) {
                        throw new HttpError('Not found', response.status);
                    }
                    else {
                        throw new HttpError('Sorry, something went wrong. Please contact us to investigate.', response.status);
                    }
                }
            });
    } 

    static postData(relativeUrl, data, additionalHeaders = {}) {
        return fetch(urls.API_URL + relativeUrl, Object.assign({}, this.getHeaders(additionalHeaders), {
            method: 'POST',                
            body: JSON.stringify(data)
        }))
        .then(response => {
            if(response.ok) {
                return response.status !== 204 ? response.json() : '';
            }
            else {
                if(response.status === 400) {
                    return response.json()
                        .then(function(err) {
                            if(err.error_description && err.error_description.length) {
                                throw new HttpError(JSON.stringify(err.error_description), response.status);
                            }
                            throw new HttpError(err.message);
                        });
                }
                else {
                    let message;
                    if(response.status === 401 || response.status === 403) {
                        message = 'Sorry, you are not authorised to complete this action';
                    }
                    else if(response.status === 409) {
                        message = 'Conflict';
                    }
                    else {
                        message = 'Sorry, something went wrong. Please contact us to investigate.';
                    }
                    throw new HttpError(message, response.status);
                }
            }
        });
    }

    static putData(relativeUrl, data, additionalHeaders = {}) {
        return fetch(urls.API_URL + relativeUrl, Object.assign({}, this.getHeaders(additionalHeaders), {
            method: 'PUT',                
            body: JSON.stringify(data)
        }))
        .then(response => {
            if(response.ok) {
                return response.status !== 204 ? response.json() : '';
            }
            else {
                if(response.status === 400) {
                    return response.json()
                        .then(function(err) {
                            if(err.error_description && err.error_description.length) {
                                throw new HttpError(JSON.stringify(err.error_description), response.status);
                            }
                            throw new HttpError(err.message, response.status);
                        });
                }
                else {
                    let message;
                    if(response.status === 404) {
                        message = 'Not found';
                    }
                    else if(response.status === 401 || response.status === 403) {
                        message = 'Sorry, you are not authorised to complete this action';
                    }
                    else if(response.status === 409) {
                        message = 'Conflict';
                    }
                    else {
                        message = 'Sorry, something went wrong. Please contact us to investigate.';
                    }
                    throw new HttpError(message, response.status);
                }
            }
        });
    }

    static deleteData(relativeUrl, additionalHeaders = {}) {
        return fetch(urls.API_URL + relativeUrl, Object.assign({}, this.getHeaders(additionalHeaders), {
            method: 'DELETE'
        }))
        .then(response => {
            if(response.ok) {
                return response.status !== 204 ? response.json() : '';
            }
            else {
                if(response.status === 409) {
                    return response.json()
                        .then(function(err) {
                            throw new HttpError('Conflict error', response.status);
                        });
                }
                else {
                    let message;
                    if(response.status === 404) {
                        message = 'Not found';
                    }
                    if(response.status === 401 || response.status === 403) {
                        message = 'Sorry, you are not authorised to complete this action';
                    }
                    else if(response.status === 409) {
                        message = 'Conflict';
                    }
                    else {
                        message = 'Sorry, something went wrong. Please contact us to investigate.';
                    }
                    throw new HttpError(message, response.status);
                }
            }
        });
    }
    
    static getHeaders(additionalHeaders) {
        if(this.token) {
            return {
                headers: Object.assign({}, {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`,
                    'SessionID': this.sessionID || ''
                }, {...additionalHeaders }),
                credentials: 'include'
            };
        }
        else {
            return {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
        }
    }

    static setToken(token, expiry) {
        this.token = token;
        this.tokenExpiry = expiry;
    }

    static clearToken() {
        this.token = null;
        this.tokenExpiry = null;
    }

    static setSessionID(sessionID) {
        this.sessionID = sessionID;
    }

    static clearSessionID() {
        this.sessionID = null;
    }

    static returnAssignedObject(obj) {
        return Object.assign({}, obj);
    }

    static returnAssignedArray(obj) {
        return Object.assign([], obj);
    }

    static getAndReturn(url, onSuccess, errorToLog, throwError, customErrorMessage = null, additionalHeaders = {}) {
        return this.getData(url, additionalHeaders)
            .then(data => {
                return onSuccess(data);
            })
            .catch(error => {
                //alert(error);
                if(errorToLog) {
                    console.log(errorToLog);
                }
                if(throwError) {
                    throw customErrorMessage || error;
                }
            });
    }

    static postAndReturn(url, data, onSuccess, errorToLog, throwError, customErrorMessage = null, additionalHeaders = {}) {
        return this.postData(url, data, additionalHeaders)
            .then(data => {
                return onSuccess(data);
            })
            .catch(error => {
                if(errorToLog) {
                    console.log(errorToLog);
                }
                if(throwError) {
                    throw customErrorMessage || error;
                }
            });
    }

    static putAndReturn(url, data, onSuccess, errorToLog, throwError, customErrorMessage = null, additionalHeaders = {}) {
        return this.putData(url, data, additionalHeaders)
            .then(data => {
                return onSuccess(data);
            })
            .catch(error => {
                if(errorToLog) {
                    console.log(errorToLog);
                }
                if(throwError) {
                    throw customErrorMessage || error;
                }
            });
    }

    static deleteAndReturn(url, onSuccess, errorToLog, throwError, customErrorMessage = null) {
        return this.deleteData(url)
            .then(data => {
                return onSuccess(data);
            })
            .catch(error => {
                if(errorToLog) {
                    console.log(errorToLog);
                }
                if(throwError) {
                    throw customErrorMessage || error;
                }
            });
    }
}
export default BaseService;