import * as types from '../actions/actionTypes';
import initialState from './initialState';
import accountReduce from './reduceFunctions/accountReduce';
import artistReduce from './reduceFunctions/artistReduce';
import taskReduce from './reduceFunctions/taskReduce';
import eventReduce from './reduceFunctions/eventReduce';
import goalReduce from './reduceFunctions/goalReduce';
import financeReduce from './reduceFunctions/financeReduce';
import resourceReduce from './reduceFunctions/resourceReduce';
import projectReduce from './reduceFunctions/projectReduce';

export default function accountReducer(state = initialState.account, action) {
    //check if this has the saved state included
    switch(action.type) {
        case types.LOGIN_SUCCESS:
        case types.TOKEN_REFRESHED:           
        case types.TOKEN_REFRESH_FAILED:           
        case types.SET_USER_LOADING:          
        case types.GET_USER_SUCCESS:        
        case types.TOKEN_REFRESHING:                
        case types.SET_REFRESH_TOKEN_TIMEOUT_HANDLE:         
        case types.SWITCH_ACTIVE_PROFILE:
        case types.LOGOUT:       
            return accountReduce(state, action);   

        case types.ARTIST_CREATE_SUCCESS:        
        case types.ARTIST_UPDATE_SUCCESS:
        case types.ARTIST_INVITATION_CODE_CREATE_SUCCESS:
            return artistReduce(state, action);
        
        case types.TASK_CREATE_SUCCESS:        
        case types.TASK_UPDATE_SUCCESS:        
        case types.TASK_DELETE_SUCCESS:
            return taskReduce(state, action);

        case types.EVENT_CREATE_SUCCESS:        
        case types.EVENT_UPDATE_SUCCESS:        
        case types.EVENT_DELETE_SUCCESS:
        case types.EVENT_ATTENDANCE_UPDATE_SUCCESS:
        case types.EVENT_NOTE_CREATE_SUCCESS:
        case types.EVENT_NOTE_DELETE_SUCCESS:
            return eventReduce(state, action);
               
        case types.GOAL_CREATE_SUCCESS:        
        case types.GOAL_UPDATE_SUCCESS:        
        case types.GOAL_DELETE_SUCCESS:
            return goalReduce(state, action);

        case types.GET_RECEIPT_SUCCESS:
        case types.UPLOAD_RECEIPT_SUCCESS:
        case types.DELETE_RECEIPT_SUCCESS:
        case types.EXPENSE_CREATE_SUCCESS:
        case types.EXPENSE_UPDATE_SUCCESS:
        case types.EXPENSE_DELETE_SUCCESS:
        case types.INCOME_CREATE_SUCCESS:
        case types.INCOME_UPDATE_SUCCESS:
        case types.INCOME_DELETE_SUCCESS:
        case types.BALANCE_CREATE_SUCCESS:
        case types.BALANCE_UPDATE_SUCCESS:
        case types.BALANCE_DELETE_SUCCESS:
        case types.BUDGET_EXPENSE_CREATE_SUCCESS:
        case types.BUDGET_EXPENSE_UPDATE_SUCCESS:
        case types.BUDGET_EXPENSE_DELETE_SUCCESS:
        case types.BUDGET_INCOME_CREATE_SUCCESS:
        case types.BUDGET_INCOME_UPDATE_SUCCESS:
        case types.BUDGET_INCOME_DELETE_SUCCESS:
            return financeReduce(state, action);

        case types.RESOURCE_CREATE_SUCCESS:
        case types.RESOURCE_UPDATE_SUCCESS:
        case types.RESOURCE_DELETE_SUCCESS:
            return resourceReduce(state, action);

        case types.PROJECT_CREATE_SUCCESS:        
        case types.PROJECT_UPDATE_SUCCESS:        
        case types.PROJECT_DELETE_SUCCESS:
            return projectReduce(state, action);
    
            
        default:
            return state;
    }
}