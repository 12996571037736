import * as reducerUtils from '../reducerUtils';
import * as types from '../../actions/actionTypes';

export default function taskReduce(state, action) {
    const artistPredicate = (a, artistID) => a.ID === artistID;
    const taskPredicate = (t, taskID) => t.ID === taskID;

    switch(action.type) {            
        
        case types.TASK_CREATE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const task = action.payload.task;
            const user = {...state.user};            
            user.Artists = reducerUtils.addNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Tasks', task);           
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }      
        case types.TASK_UPDATE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const task = action.payload.task;
            const user = {...state.user};
            user.Artists = reducerUtils.updateNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Tasks', (t) => taskPredicate(t, task.ID), task);    
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }
        case types.TASK_DELETE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const taskID = action.payload.taskID;
            const user = {...state.user};
            user.Artists = reducerUtils.deleteNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Tasks', (t) => taskPredicate(t, taskID));
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }            
        default:
            return state;
    }
}