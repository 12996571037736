import BaseService from './baseService';
import { getReferrer } from '../utils/urlUtils';

class AccountService {
    
    static login(email, password) {
        return BaseService.postAndReturn('/Token', { username: email, password, grant_type: 'password' }, BaseService.returnAssignedObject, 'Unable to log in', true);
    }  

    //we only need to send the token if this is an additional login and we don't already have this token saved for the logged-in user
    static getUserInfo(token = null) {
        return BaseService.getAndReturn('/Account/UserInfo', BaseService.returnAssignedObject, 'Unable to get user info', true, null,  token ? {'Authorization': `Bearer ${token}`} : null)
    }
    
    static getUser() {
        return BaseService.getAndReturn('/Account/Me', BaseService.returnAssignedObject, 'Unable to get user', true);
    }

    static updateUser(email, firstName, lastName) {
        //we need to implement email confirmation on the back end and changing the email should require confirmation
        return BaseService.putAndReturn('/Account/Me', { email, firstName, lastName }, BaseService.returnAssignedObject, 'Unable to get user', true);
    }

    static changePassword(oldPassword, newPassword, confirmPassword) {
        return BaseService.postAndReturn('/Account/ChangePassword', { oldPassword, newPassword, confirmPassword }, BaseService.returnAssignedObject, 'Unable to change password', true);
    }  
    
    static setPassword(password) {
        return BaseService.postAndReturn('/Account/SetPassword', { password }, BaseService.returnAssignedObject, 'Unable to set password', true);
    }  
    
    static forgotPassword(email) {
        return BaseService.postAndReturn('/Account/ForgotPassword', { email }, (data) => data, 'Unable to send password reset', true);
    }  

    static resetPassword(email, code, password) {
        return BaseService.postAndReturn('/Account/ResetPassword', { email, code, password }, (data) => data, 'Unable to reset password', true);
    }  
    
    static addLogin(externalAccessToken) {
        return BaseService.postAndReturn('/Account/AddExternalLogin', { externalAccessToken }, (data) => data, 'Unable to add login', true);
    }

    static removeLogin(loginProvider, providerKey) {
        return BaseService.postAndReturn('/Account/RemoveLogin', { loginProvider, providerKey }, (data) => data, 'Unable to remove login', true);
    }  

    static getExternalLoginsList(returnUrl = '/login') {
        return BaseService.getAndReturn(`/Account/ExternalLogins?returnUrl=${returnUrl}&generateState=true&useCookies=true`, BaseService.returnAssignedArray, 'Unable to get external logins', true);
    }

    static getExternalLoginsInsightsList(returnUrl = '/social') {
        return BaseService.getAndReturn(`/Account/ExternalLoginsInsights?returnUrl=${returnUrl}&generateState=true&useCookies=true`, BaseService.returnAssignedArray, 'Unable to get external logins', true);
    }

    static getFacebookAccounts(token) {
        return BaseService.getAndReturn('/Account/Facebook', BaseService.returnAssignedArray, 'Unable to get external logins', true, null, {Authorization: `Bearer ${token}`});
    }
    
    /*static getExternalLogins() {
        return BaseService.getAndReturn('/Account/ExternalLogins', BaseService.returnAssignedArray, 'Unable to get external logins', true);
    }*/

    static signup(email, password, firstName, lastName, marketingOptIn) {
        return BaseService.postAndReturn('/Account/Register', { email, password, confirmPassword: password, firstName, lastName, marketingOptIn, signupPlatform: 'Web', source: getReferrer() }, BaseService.returnAssignedObject, 'Unable to sign up', true);
    }

    static registerExternal(email, marketingOptIn) {
        return BaseService.postAndReturn('/Account/RegisterExternal', { email, signupPlatform: 'Web', source: getReferrer(), marketingOptIn }, BaseService.returnAssignedObject, 'Unable to register', true);
    }  

    static createStripeCustomer(currencyCode) {
        return BaseService.postAndReturn(`/Account/Stripe?currencyCode=${currencyCode}`, {}, BaseService.returnAssignedObject, 'Unable to create Stripe customer account', true);
    }

    static getStripeCustomerPortal(customerID, returnUrl) {
        return BaseService.getAndReturn(`/Account/StripeCustomerPortal/${customerID}?returnUrl=${returnUrl}`, BaseService.returnAssignedObject, 'Unable to redirect to Stripe', true);
    }

    /*static confirmEmail(email, code) {
        return BaseService.postAndReturn('/Account/ConfirmEmail', { email, code }, BaseService.returnAssignedObject, 'Unable to confirm email', true);
    }  

    static resendConfirmationEmail(email) {
        return BaseService.postAndReturn('/Account/ResendConfirmationEmail', { email }, (data) => data, 'Unable to resend confirmation email', true);
    }  */  


    /*static closeAccount(reason, removeFromMailingList) {
        return BaseService.postAndReturn('/Account/Close', { reason, removeFromMailingList }, (data) => data, 'Unable to close account', true);
    } */ 

    static deleteAccount() {
        return BaseService.deleteAndReturn('/Account', BaseService.returnAssignedObject, 'Unable to delete account, something went wrong', true);
    }
}
export default AccountService;