import BaseService from './baseService';

class ProjectService {

    static createProject(artistID, project) {
        return BaseService.postAndReturn(`/Artists/${artistID}/Projects`, project, BaseService.returnAssignedObject, 'Unable to create project', true);
    } 

    static editProject(artistID, project) {
        return BaseService.putAndReturn(`/Artists/${artistID}/Projects/${project.ID}`, project, BaseService.returnAssignedObject, 'Unable to update project', true);
    } 
    
    static deleteProject(artistID, projectID, deleteTasks) {
        return BaseService.deleteAndReturn(`/Artists/${artistID}/Projects/${projectID}?deleteTasks=${deleteTasks}`, BaseService.returnAssignedObject, 'Unable to delete project', true);
    }
 }

export default ProjectService;