import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import '../node_modules/toastr/build/toastr.min.css';
import './styles/bootstrap.min.css';
import './styles/slick.css';
import './styles/slick-theme.css';
import './styles/style.scss';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './store/configureStore';
import {loadState} from './utils/localStorage';
import initialState from './reducers/initialState';
import BaseService from './services/baseService';
import * as types from './actions/actionTypes';
import * as accountActions from './actions/accountActions';

import jQuery from 'jquery';
import * as RoutesModule from './routes';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import settings from './constants/settings.js';
import AnalyticsService from './services/analyticsService';
import { saveReferrer } from './utils/urlUtils';
//import tether from 'tether';
window.jQuery = jQuery;
window.$ = jQuery;

//global.Tether = tether;

require('bootstrap');
//import * as slick from './scripts/slick.min.js';
require('slick-carousel');

let routes = RoutesModule.routes;

const persistedState = loadState();
const updatedState = Object.assign({}, initialState, persistedState);
const store = configureStore(updatedState);

if(updatedState.account.token) {
    BaseService.setToken(updatedState.account.token);
    store.dispatch({ type: types.SCHEDULE_TOKEN_REFRESH, payload: { tokenExpiry: updatedState.account.tokenExpiry, refreshToken: updatedState.account.refreshToken} });
    //try loading the user, if we needed to refresh the token, we'll also try after it's been refreshed
    accountActions.getUser(false, true, window.location.pathname)(store.dispatch, store.getState)
        .catch(error => {
            //we can't get a user from the token, logout 
            accountActions.logout(false)(store.dispatch);
        });
}
const stripePromise = loadStripe(settings.STRIPE_PUBLISHABLE_KEY);

function renderApp() {
    // This code starts up the React app when it runs in a browser. It sets up the routing configuration
    // and injects the app into a DOM element.
    ReactDOM.render(
        <Elements stripe={stripePromise}>
            <Provider store={ store }>
                <ConnectedRouter history={ history } children={ routes } />
            </Provider>
        </Elements>,
        document.getElementById('root')
    );
}

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
if(!updatedState.account.token) {
    //we're not logged in, save the referrer so we can send it if the user signs up
    saveReferrer();
}

//delay this so it doesn't affect the page load time
window.setTimeout(() => {
    AnalyticsService.initialiseGA();
    AnalyticsService.initialiseFB();
    //AnalyticsService.initialiseTwitter();
    AnalyticsService.initialiseHotjar();
    AnalyticsService.initialiseMixpanel();
}, 2000);

history.listen(function (location) {
    locationSet(location);
 });
 //log the initial location
 locationSet(history.location, true);

 function locationSet(location, initialLoad = false) {
    if(initialLoad) {
        window.setTimeout(() => {
            AnalyticsService.logPageViewGA(location.pathname);
            AnalyticsService.logPageViewFB(location.pathname);
        }, 3000);
    }
    else {
        AnalyticsService.logPageViewGA(location.pathname);
        AnalyticsService.logPageViewFB(location.pathname);
    }
 }