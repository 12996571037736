import { history } from "../store/configureStore";

export function getMemberName(user, members, userID) {
    if(user.ID === userID) {
        return 'Me';
    }
    else {
        const member = members.find(m => m.ID === userID);
        if(member) {
            //if there is more than 1 member with the same first name, we can add the surname?
            return member.FirstName;
        }
        else {
            return '';
        }
    }
}

export function checkSubscription(artist, successCallback, actionDescription) {
    if(artist.HasActiveSubscription) {
        successCallback();
    }
    else {
        if(window.$('body').hasClass('modal-open')) {
            window.$('#modal').modal('hide');
        }        
        history.push('/subscription', { message: actionDescription, returnUrl: window.location.pathname });
    }
}




