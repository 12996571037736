import React, { useState, useEffect, useRef } from 'react';

export function useFormInput(defaultValue) {
    const [value, setValue] = useState(defaultValue);

    const onChange = (event) => {
        setValue(event.target.value);
    }
    
    return { value, onChange };
}

export function useFormCheckInput(defaultValue) {
    const [checked, setChecked] = useState(defaultValue);

    const onChange = (event) => {
        setChecked(event.target.checked);
    }
    return { checked, onChange };
}

/*export function useTraceUpdate(props) {
    const prev = useRef(props);
    useEffect(() => {
      const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
        if (prev.current[k] !== v) {
          ps[k] = [prev.current[k], v];
        }
        return ps;
      }, {});
      if (Object.keys(changedProps).length > 0) {
        console.log('Changed props:', changedProps);
      }
      prev.current = props;
    });
  }*/

