export default {
    account: {
        token: null,
        tokenExpiry: null,
        refreshToken: null,
        user: null,
        loginError: null,
        signupError: null,
        forgotPasswordEmailSent: false,
        forgotPasswordError: null,
        changePasswordError: null,
        passwordReset: false,
        resetPasswordError: null,
        accountSettingsError: null,
        refreshTokenPromise: null,
        refreshTokenTimeoutHandle: null,
        refreshingToken: false,
        activeProfileID: null
    },
    currencies: [],
    pricingPlans: [],
    greeting: {
        quoteText: null,
        text: null,
        timeOfDay: null
    }
};