import BaseService from './baseService';

class EventService {

    static createEvent(artistID, event) {
        return BaseService.postAndReturn(`/Artists/${artistID}/Events`, event, BaseService.returnAssignedObject, 'Unable to create event', true);
    } 

    static editEvent(artistID, event) {
        return BaseService.putAndReturn(`/Artists/${artistID}/Events/${event.ID}`, event, BaseService.returnAssignedObject, 'Unable to update event', true);
    } 
    
    static deleteEvent(artistID, eventID) {
        return BaseService.deleteAndReturn(`/Artists/${artistID}/Events/${eventID}`, BaseService.returnAssignedObject, 'Unable to delete event', true);
    } 

    static setEventAttendance(artistID, eventID, attendance) {
        return BaseService.postAndReturn(`/Artists/${artistID}/Events/${eventID}/Attendance`, attendance, BaseService.returnAssignedObject, 'Unable to set attendance', true);
    } 

    static createEventNote(artistID, eventID, note) {
        return BaseService.postAndReturn(`/Artists/${artistID}/Events/${eventID}/Notes`, note, BaseService.returnAssignedObject, 'Unable to create note', true);
    } 

    static deleteEventNote(artistID, eventID, noteID) {
        return BaseService.deleteAndReturn(`/Artists/${artistID}/Events/${eventID}/Notes/${noteID}`, BaseService.returnAssignedObject, 'Unable to delete note', true);
    } 

    static linkEventResource(artistID, eventID, resourceID) {
        return BaseService.postAndReturn(`/Artists/${artistID}/Events/${eventID}/Resources`, resourceID, BaseService.returnAssignedObject, 'Unable to link item', true);
    } 

    static deleteEventResourceLink(artistID, eventID, resourceID) {
        return BaseService.deleteAndReturn(`/Artists/${artistID}/Events/${eventID}/Resources/${resourceID}`, BaseService.returnAssignedObject, 'Unable to delete item', true);
    } 
 }

export default EventService;