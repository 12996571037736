import magnific from 'magnific-popup';

export function showImage(imageSrc, openerElement) {
    window.$.magnificPopup.open({
        items: {
          src: imageSrc
        },
        type: 'image',
        zoom: {
            enabled: true, 
            duration: 300, 
            easing: 'ease-in-out',
            opener: function() {    //function(openerElement)
                openerElement = window.$(openerElement);     
                return openerElement.is('img') ? openerElement : openerElement.find('img');
            }
          }
      });
}

export function showFile(src, openerElement) {
  window.$.magnificPopup.open({
      items: {
        src: src
      },
      type: 'iframe',
      zoom: {
          enabled: true, 
          duration: 300, 
          easing: 'ease-in-out',
          opener: function() { 
              openerElement = window.$(openerElement);     
              return openerElement.is('img') ? openerElement : openerElement.find('img');
          }
        }
    });
}

export function showInlineContent(src, openerElement, closeCallback) {
  window.$.magnificPopup.open({
    items: {
      src: src
    },
    type: 'inline',
    callbacks: {
      close: () => closeCallback ? closeCallback() : null
    },
    zoom: {
      enabled: true, 
      duration: 300, 
      easing: 'ease-in-out',
      opener: function(openerElement) {
        openerElement = window.$(openerElement);     
        return openerElement.is('img') ? openerElement : openerElement.find('img');
      }
    }  
  });
  
}