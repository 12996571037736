import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as artistActions from '../../actions/artistActions';
import '../../styles/style-ui.scss';
import Header from '../account/Header';
import ToastrService from '../../services/toastrService';
import '../../styles/style-onboarding.scss';
import * as artistUtils from '../../utils/artistUtils';
import * as dateUtils from '../../utils/dateUtils';

function BandMembersPage({user, actions, history}) {   
    const activeProfile = user.activeProfile;
    let invitationCode;
    const now = new Date();
    const validInvitationCodes = activeProfile.InvitationCodes.filter(i => i.ExpiryDate > now);
    if(validInvitationCodes.length) {
        //get the latest one
        invitationCode = validInvitationCodes.sort((a, b) => b.ExpiryDate - a.ExpiryDate)[0];
    }
    const [loadingInvitationCode, setLoadingInvitationCode] = useState(false);
    const invitationCodeInput = useRef();
    const bandMembers =  activeProfile.Members
        .map(member => ({ ID: member.ID, Name: artistUtils.getMemberName(user, activeProfile.Members, member.ID) }))
        .sort((a, b) => {
            if(a.Name === 'Me') {
                return -1;
            }
            else if(b.Name === 'Me') {
                return 1;
            }
            else {
                return 0;
            }
        });
    
    const createInvitationCode = (event) => {
        event.preventDefault();
        setLoadingInvitationCode(true);
        actions.createInvitationCode(activeProfile.ID)
            .then(invitationCode => setLoadingInvitationCode(false))
            .catch(error => setLoadingInvitationCode(false));
    }

    const close = (event) => {
        event.preventDefault();
        history.goBack();
    }

    const copyInvitationCode = () => {
        const copyText = invitationCodeInput.current;
        copyText.select();
        copyText.setSelectionRange(0, 99999); 
        document.execCommand("copy");
        const toastrService = new ToastrService();
        toastrService.showSuccess('', 'Copied to clipboard!');
    }

    return (        
        <main className="onboarding">
            <div className="container">
                <button className="close-page" onClick={close} />
                <Header className="page__branding" />
                <div className="members">
                    <h1 className="members__title">Members</h1>
                    <div className="members__list">
                        {
                            bandMembers.map(member =>
                                <div key={member.ID} className="members__item">
                                    {member.Name}
                                </div>
                            )
                        }
                    </div>

                    <div className="members__text">
                        Invite new band members:
                    </div>
                    {
                        invitationCode ?
                            <div className="members__code">
                                <div className="members__text">
                                    Your invitation code is shown below.
                                    Members can join this group by signing up and using this code on the band set-up screen.
                                </div>
                                <div className="members__input-wrapper">
                                    <div className="members__input">
                                        <input type="text" value={invitationCode.Code} ref={invitationCodeInput} />
                                        <div className="members__icons">
                                            <img src={require('../../images/copy-icon.svg')} alt="Copy Code" className="members__copy-code" onClick={copyInvitationCode} />
                                            <img src={require('../../images/ic_autorenew_white_24dp_2x.png')} className={`members-loader ${loadingInvitationCode ? 'active' : null}`} alt="Reload" onClick={createInvitationCode} />
                                        </div>
                                    </div>
                                </div>
                                <div className="members__text">
                                    Valid until {`${dateUtils.getShortDate(invitationCode.ExpiryDate)} ${dateUtils.getTime(invitationCode.ExpiryDate)}`}
                                </div>
                            </div>
                        :
                            <div className="members__add">
                                <div className="members__text">
                                    To add new members, use the button below to generate an invitation code which new members can use to join this group
                                </div>
                                <button className="bth--transparent" onClick={createInvitationCode}>
                                    ADD MEMBERS
                                </button>
                            </div>
                    }            
                </div>
            </div>
        </main>
    );
}

BandMembersPage.propTypes = {
    //myProp: PropTypes.string.isRequired
};


function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user
    };
}


function mapDispatchToProps(dispatch) {
    return {
        //account actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, artistActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BandMembersPage);