import ArtistService from '../services/artistService';
import ToastrService from '../services/toastrService';
import { push } from 'connected-react-router';
import * as types from './actionTypes';
import * as actionUtils from '../utils/actionUtils';
import { normaliseInvitationCode, normaliseArtist, normaliseInsights } from '../utils/normaliseUtils';
import AnalyticsService from '../services/analyticsService';
import { wrapServiceCall } from './baseActions';

export function createArtist(displayName, profileType, defaultCurrencyID) {    
    return function(dispatch) {
        const toastrService = new ToastrService();
        return ArtistService.createArtist(displayName, profileType, defaultCurrencyID).then(artist => {
            normaliseArtist(artist);
            dispatch({ type: types.ARTIST_CREATE_SUCCESS, artist });
            toastrService.showSuccess('', 'Your profile has been created!')
            //set it as the active profile and then redirect to the pricing page
            dispatch(push('/subscription'));
            //AnalyticsService.logEvent('')
            return artist;
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function updateArtist(artistID, displayName, defaultCurrencyID) {    
    return function(dispatch) {
        const toastrService = new ToastrService();
        return ArtistService.updateArtist(artistID, displayName, defaultCurrencyID).then(artist => {
            normaliseArtist(artist);
            dispatch({ type: types.ARTIST_UPDATE_SUCCESS, artist });
            dispatch(push('/'));            
            toastrService.showSuccess('', 'Your profile has been updated!')
            return artist;
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function createInvitationCode(artistID) {    
    return function(dispatch) {
        const toastrService = new ToastrService();
        return ArtistService.createInvitationCode(artistID).then(invitationCode => {
            normaliseInvitationCode(invitationCode);
            dispatch({ type: types.ARTIST_INVITATION_CODE_CREATE_SUCCESS, payload: {artistID, invitationCode} });
            toastrService.showSuccess('', 'Invitation code has been created!');
            AnalyticsService.logEvent('Invitation Code Created');
            return invitationCode;
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function redeemInvitationCode(invitationCode) {    
    return function(dispatch) {
        const toastrService = new ToastrService();
        return ArtistService.redeemInvitationCode(invitationCode).then(artist => {
            dispatch({ type: types.ARTIST_CREATE_SUCCESS, artist });
            toastrService.showSuccess('', `You have joined ${artist.DisplayName}!`);
            dispatch(push('/'));  
            AnalyticsService.logEvent('Invitation Code Redeemed');
            return artist;
        }).catch(error => {
            if(error.statusCode === 404) {
                toastrService.showError('', 'Sorry, your invitation code is not valid or may have expired');
            }
            else {
                actionUtils.handleError(error);
            }            
            throw error;
        });
    };
}

export function createStripeSubscription(artistID, customerID, paymentMethodID, priceID) {    
    return function(dispatch, getState) {
        return ArtistService.createStripeSubscription(artistID, customerID, paymentMethodID, priceID).then(result => {            
            return {
                paymentMethodID,
                priceID,
                subscription: result,
            };
        });
    };
}

export function retryStripeSubscription(artistID, customerID, paymentMethodID, invoiceID, priceID) {    
    return function(dispatch, getState) {
        return ArtistService.retryStripeSubscription(artistID, customerID, paymentMethodID, invoiceID).then(result => {            
            return {
                paymentMethodID,
                invoiceID,
                subscription: result,
                priceID,
                isRetry: true,
            };
        });
    };
}

export function reloadArtist(artistID) {
    return function(dispatch) {
        return ArtistService.getArtist(artistID).then(artist => {
            normaliseArtist(artist);
            dispatch({ type: types.ARTIST_UPDATE_SUCCESS, artist });
            const toastrService = new ToastrService();
            toastrService.showSuccess('', 'Your subscription has been completed successfully!');
            return artist;
        }).catch(error => {            
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function viewArtistAdmin(artistID) {    
    return function(dispatch) {
        return ArtistService.getArtist(artistID).then(artist => {
            normaliseArtist(artist);
            dispatch({ type: types.ARTIST_CREATE_SUCCESS, artist });
            dispatch(push('/'));  
            const toastrService = new ToastrService();
            toastrService.showSuccess('', 'Artist has been loaded!');
            return artist;
        }).catch(error => {            
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function setFacebookProfile(artistID, pageAccessToken) {    
    return function(dispatch) {
        const toastrService = new ToastrService();
        return ArtistService.setFacebookProfile(artistID, pageAccessToken).then(artist => {
            normaliseArtist(artist);
            dispatch({ type: types.ARTIST_UPDATE_SUCCESS, artist });
            return artist;
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function clearSocialProfile(artistID, provider) {    
    return function(dispatch) {
        const toastrService = new ToastrService();
        return ArtistService.clearSocialProfile(artistID, provider).then(artist => {
            normaliseArtist(artist);
            dispatch({ type: types.ARTIST_UPDATE_SUCCESS, artist });
            return artist;
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function getSocialInsights(artistID, provider) {
    return function(dispatch) {        
        return ArtistService.getSocialInsights(artistID, provider).then(insights => {
            //the key is a string representation of the date and may include a time so convert it to a date stripping off the time
            debugger;
            normaliseInsights(insights);
            return insights;
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function setTwitterProfile(artistID, accessToken) {    
    return function(dispatch) {
        const toastrService = new ToastrService();
        return ArtistService.setTwitterProfile(artistID, accessToken).then(artist => {
            normaliseArtist(artist);
            dispatch({ type: types.ARTIST_UPDATE_SUCCESS, artist });
            return artist;
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function setInstagramProfile(artistID, pageAccessToken) {    
    return function(dispatch) {
        const toastrService = new ToastrService();
        return ArtistService.setInstagramProfile(artistID, pageAccessToken).then(artist => {
            normaliseArtist(artist);
            dispatch({ type: types.ARTIST_UPDATE_SUCCESS, artist });
            return artist;
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function setYouTubeProfile(artistID, channelID) {    
    return function(dispatch) {
        const toastrService = new ToastrService();
        return ArtistService.setYouTubeProfile(artistID, channelID).then(artist => {
            normaliseArtist(artist);
            dispatch({ type: types.ARTIST_UPDATE_SUCCESS, artist });
            return artist;
        }).catch(error => {
            throw error;
        });
    };
}

export function setSoundCloudProfile(artistID, username) {    
    return function(dispatch) {
        const toastrService = new ToastrService();
        return ArtistService.setSoundCloudProfile(artistID, username).then(artist => {
            normaliseArtist(artist);
            dispatch({ type: types.ARTIST_UPDATE_SUCCESS, artist });
            return artist;
        }).catch(error => {
            throw error;
        });
    };
}

export function setLoudUpProfile(artistID, username) {    
    return function(dispatch) {
        const toastrService = new ToastrService();
        return ArtistService.setLoudUpProfile(artistID, username).then(artist => {
            normaliseArtist(artist);
            dispatch({ type: types.ARTIST_UPDATE_SUCCESS, artist });
            return artist;
        }).catch(error => {
            throw error;
        });
    };
}

/*export function cancelStripeSubscription(artistID, subscriptionID) {    
    return function(dispatch, getState) {
        return ArtistService.cancelStripeSubscription(artistID, subscriptionID).then(result => {            
            return {
                subscription: result,
            };
        });
    };
}*/