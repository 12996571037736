import QuoteService from '../services/quoteService';
import * as types from './actionTypes';
import { handleError } from '../utils/actionUtils';
import { messageTags, timeOfDays, welcomeMessages } from '../constants/appMessages';
import _ from 'lodash';

export function getQuote() {
    return function(dispatch, getState) {
        const state = getState();
        const existingQuote = state.greeting.quoteText;
        const quoteExpiry = new Date();
        quoteExpiry.setHours(quoteExpiry.getHours() - 2);
        if(!existingQuote || existingQuote.loadedDate < quoteExpiry) {
            return QuoteService.getQuote().then(quote => {
                quote.loadedDate = new Date();
                dispatch({ type: types.GET_QUOTE_SUCCESS, quote });
                return quote;
            }).catch(error => {
                handleError(error);
                return null;
            });
        }
        else {
            return Promise.resolve(existingQuote);
        }
    };
}

export function getGreetingMessage() {
    return function(dispatch, getState) {
        const state = getState();
        const d = new Date();
        const hour = d.getHours();       
        const timeOfDay = timeOfDays.find(t => t.isCurrent(hour)).name;
        if(state.greeting.timeOfDay !== timeOfDay) {
            //replace the greeting
            const matchingMessageTags = messageTags.filter(m => m.isCurrent(hour)).map(m => m.name);       
            let messages = _.flatten(matchingMessageTags.map(tag => welcomeMessages[tag]));
              //select a random greeting
            const selectedMessage = _.sample(messages);
            dispatch({ type: types.GET_GREETING_SUCCESS, greeting: {timeOfDay, text: selectedMessage}})
            return selectedMessage;
        }
        else {
            return Promise.resolve(state.greeting.text);
        }
    };
}