import React, { useState, useRef, useEffect} from 'react';
import { getShortDate } from '../../utils/dateUtils';
import { getFileSizeText } from '../../utils/fileUtils';

function AudioViewContent({item, file, blob}) {
    const [secsPlayed, setSecsPlayed] = useState(0);
    const [totalSecs, setTotalSecs] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [fileSize, setFileSize] = useState(false);    
    const playInterval = useRef();
    const audioRef = useRef();

    useEffect(() => {
        if(blob || file) {
            audioRef.current = new Audio();
            audioRef.current.addEventListener('loadeddata', () => {
                const duration = audioRef.current.duration;
                setTotalSecs(duration);
            })
            audioRef.current.addEventListener('ended', () =>{
                setIsPlaying(false);
                clearInterval(playInterval.current);
                setSecsPlayed(0);
            });
            if(blob) {
                audioRef.current.src = URL.createObjectURL(blob);
                setFileSize(getFileSizeText(blob.size));
            }
            else {
                const dataBase64 = `data:${file.MetaData.ContentType};base64,${file.Data}`;
                audioRef.current.src = dataBase64;
                setFileSize(getFileSizeText(file.Length));
            }
        }
        return (() => {
            if(playInterval.current) {
                window.clearInterval(playInterval.current);
            }
            if(audioRef.current) {
                audioRef.current.pause();
                if(blob) {
                    //we created an object url so revoke it
                    URL.revokeObjectURL(audioRef.current.src);
                }
                audioRef.current.removeAttribute('src');
                audioRef.current.load();
                audioRef.current = null;
            }
        })
    }, [file, blob]);

    useEffect(() => {
        //update the played time
        if(isPlaying) {
            playInterval.current = setInterval(() => {
                setSecsPlayed(audioRef.current.currentTime);
            }, 1000);
        }
        return () => {
            if(playInterval.current) {
                clearInterval(playInterval.current);
                playInterval.current = null;
            }
        }
    }, [isPlaying]);

    const playPause = (event) => {
        event.preventDefault();
        if(!isPlaying && audioRef.current) {
            audioRef.current.play();
            setIsPlaying(true);            
        }
        else {
            audioRef.current.pause();
            setIsPlaying(false);            
        }
    }
    
    const getDisplayTime = (totalSecs) => {
        const mins = Math.floor(totalSecs / 60).toString().padStart(2, '0');
        const secs = Math.round(totalSecs % 60).toString().padStart(2, '0');  
        return `${mins}:${secs}`;
    }

    const playedTime = getDisplayTime(secsPlayed);
    const totalTime = getDisplayTime(totalSecs);
    return (
        <div className="audio-note__content">
        {
            item ?                                         
                <div className="note-view__header note-view__header--file">
                    <div className="note-view__title">
                        {item.Name}
                    </div>                                    
                    <div className="note-view__date">
                        {getShortDate(item.CreatedDate)}
                    </div>
                </div>
                : null
        }
        <div className={`audio-buttons ${isPlaying ? 'active' : ''}`} onClick={playPause}>
            {
                isPlaying ?
                    <img src={require('../../images/vault-btn-pause.png')} alt="" className="pause" />
                    : 
                    <img src={require('../../images/vault-btn-play.png')} alt="" className="play" />
            }
        </div>
        <div className="audio-time">
            {playedTime}/{totalTime}
            
        </div>
        <div className="audio-time">
            {fileSize}
        </div>
    </div>
    )
}

export default AudioViewContent;