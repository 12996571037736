import React from 'react';

const Footer = React.memo(() =>
{
    return(
        <footer className="login__footer">
            <p className="text-muted">© {new Date().getFullYear()} LoudUp LTD. All Rights Reserved.</p>                                    
        </footer>
    );
});

Footer.propTypes = {
};

export default Footer;