import React, { useState, useRef, useEffect } from 'react';
import { useFormInput } from '../../utils/customHooks';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as accountActions from '../../actions/accountActions';
import { trim } from 'lodash';
import Header from '../account/Header';
import ToastrService from '../../services/toastrService';
import { getMemberName } from '../../utils/artistUtils';
import moment from 'moment';
import { Link } from 'react-router-dom';

function ManageSubscriptionPage({user, actions, history}) {
    const activeProfile = user.activeProfile;    
    const activePayment = activeProfile.ActivePayment;
    const [loadingManageStripe, setLoadingManageStripe] = useState(false);

    const manageStripeSubscription = (event) => {
        event.preventDefault();
        setLoadingManageStripe(true);
        actions.getStripeCustomerPortal(activePayment.CustomerID, window.location.href)
            .then(result => {
                setLoadingManageStripe(false);
                window.location = result.Url;
            })
            .catch(error => setLoadingManageStripe(false));
    }

    const getSubscriptionText = () => {
        const output = [];
        let text = `Your ${activePayment.FreeTrialPeriod ? 'trial ' : ''}subscription `;
        if(activeProfile.ArtistType === 'Band' && activePayment.Source !== 'Comp') {
            text += `was created by ${activePayment.UserID === user.ID ? 'you' : getMemberName(user, activeProfile.Members, activePayment.UserID)},`;
        }
        if(activePayment.UserID !== user.ID && activeProfile.ArtistType === 'Band' && activePayment.Source !== 'Comp') {
            //add an 'and' because we'll stop after the next part
            text += ' and';
        }
        if(activeProfile.ActivePayment.Source === 'Comp') {
            text += ' is complementary '
        }
        else {
            text += ` is managed by ${activePayment.Source} `;
        }
        if(activeProfile.ActivePayment.Source === 'Comp') {
            text += `and is currently set to expire on ${moment(activePayment.ExpiryDate).format('DD MMM YYYY')}.`;
            output.push(<p key="expiryText" className="pt-4">{text}</p>);           
        }
        else if(activePayment.UserID === user.ID) {            
            //check if we have a reliable renewal date and replace this
            text += `and is currently set to ${activePayment.RenewalDate ? 'renew' : 'expire'} on ${moment(activePayment.ExpiryDate).format('DD MMM YYYY')}.`;
            output.push(<p key="expiryText" className="pt-4">{text}</p>);
            if(activePayment.Source !== 'Comp' && user.ID === activePayment.UserID) {
                //change this to the autorenew flag once it's implemented
                let cancelText = activePayment.RenewalDate ? `If you don\'t wish to continue the subscription beyond this date${activePayment.Source === 'Stripe' ? ' or want to change your payment method' : ''}, please ` : 'Please ';
                if(activePayment.Source === 'Stripe') {
                    output.push(cancelText);
                    output.push(<a key="manageStripeLink" href="#" onClick={manageStripeSubscription}>click here</a>)
                    output.push(' to manage your subscription.');
                }
                else {
                    cancelText += 'follow the instructions below:'
                    output.push(cancelText);
                    if(activePayment.Source === 'Google') {
                        output.push(<p key="manageGoogleInstructions">Open your Play Store app and click on the Menu, then Account, and then Subscriptions, you should be able to manage your subscription from this screen. <br />You can also manage it from a Computer, there are instructions at the following link:<br />
                        <a href="https://support.google.com/googleplay/answer/7018481" target="_blank" rel="noopener noreferer">https://support.google.com/googleplay/answer/7018481</a>
                        </p>);
                    }
                    else if(activePayment.Source === 'Apple') {
                        output.push(<p key="manageAppleInstructions">On your phone/tablet, go to Settings, tap on your name at the top of the Settings menu, then on iTunes & App Store, tap on the Apple ID at the top, then on View Apple ID, and then click on Subscriptions. You will be able to manage your subscription from this screen.</p>)
                    }
                }
            }        
        }
        else {
            output.push(<p className="pt-4 text-center">{text}</p>);
        }
        return output;
    }

    return (
        <React.Fragment>
            <main className="">
                <button className="close-page" onClick={() => history.push('/')} />
                <div className="container">
                    <Header className="page__branding" />
                    <div className="members">         
                        <div className="account-setting__content">
                            <div className="setting-form"> 
                                <h1 className="members__title">Manage Subscription</h1>
                                {
                                    activeProfile.ActivePayment ?
                                        getSubscriptionText()
                                    : 
                                    <p className="pt-4">You don't have an active subscription, click <Link to="/subscription">here</Link> to subscribe</p>
                                }
                            </div>
                        </div>    
                    </div>
                </div>
            </main>
            {
                loadingManageStripe ?
                    <div className="modal-window__loader modal-window__loader-task modal-window__loader-login active">
                        <img src={require('../../images/loader.svg')} alt="loading" />
                        Loading...                     
                    </div>
                    : null
            } 
        </React.Fragment>
    )
}

function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //actions will now be available under props.actions
        actions: bindActionCreators(Object.assign({}, accountActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageSubscriptionPage);