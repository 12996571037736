import BaseService from './baseService';

class GoalService {

    static createGoal(artistID, goal) {
        return BaseService.postAndReturn(`/Artists/${artistID}/Goals`, goal, BaseService.returnAssignedObject, 'Unable to create goal', true);
    } 

    static editGoal(artistID, goal) {
        return BaseService.putAndReturn(`/Artists/${artistID}/Goals/${goal.ID}`, goal, BaseService.returnAssignedObject, 'Unable to update goal', true);
    } 
    
    static deleteGoal(artistID, goalID) {
        return BaseService.deleteAndReturn(`/Artists/${artistID}/Goals/${goalID}`, BaseService.returnAssignedObject, 'Unable to delete goal', true);
    }
 }

export default GoalService;