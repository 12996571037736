import ToastrService from '../services/toastrService';

const toastrService = new ToastrService();

export function handleError(error) {
    if(error.statusCode === 400) {
        toastrService.showError('', error.message, null);
    }
    else if(error.statusCode === 415) {
        toastrService.showError('', 'Sorry, the file type provided is invalid');
    }
    else if(error.statusCode === 409) {
        toastrService.showError('', 'Sorry, there is a data conflict. Please contact us to investigate.');
    }
    else {
        toastrService.showError('', 'Sorry, something went wrong!', null);
    }
}