import moment from 'moment';

export function getShortDate(date) {
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    };
    return date.toLocaleDateString(getLocale(), options);
};

function getLocale() {
    return window.navigator.userLanguage || window.navigator.language;
}

export function getTime(date) {
    const options = {
        hour: '2-digit', 
        minute: '2-digit' 
    };
    return date.toLocaleTimeString(getLocale(), options);
}

export function getTimeAgo(date) {
    const now = moment();
    const days = now.diff(moment(date), 'days');
    if (days > 6) {
        const weeks = Math.floor(days / 7);
        return `${weeks}W AGO`;
    }
    else if (days > 0) {
        return `${days}D AGO`;
    }
    const hours = now.diff(moment(date), 'hours');
    if (hours > 0) {
        return `${hours}H AGO`;
    }
    const minutes = now.diff(moment(date), 'minutes');
    if (minutes > 0) {
        return `${minutes}M AGO`;
    }
    return 'Just now';
}

export function isValidDate(dateString) {
    const date = moment(dateString);
    let isValid = date.isValid() && date < moment('9999-12-31 23:59:59');
    return isValid;
}

let browserDateTypeSupported = null;
export function isBrowserDateTypeSupported() {
    if(browserDateTypeSupported === null) {
        const input = document.createElement('input');
        const value = 'a';
        input.setAttribute('type', 'date');
        input.setAttribute('value', value);
        //if the date type is supported, an input of 'a' won't be allowed
        browserDateTypeSupported = input.value !== value;
    }
    return browserDateTypeSupported;
}