import moment from 'moment';
import * as dateUtils from './dateUtils';

export function getDateText(createdDate) {
    const momentCreatedDate = moment(createdDate);
    const momentToday = moment();
    const daysDiff = momentToday.diff(momentCreatedDate, 'days');
    let dateText;
    if (daysDiff > 7) {
        dateText = dateUtils.getShortDate(momentCreatedDate.toDate());
    }
    else {
        if (daysDiff === 0) {
            dateText = 'TODAY';
        }
        else if (daysDiff === 1) {
            dateText = 'YESTERDAY';
        }
        else {
            dateText = `${daysDiff} DAYS AGO`;
        }
    }
    return dateText;
}

export function getVaultType (resource) {
    if(resource.Type === 'Text') {
        return 'Text';
    }
    else if(resource.FileType === 'Audio') {
        return 'Audio';
    }
    else if(resource.FileType === 'Image') {
        return 'Image';
    }
    else {
        return 'PDF';
    }
}


export function getResourceDescription(resource) {
    if(resource.VaultType === 'Text') {
        return 'note';
    }
    else if(resource.VaultType === 'Audio') {
        return 'audio file';
    }
    else if(resource.VaultType === 'Image') {
        return 'image file';
    }
    else {
        return 'PDF file';
    }
}