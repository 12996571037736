import * as types from './actionTypes';
import AccountService from '../services/accountService';
import { push } from 'connected-react-router';
import ToastrService from '../services/toastrService';
import AnalyticsService from '../services/analyticsService';
import * as userUtils from '../utils/userUtils';
import * as actionUtils from '../utils/actionUtils';
import { normaliseArtist } from '../utils/normaliseUtils';
import BaseService from '../services/baseService';
import { clearReferrer } from '../utils/urlUtils';
import { wrapServiceCall } from './baseActions';

export function login(email, password, returnUrl) {    
    return function(dispatch) {
        return AccountService.login(email, password).then(payload => {
            dispatch({ type: types.LOGIN_SUCCESS, payload });
            dispatch(getUser(true, true, returnUrl));
            return true;
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function signup(email, password, firstName, lastName, marketingOptIn){    
    return function(dispatch) {
        const toastrService = new ToastrService();
        return AccountService.signup(email, password, firstName, lastName, marketingOptIn).then(payload => {   
            dispatch(push('/login'));
            toastrService.showSuccess('', 'Your account has been created, please log in');
            clearReferrer();
            return true;
        }).catch(error => {
            if(error.message === 'Conflict') {
                toastrService.showError('', 'There is already an account registered with that email address, did you mean to log in?', null);
            }
            else {                
                actionUtils.handleError(error);
            }
            throw error;
        });
    };
}

export function getUser(showSuccessToast = false, isLogin = true, returnUrl){
    return function(dispatch, getState) {
        dispatch({ type: types.SET_USER_LOADING, loading: true });
        return AccountService.getUser().then(user => {
            user.Artists.forEach(artist => {
                artist = normaliseArtist(artist);
            });            
            dispatch({ type: types.GET_USER_SUCCESS, user });
            //get the user from state as after the previous dispatch. we would have set the active profile there if we could set it
            dispatch(push(userUtils.getNext(getState().account.user, returnUrl)));
            if(showSuccessToast) {
                const toastrService = new ToastrService();
                toastrService.showSuccess('', `You have ${ isLogin ? 'logged in' : 'signed up' } successfully!`);
            }            
            debugger;
            BaseService.setSessionID(getUUID());
            AnalyticsService.identifyMixpanel(user.ID);
            return true;
        }).catch(error => {            
            dispatch({ type: types.SET_USER_LOADING, loading: false });
            throw(error);
        });
    };
}

function getUUID() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ (window.crypto || window.msCrypto).getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

export function forgotPassword(email) {    
    return function(dispatch) {
        return AccountService.forgotPassword(email).then(expiry => {
            const expiryParts = expiry.split(':');
            const hours = parseInt(expiryParts[0] * 24) + parseInt(expiryParts[1]);          
            dispatch(push('/resetpassword', { email, expiry: hours || 8 }));
            AnalyticsService.logEvent('Forgot Password');
            return true;
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function resetPassword(email, code, password) {    
    return function(dispatch) {
        const toastrService = new ToastrService();
        return AccountService.resetPassword(email, code, password).then(payload => {
            dispatch(push('/login'));
            toastrService.showSuccess('', 'Your password has been reset successfully, please log in');
            AnalyticsService.logEvent('Reset Password');
            return true;
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function changePassword(oldPassword, newPassword, confirmPassword) {    
    return function(dispatch) {
        const toastrService = new ToastrService();
        return AccountService.changePassword(oldPassword, newPassword, confirmPassword).then(payload => {
            toastrService.showSuccess('', 'Your password has been changed successfully!', null);
            AnalyticsService.logEvent('Change Password');
            return true;
        }).catch(error => {
            if(error.message === 'Unable to change password') {
                toastrService.showError('', 'Something went wrong, unable to change your password', null);
            }
            else {
                actionUtils.handleError(error);
            }
            throw error;
        });
    };
}

export function updateUser(email, firstName, lastName) {    
    return function(dispatch) {
        const toastrService = new ToastrService();
        return AccountService.updateUser(email, firstName, lastName).then(user => {
            dispatch({ type: types.GET_USER_SUCCESS, user });
            toastrService.showSuccess('', 'Your settings have been updated successfully!', null);
            return user;
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
         });
    };
}

export function logout(showSuccessToast = true){
    return function(dispatch) {
        dispatch({ type: types.LOGOUT });
        dispatch(push('/'));
        AnalyticsService.logEvent('Logout');
        AnalyticsService.resetMixpanel();
        if(showSuccessToast) {
            const toastrService = new ToastrService();
            toastrService.showSuccess('', 'You have logged out successfully!', null);
        }
    };
}

export function getUserInfo(accessToken) {
    return wrapServiceCall(() => AccountService.getUserInfo(accessToken));
}

export function registerExternal(email, marketingOptIn){
    return function(dispatch) {
        return AccountService.registerExternal(email, marketingOptIn).then(payload => {        
            //call login success again as this call will give us a longer token for the registered user
            dispatch({ type: types.LOGIN_SUCCESS, payload });
            dispatch(getUser(true, false));
            logNewSignup();
            clearReferrer();
            return payload;
        }).catch(error => {
            throw(error);
        });
    };
}

export function loginSuccess(accessToken, refreshToken, expires) {
    const payload = { access_token: accessToken, refresh_token: refreshToken };
    payload['.expires'] = expires;
    return { type: types.LOGIN_SUCCESS, payload };
}

export function createStripeCustomer(currencyCode) {    
    return function(dispatch, getState) {
        return AccountService.createStripeCustomer(currencyCode).then(result => {
            const state = getState();
            const user = {...state.account.user, StripeCustomerID: result.StripeCustomerID};
            dispatch({ type: types.USER_UPDATE_SUCCESS, user });
            return result.StripeCustomerID;
        }).catch(error => {
            actionUtils.handleError(error);
            return null;
         });
    };
}

export function getStripeCustomerPortal(customerID, returnUrl) {
    return wrapServiceCall(() => AccountService.getStripeCustomerPortal(customerID, returnUrl));
}

export function switchActiveProfile(id){
    return function(dispatch) {
        dispatch({ type: types.SWITCH_ACTIVE_PROFILE, id });
    };
}

function logNewSignup() {
    try {
        AnalyticsService.logEventGA('user', 'signup');
        AnalyticsService.logEventFB('MikeSignup');
        AnalyticsService.logEventTWT('MikeSignup');
    }
    catch(error) {
        //do nothing
    }
}

export function getExternalLoginsList(returnUrl) {
    return wrapServiceCall(() => AccountService.getExternalLoginsList(returnUrl));
}

export function getExternalLoginsInsightsList(returnUrl) {
    return wrapServiceCall(() => AccountService.getExternalLoginsInsightsList(returnUrl));
}

export function getFacebookAccounts(token) {
    return wrapServiceCall(() => AccountService.getFacebookAccounts(token));
}

export function deleteAccount() {
    return function(dispatch) {
        const toastrService = new ToastrService();
        return AccountService.deleteAccount().then(res => {
            dispatch(logout(false));
            toastrService.showSuccess('', 'Your account has been deleted successfully!', null);
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
         });
    };
}

