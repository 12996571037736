import TaskService from '../services/taskService';
import ToastrService from '../services/toastrService';
import * as types from './actionTypes';
import * as processUtils from '../utils/processUtils';
import * as actionUtils from '../utils/actionUtils';
import { normaliseTask } from '../utils/normaliseUtils';
import { deleteArrayItem } from '../reducers/reducerUtils';
import { taskCompletedSuccessMessages, taskCompleteSuccessMessages } from '../constants/appMessages';
import _ from 'lodash';

export function addTask(artistID, task) {
    return function(dispatch, getState) {
        const toastrService = new ToastrService();
        return TaskService.createTask(artistID, task).then(task => {
            normaliseTask(task);
            dispatch({ type: types.TASK_CREATE_SUCCESS, payload: { artistID, task } });
            const state = getState();
            const artist = state.account.user.Artists.find(a => a.ID === artistID);
            if(!artist.Goals.length && artist.Tasks.length === 1) {
                toastrService.showSuccess('', 'Congratulations on creating your 1st task, you are well on your way to world domination!');
            }
            else {
                toastrService.showSuccess('', 'Task has been created!')
            }
            return task;
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function editTask(artistID, task, successCallback, timeout) {
    return function(dispatch, getState) {
        const toastrService = new ToastrService();
        const state = getState();
        const existingTask = state.account.user.Artists.find(a => a.ID === artistID).Tasks.find(t => t.ID === task.ID);
        return TaskService.editTask(artistID, task).then(async task => {
            if(successCallback) {
                successCallback();
                await processUtils.sleep(timeout);
            }
            normaliseTask(task);
            dispatch({ type: types.TASK_UPDATE_SUCCESS, payload: { artistID, task } });
            if(existingTask.Status === 'Pending' && task.Status === 'Completed') {
                //how do we know if we've reached a milestone as we don't update the count on the Artist level. Should we make another call to get this for completed tasks?
                // and then 
                const successMessage = _.sample(taskCompletedSuccessMessages);
                toastrService.showSuccess('', successMessage);
            }
            else {
                toastrService.showSuccess('', 'Task has been updated!');
            }
        }).catch(error => {
            if(error.statusCode === 409) {
                toastrService.showError('', 'The task has already been updated elsewhere, please refresh the page to reload it and try again');
            }
            else {
                actionUtils.handleError(error);
            }
        });
    };
}

export function deleteTask(artistID, taskID) {
    return function(dispatch) {
        const toastrService = new ToastrService();
        return TaskService.deleteTask(artistID, taskID).then(_ => {
            dispatch({ type: types.TASK_DELETE_SUCCESS, payload: { artistID, taskID } });
            toastrService.showSuccess('', 'Task has been deleted!');
        }).catch(error => {
            actionUtils.handleError(error);
        });
    };
}

export function linkTaskResource(artistID, taskID, resourceID) {
    return function(dispatch, getState) {
        const toastrService = new ToastrService();
        return TaskService.linkTaskResource(artistID, taskID, resourceID).then(task => {
            normaliseTask(task);
            dispatch({ type: types.TASK_UPDATE_SUCCESS, payload: { artistID, task } });
            const state = getState();
            const resource = state.account.user.Artists.find(a => a.ID === artistID).Resources.find(r => r.ID === resourceID);
            toastrService.showSuccess('', `Item '${resource.Name}' has been linked to task '${task.Name}'`);
            return task;
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function deleteTaskResourceLink(artistID, taskID, resourceID, showToast = true) {
    return function(dispatch, getState) {
        const toastrService = new ToastrService();
        return TaskService.deleteTaskResourceLink(artistID, taskID, resourceID).then(_ => {
            const state = getState();
            const task = {...state.account.user.activeProfile.Tasks.find(t => t.ID === taskID)};            
            task.ResourceIDs = deleteArrayItem(task.ResourceIDs, r => r === resourceID);
            dispatch({ type: types.TASK_UPDATE_SUCCESS, payload: { artistID, task } });
            if(showToast) {
                toastrService.showSuccess('', 'The link has been deleted successfully');
            }
            return task;
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}