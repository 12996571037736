import _ from 'lodash';

export function readImage(event, onloadCallback, fileSizeExceededCallback, fileSizeLimitMB = 2) {
    const reader = new FileReader();
    reader.onload = onloadCallback;
    const file = event.target.files[0];
    const fileSizeMB = file.size/1024/1024;
    if(fileSizeMB > fileSizeLimitMB) {
        if(fileSizeExceededCallback) {
            fileSizeExceededCallback(`The file selected is too big, please upload a file under ${fileSizeLimitMB}MB`);
        }
    }
    else if(/\.(jpe?g|png|gif)$/i.test(file.name)) {
        reader.readAsDataURL(file);
    }  
}

export function readPDF(event, onloadCallback, fileSizeExceededCallback, fileSizeLimitMB = 10) {
    const reader = new FileReader();
    reader.onload = onloadCallback;
    const file = event.target.files[0];
    const fileSizeMB = file.size/1024/1024;
    if(fileSizeMB > fileSizeLimitMB) {
        if(fileSizeExceededCallback) {
            fileSizeExceededCallback(`The file selected is too big, please upload a file under ${fileSizeLimitMB}MB`);
        }
    }
    else if(/\.(pdf)$/i.test(file.name)) {
        reader.readAsDataURL(file);
    }  
}

export function getImageExtension(rawString) {
    const identifier = rawString.trim()[0];
    let extension;
    if(identifier === '/') {
        extension = 'jpg';
    }
    else if(identifier === 'i') {
        extension = 'png';
    }
    else if(identifier === 'R') {
        extension = "gif";
    }
    else {
        extension = "bmp";
    }
    return extension;
}

export function getBase64Url(rawString) {    
    const extension = getImageExtension(rawString);
    return `data:image/${extension};base64,${rawString}`;
}

export function getFileSizeText(length) {
    const fileSizeKb = _.round(length / 1024, 1);
    let fileSizeText = '';
    if (fileSizeKb > 1024) {
        fileSizeText = `${Math.round(fileSizeKb / 1024)} MB`;
    }
    else {
        fileSizeText = `${fileSizeKb} KB`;
    }
    return fileSizeText;
}

export function getFileSizeTextFromBytes(bytes) {
    return getFileSizeText(bytes.length)
}

export function getFileSizeTextFromBase64(base64String) {
    const base64Content = base64String.split('base64,')[1];
    const decoded = atob(base64Content);
    return getFileSizeText(decoded);
}

export function blobToBase64(blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
        reader.onloadend = () => {
            resolve(reader.result);
        };
    });
};