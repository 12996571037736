import React from 'react';

const Footer = React.memo(() => {
    return(
        <footer className="site-footer">
            <div className="container">
                <p className="m-0 text-muted">© {new Date().getFullYear()} LoudUp LTD. All Rights Reserved.</p>
                 <div className="footer__links-to-app">
                    <a href="https://itunes.apple.com/us/app/mike-manage-your-music-career/id1297774596" target="_blank" rel="noopener noreferrer"><img src={require('../../images/appstore.png')} alt="" /></a>
                    <a href="https://play.google.com/store/apps/details?id=co.mikeapp" target="_blank" rel="noopener noreferrer"><img src={require('../../images/googleplay.png')} alt="" /></a>
                </div>
            </div>
        </footer>
    );
});

Footer.propTypes = {
};

export default Footer;