import FinanceService from '../services/financeService';
import ToastrService from '../services/toastrService';
import { push } from 'connected-react-router';
import * as types from './actionTypes';
import * as actionUtils from '../utils/actionUtils';
import { normaliseIncome, normaliseBalance, normaliseExpense, normaliseBudgetIncome, normaliseBudgetExpense } from '../utils/normaliseUtils';

export function createExpense(artistID, expense) {
    return function(dispatch) {
        const toastrService = new ToastrService();
        return FinanceService.createExpense(artistID, expense).then(expense => {
            normaliseExpense(expense);
            dispatch({ type: types.EXPENSE_CREATE_SUCCESS, payload: { artistID, expense } });
            toastrService.showSuccess('', 'Expense has been created!')
            if(window.location.pathname !== '/finances') {
                dispatch(push('/finances'));
            }
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function editExpense(artistID, expense) {
    return function(dispatch) {
        const toastrService = new ToastrService();
        return FinanceService.editExpense(artistID, expense).then(expense => {
            normaliseExpense(expense);
            dispatch({ type: types.EXPENSE_UPDATE_SUCCESS, payload: { artistID, expense } });
            toastrService.showSuccess('', 'Expense has been updated!')
            if(window.location.pathname !== '/finances') {
                dispatch(push('/finances'));
            }
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function deleteExpense(artistID, expenseID) {
    return function(dispatch) {
        const toastrService = new ToastrService();
        return FinanceService.deleteExpense(artistID, expenseID).then(_ => {
            dispatch({ type: types.EXPENSE_DELETE_SUCCESS, payload: { artistID, expenseID } });
            toastrService.showSuccess('', 'Expense has been deleted!');
        }).catch(error => {
            actionUtils.handleError(error);
        });
    };
}

export function createIncome(artistID, income) {
    return function(dispatch) {
        const toastrService = new ToastrService();
        return FinanceService.createIncome(artistID, income).then(income => {
            normaliseIncome(income);
            dispatch({ type: types.INCOME_CREATE_SUCCESS, payload: { artistID, income } });
            toastrService.showSuccess('', 'Income has been created!')
            if(window.location.pathname !== '/finances') {
                dispatch(push('/finances'));
            }
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function editIncome(artistID, income) {
    return function(dispatch) {
        const toastrService = new ToastrService();
        return FinanceService.editIncome(artistID, income).then(income => {
            normaliseIncome(income);
            dispatch({ type: types.INCOME_UPDATE_SUCCESS, payload: { artistID, income } });
            toastrService.showSuccess('', 'Income has been updated!')
            if(window.location.pathname !== '/finances') {
                dispatch(push('/finances'));
            }
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function deleteIncome(artistID, incomeID) {
    return function(dispatch) {
        const toastrService = new ToastrService();
        return FinanceService.deleteIncome(artistID, incomeID).then(_ => {
            dispatch({ type: types.INCOME_DELETE_SUCCESS, payload: { artistID, incomeID } });
            toastrService.showSuccess('', 'Income has been deleted!');
        }).catch(error => {
            actionUtils.handleError(error);
        });
    };
}

export function createBalance(artistID, balance) {
    return function(dispatch, getState) {
        const toastrService = new ToastrService();
        return FinanceService.createBalance(artistID, balance).then(balance => {
            normaliseBalance(balance);
            dispatch({ type: types.BALANCE_CREATE_SUCCESS, payload: { artistID, balance } });
            toastrService.showSuccess('', 'Balance has been saved!')
            if(window.location.pathname !== '/finances') {
                dispatch(push('/finances'));
            }
        }).catch(error => {
            actionUtils.handleError(error);
        });
    };
}

export function editBalance(artistID, balance) {
    return function(dispatch) {
        const toastrService = new ToastrService();
        return FinanceService.editBalance(artistID, balance).then(balance => {
            normaliseBalance(balance);
            dispatch({ type: types.BALANCE_UPDATE_SUCCESS, payload: { artistID, balance } });
            toastrService.showSuccess('', 'Balance has been updated!')
            if(window.location.pathname !== '/finances') {
                dispatch(push('/finances'));
            }
        }).catch(error => {
            actionUtils.handleError(error);
        });
    };
}

export function deleteBalance(artistID, balanceID) {
    return function(dispatch) {
        const toastrService = new ToastrService();
        return FinanceService.deleteBalance(artistID, balanceID).then(_ => {
            dispatch({ type: types.BALANCE_DELETE_SUCCESS, payload: { artistID, balanceID } });
            toastrService.showSuccess('', 'Balance has been deleted!');
        }).catch(error => {
            actionUtils.handleError(error);
        });
    };
}

export function createBudgetExpense(artistID, budgetExpense) {
    return function(dispatch) {
        const toastrService = new ToastrService();
        return FinanceService.createBudgetExpense(artistID, budgetExpense).then(budgetExpense => {
            normaliseBudgetExpense(budgetExpense);
            dispatch({ type: types.BUDGET_EXPENSE_CREATE_SUCCESS, payload: { artistID, budgetExpense } });
            toastrService.showSuccess('', 'Budgeted expense has been created!')
            if(window.location.pathname !== '/finances') {
                dispatch(push('/finances'));
            }
        }).catch(error => {
            actionUtils.handleError(error);
        });
    };
}

export function editBudgetExpense(artistID, budgetExpense) {
    return function(dispatch) {
        const toastrService = new ToastrService();
        return FinanceService.editBudgetExpense(artistID, budgetExpense).then(budgetExpense => {
            normaliseBudgetExpense(budgetExpense);
            dispatch({ type: types.BUDGET_EXPENSE_UPDATE_SUCCESS, payload: { artistID, budgetExpense } });
            toastrService.showSuccess('', 'Budgeted expense has been updated!')
            if(window.location.pathname !== '/finances') {
                dispatch(push('/finances'));
            }
        }).catch(error => {
            actionUtils.handleError(error);
        });
    };
}

export function deleteBudgetExpense(artistID, budgetExpenseID) {
    return function(dispatch) {
        const toastrService = new ToastrService();
        return FinanceService.deleteBudgetExpense(artistID, budgetExpenseID).then(_ => {
            dispatch({ type: types.BUDGET_EXPENSE_DELETE_SUCCESS, payload: { artistID, budgetExpenseID } });
            toastrService.showSuccess('', 'Budgeted expense has been deleted!');
        }).catch(error => {
            actionUtils.handleError(error);
        });
    };
}

export function createBudgetIncome(artistID, budgetIncome) {
    return function(dispatch) {
        const toastrService = new ToastrService();
        return FinanceService.createBudgetIncome(artistID, budgetIncome).then(budgetIncome => {
            normaliseBudgetIncome(budgetIncome);
            dispatch({ type: types.BUDGET_INCOME_CREATE_SUCCESS, payload: { artistID, budgetIncome } });
            toastrService.showSuccess('', 'Budgeted income has been created!')
            if(window.location.pathname !== '/finances') {
                dispatch(push('/finances'));
            }
        }).catch(error => {
            actionUtils.handleError(error);
        });
    };
}

export function editBudgetIncome(artistID, budgetIncome) {
    return function(dispatch) {
        const toastrService = new ToastrService();
        return FinanceService.editBudgetIncome(artistID, budgetIncome).then(budgetIncome => {
            normaliseBudgetIncome(budgetIncome);
            dispatch({ type: types.BUDGET_INCOME_UPDATE_SUCCESS, payload: { artistID, budgetIncome } });
            toastrService.showSuccess('', 'Budgeted income has been updated!')
            if(window.location.pathname !== '/finances') {
                dispatch(push('/finances'));
            }
        }).catch(error => {
            actionUtils.handleError(error);
        });
    };
}

export function deleteBudgetIncome(artistID, budgetIncomeID) {
    return function(dispatch) {
        const toastrService = new ToastrService();
        return FinanceService.deleteBudgetIncome(artistID, budgetIncomeID).then(_ => {
            dispatch({ type: types.BUDGET_INCOME_DELETE_SUCCESS, payload: { artistID, budgetIncomeID } });
            toastrService.showSuccess('', 'Budgeted income has been deleted!');
        }).catch(error => {
            actionUtils.handleError(error);
        });
    };
}

export function getReceipt(artistID, receiptID) {
    return function(dispatch) {
        return FinanceService.getReceipt(artistID, receiptID).then(receipt => {
            dispatch({ type: types.GET_RECEIPT_SUCCESS, payload: { artistID, receipt } });
            return receipt;
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function uploadReceipt(artistID, filename, dataString, description) {
    return function(dispatch) {
        return FinanceService.uploadReceipt(artistID, filename, dataString, description).then(receipt => {
            dispatch({ type: types.UPLOAD_RECEIPT_SUCCESS, payload: { artistID, receipt } });
            return receipt;
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function deleteReceipt(artistID, receiptID) {
    return function(dispatch) {
        return FinanceService.deleteReceipt(artistID, receiptID).then(_ => {
            dispatch({ type: types.DELETE_RECEIPT_SUCCESS, payload: { artistID, receiptID } });
            return receiptID;
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function exportTransactions(artistID, itemType, startDate, endDate, format, includeDescriptions) {
    return function(dispatch, getState) {
        const toastrService = new ToastrService();
        return FinanceService.exportTransactions(artistID, itemType, startDate, endDate, format, includeDescriptions).then(_ => {
            const state = getState();
            //we might want to change this to a div on the page
            toastrService.showSuccess('', `Your export has been generated and emailed to you at ${state.account.user.Email}`);
            return true;
        }).catch(error => {
            actionUtils.handleError(error);
            return false;
        });
    };
}

export function exportBudget(artistID, itemType, budgetPeriod, format) {
    return function(dispatch, getState) {
        const toastrService = new ToastrService();
        return FinanceService.exportBudget(artistID, itemType, budgetPeriod, format).then(_ => {
            const state = getState();
            //we might want to change this to a div on the page
            toastrService.showSuccess('', `Your export has been generated and emailed to you at ${state.account.user.Email}`);
            return true;
        }).catch(error => {
            actionUtils.handleError(error);
            return false;
        });
    };
}