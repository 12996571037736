import BaseService from './baseService';

class TaskService {

    static createTask(artistID, task) {
        return BaseService.postAndReturn(`/Artists/${artistID}/Tasks`, task, BaseService.returnAssignedObject, 'Unable to create task', true);
    } 

    static editTask(artistID, task) {
        return BaseService.putAndReturn(`/Artists/${artistID}/Tasks/${task.ID}`, task, BaseService.returnAssignedObject, 'Unable to update task', true);
    } 
    
    static deleteTask(artistID, taskID) {
        return BaseService.deleteAndReturn(`/Artists/${artistID}/Tasks/${taskID}`, BaseService.returnAssignedObject, 'Unable to delete task', true);
    }

    static linkTaskResource(artistID, taskID, resourceID) {
        return BaseService.postAndReturn(`/Artists/${artistID}/Tasks/${taskID}/Resources`, resourceID, BaseService.returnAssignedObject, 'Unable to link item', true);
    } 

    static deleteTaskResourceLink(artistID, taskID, resourceID) {
        return BaseService.deleteAndReturn(`/Artists/${artistID}/Tasks/${taskID}/Resources/${resourceID}`, BaseService.returnAssignedObject, 'Unable to delete item', true);
    }
 }

export default TaskService;