import BaseService from './baseService';

class ResourceService {

    static createResource(artistID, resource) {
        return BaseService.postAndReturn(`/Artists/${artistID}/Resources`, resource, BaseService.returnAssignedObject, 'Unable to create resource', true);
    } 

    static editResource(artistID, resource) {
        return BaseService.putAndReturn(`/Artists/${artistID}/Resources/${resource.ID}`, resource, BaseService.returnAssignedObject, 'Unable to update resource', true);
    } 
    
    static deleteResource(artistID, resourceID) {
        return BaseService.deleteAndReturn(`/Artists/${artistID}/Resources/${resourceID}`, BaseService.returnAssignedObject, 'Unable to delete resource', true);
    }

    static getFile(artistID, resourceID) {
        return BaseService.getAndReturn(`/Artists/${artistID}/Resources/${resourceID}/File`, BaseService.returnAssignedObject, 'Unable to get file', true);
    }
 }

export default ResourceService;