import BaseService from './baseService';

class PricingService {
    static getPricingPlans() {
        return BaseService.getAndReturn('/PricingPlans', BaseService.returnAssignedArray, 'Unable to get pricing plans', false);
    }

    static getPricingPlansStripe() {
        return BaseService.getAndReturn('/PricingPlans/Stripe', BaseService.returnAssignedArray, 'Unable to get Stripe pricing plans', true);
    }
}
export default PricingService;