import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as goalActions from '../../actions/goalActions';
import { useFormInput, useFormCheckInput } from '../../utils/customHooks';
import { getMemberName, checkSubscription } from '../../utils/artistUtils';
import { goalTypes } from '../../constants/types';
import ToastrService from '../../services/toastrService';
import moment from 'moment';
import 'select2/dist/js/select2';
import 'select2/dist/css/select2.min.css'
import { isValidDate, isBrowserDateTypeSupported } from '../../utils/dateUtils';
import AnalyticsService from '../../services/analyticsService';
import { getSocialPlatformUrl } from '../../utils/urlUtils';
import { Link } from 'react-router-dom';

function GoalModal({goal, user, actions, viewMode = false}) {
    const activeProfile = user.activeProfile;
    const [type, setType] = useState(goal ? goal.Type : '');
    const initialInput = useFormInput(goal ? goal.Initial : 0);
    const targetInput = useFormInput(goal ? goal.Target : 0);
    const currentInput = useFormInput(goal ? goal.Current : 0);
    const targetDateInput = useFormInput(goal ? goal.TargetDate.toISOString().split("T")[0] : '');
    const [linkedSocialProfileID, setLinkedSocialProfileID] = useState(goal ? goal.LinkedSocialProfileID : null);
    const [isViewMode, setIsViewMode] = useState(viewMode);
    const [savingGoal, setSavingGoal] = useState(false);

    const typeRef = useRef();

    const typeChanged = (event) => {
        const data = event.params.data;
        if(data) {            
            setType(data.id);
        }
    }

    useEffect(() => {
        if(typeRef.current) {
            const typeControl = window.$(typeRef.current);
            typeControl.select2({
                theme : "classic" 
            })
            .on('select2:select', typeChanged);
            if(type) {
                typeControl.val(type).trigger('change');
            }
        }
    }, []);

    const isEdit = goal && !isViewMode;
    const browserDateTypeSupported = isBrowserDateTypeSupported();

    useEffect(() => {
        let eventName = isEdit ? 'Edit Goal Launched' : 'Add Goal Launched';
        AnalyticsService.logEvent(eventName);
    }, []);

    useEffect(() => {
        setLinkedSocialProfileID(goal && goal.Type === type ? goal.LinkedSocialProfileID : null);
    }, [type])

    const validateGoal = () => {
        const toastrService = new ToastrService();
        if(!type) {
            toastrService.showError('', 'Looks like you forgot to select the goal type');
            return false;
        }
        if(!targetDateInput.value) {
            toastrService.showError('', 'Looks like you forgot to enter the target date');
            return false;
        }
        if(!isValidDate(targetDateInput.value)) {
            toastrService.showError('', 'Looks like the target date isn\'t valid, please enter the date in the format YYYY-MM-DD');
            return false;
        }
        if(!isEdit && moment(new Date(targetDateInput.value)) < moment(new Date()).startOf('day')) {
            toastrService.showError('', 'The target date cannot be a date in the past');
            return false;
        }
        if(!targetInput.value) {
            toastrService.showError('', 'Looks like you forgot to enter the target');
            return false;
        }
        if(targetDateInput.value < initialInput.value) {
            toastrService.showError('', 'Looks like you have already hit your target');
            return false;
        }
        return true;
    }

    const getGoal = () => {
        return {
            ID: isEdit ? goal.ID : null,
            Type: type,
            Initial: initialInput.value || 0,
            Current: isEdit ? currentInput.value : initialInput.value || 0,
            Target: targetInput.value,
            Timestamp: isEdit ? goal.Timestamp : new Date(),
            TargetDate: new Date(targetDateInput.value).toISOString().split('T')[0] + 'T00:00:00+00:00', //set the target date with no time or time zone applied
            LinkedSocialProfileID: linkedSocialProfileID,
            IsLinkedToSocialProfile: linkedSocialProfileID ? true : false,
            Version: isEdit ? goal.Version : 0
        };			
    }

    const createGoal = (event) => {
        event.preventDefault();
        checkSubscription(activeProfile, () => {
            if(validateGoal()) {
                setSavingGoal(true);
                const newGoal = getGoal();
                actions.addGoal(activeProfile.ID, newGoal)
                    .then(goal => {
                        window.$("#modal").modal("hide");
                        setSavingGoal(false);
                    })
                    .catch(error => {
                        //do nothing, the action will show the error
                        setSavingGoal(false);
                    });
            }      
        }, 'create a goal');          
    }

    const updateGoal = (event) => {
        event.preventDefault();
        checkSubscription(activeProfile, () => {
            if(validateGoal()) {
                setSavingGoal(true);
                const updatedGoal = getGoal();
                actions.editGoal(activeProfile.ID, updatedGoal)
                    .then(goal => {
                        window.$("#modal").modal("hide");
                        setSavingGoal(false);
                    })
                    .catch(error => {
                        //do nothing, the action will show the error
                        setSavingGoal(false);
                    });
            }
        }, 'update a goal');        
    }

    const socialProfile = type && goalTypes[type].SocialPlatform ? activeProfile.Social[goalTypes[type].SocialPlatform] : null;

    const toggleSocialProfileLinked = (event) => {
        if(event.target.checked) {
            setLinkedSocialProfileID(socialProfile.ID)
        }
        else {
            setLinkedSocialProfileID(null);
        }
    }

    return (     
        <div className={`modal-dialog modal-lg modal-dialog--goal ${!browserDateTypeSupported ? 'no-date-support' : ''}`}>
            {
                savingGoal ?
                    <div className="modal-window__loader modal-window__loader-task">
                        <img src={require('../../images/loader.svg')} alt="" />
                        {
                            isEdit ? 'Saving goal' : 'Creating goal'
                        }
                    </div>
                    : null
            }            
            <div className="modal-content">
                <div className="modal-header modal-header--goals">
                    <h5 className="modal-title" id="staticBackdropLabel">{isEdit ? 'Edit Goal' : (isViewMode ? 'View Goal' : 'New Goal')}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i className="mikeicon-x" />
                    </button>
                </div>
                <div className="modal-body">
                    <form className="form form--lined form--inputbg modal-inner" onSubmit={isEdit ? updateGoal : (!isViewMode ? createGoal : null)}>
                        <div className="form-group mb-2">
                            <label>Goal Type</label>
                            {
                                isViewMode ?
                                    <input className="form-control" type="text" value={(goalTypes[type] ? goalTypes[type].DisplayText : null) || ''} readOnly /> 
                                :
                                    <select className="form-control select" ref={typeRef}>
                                        <option value="" disabled selected>Select goal type</option>
                                        {
                                            Object.keys(goalTypes).map(goalType =>
                                                <option key={goalType} value={goalType}>{goalTypes[goalType].DisplayText}</option>
                                            )
                                        }
                                    </select>
                            }                            
                        </div>
                        {
                            type && goalTypes[type].SocialPlatform ?
                                <div className="connect-prompt">
                                    {
                                        socialProfile && socialProfile.IsConfigured ? 
                                            <div className="custom-control custom-checkbox mb-3">
                                                <input type="checkbox" class="custom-control-input" id="check-includecompleted" checked={linkedSocialProfileID ? true : false} onChange={toggleSocialProfileLinked} readOnly={isViewMode} />
                                                <label className="custom-control-label" for="check-includecompleted">Connect this goal to {goalTypes[type].SocialPlatform} profile <span><a href={getSocialPlatformUrl(socialProfile, goalTypes[type].SocialPlatform)} target="_blank" rel="noopener noreferer">{socialProfile.Username ? `@${socialProfile.Username}` : socialProfile.Name}</a></span> to automatically update your progress against this goal</label>
                                            </div>  
                                        :
                                            !isViewMode ?
                                                <div>Connect to your <Link to="/social" onClick={() => window.$("#modal").modal("hide")}>{goalTypes[type].SocialPlatform} profile</Link> to automatically update your progress against this goal.</div>
                                                : null
                                    }                           
                                </div>
                                : null
                        }                        
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label>Target value</label>
                                    <input className="form-control" type="number" placeholder="0" {...targetInput} readOnly={isViewMode} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-2">
                                    <label>Target date</label>
                                    <div className="datapicker-wrapper" readOnly={isViewMode}>
                                        <input className="form-control form-date datepicker" type="date" min={!isEdit ? new Date().toISOString().split("T")[0] : null} placeholder={browserDateTypeSupported ? 'Select date' : 'yyyy-mm-dd'} {...targetDateInput} readOnly={isViewMode} /> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label>Initial value</label>
                                    <input className="form-control" type="number" placeholder="0" {...initialInput} readOnly={isViewMode || (!isEdit && linkedSocialProfileID)} />
                                    {
                                        !isEdit && linkedSocialProfileID ? 
                                            <span className="text-muted small">This value will be updated automatically</span>
                                            : null
                                    }
                                </div>
                            </div>
                            {
                                isEdit ?
                                    <div className="col-md-6">
                                        <div className="form-group mb-4">
                                            <label>Current value</label>                                        
                                            <input className="form-control" type="number" placeholder="0" {...currentInput} readOnly={isViewMode || linkedSocialProfileID} />
                                            {
                                                !isViewMode && linkedSocialProfileID ? 
                                                    <span className="text-muted small">This value will be updated automatically</span>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                    : null
                            }                                
                        </div>
                        <div className={`form-group text-center mt-4 modal-buttons ${isViewMode ? 'modal-buttons--view' : ''}`}>
                            {
                                isViewMode ? 
                                    <button type="button" className="btn btn-primary btn-wide m-1" onClick={() => checkSubscription(activeProfile, () => setIsViewMode(false), 'edit a goal') }>Edit Goal</button>
                                    :
                                    <React.Fragment>
                                        <button type="button" className="btn btn-outline btn-wide m-1" data-dismiss="modal">Cancel</button>
                                        <button type="submit" className="btn btn-primary btn-wide m-1">{isEdit ? 'Save Goal' : 'Create Goal'}</button>
                                    </React.Fragment>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user
    };
}


function mapDispatchToProps(dispatch) {
    return {
        //actions will now be available under props.actions
        actions: bindActionCreators(Object.assign({}, goalActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GoalModal);
