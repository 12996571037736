import React, { useState, useRef, useEffect } from 'react'
import moment from 'moment';
import { formatNumber } from '../../utils/financeUtils.js';
import { getMemberName, checkSubscription } from '../../utils/artistUtils.js';
import { getTimeAgo } from '../../utils/dateUtils.js';
import swal from 'sweetalert';
import { trim } from 'lodash';
import LinkedItems from '../common/LinkedItems';
import ToastrService from '../../services/toastrService.js';
/* global google */

function ViewEvent({event, setIsViewMode, setLoadingFile, setLoadingPhoto, setLoadingAudio, setDeletingLink, user, actions, googleMapsPromise}) {
    const startDateISOParts = event.StartDate.toISOString().split("T");
    const startDate = new Date(startDateISOParts[0] + 'T' + startDateISOParts[1].substring(0, 5));
    const startDateMoment = moment(startDate);
    let endDateMoment = null;
    if(event.EndDate) {
        const endDateISOParts = event.EndDate.toISOString().split("T");
        const endDate = new Date(endDateISOParts[0] + 'T' + endDateISOParts[1].substring(0, 5));
        endDateMoment = moment(endDate);
    }
    const activeProfile = user.activeProfile;
    const [note, setNote] = useState('');
    const noteInputRef = useRef();
    const [addingNote, setAddingNote] = useState(false);
    const [updatingAttendance, setUpdatingAttendance] = useState(false);;
    const mountedRef = useRef(true);
    const googleMapRef = useRef();
    const displayMap = event.Longitude !== null && event.Latitude !== null;
    const userIsGoing = (userID) => event.Attendances.find(a => a.UserID === userID && a.Status === 'Attending') != null; //we can't use !== as it will fail for undefined
    const userIsNotGoing = (userID) => event.Attendances.find(a => a.UserID === userID && a.Status === 'NotAttending') != null;
    const going = userIsGoing(user.ID);
    const notGoing = userIsNotGoing(user.ID);
    const goingText = activeProfile.ArtistType === 'Band' ? 
        activeProfile.Members.filter(m => userIsGoing(m.ID))
            .map(m => getMemberName(user, activeProfile.Members, m.ID))
            .join(', ') || '-' 
        : '';
    const notGoingText = activeProfile.ArtistType === 'Band' ? 
        activeProfile.Members.filter(m => userIsNotGoing(m.ID))
            .map(m => getMemberName(user, activeProfile.Members, m.ID))
            .join(', ') || '-' 
        : '';
    
    useEffect(() => {
        if(displayMap) {        
            initMap(); 
        }
        return () => mountedRef.current = false;
    }, []);

    const initMap = () => {
        if(googleMapsPromise) {
            googleMapsPromise.then(_ => {
                const latLng = new google.maps.LatLng(event.Latitude, event.Longitude);
                const mapProp = {
                    center: latLng,
                    zoom: 16,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.TOP_RIGHT
                    },
                    streetViewControlOptions: {
                        position: google.maps.ControlPosition.LEFT_CENTER
                    }
                };
                const map = new google.maps.Map(googleMapRef.current, mapProp);
                const marker = new google.maps.Marker({
                    position: latLng,
                    map: map,
                    title: event.Location
                });
            })
            
        }          
    };

    const toggleAddNote = () => {
       checkSubscription(activeProfile, () => window.$(noteInputRef.current).slideToggle(), 'add a note');
    }

    const addNote = () => {
        checkSubscription(activeProfile, () => {
            if(trim(note)) {
                setAddingNote(true);
                const newNote = { UserID: user.ID, Timestamp: new Date(), Text: trim(note) };
                actions.addEventNote(activeProfile.ID, event.ID, newNote)
                    .then(_ => {
                        setAddingNote(false);
                        setNote('');
                        toggleAddNote();
                    })
                    .catch(error => setAddingNote(false));
            }
        }, 'add a note');        
    }

    const deleteNote = (e, note) => {
        checkSubscription(activeProfile, () => {
            swal({
                title: 'Are you sure?',
                text: 'Once deleted, you will not be able to recover this note!',
                buttons: true,
                dangerMode: true,
              })
              .then((willDelete) => {
                if (willDelete) {
                    actions.deleteEventNote(activeProfile.ID, event.ID, note.ID);
                }
            });     
        }, 'delete a note');           
    }

    const setAttendance = (status) => {
        const now = new Date();
        if(event.StartDate >= now || (event.EndDate && event.EndDate >= now)) {
            checkSubscription(activeProfile, () => {
                if(!updatingAttendance && event.Status !== 'Cancelled') {
                    const existingAttendance = event.Attendances.find(a => a.UserID === user.ID);
                    let attendance;
                    if(existingAttendance) {
                        //use the exising attendance object so the version number will be correct. if the status matches the existing status, reset to Pending
                        attendance = {...existingAttendance, Status: existingAttendance.Status === status ? 'Pending' : status };
                    }
                    else {
                        attendance = { UserID: user.ID, Status: status };
                    }
                    setUpdatingAttendance(true);
                    actions.setEventAttendance(activeProfile.ID, event.ID, attendance)
                        .then(_ => setUpdatingAttendance(false))
                        .catch(error => setUpdatingAttendance(false));
                }       
            }, 'update your attendance'); 
        }
        else {
            const toastrService = new ToastrService();
            toastrService.showInfo('', 'It looks like this event has already been and gone!');
        }     
    }

    const notes = event.Notes.sort((a, b) => b.Timestamp - a.Timestamp);

    return (
        <React.Fragment>
            <div className={`event-modal__map-wrapper ${!displayMap ? 'no-adress' : ''}`}>
                <div id="googleMap" ref={googleMapRef} />
                 <div className="event-modal__header-content">
                    <h4 className="event-modal__title">{event.Name}</h4>
                    <div className="event-modal__separator" /> 
                    <div className="event-modal__location"> 
                        {`${event.Location} ${event.Address ? ` - ${event.Address}` : ''}`}
                    </div>  
                </div>
            </div>                
            <div className="event-modal__header-buttons">
                <div className={`event-modal__header-button-cancel event-modal__header-button ${notGoing ? 'active': ''} ${updatingAttendance || event.Status === 'Cancelled' ? 'disabled' : ''}`} onClick={() => setAttendance('NotAttending')}>
                    <img src={require('../../images/close.svg')} alt="" />
                </div>
                <div className={`event-modal__header-button-check event-modal__header-button ${going ? 'active' : ''} ${updatingAttendance || event.Status === 'Cancelled' ? 'disabled' : ''}`} onClick={() => setAttendance('Attending')}> 
                        <img src={require('../../images/tick.svg')} alt="" />
                </div>
            </div>
            <div className="modal-body">
                <div className="event-modal__item">
                    <label htmlFor="">STARTING</label>
                    <span>{startDateMoment.format('DD MMM YYYY')}, <span className="event-modal__time">{startDateMoment.format('HH:mm')}</span></span>
                </div>
                {
                    endDateMoment ?
                        <div className="event-modal__item">
                            <label htmlFor="">Ending</label>
                            <span>{endDateMoment.format('DD MMM YYYY')}, <span className="event-modal__time">{endDateMoment.format('HH:mm')}</span></span>
                        </div>
                        : null
                }
                
                <div className="event-modal__item">
                    <label htmlFor="">Type</label>
                    <span>{event.Type}</span>
                </div>
                <div className="event-modal__item">
                    <label htmlFor="">STATUS</label>
                    <span>{event.Status}</span>
                </div>
                <div className="event-modal__item">
                    <label htmlFor="">Description</label>
                    <span>{event.Description || '-'}</span>
                </div>
                {
                    event.Type === 'Gig' ?
                        <div className="event-modal__item">
                            <label htmlFor="">Income</label>
                            <span>{formatNumber(event.Income)}</span>
                        </div>
                        : null
                }            
                {
                    activeProfile.ArtistType === 'Band' ?
                        <React.Fragment>
                            <div className="event-modal__item">
                                <label htmlFor="">Going</label>
                                <span>{goingText}</span>
                            </div>
                            <div className="event-modal__item">
                                <label htmlFor="">Not Going</label>
                                <span>{notGoingText}</span>
                            </div>
                        </React.Fragment>
                        : null
                }
                <div className="event-modal__item">
                    <label htmlFor="">Notes <span className="add-notes" onClick={toggleAddNote}>+</span></label>
                    <span className="notes-list">
                        <span className="notes-list__item notes-list__item--hidden" ref={noteInputRef}>                            
                            <span className="notes-list__item-text">
                                <span className="form-group">
                                    <form action="" className="form form--inputbg form--lined">
                                        <input className="form-control" type="text" placeholder="Enter your note" value={note} onChange={(e) => setNote(e.target.value)} />
                                    </form>
                                </span>                                
                            </span>
                            <span className="add-notes__footer">
                                <button className="btn btn-transparent-white btn--cancel" onClick={toggleAddNote} disabled={addingNote}>
                                    Cancel
                                </button>&nbsp;
                                <button className="btn btn-transparent-white" onClick={!addingNote ? addNote : null} disabled={addingNote}>
                                    Save
                                </button>
                            </span>
                        </span>
                        {
                            notes.map(note =>
                                <span key={note.ID} className="notes-list__item">
                                    <span className="notes-list__item-header">
                                        <span className="notes-list__item-author">
                                            {getMemberName(user, activeProfile.Members, note.UserID)}
                                        </span> <span className="notes-list__dot"></span>
                                        <span className="notes-list__item-date">
                                            {getTimeAgo(note.Timestamp)}
                                        </span>
                                    </span>
                                    <span className="notes-list__item-text">
                                        {note.Text}
                                    </span>
                                    <span className="notes-item__close" onClick={(e) => deleteNote(e, note)}>
                                        +
                                    </span>
                                </span>
                            )
                        }
                    </span>                   
                </div>
                {
                    event.ResourceIDs.length ?
                        <LinkedItems type="Event" item={event} resourceIDs={event.ResourceIDs} setLoadingFile={setLoadingFile} setLoadingPhoto={setLoadingPhoto} setLoadingAudio={setLoadingAudio} setDeletingLink={setDeletingLink} />
                        : null
                }
                <div className="form-group text-center mt-4 modal-buttons modal-buttons--view">
                    <button type="button" className="btn btn-primary btn-wide m-1" onClick={() => checkSubscription(activeProfile, () => setIsViewMode(false), 'edit an event') }>Edit Event</button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ViewEvent;