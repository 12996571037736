import CurrencyService from '../services/currencyService';
import * as types from './actionTypes';

export function getCurrencies(){
    return function(dispatch, getState) {
        const savedCurrencies = getState().currencies;
        if(savedCurrencies.length === 0) {
            return CurrencyService.getCurrencies().then(currencies => {
                dispatch({ type: types.GET_CURRENCIES_SUCCESS, currencies });
                return currencies;
            }).catch(error => {
                return null;
            });
        }
        else {
            return savedCurrencies;
        }
    };
}

export function getCurrency(){
    return function(dispatch, getState) {
        return CurrencyService.getCurrency().then(currency => {
            return currency;
        }).catch(error => {
            return null;
        });
    };
}