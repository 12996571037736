import moment from 'moment';

export function addGoalCalculatedFields(goal) {
    goal.Percentage = getGoalPercentage(goal);
    goal.OnTrack = getGoalOnTrack(goal);
    goal.DaysLeft = moment(goal.TargetDate).diff(moment(), 'days');
}
function getGoalPercentage(goal) {
    if(goal.Target === 0) {
        return 0;
    }
    else if(goal.Target == goal.Initial) {
        //if target == initial, return 100 else we end up with a divide by zero error
        return 100;
    }
    else {
        return (goal.Current - goal.Initial) / (goal.Target - goal.Initial) * 100
    }
}

function getGoalOnTrack(goal) {
    const targetDate = moment(goal.TargetDate);
    const timestamp = moment(goal.Timestamp).startOf('day');
    const totalDays = targetDate.diff(timestamp, 'days');
    const daysUsed = moment().diff(timestamp, 'days');
    const timePercentage = (daysUsed / totalDays) * 100;    
    return !goal.Percentage ? false : goal.Percentage >= timePercentage; 
}
