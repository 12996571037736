import React, { useState, useRef, useEffect } from 'react';
import { useFormInput } from '../../utils/customHooks';
import { withModalContext } from '../../services/modalService';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as accountActions from '../../actions/accountActions';
import { trim } from 'lodash';
import Header from './Header';
import ToastrService from '../../services/toastrService';
import PasswordChange from './PasswordChange';

function AccountSettingsPage({user, actions, showModal, history}) {
    const emailInput = useFormInput(user.Email);
    const firstNameInput = useFormInput(user.FirstName);
    const lastNameInput = useFormInput(user.LastName);
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [passwordChanging, setPasswordChanging] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const accountSettingsRef = useRef();
    const changePasswordRef = useRef();

    useEffect(() => {
        if(showChangePassword) { 
            window.$(accountSettingsRef.current).slideUp();
            window.$(changePasswordRef.current).slideDown();
        }
        else {            
            window.$(accountSettingsRef.current).slideDown();
            window.$(changePasswordRef.current).slideUp();
        }
    }, [showChangePassword])

    const validate = () => {
        const toastrService = new ToastrService();
        if(!trim(emailInput.value)) {
            toastrService.showError('', 'Looks like you forgot to enter your email address');
            return false;
        }
        if(!trim(firstNameInput.value)) {
            toastrService.showError('', 'Looks like you forgot to enter your first name');
            return false;
        }
        if(!trim(lastNameInput.value)) {
            toastrService.showError('', 'Looks like you forgot to enter your last name');
            return false;
        }
        return true;
    }

    const updateUser = (event) => {
        event.preventDefault();
        setSaving(true);
        if(validate()) {
            actions.updateUser(emailInput.value, firstNameInput.value, lastNameInput.value)
                .then(result => setSaving(false))
                .catch(error => setSaving(false));
        }
    }

    return (
        <React.Fragment>
            <main className="">
                <button className="close-page" onClick={() => showChangePassword ? setShowChangePassword(false) : history.push('/')} />
                <div className="container">
                    <Header className="page__branding" />
                    <div className="members">         
                        <div className="account-setting__content">
                            <div className="setting-form" ref={accountSettingsRef}> 
                                <h1 className="members__title">Account settings</h1>
                                <form className="form form--lined form--inputbg" onSubmit={updateUser}>
                                    <div className="form-group mb-2">
                                        <label>Email</label>
                                        <input className="form-control" type="email" required placeholder="Enter your email address" {...emailInput} />
                                    </div>
                                    <div className="form-group mb-2">
                                        <label>First name</label>
                                        <input className="form-control" type="text" required placeholder="Enter your first name" {...firstNameInput} />
                                    </div>
                                    <div className="form-group mb-2">
                                        <label>Last name</label>
                                        <input className="form-control" type="text" required placeholder="Enter your last name" {...lastNameInput} />
                                    </div>             
                                    <div className="password-change" onClick={() => setShowChangePassword(true)}>
                                        Change password
                                    </div>                 
                               
                                    <div className="form-group text-center mt-4 modal-buttons">
                                        <button type="submit" className="btn btn-primary btn-wide m-1">Save</button>
                                    </div>    
                               </form>
                            </div>
                            <PasswordChange user={user} actions={actions} setPasswordChanging={setPasswordChanging} changePasswordRef={changePasswordRef} />
                        </div>    
                    </div>
                </div>
            </main>
            {
                saving || passwordChanging ?
                    <div className="modal-window__loader modal-window__loader-task modal-window__loader-login active">
                        <img src={require('../../images/loader.svg')} alt="loading" />
                        {saving ? 'Saving changes...' : 'Changing password...'}                        
                    </div>
                    : null
            } 
        </React.Fragment>
    )
}

function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //actions will now be available under props.actions
        actions: bindActionCreators(Object.assign({}, accountActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettingsPage);