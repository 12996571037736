import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as resourceActions from '../../actions/resourceActions';
import FinaliseSave from './FinaliseSave';
import { useFormInput } from '../../utils/customHooks';
import ToastrService from '../../services/toastrService';
import { getShortDate } from '../../utils/dateUtils';

function SelectLinkModal({onTaskSelected, onEventSelected}) {
    return (  
        <div className="modal-dialog modal-lg modal-dialog--add-link">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">Please select</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i className="mikeicon-x"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <form className="form form--lined form--inputbg modal-inner">
                        <div className="form-group text-center mt-4 add-link__buttons">
                            <button type="button" className="btn bth--transparent btn-wide m-1" onClick={onTaskSelected} data-dismiss="modal">Link to a task</button>
                            <button type="button" className="btn bth--transparent btn-wide m-1" onClick={onEventSelected} data-dismiss="modal">Link to an event</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>      
        
    );
}

export default SelectLinkModal;