import React from 'react';
import {CardElement} from '@stripe/react-stripe-js';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#fff",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      boxShadow: "inset 0 0 0 50px #313140",
      "::placeholder": {
        color: "#78778E"
      },
      ':-webkit-autofill': {
        color: "#fff"
      }
    },
    invalid: {
      color: "#FA755A",
      iconColor: "#FA755A"
    }
  }
};

function CardSection() {
    return (
        <CardElement id="card-element" className="MyCardElement" options={CARD_ELEMENT_OPTIONS} />
    );
};

export default CardSection;