/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import PageLoading from '../common/PageLoading.js';
import SidePromo from './SidePromo.js';
import Header from './Header.js';
import Footer from './Footer.js';
import * as accountActions from '../../actions/accountActions';
import * as userUtils from '../../utils/userUtils';
import '../../styles/style-signup.css';
import AnimateIn from '../common/AnimateIn.js';
import { useFormInput } from '../../utils/customHooks.js';
import ExternalLogin from './ExternalLogin.js';

function LoginPage(props) {    
    const [loadingExternalLogin, setLoadingExternalLogin] = useState(false);
    const emailInput = useFormInput('');
    const passwordInput = useFormInput('');   
    const fromPath = props.location.state && props.location.state.from && props.location.state.from.pathname ? props.location.state.from.pathname + props.location.state.from.hash : null;
    const [isBusy, setIsBusy] = useState(false);

    const login = (event) => {
        event.preventDefault();
        if(emailInput.value && passwordInput.value) {
            setIsBusy(true);
            props.actions.login(emailInput.value, passwordInput.value, fromPath)
                .then(_ => setIsBusy(false))
                .catch(error => setIsBusy(false));
        }
    }    

    return (
        userUtils.isAuthenticated(props.user) ?
            <Redirect to={userUtils.getNext(props.user, fromPath)} />
            :
            !props.userLoading ?
                <React.Fragment>
                    <main className="login">
                        <SidePromo />            
                        <div id="loginContent" className="login__content">
                            <Header className="login__branding" />
                            <AnimateIn className="login__form">
                                <div className="login__body">
                                    <form className="form form--lined form--login" onSubmit={login}>
                                        <ExternalLogin isSignup={false} setLoadingExternalLogin={setLoadingExternalLogin} location={props.location} actions={props.actions} user={props.user} />
                                        <p className="form-text text-center text-muted text-or">
                                            <span>OR</span>
                                        </p>
                                        <p className="form-text text-center text-muted">Log in with your account</p>
                                        <div className="form-group">
                                            <div className="form-prefix">
                                                <i className="mikeicon-email form__picto" />
                                                <input className="form-control" type="email" required placeholder="Email" {...emailInput} />
                                            </div>
                                        </div>
                                        <div className="form-group mb-1">
                                            <div className="form-prefix">
                                                <i className="mikeicon-password form__picto" />
                                                <input className="form-control" type="password" required placeholder="Password" {...passwordInput} />
                                            </div>
                                        </div>
                                        <div className="form-group form-group--pass text-right mb-4">
                                            <Link to="/forgotpassword" className="btn-text btn-forgotpass small">Forgot password?</Link>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-block btn--outline btn-transparent-white">Login</button>
                                        </div>
                                        <div className="form-group text-center mt-5">
                                            <p className="text-muted small">Don't have an account? <Link to="/signup" className="btn-text btn-gotosignup">Sign up</Link></p>
                                        </div>
                                    </form>    
                                </div>
                            </AnimateIn>
                            <Footer />
                        </div>                        
                    </main>
                    {
                        isBusy || loadingExternalLogin ?
                            <div className="modal-window__loader modal-window__loader-task modal-window__loader-login active">
                                <img src={require('../../images/loader.svg')} alt="loading" />
                                Logging in...
                            </div>
                            : null
                    } 
                </React.Fragment>
                : 
                <div>
                    <PageLoading />
                </div> 
    );     
}

LoginPage.propTypes = {
    //myProp: PropTypes.string.isRequired
};


function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user,
        userLoading: state.account.userLoading
    };
}


function mapDispatchToProps(dispatch) {
    return {
        //account actions will now be available under props.actions
        actions: bindActionCreators(Object.assign({}, accountActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
