import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as resourceActions from '../../actions/resourceActions';
import * as taskActions from '../../actions/taskActions';
import * as eventActions from '../../actions/eventActions';
import '../../styles/style-ui.scss';
import Footer from '../common/Footer';
import { withModalContext } from '../../services/modalService';
import swal from 'sweetalert';
import { getDateText, getVaultType, getResourceDescription } from '../../utils/resourceUtils';
import RecordAudioModal from './RecordAudioModal';
import AudioModal from './AudioModal';
import NoteModal from './NoteModal';
import FileModal from './FileModal';
import ImageModal from './ImageModal';
import SelectLinkModal from './SelectLinkModal';
import ManageLinksModal from './ManageLinksModal';
import { checkSubscription } from '../../utils/artistUtils';
import Header from '../common/Header';
import ArtistNameHeader from '../common/ArtistNameHeader';
import { vaultPageHeaders } from '../../constants/appMessages';
import _ from 'lodash';

function VaultPage({user, actions, history, showModal}) {   
    const activeProfile = user.activeProfile;

    let allTags = activeProfile.Resources.reduce((previous, current) => {
        return previous.concat(current.Tags);
    }, []);
    //get distinct values
    allTags = [...new Set(allTags)].sort((a, b) => a.localeCompare(b, 'en', {'sensitivity': 'base'}));
    
    const [selectedType, setSelectedType] = useState('All');
    const [showMenuForItem, setShowMenuForItem] = useState();
    const [selectedTags, setSelectedTags] = useState([]);
    const [showRecordModal, setShowRecordModal] = useState(false);
    const headerText = useRef(_.sample(vaultPageHeaders)).current;

    useEffect(() => {
        window.$(document).on('click', '', closeDropDown);
        return () => {
            window.$(document).off('click', '', closeDropDown);
        }
    }, []);

    const selectTag = (event, tag) => {
        const tags = [...selectedTags];
        const index = tags.indexOf(tag);
        if(index >= 0) {
            tags.splice(index, 1);
            setSelectedTags(tags);
        }
        else {
            tags.push(tag);
            setSelectedTags(tags);
        }
    }
    
    //check the type here - we use a type of file with resource file type for specifics
    const resources = activeProfile.Resources.map(r => ({...r, VaultType: getVaultType(r)}))
        .filter(r => (selectedType === 'All' || r.VaultType === selectedType) && (!selectedTags.length || r.Tags.find(t => selectedTags.includes(t))))
        .sort((a, b) => b.CreatedDate - a.CreatedDate);

    const getActiveClass = (type) => {
        if(selectedType === type) {
            return 'active';
        }
        return '';
    }

    const editItem = (event, item) => {
        event.preventDefault();
        event.stopPropagation();
        checkSubscription(activeProfile, () => {
            if(item.VaultType === 'Audio') {
                editAudioItem(event, item);
            }
            else if(item.VaultType === 'Text') {
                editNote(event, item);
            }
            else if(item.VaultType === 'PDF') {
                editFile(event, item);
            }
            else {
                editImage(event, item);
            }
        }, 'edit this item');        
    }

    const deleteItem = (event, item) => {
        event.preventDefault();        
        event.stopPropagation();
        checkSubscription(activeProfile, () => {
            const taskLinks = activeProfile.Tasks.filter(t => t.ResourceIDs && t.ResourceIDs.includes(item.ID));
            const eventLinks = activeProfile.Events.filter(e => e.ResourceIDs && e.ResourceIDs.includes(item.ID));
            const haveLinks =  taskLinks.length || eventLinks.length;
            const itemDesc = getResourceDescription(item);
            swal({
                title: 'Are you sure?',
                text: `${haveLinks ? `There are tasks and/or events linked to this ${itemDesc}. ` : ''}Once deleted, you will not be able to recover this ${itemDesc}!`,
                buttons: true,
                dangerMode: true,
              })
              .then(async (willDelete) => {
                if (willDelete) {
                    const linksDeleted = true;                
                    if(haveLinks)  {
                        if(taskLinks.length) {
                            for(let i = 0; i < taskLinks.length; i++) {
                                const task = taskLinks[i];
                                await actions.deleteTaskResourceLink(activeProfile.ID, task.ID, item.ID, false)
                                    .catch(error => {
                                        linksDeleted = false;
                                    });
                                if(!linksDeleted) {
                                    return;
                                }
                            }
                        }
                        if(eventLinks.length) {
                            for(let i = 0; i < eventLinks.length; i++) {
                                const eventObj = eventLinks[i];
                                await actions.deleteEventResourceLink(activeProfile.ID, eventObj.ID, item.ID, false)
                                    .catch(error => {
                                        linksDeleted = false;
                                    });
                                if(!linksDeleted) {
                                    return;
                                }
                            }
                        }
                    }
                    if(linksDeleted) {  
                        actions.deleteResource(activeProfile.ID, item);
                    }
                }
            });
        }, 'delete this item');        
    }

    const viewItem = (event, item) => {
        event.preventDefault();
        event.stopPropagation();
        if(item.VaultType === 'Audio') {
            viewAudioItem(event, item);
        }
        else if(item.VaultType === 'Text') {
            viewNote(event, item);
        }
        else if(item.VaultType === 'PDF') {
            viewFile(event, item);
        }
        else {
            viewImage(event, item);
        }
    }

    const viewAudioItem = (event, item) => {
        event.preventDefault();
        const audioModal = <AudioModal item={item} viewMode={true} allTags={allTags} />
        showModal(() => audioModal, { isOpen: true })
    }

    const editAudioItem = (event, item) => {
        event.preventDefault();
        checkSubscription(activeProfile, () => {
            const audioModal = <AudioModal item={item} viewMode={false} allTags={allTags} />
            showModal(() => audioModal, { isOpen: true })
        }, 'edit this item');        
    }

    const createAudioItem = (event, blob) => {
        event.preventDefault();
        checkSubscription(activeProfile, () => {
            const audioModal = <AudioModal blob={blob} viewMode={false} allTags={allTags} />
            showModal(() => audioModal, { isOpen: true })
        }, 'create an audio item');        
    }

    const viewNote = (event, item) => {
        event.preventDefault();
        const noteModal = <NoteModal item={item} viewMode={true} allTags={allTags} />
        showModal(() => noteModal, { isOpen: true })
    }

    const editNote = (event, item) => {
        event.preventDefault();
        checkSubscription(activeProfile, () => {
            const noteModal = <NoteModal item={item} viewMode={false} allTags={allTags} />
            showModal(() => noteModal, { isOpen: true })
        }, 'edit a note');        
    }

    const createNote = (event) => {
        event.preventDefault();
        checkSubscription(activeProfile, () => {
            const noteModal = <NoteModal viewMode={false} allTags={allTags} />
            showModal(() => noteModal, { isOpen: true })
        }, 'create a note');        
    }

    const viewFile = (event, item) => {
        event.preventDefault();
        const fileModal = <FileModal item={item} viewMode={true} allTags={allTags} />
        showModal(() => fileModal, { isOpen: true })
    }

    const editFile = (event, item) => {
        event.preventDefault();
        checkSubscription(activeProfile, () => {
            const fileModal = <FileModal item={item} viewMode={false} allTags={allTags} />
            showModal(() => fileModal, { isOpen: true })
        }, 'edit a file');        
    }

    const createFile = (event) => {
        event.preventDefault();
        checkSubscription(activeProfile, () => {
            const fileModal = <FileModal viewMode={false} allTags={allTags} />
            showModal(() => fileModal, { isOpen: true })
        }, 'add a file');
    }

    const viewImage = (event, item) => {
        event.preventDefault();
        const imageModal = <ImageModal item={item} viewMode={true} allTags={allTags} />
        showModal(() => imageModal, { isOpen: true })
    }

    const editImage = (event, item) => {
        event.preventDefault();
        checkSubscription(activeProfile, () => {
            const imageModal = <ImageModal item={item} viewMode={false} allTags={allTags} />
            showModal(() => imageModal, { isOpen: true })
        }, 'edit an image');        
    }

    const createImage = (event) => {
        event.preventDefault();
        checkSubscription(activeProfile, () => {
            const imageModal = <ImageModal viewMode={false} allTags={allTags} />
            showModal(() => imageModal, { isOpen: true })
        }, 'add an image');        
    }

    const addLink = (event, resource) => {
        event.preventDefault();        
        event.stopPropagation();
        checkSubscription(activeProfile, () => {
            const selectLinkModal = <SelectLinkModal onTaskSelected={(event) => openTaskLink(event, resource)} onEventSelected={(event) => openEventLink(event, resource)} />
            showModal(() => selectLinkModal, { isOpen: true })
        }, 'link this item');        
    }

    const openTaskLink = (event, resource) => {
        event.preventDefault();
        history.push('/tasks', { resource });
    }

    const openEventLink = (event, resource) => {
        event.preventDefault();
        history.push('/schedule', { resource });
    }

    const manageLinks = (event, resource) => {
        event.preventDefault();        
        event.stopPropagation();
        const manageLinksModal = <ManageLinksModal resource={resource} showModal={showModal} />
        showModal(() => manageLinksModal, { isOpen: true })
    }

    const closeDropDown = (e) => {
        const div = window.$('.list-row__menu');    
        if (!div.is(e.target) && div.has(e.target).length === 0) {
            div.removeClass('active');
        }
    }

    const menuClicked = (event, item) => {
        event.preventDefault();
        event.stopPropagation();
        setShowMenuForItem(showMenuForItem !== item.ID ? item.ID : null)
    }

    const getItemClass = (resource) => {
        if(resource.VaultType === 'Audio') {
            return 'vault-item--audio';
        }
        else if(resource.VaultType === 'Text') {
            return 'vault-item--text';
        }
        else if(resource.VaultType === 'Image') {
            return 'vault-item--photo';
        }
        else {
            return 'vault-item--file';
        }        
    }

    const getItemImage = (resource) => {
        if(resource.VaultType === 'Audio') {
            return require('../../images/vault-icon-record-small.png');
        }
        else if(resource.VaultType === 'Text') {
            return require('../../images/vault-icon-write-small.png');
        }
        else if(resource.VaultType === 'Image') {
            return require('../../images/vault-icon-photo-small.png');
        }
        else {
            return require('../../images/vault-icon-attach-small.png');
        }        
    }

    return (        
        <div className="home">
            <Header />
            <main className="content">
                <div className="container">
                    <ArtistNameHeader user={user} greeting={`Hey ${user.FirstName}, ${headerText}`} />
                    <div className="content-body">
                        {
                            activeProfile.Resources.length ?
                                <div className="vault-content">
                                    <div className="dropdown ml-3 vault-content__filter">
                                        <a className="btn-text-simple dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className={`mikeicon-filter ${selectedTags.length ? 'active' : ''}`}></i>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-animated dropdown-menu-right dropdown-menu--filter" aria-labelledby="filterDropdown ">
                                            <span className="dropdown-item dropdown-item--all dropdown-item--tag">FILTER BY TAG</span>
                                            {
                                                allTags.map(tag => 
                                                    <a key={tag} className={`dropdown-item ${selectedTags.includes(tag) ? 'choosen' : ''}`} href="#" onClick={(event) => selectTag(event, tag)}>{tag}</a>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="vault__tabs">
                                        <div className={`vault-tab ${getActiveClass('All')}`} onClick={() => setSelectedType('All')}>
                                            All
                                        </div>
                                        <div className={`vault-tab ${getActiveClass('Text')}`} onClick={() => setSelectedType('Text')}>
                                            Text
                                        </div>
                                        <div className={`vault-tab ${getActiveClass('Audio')}`} onClick={() => setSelectedType('Audio')}>
                                            Audio
                                        </div>
                                        <div className={`vault-tab ${getActiveClass('File')}`} onClick={() => setSelectedType('File')}>
                                            File
                                        </div>
                                        <div className={`vault-tab ${getActiveClass('Image')}`} onClick={() => setSelectedType('Image')}>
                                            Image
                                        </div>
                                    </div>
                                    <div className="vault-content__add-note">
                                        <a className="add-note__img" onClick={createNote}>
                                            <img src={require('../../images/vault-icon-write-small.png')} alt="" /> 
                                        </a>
                                        <a className="add-note__input" onClick={createNote}>
                                            <input type="text" placeholder="Start typing your note..." />
                                        </a>
                                        <span className="valute__empty__item--audio valute__item--audio" onClick={() => checkSubscription(activeProfile, () => setShowRecordModal(true), 'create a new recording') }>
                                            <img src={require('../../images/vault-icon-record-small.png')} alt="" />
                                        </span>
                                        <a className="add-note__img" onClick={createImage}>
                                            <img src={require('../../images/vault-icon-photo-small.png')} alt="" />
                                        </a>
                                        <a className="add-note__img" onClick={createFile}>
                                            <img src={require('../../images/vault-icon-attach-small.png')} alt="" />
                                        </a>
                                    </div>
                                    <div className="vault__tab-content">
                                        {
                                            resources.length ?
                                                <div className="vault__tab-item">
                                                    {
                                                        resources.map(resource =>
                                                            <div key={resource.ID} className={`vault-item ${getItemClass(resource)}`} onClick={(event) => viewItem(event, resource)}>
                                                                <div className="vault-item__left">
                                                                    <div className="vault-item__img">
                                                                        <img src={getItemImage(resource)} alt=" " /> 
                                                                    </div>
                                                                    <div className="vault-item__content">
                                                                        <div className="vault-item__title">
                                                                            {
                                                                                resource.Visibility === 'Private' ?  
                                                                                    <img src={require('../../images/icon-lock.png')} alt="" /> : null
                                                                            }
                                                                            {resource.Name}
                                                                        </div>
                                                                        <div className="vault-item__date">
                                                                            {getDateText(resource.CreatedDate)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <span className={`list-row__menu list-row__menu--vault ${showMenuForItem === resource.ID ? 'active' : ''}`}>
                                                                    <span className="list-row__menu-btn" onClick={(event) => menuClicked(event, resource)}>                                                 
                                                                        <span></span>
                                                                        <span></span>
                                                                        <span></span>
                                                                    </span>
                                                                    <span className="list-row__menu-dropdown list-row__menu-dropdown--event">                                                        
                                                                        <a className="list-row__menu-dropdown-item list-row__menu--view" onClick={(event) => viewItem(event, resource)}>                                                              
                                                                            <img src={require('../../images/eye.svg')} /> 
                                                                            <span>View</span>
                                                                        </a> 
                                                                        <a className="list-row__menu-dropdown-item list-row__menu--edit" onClick={(event) => editItem(event, resource)}>
                                                                            <img src={require('../../images/icon-goals-edit.png')} />
                                                                            <span>Edit</span>
                                                                        </a>  
                                                                        <span className="list-row__menu-dropdown-item list-row__menu--delete" onClick={(event) => deleteItem(event, resource)}>
                                                                            <img src={require('../../images/icon-goals-delete.png')} alt="" className="" /> 
                                                                            <span>Delete </span>
                                                                        </span>
                                                                        <span className="list-row__menu-dropdown-item list-row__menu--add-link" onClick={(event) => addLink(event, resource)}>
                                                                            <img src={require('../../images/link.svg')} alt="" className="" /> 
                                                                            <span>Add link</span>
                                                                        </span>
                                                                        <span className="list-row__menu-dropdown-item list-row__menu--manage-links" onClick={(event) => manageLinks(event, resource)}>
                                                                            <img src={require('../../images/server.svg')} alt="" className="" /> 
                                                                            <span>Manage links</span>                                                        
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </div>  
                                                        )
                                                    }
                                                </div>
                                            :
                                                <div className="list-row--empty">
                                                    <div className="list-row--empty__text">
                                                        You don't have any matching items
                                                    </div>
                                                </div>
                                        }                                  
                                    </div>
                                </div>
                            :
                                <div className="valute__empty">
                                    <div className="valute__empty-icon">
                                        <img src={require('../../images/vault-empty.png')} alt="" />
                                    </div>
                                    <div className="valute__empty-text">
                                        This is the place to capture notes, lyric ideas, that melody that just popped into your head, set lists, and other useful stuff. You can add text, record audio, or upload files and photos, and attach any of these items to your tasks or events.
                                    </div>
                                    <div className="valute__empty__items">
                                        <a href="#" className="valute__empty__item" onClick={createNote}>
                                            <img src={require('../../images/vault-btn-write.png')} alt="" />
                                            <div className="valute__empty__title">
                                                WRITE <br />NOTE
                                            </div>
                                        </a>
                                        <div className="valute__empty__item valute__empty__item--audio" onClick={() => checkSubscription(activeProfile, () => setShowRecordModal(true), 'create a new recording')}>
                                            <img src={require('../../images/vault-btn-record.png')} alt="" />
                                            <div className="valute__empty__title">
                                                RECORD <br /> AUDIO
                                            </div>
                                        </div>
                                        <a href="#" className="valute__empty__item" onClick={createFile}>
                                            <img src={require('../../images/vault-btn-attach.png')} alt="" />
                                            <div className="valute__empty__title">
                                                ATTACH <br /> FILE
                                            </div>
                                        </a>
                                        <a href="#" className="valute__empty__item" onClick={createImage}>
                                            <img src={require('../../images/vault-btn-photo.png')} alt="" />
                                            <div className="valute__empty__title">
                                                ADD <br />PHOTO
                                            </div>
                                        </a>
                                    </div>
                                </div>
                        }                    
                    </div>
                </div>
            </main>            
            <Footer />
            {
                showRecordModal ?
                    <RecordAudioModal closeRecordModal={() => setShowRecordModal(false)} createAudioItem={createAudioItem} />
                    : null
            }
        </div>
    );
}

VaultPage.propTypes = {
    //myProp: PropTypes.string.isRequired
};


function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user
    };
}


function mapDispatchToProps(dispatch) {
    return {
        //account actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, resourceActions, taskActions, eventActions), dispatch)
    };
}

export default withModalContext(connect(mapStateToProps, mapDispatchToProps)(VaultPage));