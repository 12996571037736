import BaseService from './baseService';

class ArtistService {
    static getArtist(artistID) {
        return BaseService.getAndReturn(`/Artists/${artistID}`, BaseService.returnAssignedObject, 'Unable to get artist', true);
    } 

    static createArtist(displayName, profileType, defaultCurrencyID) {
        return BaseService.postAndReturn('/Artists', { displayName, artistType: profileType, defaultCurrencyID }, BaseService.returnAssignedObject, 'Unable to create artist', true);
    } 

    static updateArtist(artistID, displayName, defaultCurrencyID) {
        return BaseService.putAndReturn(`/Artists/${artistID}`, { ID: artistID, displayName, defaultCurrencyID }, BaseService.returnAssignedObject, 'Unable to update artist', true);
    } 

    static createInvitationCode(artistID) {
        return BaseService.postAndReturn(`/Artists/${artistID}/InvitationCodes`, { }, BaseService.returnAssignedObject, 'Unable to create invitation code', true);
    } 

    static redeemInvitationCode(invitationCode) {
        return BaseService.postAndReturn(`/Artists/InvitationCodes/${invitationCode}/Redemptions`, { }, BaseService.returnAssignedObject, 'Unable to redeem invitation code', true);
    } 

    static createStripeSubscription(artistID, customerID, paymentMethodID, priceID) {
        return BaseService.postAndReturn(`/Artists/${artistID}/StripeSubscription`, { customerID, paymentMethodID, priceID }, BaseService.returnAssignedObject, 'Unable to create subscription', true);
    } 

    static retryStripeSubscription(artistID, customerID, paymentMethodID, invoiceID) {
        return BaseService.postAndReturn(`/Artists/${artistID}/StripeSubscriptionRetry`, { customerID, paymentMethodID, invoiceID }, BaseService.returnAssignedObject, 'Unable to create subscription', true);
    } 

    static cancelStripeSubscription(artistID, subscriptionID) {
        return BaseService.deleteAndReturn(`/Artists/${artistID}/StripeSubscription/${subscriptionID}`, BaseService.returnAssignedObject, 'Unable to delete subscription', true);
    } 

    static setFacebookProfile(artistID, pageAccessToken){
        return BaseService.putAndReturn(`/Artists/${artistID}/SocialProfile/Facebook`, pageAccessToken, BaseService.returnAssignedObject, 'Unable to set Facebook profile', true);
    }

    static clearSocialProfile(artistID, provider){
        return BaseService.deleteAndReturn(`/Artists/${artistID}/SocialProfile/${provider}`, BaseService.returnAssignedObject, `Unable to clear ${provider} profile`, true);
    }

    static setTwitterProfile(artistID, accessToken){
        return BaseService.putAndReturn(`/Artists/${artistID}/SocialProfile/Twitter`, accessToken, BaseService.returnAssignedObject, 'Unable to set Facebook profile', true);
    }

    static setInstagramProfile(artistID, pageAccessToken){
        return BaseService.putAndReturn(`/Artists/${artistID}/SocialProfile/Instagram`, pageAccessToken, BaseService.returnAssignedObject, 'Unable to set Instagram profile', true);
    }

    static setYouTubeProfile(artistID, channelID){
        return BaseService.putAndReturn(`/Artists/${artistID}/SocialProfile/YouTube`, channelID, BaseService.returnAssignedObject, 'Unable to set YouTube profile', true);
    }

    static setSoundCloudProfile(artistID, username){
        return BaseService.putAndReturn(`/Artists/${artistID}/SocialProfile/SoundCloud`, username, BaseService.returnAssignedObject, 'Unable to set SoundCloud profile', true);
    }

    static setLoudUpProfile(artistID, username){
        return BaseService.putAndReturn(`/Artists/${artistID}/SocialProfile/LoudUp`, username, BaseService.returnAssignedObject, 'Unable to set LoudUp profile', true);
    }    

    static getSocialInsights(artistID, provider){
        return BaseService.getAndReturn(`/Artists/${artistID}/SocialProfile/${provider}/Insights`, BaseService.returnAssignedObject, `Unable to get ${provider} insights`, true);
    }
 }

export default ArtistService;