import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import 'select2/dist/js/select2';
import 'select2/dist/css/select2.min.css'
import { incomeTypes, expenseTypes } from '../../constants/types';
import { getFinanceTypeIcon } from '../../utils/financeUtils';

function FinancesBudget({user, currency, budgetSelectedTab, setBudgetSelectedTab, budgetFilter, setBudgetFilter, showBudgetModal}) {
    const activeProfile = user.activeProfile;
    const today = moment();

    const filters = [{
        Name: 'Current Month',
        Filter: (i) => {
            const date = moment(i.Date);
            return today.month() === date.month() && today.year() === date.year();
        }
    }, {
        Name: 'Last Month',
        Filter: (i) =>{
            const date = moment(i.Date);
            const lastMonth = moment().add(-1, 'month');
            return lastMonth.month() === date.month() && lastMonth.year() === date.year();
        }
    }, {
        Name: 'Current Year',
        Filter: (i) => {
            const date = moment(i.Date);
            return today.year() === date.year();
        }
    }, {
        Name: 'Last Year',
        Filter: (i) => {
            const date = moment(i.Date);
            const lastYear = moment().add(-1, 'year');
            return lastYear.year() === date.year();
        }
    }];

    const filterRef = useRef();

    const filterChanged = (event) => {
        const data = event.params.data;
        if(data) {                    
            setBudgetFilter(data.id);
        }
    }

    useEffect(() => {
        if(filterRef.current) {
            const filterControl = window.$(filterRef.current);
            filterControl.select2({
                theme : "classic" 
            })
            .on('select2:select', filterChanged);
            if(budgetFilter) {
                filterControl.val(budgetFilter).trigger('change');
            }
        }
    }, []);

    const getActiveClass = (tabName) => {
        if(budgetSelectedTab === tabName) {
            return 'active';
        }
        return '';
    }
    
    const updateSelectedTab = (tab) => {
        setBudgetSelectedTab(tab);
    }

    const getItemColour = (planned, actual) => {
        return budgetSelectedTab === 'Income' && actual >= planned ? 'finances__value--green' : 'finances__value--red';
    }
    
    const currentFilter = filters.find(f => f.Name === budgetFilter);
    let items = budgetSelectedTab === 'Income' ? activeProfile.Finances.Income.filter(i => currentFilter.Filter(i)) : activeProfile.Finances.Expenses.filter(e => currentFilter.Filter(e));

    let plannedTotal = 0;
    let actualTotal = 0;
    const budget = [];

    const year = budgetFilter.toUpperCase().indexOf('YEAR') > -1;

    const budgetItems = budgetSelectedTab === 'Income' ? activeProfile.Finances.Budget.Income : activeProfile.Finances.Budget.Expenses;
    budgetItems.forEach(item => {
        const actual = items.filter(i => (i.Type || i.Breakdown[0].Type) === item.Type).map(i => i.Amount).reduce((a, b) => a + b, 0);
        actualTotal += actual;
        const planned = year ? item.Amount * 12 : item.Amount;
        plannedTotal += planned;
        budget.push({ Type: item.Type, Planned: planned, Actual: actual });
    });
    //add any items that we didn't budget for - the .Equals in the filter seems to matter as we're handling the enums as object type. using == seems to compare the object references but .equals seems to compare the enums themself
    const itemsNotBudgetedFor = {};
    items.filter(item => !budgetItems.find(budgetItem => budgetItem.Type === (item.Type || item.Breakdown[0].Type))).forEach(item => {
        const type = item.Type || item.Breakdown[0].Type;
        if(!itemsNotBudgetedFor[type]) {
            itemsNotBudgetedFor[type] = 0;
        }
        itemsNotBudgetedFor[type] += item.Amount;
        actualTotal += item.Amount;
    });
    Object.keys(itemsNotBudgetedFor).forEach(type => {
        budget.push({ Type: type, Planned: 0, Actual: itemsNotBudgetedFor[type] });
    })
    
    const actualColor = getItemColour(plannedTotal, actualTotal);

    return (
        <div className="tab-pane fade show" id="completed" role="tabpanel" aria-labelledby="budget-tab">
            <div className="budget__tabs">
                <div className={`budget__tab ${getActiveClass('Income')}`} onClick={(e => updateSelectedTab('Income'))}>Income</div>
                <div className={`budget__tab ${getActiveClass('Expenses')}`} onClick={(e => updateSelectedTab('Expenses'))}>Expenses</div>
            </div>
            <div className="budget__tab-content">
                <div className="budget__tab-item">
                    <div className="budget__tab-item-content">
                        <div className="current-balance">
                            <div className="current-balance__value">
                                {currency ? currency.Symbol : ''}{plannedTotal.toFixed(2)}
                            </div>
                            <div className="current-balance__title">
                                PLANNED {budgetSelectedTab.toUpperCase()}
                            </div>
                        </div>
                        <div className="current-balance">
                            <div className={`current-balance__value ${actualColor}`}>
                            {currency ? currency.Symbol : ''}{actualTotal.toFixed(2)}
                            </div>
                            <div className="current-balance__title">
                                ACTUAL {budgetSelectedTab.toUpperCase()}
                            </div>
                        </div>
                    </div>
                    <div className="budget-select">
                        <select className="form-control select" ref={filterRef}>
                            {
                                filters.map(f => 
                                    <option key={f.Name} value={f.Name}>{f.Name}</option>
                                )
                            }
                        </select>
                    </div>
                    <div className="list-body">
                        <a href="#" className="list-row list-row-link list-row--finances list-row--finances--transparent list-row--finances--first">                    
                            <div className="list-col list-col-100 list-col-icon" />
                            <div className="list-col" />                            
                            <div className="list-col list-col-100 finances__value--col">
                                <div className="finances__value finances__value--title ">
                                    PLANNED
                                </div>
                            </div>
                            <div className="list-col list-col-100 finances__value--col">
                                <div className="finances__value finances__value--title ">
                                    ACTUAL
                                </div>
                            </div>
                            <span className="edit-finance-item" />
                        </a>
                        {
                            budget.map(item => 
                                <a key={item.Type} href="#" className="list-row list-row-link list-row--finances list-row--finances--transparent">                    
                                    <div className="list-col list-col-100 list-col-icon">
                                        <div className="finances__item-icon">
                                            <img src={getFinanceTypeIcon(budgetSelectedTab, item.Type)} alt="" /> 
                                        </div>                                
                                    </div>
                                    <div className="list-col">
                                        <p>{budgetSelectedTab === 'Income' ? incomeTypes[item.Type] : expenseTypes[item.Type]}</p>                            
                                    </div>
                                    <div className="list-col list-col-100 finances__value--col">
                                        <div className="finances__value finances__value--white ">
                                            {currency ? currency.Symbol : ''}{item.Planned.toFixed(2)}
                                        </div>
                                    </div>
                                    <div className="list-col list-col-100 finances__value--col">
                                        <div className={`finances__value ${getItemColour(item.Planned, item.Actual)}`}>
                                            {currency ? currency.Symbol : ''}{item.Actual.toFixed(2)}
                                        </div>
                                    </div>
                                    <span className="edit-finance-item" onClick={() => showBudgetModal(item)}>
                                        <img src={require('../../images/icon-finances-edit.png')} alt="" />
                                    </span>
                                </a>                                
                            )
                        }                                           
                    </div>                    
                </div>                    
            </div>
        </div>
    );
}

export default FinancesBudget;