/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import SidePromo from './SidePromo.js';
import Header from './Header.js';
import Footer from './Footer.js';
import * as accountActions from '../../actions/accountActions';
import '../../styles/style-signup.css';
import AnimateIn from '../common/AnimateIn.js';
import { useFormInput } from '../../utils/customHooks.js';
import * as textUtils from '../../utils/textUtils';

const ResetPasswordPage = ({user, actions, location}) =>
{
    const email = location.state && location.state.email ? location.state.email : null;
    const expiry = location.state && location.state.email ? parseInt(location.state.expiry, 10) : null;
    const codeInput = useFormInput('');
    const passwordInput = useFormInput('');
    const [isBusy, setIsBusy] = useState(false);

    const resetPassword = (event) => {
        event.preventDefault();
        if(email && codeInput.value && passwordInput.value) {
            setIsBusy(true);
            actions.resetPassword(email, codeInput.value, passwordInput.value)
                .then(_ => setIsBusy(false))
                .catch(error => setIsBusy(false));
        }        
    }
    return(
        user ?
            <Redirect to='/' />
            :            
            <React.Fragment>
                <main className="login">
                    <SidePromo />
                    <div id="loginContent" className="login__content">
                        <Header className="login__branding" />
                        <AnimateIn className="login__form">
                            <div className="login__body">                                                                      
                                <form className="form form--lined form--password" onSubmit={resetPassword}>
                                    <h5 className="text-center">Reset password</h5>
                                    <p className="form-text text-center text-muted mb-4">We have emailed you a reset code, please copy and paste it into the input below. The code will expire in {expiry} {textUtils.pluralise('hour', expiry)}.</p>
                                    <div className="form-group">
                                        <div className="form-prefix">
                                            <i className="mikeicon-email form__picto"></i>
                                            <input className="form-control" type="text" placeholder="Reset code" required {...codeInput} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-prefix">
                                            <i className="mikeicon-password form__picto"></i>
                                            <input className="form-control" type="password" placeholder="New password" required {...passwordInput} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-block btn--outline btn-transparent-white">Reset password</button>
                                    </div>
                                </form>
                            </div>
                        </AnimateIn>
                        <Footer />
                    </div>
                </main>
                {
                    isBusy ?
                        <div className="modal-window__loader modal-window__loader-task modal-window__loader-sign-up active">
                            <img src={require('../../images/loader.svg')} alt="loading" />
                            Resetting password...
                        </div>
                        : null
                }    
            </React.Fragment>
    );
};

ResetPasswordPage.propTypes = {
    //myProp: PropTypes.string.isRequired
};


function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user
    };
}


function mapDispatchToProps(dispatch) {
    return {
        //account actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, accountActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
