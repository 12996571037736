import React, { useState } from 'react';
import { isValidDate, isBrowserDateTypeSupported } from '../../utils/dateUtils';
import { useFormInput } from '../../utils/customHooks';
import ToastrService from '../../services/toastrService';

function ExportModal({user, actions, type = 'Overview', itemType, budgetPeriod = 'CurrentMonth'}) {
    const activeProfile = user.activeProfile;
    const startDateInput = useFormInput('');
    const endDateInput = useFormInput('');
    const [selectedFormat, setSelectedFormat] = useState();
    const browserDateTypeSupported = isBrowserDateTypeSupported();
    const [requestingExport, setRequestingExport] = useState(false);

    const getActiveClass = (format) => {
        if(selectedFormat === format) {
            return 'active';
        }
        return '';
    }

    const requestExport = () => {
        const toastrService = new ToastrService();
        if(startDateInput.value && !isValidDate(startDateInput.value)) {
            toastrService.showError('', `Looks like the start date isn't valid${!browserDateTypeSupported ? ', please enter the date in the format YYYY-MM-DD' : ''}`);
            return false;
        }
        else if(endDateInput.value && !isValidDate(endDateInput.value)) {
            toastrService.showError('', `Looks like the end date isn't valid${!browserDateTypeSupported ? ', please enter the date in the format YYYY-MM-DD' : ''}`);
            return false;
        }
        else if(!selectedFormat) {
            toastrService.showError('', 'Looks like you forgot to select an export format');
            return false;
        }
        else {
            setRequestingExport(true);
            if(type === 'Overview') {
                const startDate = startDateInput.value ? new Date(startDateInput.value).toISOString().split('T')[0] + 'T00:00:00+00:00' : null;
                const endDate = endDateInput.value ? new Date(endDateInput.value).toISOString().split('T')[0] + 'T23:59:59+00:00': null;
                actions.exportTransactions(activeProfile.ID, itemType, startDate, endDate, selectedFormat, true)
                    .then(response => {
                        setRequestingExport(false);
                        window.$("#modal").modal("hide")
                    })
                    .catch(error => setRequestingExport(false));
            }
            else {
                actions.exportBudget(activeProfile.ID, itemType, budgetPeriod, selectedFormat)
                    .then(response => {
                        setRequestingExport(false);
                        window.$("#modal").modal("hide")
                    })
                    .catch(error => setRequestingExport(false));;
            }
        }
    }

    return (
        <div className={`modal-dialog modal-lg ${!browserDateTypeSupported ? 'no-date-support' : ''} modal-dialog--view-task`}>
            {
                requestingExport ?
                    <div className="modal-window__loader modal-window__loader-task">
                        <img src={require('../../images/loader.svg')} alt="" />
                        {
                            'Requesting export...'
                        }
                    </div>
                    : null
            }      
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">EXPORT {type === 'Overview' ? 'TRANSACTIONS' : 'BUDGET'}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i className="mikeicon-x"></i>
                    </button>
                </div>
                <p className="modal-sub-title">
                    Please select from the options below and we will email the export to your registered email
                </p>
                <div className="modal-body">
                    <form className="form form--lined form--inputbg modal-inner">
                        {
                            type === 'Overview' ?
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group mb-2">
                                            <label>Start date</label>
                                            <div className="datapicker-wrapper">
                                                    <input className="form-control form-date datepicker" type="date" placeholder={browserDateTypeSupported ? 'Select date' : 'yyyy-mm-dd'} {...startDateInput}/>
                                                </div>                                    
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-2">
                                            <label>End date</label>
                                            <div className="datapicker-wrapper">
                                                    <input className="form-control form-date datepicker" type="date"  placeholder={browserDateTypeSupported ? 'Select date' : 'yyyy-mm-dd'} {...endDateInput} />
                                                </div>                                    
                                        </div>
                                    </div>
                                </div>
                                : null
                        }                       

                        <div className="form-group">
                            <label>SELECT FORMAT</label>
                            <div className="select-format-tabs">
                                <div className={`select-format-tab ${getActiveClass('CSV')}`} onClick={() => setSelectedFormat('CSV')}>CSV</div>
                                <div className={`select-format-tab ${getActiveClass('Excel')}`} onClick={() => setSelectedFormat('Excel')}>Excel</div>
                                <div className={`select-format-tab ${getActiveClass('PDF')}`} onClick={() => setSelectedFormat('PDF')}>PDF</div>
                            </div>                                    
                        </div>
                        
                        <div className="form-group text-center mt-4 modal-buttons">
                            <button type="button" className="btn btn-primary btn-wide m-1" onClick={requestExport}>REQUEST EXPORT</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ExportModal;