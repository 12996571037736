import PricingService from '../services/pricingService';
import * as types from './actionTypes';

export function getPricingPlans() {
    return function(dispatch, getState) {
        const savedPricingPlans = getState().pricingPlans;
        if(savedPricingPlans.length === 0) {
            return PricingService.getPricingPlans().then(pricingPlans => {
                dispatch({ type: types.GET_PRICING_SUCCESS, pricingPlans });
                return pricingPlans;
            }).catch(error => {
                return null;
            });
        }
        else {
            return savedPricingPlans;
        }
    };
}

export function getPricingPlansStripe() {
    return function(dispatch) {
        return PricingService.getPricingPlansStripe().then(pricingPlans => {
            return pricingPlans;
        }).catch(error => {
            return null;
        });
    };
}