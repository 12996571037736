import React, {useState, useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as projectActions from '../../actions/projectActions';
import { useFormInput } from '../../utils/customHooks';
import { checkSubscription } from '../../utils/artistUtils';
import ToastrService from '../../services/toastrService';
import { trim } from 'lodash';
import AnalyticsService from '../../services/analyticsService';

function ProjectModal({projectID, user, actions}) {
    const activeProfile = user.activeProfile;
    const project = projectID ? activeProfile.Projects.find(p => p.ID === projectID) : null;
    const nameInput = useFormInput(project ? project.Name : '');   
    const [savingProject, setSavingProject] = useState(false);
    const isEdit = project != null;

    useEffect(() => {
        let eventName = isEdit ? 'Edit Project Launched' : 'Add Project Launched';
        AnalyticsService.logEvent(eventName);
    }, []);

    const validateProject = () => {
        const toastrService = new ToastrService();
        if(!trim(nameInput.value)) {
            toastrService.showError('', 'Looks like you forgot to enter the project name');
            return false;
        }
        return true;
    }

    const getProject = () => {        
        return {
            ID: isEdit ? project.ID : null,
            Name: trim(nameInput.value),
            CreatedDate: isEdit ? project.CreatedDate : new Date(),           
            Version: isEdit ? project.Version : 0
        };			
    }

    const createProject = (event) => {
        event.preventDefault();
        checkSubscription(activeProfile, () => {
            if(validateProject()) {
                setSavingProject(true);
                const newProject = getProject();
                actions.addProject(activeProfile.ID, newProject)
                    .then(project => {
                        window.$("#modal").modal("hide");
                        setSavingProject(false);
                    })
                    .catch(error => {
                        //do nothing, the action will show the error
                        setSavingProject(false);
                    });
            }   
        }, 'create a project');
    }

    const updateProject = (event) => {
        event.preventDefault();
        checkSubscription(activeProfile, () => {
            if(validateProject()) {
                setSavingProject(true);
                const updatedProject = getProject();
                actions.editProject(activeProfile.ID, updatedProject)
                    .then(project => {
                        window.$("#modal").modal("hide");
                        setSavingProject(false);
                    })
                    .catch(error => {
                        //do nothing, the action will show the error
                        setSavingProject(false);
                    });
            }
        }, 'update a project');        
    }

    const getLoadingText = () => {
        if(savingProject) {
            return isEdit ? 'Saving project' : 'Creating project';
        }
        return 'Loading...';
    }

    return (     
        <div className="modal-dialog modal-lg modal-dialog--view-task">
            {
                savingProject ?
                    <div className="modal-window__loader modal-window__loader-task">
                        <img src={require('../../images/loader.svg')} alt="" />
                        {
                            getLoadingText()
                        }
                    </div>
                    : null
            }            
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">{isEdit ? 'Rename Project' : 'New Project'}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i className="mikeicon-x" />
                    </button>
                </div>
                <div className="modal-body">
                    <form className="form form--lined form--inputbg modal-inner" onSubmit={isEdit ? updateProject : createProject}>
                        <div className="form-group mb-2">
                            <label>Project name</label>
                            <input className="form-control" type="text" placeholder="Enter project name, e.g. release new album" {...nameInput} />
                        </div>
                        <div className="form-group text-center mt-4 modal-buttons">
                            <React.Fragment>
                                <button type="button" className="btn btn-outline btn-wide m-1" data-dismiss="modal">Cancel</button>
                                <button type="submit" className="btn btn-primary btn-wide m-1">{isEdit ? 'Save Project' : 'Create Project'}</button>
                            </React.Fragment>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state, ownProps) {
     return { 
        user: state.account.user
    };
}


function mapDispatchToProps(dispatch) {
    return {
        //actions will now be available under props.actions
        actions: bindActionCreators(Object.assign({}, projectActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectModal);