import * as reducerUtils from '../reducerUtils';
import * as types from '../../actions/actionTypes';

export default function artistReduce(state, action) {
    switch(action.type) {  
        case types.ARTIST_CREATE_SUCCESS:
        {
            const user = {...state.user};
            user.Artists = reducerUtils.addObjectToArray(user.Artists, action.artist);
            user.activeProfile = action.artist;
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }
        case types.ARTIST_UPDATE_SUCCESS:
        {
            const user = {...state.user};
            const artist = action.artist;
            user.Artists = reducerUtils.updateArrayItem(user.Artists, a => a.ID === artist.ID, artist)
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artist.ID);
            return {
                ...state,
                user
            }; 
        }       
        case types.ARTIST_INVITATION_CODE_CREATE_SUCCESS:    
        {
            const user = {...state.user};
            const { artistID, invitationCode } = action.payload;
            const artistPredicate = (a) => a.ID === artistID;
            user.Artists = reducerUtils.addNestedArrayItem(user.Artists, artistPredicate, 'InvitationCodes', invitationCode);
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }  
        default:
            return state;
    }
}