import React, { useState, useEffect, useRef } from 'react';
import { formatNumber, getBalanceForDate, getFinanceTypeIcon } from '../../utils/financeUtils';
import moment from 'moment';
import FinanceItemModal from './FinanceItemModal';
import swal from 'sweetalert';
import { withModalContext } from '../../services/modalService';
import Chart from 'chart.js';
import { addObjectToArray } from '../../reducers/reducerUtils';
import { reverse } from 'lodash';
import { useFormInput } from '../../utils/customHooks';
import ReactDOM from 'react-dom';
import { checkSubscription } from '../../utils/artistUtils';

function FinancesOverview({user, currency, showModal, actions, balanceRenderNode}) {
    const activeProfile = user.activeProfile;
    
    const [showMenuForItem, setShowMenuForItem] = useState();
    const [showBalanceModal, setShowBalanceModal] = useState(false);

    const today = new Date();
    const currentBalance = getBalanceForDate(activeProfile, today);
    const balanceInput = useFormInput(currentBalance.toFixed(2) || 0.00);
    const [balanceItemEditing, setBalanceItemEditing] = useState();
    const [savingBalance, setSavingBalance] = useState(false);

    let items = activeProfile.Finances.Income.map(item => ({...item, ItemType: 'Income'}))
        .concat(activeProfile.Finances.Expenses.map(item => ({...item, ItemType: 'Expense', Type: item.Breakdown[0].Type})))
        .concat(activeProfile.Finances.Balances.map(item =>  ({...item, ItemType: 'Balance'})))
        .sort((a, b) => {
            //sort by date descending, then by timestamp descending
            if(a.Date.valueOf() === b.Date.valueOf()) {
                return b.Timestamp - a.Timestamp;
            }
            else {
                return b.Date - a.Date;
            }
        });

    useEffect(() => {
        window.$(document).on('click', '', closeDropDown);
        return () => {
            window.$(document).off('click', '', closeDropDown);
        }
    }, []);

    const balanceChartRef = useRef();

    useEffect(() => {
        let chart;
        if(currency) {
            const ctx = balanceChartRef.current.getContext('2d');
            const gradient = ctx.createLinearGradient(0, 0, 0, 400);
            gradient.addColorStop(0, '#25252f');   
            gradient.addColorStop(1, '#32313f');
            const chartData = getChartData();
            const labels = Object.keys(chartData).reverse();
            const data = Object.values(chartData).reverse();
            const currencySymbol = currency ? currency.Symbol : '';
            chart = new Chart(ctx, {
                type: 'line',
                data: {
                    datasets: [{
                        backgroundColor: gradient,
                        borderColor: '#E4662A',
                        data: data,
                        pointRadius: 5,
                        pointBackgroundColor: '#313140',
                        pointBorderWidth: 3
                    }]
                },
                options: {
                    legend: {
                        display: false,
                    },
                    scales: {
                        xAxes: [{
                            type: 'time',
                            distribution: 'linear',
                            time: {
                                unit: 'month'
                            }
                        }]
                    },
                    tooltips: {
                        callbacks: {
                            title: (tooltipItem, data) => {
                                return labels[tooltipItem[0].index];
                            },
                            label: (tooltipItem, data) => {
                                return currencySymbol + tooltipItem.value;
                            }
                        }
                    }
                }
            });
        }
        return () => {
            if(chart) { 
                chart.destroy();
            }
        } 
    }, [activeProfile.Finances.Income, activeProfile.Finances.Expenses, activeProfile.Finances.Balances, currency]);

    useEffect(() => {
        balanceInput.onChange({ target: { value: currentBalance.toFixed(2) || 0.00 }});
    }, [currentBalance]);

    const getChartData = () => {
        const chartData = {};
        let now = moment();
        if(now.date() !== 1) {
            //we're not at the start of the month, add an entry for today
            chartData['TODAY'] = { x: now, y: getBalanceForDate(activeProfile, now.toDate()) };
        }
        let monthStart = moment(now).startOf('month');
        chartData[monthStart.format('MMM yy')] = { x: monthStart, y: getBalanceForDate(activeProfile, monthStart.toDate()) };
        for (let i = 0; i < 12; i++) {
            monthStart = moment(monthStart).add(-1, 'month');
            chartData[monthStart.format('MMM yy')] = { x: monthStart, y: getBalanceForDate(activeProfile, monthStart.toDate()) };
        }
        return chartData;
    }

    useEffect(() => {
        window.$(document).on('click', '', closeModal);
        return () => {
            window.$(document).off('click', '', closeModal);
        }
    }, []);

    const closeModal = (e) => { 
        const modalContent = window.$('.modal-window__content');
        if (!modalContent.is(e.target) && modalContent.has(e.target).length === 0) {
            setShowBalanceModal(false);
        }
    }

    const closeDropDown = (e) => {
        const div = window.$('.list-row__menu');    
        if (!div.is(e.target) && div.has(e.target).length === 0) {
            div.removeClass('active');
        }
    }

    const itemMenuClicked = (event, item) => {
        event.preventDefault();
        event.stopPropagation();
        setShowMenuForItem(showMenuForItem !== item.ID ? item.ID : null)
    }

    const editItem = (event, item) => {
        event.preventDefault();
        event.stopPropagation();
        checkSubscription(activeProfile, () => {
            if(item.ItemType === 'Balance') {
                setBalanceItemEditing(item);
                setShowBalanceModal(true);
            }
            else {
                const financeItemModal = <FinanceItemModal item={item} />
                showModal(() => financeItemModal, { isOpen: true })
            }
        }, `edit this ${item.ItemType.toLowerCase()} item`);
    }

    const cloneItem = (event, item) => {
        event.preventDefault();
        event.stopPropagation();
        checkSubscription(activeProfile, () => {
            const financeItemModal = <FinanceItemModal item={item} clone={true} />
            showModal(() => financeItemModal, { isOpen: true })
        }, 'clone this item');        
    }

    const deleteItem = (event, item) => {
        event.preventDefault();        
        event.stopPropagation();
        checkSubscription(activeProfile, () => {
            swal({
                title: 'Are you sure?',
                text: 'Once deleted, you will not be able to recover this item!',
                buttons: true,
                dangerMode: true,
              })
              .then(async (willDelete) => {
                if (willDelete) {
                    if(item.ItemType === 'Income') {
                        actions.deleteIncome(activeProfile.ID, item.ID);
                    }
                    else if(item.ItemType === 'Expense') {
                        actions.deleteExpense(activeProfile.ID, item.ID);
                    }
                    else if(item.ItemType === 'Balance') {
                        actions.deleteBalance(activeProfile.ID, item.ID);
                    }
                }
            });
        }, 'delete this item');        
    }

    const viewItem = (event, item) => {
        event.preventDefault();
        const financeItemModal = <FinanceItemModal item={item} viewMode={true} />
        showModal(() => financeItemModal, { isOpen: true })
    }

    const setBalance = (event) => {
        event.preventDefault();
        checkSubscription(activeProfile, () => {
            if(balanceInput.value) {
                const amount = parseFloat(balanceInput.value);
                let existingBalance = balanceItemEditing;
                let today = new Date();
                //use today 00:00 UTC
                const todayStart = new Date(today.toISOString().split('T')[0] + 'T00:00:00+00:00');
                if(!existingBalance) {
                    //we're creating a new balance, so it will be for today. check if we have an existing balance for today and update it instead, setting the time to now
                    existingBalance = activeProfile.Finances.Balances.find(b => b.Date.valueOf() === todayStart.valueOf());
                    if(existingBalance) {
                        existingBalance = {...existingBalance, Timestamp: today};
                    }
                }
                const balanceDate = existingBalance ? existingBalance.Date : todayStart;
                const balanceItem = { 
                    ID: existingBalance ? existingBalance.ID : null,
                    Amount: amount, 
                    Date: balanceDate.toISOString().split('T')[0] + 'T00:00:00+00:00', 
                    Timestamp: existingBalance ? existingBalance.Date : today,
                    Version: existingBalance ? existingBalance.Version : 0 
                };
                
                const action = existingBalance ? actions.editBalance : actions.createBalance;
                setSavingBalance(true);
                action(activeProfile.ID, balanceItem)
                    .then(response => {
                        closeBalanceModal(event);
                        setSavingBalance(false);
                    })
                    .catch(error => closeBalanceModal(event));
            }
        }, 'update the balance');        
    }

    const closeBalanceModal = (event) => {
        event.preventDefault();
        setShowBalanceModal(false);
        setBalanceItemEditing(null);
    }

    return (
        <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">                                
            <div className="current-balance">
                <div className="current-balance__value">
                    {currency ? currency.Symbol : ''}{formatNumber(currentBalance)}
                    <img src={require('../../images/edit.png')} alt="" className="edit-balance" onClick={() => checkSubscription(activeProfile, () => setShowBalanceModal(true), 'edit the balance')} />
                </div>
                <div className="current-balance__title">
                    CURRENT BALANCE
                </div>                                    
            </div>
            <div className="chart-wrapper">
                <canvas id="balanceChart" ref={balanceChartRef} />
            </div>    
            <div className="list-body">
                {
                    items.map(item => {
                        let itemClass = '';
                        if(item.ItemType === 'Income') {
                            itemClass = 'finances__value--green';
                        }
                        else if(item.ItemType === 'Expense') {
                            itemClass = 'finances__value--red';
                        }
                        return <a key={item.ID} href="#" className={`list-row list-row-link list-row--finances ${item.ItemType === 'Balance' ? 'list-row--balance-update' : ''}`} onClick={item.ItemType !== 'Balance' ? (event) => viewItem(event, item) : (event) => event.preventDefault()}>
                            <span className={`list-row__menu ${showMenuForItem === item.ID ? 'active' : ''}`}>
                                <span className="list-row__menu-btn" onClick={(event) => itemMenuClicked(event, item)}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                <span className="list-row__menu-dropdown">
                                    {
                                        item.ItemType !== 'Balance' ?
                                            <span className="list-row__menu-dropdown-item list-row__menu--view" onClick={(event) => viewItem(event, item)}>
                                                <img src={require('../../images/eye.svg')} /> 
                                                <span>View</span>
                                            </span>
                                            : null
                                    }                                                                
                                    <span className="list-row__menu-dropdown-item list-row__menu--edit" onClick={(event) => editItem(event, item)}>
                                        <img src={require('../../images/icon-goals-edit.png')} />
                                        <span>Edit</span>
                                    </span>  
                                    {
                                        item.ItemType !== 'Balance' ?
                                            <span className="list-row__menu-dropdown-item list-row__menu--clone" onClick={(event) => cloneItem(event, item)}>
                                                <img src={require('../../images/sheet.svg')} />
                                                <span>Clone</span>
                                            </span>
                                            : null
                                    }                                    
                                    <span className="list-row__menu-dropdown-item list-row__menu--delete" onClick={(event) => deleteItem(event, item)}>
                                        <img src={require('../../images/icon-goals-delete.png')} />
                                        <span>Delete </span>                                                            
                                    </span>  
                                </span>                                                                                                
                            </span>
                            <div className="list-col list-col-100 list-col-icon">
                                <div className="finances__item-icon">
                                    <img src={getFinanceTypeIcon(item.ItemType, item.Type)} alt="" /> 
                                </div>
                            </div>
                            <div className="list-col">
                                <p>{item.ItemType === 'Balance' ? 'Balance Update' : item.Title}</p>
                                <div className="finances__date">{moment(item.Date).format('DD MMMM, yyyy')}</div>
                            </div>
                            <div className="list-col list-col-200">
                                <div className={`finances__value ${itemClass}`}>
                                    {currency ? currency.Symbol : ''}{formatNumber(item.Amount)}
                                </div>
                            </div>                                                        
                        </a>
                    })
                }
            </div>
            {
                balanceRenderNode.current ?
                    ReactDOM.createPortal(
                        <div className={`modal-window modal-window--new-balance ${showBalanceModal ? 'active' : ''}`}>
                            <div className="modal-window__content load">       
                            {
                                savingBalance ?
                                    <div className="modal-window__loader">
                                        <img src={require('../../images/loader.svg')} alt='loading' />
                                        Saving balance
                                    </div>
                                    : null
                            }            
                                <div className="close" onClick={closeBalanceModal}>+</div>
                                <div className="new-balance__title">
                                    Enter the new balance
                                </div>                
                                <form onSubmit={setBalance} className="form form--lined form--inputbg">
                                    <div className="form-group">
                                        <input className="form-control" type="number" placeholder="0.00" {...balanceInput} />
                                    </div>
                                    <div className="modal-window__btn-wrapper">
                                        <button className="btn bth--transparent" onClick={closeBalanceModal}>Cancel</button>
                                        <button type="submit" className="btn bth--transparent">Save</button>
                                    </div>
                                </form>
                            </div>
                        </div>,
                        balanceRenderNode.current
                    )
                    : null
            }            
        </div>
    )
}

export default withModalContext(FinancesOverview);