import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function currencyReducer(state = initialState.currencies, action) {
     switch (action.type) {
        case types.GET_CURRENCIES_SUCCESS:
            return action.currencies;       
        default:
            return state;
    }
};