import React, { useState, useRef } from 'react';
import { useFormInput } from '../../utils/customHooks';
import ToastrService from '../../services/toastrService';
import { trim } from 'jquery';

function PasswordChange({user, actions, setPasswordChanging, changePasswordRef}) {
    const oldPasswordInput = useFormInput('');
    const newPasswordInput = useFormInput('');
    const confirmNewPasswordInput = useFormInput('');

    const validate = () => {
        const toastrService = new ToastrService();
        if(!trim(oldPasswordInput.value)) {
            toastrService.showError('', 'Looks like you forgot to enter the current password');
            return false;
        }
        if(!trim(newPasswordInput.value)) {
            toastrService.showError('', 'Looks like you forgot to enter the new password');
            return false;
        }
        if(!trim(confirmNewPasswordInput.value)) {
            toastrService.showError('', 'Looks like you forgot to enter the password confirmation');
            return false;
        }
        if(trim(newPasswordInput.value) !== trim(confirmNewPasswordInput.value)) {
            toastrService.showError('', 'Looks like the password doesn\'t match the password confirmation');
            return false;
        }
        return true;
    }

    const changePassword = (event) => {
        event.preventDefault();
        if(validate()) {
            setPasswordChanging(true);
            actions.changePassword(oldPasswordInput.value, newPasswordInput.value, confirmNewPasswordInput.value)
                .then(result => {
                    setPasswordChanging(false);
                }) 
                .catch(error => {
                    setPasswordChanging(false);
                })
        }
    }

    return (
        <div className="change-pass-form" ref={changePasswordRef}>
            <h1 className="members__title">CHANGE PASSWORD</h1>
            <form className="form form--lined form--inputbg modal-inner" onSubmit={changePassword}>
                <div className="form-group mb-2">
                    <label>Password</label>
                    <input className="form-control" type="password" required placeholder="Enter your current password" {...oldPasswordInput} />
                </div>
                <div className="form-group mb-2">
                    <label>New Password</label>
                    <input className="form-control" type="password" placeholder="Enter new password" {...newPasswordInput} />
                </div>
                    <div className="form-group mb-2">
                    <label>Сonfirm Password</label>
                    <input className="form-control" type="password" placeholder="Сonfirm new password" {...confirmNewPasswordInput} />
                </div>                                         
                <div className="form-group text-center mt-4 modal-buttons modal-buttons--password">
                    <button type="submit" className="btn btn-primary btn-wide m-1">Save</button>
                </div>
            </form>
        </div>
    )
}

export default PasswordChange;