import React from 'react';
import { useFormInput } from '../../utils/customHooks';

function SocialProfileInputModal({inputName, onSelected, hintText}) {

    const formInput = useFormInput('');

    const connectClicked = (event) => {
        event.preventDefault();
        if(formInput.value) {
            onSelected(formInput.value);
            window.$("#modal").modal("hide");
        }
    }
    return (
        <div className="modal-dialog modal-lg modal-dialog--goal">
            <div className="modal-content">
                <div className="modal-header modal-header--goals">
                    <h5 className="modal-title" id="staticBackdropLabel">Enter your {inputName} to connect to</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i className="mikeicon-x"></i>
                    </button>
                </div>                    
                <div className="modal-body">
                    <form className="form form--lined form--inputbg modal-inner">
                        <div className="form-group mb-2">
                            <label>{inputName}</label>
                            <input className="form-control" type="text" placeholder={`Enter your ${inputName}`} {...formInput} />
                        </div>
                        <div className="form-group py-1" style={{textAlign: 'center'}}>{hintText}</div>
                        <div className="form-group text-center mt-4 modal-buttons">
                            <button type="button" className="btn btn-outline btn-wide m-1" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary btn-wide m-1" onClick={connectClicked}>Connect</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SocialProfileInputModal;