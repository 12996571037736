import * as reducerUtils from '../reducerUtils';
import * as types from '../../actions/actionTypes';
import initialState from '../initialState';

export default function accountReduce(state, action) {
    let user;
    switch(action.type) {
        case types.LOGIN_SUCCESS:
        case types.TOKEN_REFRESHED:
            const { access_token, refresh_token } = action.payload;   
            const expires = new Date(action.payload[".expires"]);
            if(action.type === types.LOGIN_SUCCESS || state.user) { //if it's a token refresh, make sure we haven't logged out while this call was in-flight
                return {
                    ...state,
                    token: access_token,
                    tokenExpiry: expires,
                    refreshToken: refresh_token,
                    refreshingToken: false
                }
            }
            else {
                return state;
            }
        case types.TOKEN_REFRESH_FAILED:
            return {
                ...state,
                refreshingToken: false
            }  
        case types.SET_USER_LOADING:
            return {
                ...state,
                userLoading: action.loading
            }  
        case types.GET_USER_SUCCESS:
            user = action.user;         
            let activeProfileID = state.activeProfileID;
            let activeProfile;
            if(activeProfileID) {
                //we saved an activeProfileID before, try to find the profile and set it
                for(let i = 0; i < user.Artists.length; i++) {
                    if(user.Artists[i].ID === activeProfileID) {
                        activeProfile = user.Artists[i];
                        break;
                    }
                }
            }
            if(!activeProfile && user.Artists.length > 0) {
                //default the active profile to the 1st profile
                activeProfile = user.Artists[0];
                activeProfileID  = activeProfile.ID;
            }            
            if(activeProfile) {         
                user.activeProfile = activeProfile; 
            }       
            return {
                ...state,
                activeProfileID,
                user,
                userLoading: false
            }; 
        case types.TOKEN_REFRESHING:
            return {
                ...state,
                refreshTokenPromise: action.refreshTokenPromise,
                refreshingToken: true
            }         
        case types.SET_REFRESH_TOKEN_TIMEOUT_HANDLE:
            return {
                ...state,
                refreshTokenTimeoutHandle: action.timeoutHandle
            }
        case types.SWITCH_ACTIVE_PROFILE:
        {
            const activeProfileID = action.id;    
            const activeProfile = state.user.Artists.find(a => a.ID === activeProfileID);    
            let user = state.user;
            if(activeProfile) {
                user = {...user, activeProfile};
            }        
            return {
                ...state,
                activeProfileID,
                user
            }; 
        }    
        case types.LOGOUT:       
            return {
                ...initialState.account,
                refreshTokenPromise: state.refreshTokenPromise //we can't just set the promise to null, else this causes some errors if we're waiting for the result
            };                 
        default:
            return state;
    }
}