/* eslint-disable */
import mixpanel from 'mixpanel-browser'; 
import settings from '../constants/settings';
class AnalyticsService {
    constructor() {
        this.mixpanelInitialised = false;
    }    

    static initialiseGA(userID) {
        //initialise google analytics
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
        if(userID)
        {
            window.ga('create', 'UA-89912673-1', 'auto', { 'userId': userID });
        }
        else
        {
            window.ga('create', 'UA-89912673-1', 'auto');
        }
    }

    static initialiseFB() {
        //initialise FB analytics
        !function (f, b, e, v, n, t, s) {
            if (f.fbq) return; n = f.fbq = function () {
                n.callMethod ?
                n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            }; if (!f._fbq) f._fbq = n;
            n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
            t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s)
        }(window,
        document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        window.fbq('init', '756859017696922');
    }

    
    /*static initialiseTwitter() {
        //the below doesn't work, we'd need to maybe load similar to in the widget service, but make sure they don't clash. let's leave this out for now.
        require('https://platform.twitter.com/oct.js');
    }*/

    static initialiseHotjar() {   
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:1954271,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    }

    static initialiseMixpanel() {
        if(this.mixpanelInitialised) {
            mixpanel.init(settings.MIXPANEL_TOKEN);
            this.mixpanelInitialised = true;
        }
    }

    /*static initialiseOnboardFlow() {
        (function() {var po = document.createElement("script"); po.type = "text/javascript"; po.async = true;
        po.src = "https://media.onboardflow.com/gen/tracker/CMmOQDGw.min.js";
        po.onload = po.onreadystatechange = function() {var rs = this.readyState; if (rs && rs != 'complete' && rs != 'loaded') return;};
        var s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(po, s);})();
    }*/

    static identifyMixpanel(userID) {
        if(this.mixpanelInitialised) {
            mixpanel.identify(userID);
        }
    }

    /*static identifyOnboardFlow(user, artist) {
        OnboardFlowLoader = new OnboardFlowLoaderClass(); 
        window.onboardFlowSettings = {
            'site_key': 'CMmOQDGw',
            'user': {
                'id': artist.ID, 
                'customer_id': user.StripeCustomerID, // The payment providers Customer ID for the logged in user
                'email': user.Email,
                'image_url': ''
            },
            "custom_properties": {
                'total_tasks_completed': artist.Tasks.filter(t => ),
                'total_vault_items_created': artist.Resources.length,
                'total_finance_items_creat': artist.Finances.Income.length + artist.Finances.Expenses.length,
                'total_goals_created': artist.Goals.length,
                'total_events_created': artist.Events.length,
                'total_tasks_created': artist.Tasks.length
            }
        };
        OnboardFlowLoader.identify(window.onboardFlowSettings);
    }*/

    static resetMixpanel() {
        if(this.mixpanelInitialised) {
            mixpanel.reset();
        }
    }

    static logPageViewGA(url) {
        if(window.ga) {
            window.ga('send', 'pageview', url);
        }
    }

    static logPageViewFB(url) {
        if (window.fbq) {
            window.fbq('track', 'PageView');
        }
    }

    static logPageViewTWT(url) {
        if (window.twq) {
            window.twq('track', 'PageView');
        }
    }

    static logEventGA(category, action, label) {
        if (window.ga) {
            window.ga('send', 'event', category, action, label);
        }
    }

    static logEventFB(eventName) {
        if (window.fbq) {
            window.fbq('trackCustom', eventName);
        }
    }

    static logEventTWT(eventName) {
        if (window.twq) {
            window.twq('track', eventName);
        }
    }

    static logEventMixpanel(eventName) {
        if(this.mixpanelInitialised) {
            mixpanel.track(eventName);
        }
    }

    static logEvent(eventName) {
        try {
            this.logEventFB(eventName);
            this.logEventGA(eventName);
            this.logEventMixpanel(eventName);
        }
        catch(error) {}
    }
}
export default AnalyticsService;