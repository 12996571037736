import {combineReducers} from 'redux';
import { connectRouter } from 'connected-react-router';
import currencies from './currencyReducer';
import greeting from './greetingReducer';
import pricingPlans from './pricingReducer';
import account from './accountReducer';

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    account,
    currencies,
    greeting,
    pricingPlans
});

export default createRootReducer;
