import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatNumber } from '../../utils/financeUtils';

function SocialSummaryCard({platform, socialProfile, connect, disconnect}) {   
    const getSocialIcon = () => {
        if(platform === 'Facebook') {
            return require('../../images/facebook.svg');
        }
        else if(platform === 'Twitter') {
            return require('../../images/twitter.svg');
        }
        else if(platform === 'Instagram') {
            return require('../../images/instagram.svg');
        }
        else if(platform === 'YouTube') {
            return require('../../images/youtube.svg');
        }
        else if(platform === 'SoundCloud') {
            return require('../../images/soundcloud2.svg');
        }
        else if(platform === 'LoudUp') {
            return require('../../images/loudup.png');
        }
        return null;
    }

    const getSocialIconClass = () => {
        return `social-icon ${platform === 'LoudUp' ? 'social-icon--loudup' : ''}`;
    }

    const socialProfileConfigured = socialProfile && socialProfile.IsConfigured;

    const getChange = () => {
        const changeElements = [];
        if(socialProfileConfigured && socialProfile.Previous != null) { //use a single equals so we can also match to undefined
            const diff = socialProfile.Current - socialProfile.Previous;
            let changeText;
            if(!diff) {
                changeText = 'No change';
            }
            else {
                if(diff > 0) {
                    changeElements.push(<img src={require('../../images/triangle.svg')} alt="" className="top-green-arrow" />);
                }
                else {
                    changeElements.push(<img src={require('../../images/triangle-red.svg')} alt="" className="top-green-arrow top-red-arrow" />)
                }
                changeText = formatNumber(Math.abs(diff));
            }
            changeElements.push(`${changeText} over the last ${socialProfile.PreviousPeriod}`);
        }
        return changeElements;
    }

    return (
        <span className="stat stat-link stat-link--social">
            <span className="social__item">
                <span className="social-network">                                    
                    <div className="social-icon-wrapper">
                        <img src={getSocialIcon()} alt="" className={getSocialIconClass()} />
                    </div>                                    
                    <span className="social-item__name">
                        <p className="stat-desc">
                            {platform}
                        </p>
                    </span> 
                </span>                                   
                <span className="social__item-icons-wrapper">
                    <span className="followers-wrapper">
                        <span className="followers-value"> 
                            {socialProfileConfigured ? formatNumber(socialProfile.Current) : '-'}
                        </span> 
                        <span className="text-social"> 
                        {
                            socialProfileConfigured ?
                                <React.Fragment>
                                    <img src={require('../../images/user.svg')} alt="" />
                                    {socialProfile.Metric}
                                </React.Fragment>
                                : 
                                <span>&nbsp;</span>
                        }                            
                        </span> 
                        <span className="social-status">
                            {getChange()}                            
                        </span>
                    </span>  
                </span>
                <span className="social__item-footer social__item-footer-active">
                    {
                        socialProfileConfigured ?
                            <React.Fragment>
                                <Link to={`/social/${platform}`} className="connect btn view-social">View Insights</Link> 
                                <a href="#" className="disconnect" onClick={disconnect}>Disconnect</a>
                            </React.Fragment>
                            :
                            <a href="#" className="connect btn" onClick={connect}>Connect</a>
                    }                                            
                </span>     
            </span>
        </span>
    )
}

export default SocialSummaryCard;