import * as reducerUtils from '../reducerUtils';
import * as types from '../../actions/actionTypes';

export default function goalReduce(state, action) {
    const artistPredicate = (a, artistID) => a.ID === artistID;
    const goalPredicate = (g, goalID) => g.ID === goalID;

    switch(action.type) {            
        
        case types.GOAL_CREATE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const goal = action.payload.goal;
            const user = {...state.user};            
            user.Artists = reducerUtils.addNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Goals', goal);           
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }      
        case types.GOAL_UPDATE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const goal = action.payload.goal;
            const user = {...state.user};
            user.Artists = reducerUtils.updateNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Goals', (g) => goalPredicate(g, goal.ID), goal);    
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }
        case types.GOAL_DELETE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const goalID = action.payload.goalID;
            const user = {...state.user};
            user.Artists = reducerUtils.deleteNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Goals', (g) => goalPredicate(g, goalID));
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }            
        default:
            return state;
    }
}