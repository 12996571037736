import React, { useState, useRef, useReducer } from 'react';
import { useFormInput, useFormCheckInput } from '../../utils/customHooks';
import { trim } from 'lodash';
import ToastrService from '../../services/toastrService';
import { checkSubscription } from '../../utils/artistUtils';

function FinaliseSave({user, onCancel, finaliseRef, name, isPublic, itemTags, allTags, actions, getItem, setSaving}) {
    const activeProfile = user.activeProfile;
    const nameInput = useFormInput(name);
    const isPublicInput = useFormCheckInput(isPublic);
    const [selectedTags, setSelectedTags] = useState(itemTags || []);
    const tagInput = useFormInput('');
    const [tags, setTags] = useState(allTags);

    const tagValueLower = tagInput.value.toLowerCase();
    const filteredTags = tags.filter(t => t.toLowerCase().indexOf(tagValueLower) > -1);

    const addTag = (tag) => {
        if(!tag) {
            tag = trim(tagInput.value);
        }
        if(tag) {
            const updatedSelectedTags = [...selectedTags];
            updatedSelectedTags.push(tag);
            setSelectedTags(updatedSelectedTags)  
            if(!tags.includes(tag)) {
                let updatedTags = [...tags];
                updatedTags.push(tag);
                updatedTags = updatedTags.sort((a, b) => a.localeCompare(b, 'en', {'sensitivity': 'base'}))
                setTags(updatedTags);
                if(trim(tagInput.value)) {
                    tagInput.onChange({ target: { value: '' } });
                }
            }
        }
    }
    const removeTag = (tag) => {
        const updatedTags = [...selectedTags];
        updatedTags.splice(updatedTags.indexOf(tag), 1);
        setSelectedTags(updatedTags);
    }

    const addTagRef = useRef();

    const showAddTag = (event) => {
        window.$(addTagRef.current).slideToggle();
    }

    const tagSelected = (tag) => {
        if(selectedTags.includes(tag)) {
            removeTag(tag);
        }
        else {
            addTag(tag);
        }
    }

    const validate = () => {
        const toastrService = new ToastrService();
        if(!nameInput.value) {
            toastrService.showError('', 'Looks like you forgot to enter the title');
            return false;
        }
        return true;
    }

    const saveItem = () => {   
        checkSubscription(activeProfile, async () => {
            if(validate()) {
                try {
                    setSaving(true);
                    const item = {
                        ...(await getItem()), 
                        Name: nameInput.value, 
                        CreatedBy: user.ID, 
                        CreatedDate: new Date(), 
                        Tags: selectedTags, 
                        Visibility: isPublicInput.checked ? 'Public' : 'Private'
                    };
                    const action = item.ID ? actions.editResource : actions.addResource;
                    action(activeProfile.ID, item)
                        .then(result => {
                            onCancel();
                            setSaving(false);
                            window.$("#modal").modal("hide");
                        })
                        .catch(error => setSaving(false))
                }
                catch(error) {
                    setSaving(false);
                }
            }
        }, 'save this item');            
    }

    return (
        <div className="note-title-end-tags" ref={finaliseRef}>
            <div className="form-group mb-2">
                <label>Title</label>
                <input className="form-control" type="text" placeholder="Enter title" {...nameInput} />
            </div>
            {
                activeProfile.ArtistType === 'Band' ?
                    <div className="note-privacy">
                        <div className="input__checkbox">
                            <input type="checkbox" id="note-privacy" className="checkbox-input" {...isPublicInput} />
                            <label htmlFor="note-privacy"></label>
                        </div>
                        <label htmlFor="note-privacy" className="checkbox-text-label">Public (shared with your group)</label>
                    </div> 
                    : null
            }
            
            <div className="form-group tags-label">
                <label>TAGS </label><span className="add-tag-icon" onClick={showAddTag}>+</span>
            </div> 
            <div className="tag-choosen">
                {
                    selectedTags.map(tag => 
                        <div key={tag} className="tag">{tag} <div className="delete-tag" onClick={() => removeTag(tag)}>+</div></div>
                    )
                }
            </div>
            <div className="tags__wrapper" ref={addTagRef}>
                <div className="form form--lined form--inputbg modal-inner">
                    <div className="form-group">
                        <input type="text" className="form-control pull-right" id="search" placeholder={`Enter your tag ${tags.length ? 'or select from the list below' : ''}`} {...tagInput} />
                    </div>
                    {
                        filteredTags.length ?
                            <ul id="tag-list">
                                {
                                    filteredTags.map(tag => 
                                        <li key={tag} className={`tag-item ${selectedTags.includes(tag) ? 'active' : ''}`} onClick={(event) => tagSelected(tag)}>{tag}</li>
                                    )
                                }
                            </ul>
                            :
                            <div className="tag-empty active">
                                {tags.length ? 'Not found ...' : ''} <span onClick={(event) => addTag()}>+ Create new tag</span>
                            </div>
                    }
                </div>
            </div>
            <div className="form-group text-center mt-4 modal-buttons">
                <button type="button" className="btn btn-outline btn-wide m-1 cancele-note-button" onClick={onCancel}>Cancel</button>
                <button type="button" className="btn btn-primary btn-wide m-1" onClick={saveItem}>Save</button>
            </div>
        </div>
    )
}

export default FinaliseSave;