/* eslint-disable react/jsx-no-bind */
import React, {useLayoutEffect} from 'react';

//this is a static component so let's improve the performance by using memo
const SidePromo = React.memo(props => {
    useLayoutEffect(() => {    
        const $ = window.$;
        if ( $('#loginSlider').length !== 0 ) {
            $('#loginSlider').slick({
                autoplay: true,
                autoplaySpeed: 5000,
                speed: 1400,
                fade: true,
                infinite: true,
                arrows: false
            });
        }
    }, []);

    return (            
        <div className="login__promo">
            <div id="loginSlider" className="login-slider">
                <div className="login-slider__slide slide--1">
                    <div className="login__msg">
                        <p>Set your goals and track your progress</p>
                    </div>
                </div>
                <div className="login-slider__slide slide--2">
                    <div className="login__msg">
                        <p>Organise your workload and increase your productivity</p>
                    </div>
                </div>
                <div className="login-slider__slide slide--3">
                    <div className="login__msg">
                        <p>Manage your budget and spending</p>
                    </div>
                </div>
                <div className="login-slider__slide slide--4">
                    <div className="login__msg">
                        <p>Keep track of your upcoming events</p>
                    </div>
                </div>
                <div className="login-slider__slide slide--5">
                    <div className="login__msg">
                        <p>Monitor your social profiles</p>
                    </div>
                </div>
                <div className="login-slider__slide slide--6">
                    <div className="login__msg">
                        <p>Capture your ideas</p>
                    </div>
                </div>
            </div>
        </div>                        
    );     
})

SidePromo.propTypes = {
};


export default SidePromo;
