import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as projectActions from '../../actions/projectActions';
import { withModalContext } from '../../services/modalService';
import ArtistNameHeader from '../common/ArtistNameHeader';
import * as artistUtils from '../../utils/artistUtils';
import Header from '../common/Header';
import Footer from '../common/Footer';
import moment from 'moment';
import { Link } from 'react-router-dom';
import TaskList from '../tasks/TaskList';
import EmptyView from '../common/EmptyView';
import ProjectModal from './ProjectModal';
import KeepTasksCheckbox from './KeepTasksCheckbox';
import swal from 'sweetalert';
import { useFormCheckInput } from '../../utils/customHooks';
import { pluralise } from '../../utils/textUtils';
import { noProjectsMessages, noTasksDueTodayMessages, projectsPageHeaders } from '../../constants/appMessages';
import _ from 'lodash';

function ProjectPage(props) {
    const [showMenuForTask, setShowMenuForTask] = useState();
    const [showMenuForProject, setShowMenuForProject] = useState();
    const includeCompletedInput = useFormCheckInput(false);
    const activeProfile = props.user.activeProfile;
    const todayEndMoment = moment().endOf('day');
    const headerText = useRef(_.sample(projectsPageHeaders)).current;
    const isTaskMine = (task) => task.UserIDs.length === 0 || task.UserIDs.includes(props.user.ID);
    let tasks = activeProfile.Tasks.filter(t => t.Status !== 'Completed' && moment(t.DueDate) <= todayEndMoment && isTaskMine(t)).sort((a, b) => a.DueDate - b.DueDate);
    const noTasksMessages = [...noTasksDueTodayMessages];
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    if(activeProfile.Tasks.find(t => t.CompletedDate > oneWeekAgo && isTaskMine(t))) {
        noTasksMessages.push('You don\'t have any tasks due today. Take some time for yourself. You earned it!');
    }
    const noTasksDueTodayMessage = useRef(_.sample(noTasksMessages)).current;
    const noProjectsMessage = useRef(_.sample(noProjectsMessages)).current;
    let projects = activeProfile.Projects.map(p => {
        var projectTasks = activeProfile.Tasks.filter(t => t.ProjectID === p.ID);
        var completedTasksCount = projectTasks.filter(t => t.Status === 'Completed').length;
        return {...p,
            TaskCount: projectTasks.length,
            CompletedTasksCount: completedTasksCount,
            Completed: projectTasks.length && projectTasks.length === completedTasksCount
        };
    });
    if(!includeCompletedInput.checked) {
        projects = projects.filter(p => !p.Completed);
    }

    useEffect(() => {
        window.$(document).on('click', '', closeDropDown);
        return () => {
            window.$(document).off('click', '', closeDropDown);
        }
    }, []);

    const closeDropDown = (e) => {
        const div = window.$('.list-row__menu');    
        if (!div.is(e.target) && div.has(e.target).length === 0) {
            div.removeClass('active');
        }
    }

    const projectMenuClicked = (event, project) => {
        event.preventDefault();
        event.stopPropagation();
        setShowMenuForProject(showMenuForProject !== project.ID ? project.ID : null)
    }

    const createNewProject = (event) => {
        event.preventDefault();
        event.stopPropagation();
        artistUtils.checkSubscription(activeProfile, () => {
            const projectModal = <ProjectModal />
            props.showModal(() => projectModal, { isOpen: true })
        }, 'create a project');        
    }

    const renameProject = (event, project) => {
        event.preventDefault();
        event.stopPropagation();
        artistUtils.checkSubscription(activeProfile, () => {
            const projectModal = <ProjectModal projectID={project.ID} />
            props.showModal(() => projectModal, { isOpen: true })
        }, 'rename a project');        
    }

    const deleteProject = (event, project) => {
        event.preventDefault();        
        event.stopPropagation();       
        artistUtils.checkSubscription(activeProfile, () => {
            /*const buttons = {
                cancel: true
            };*/
            let content = null;
            if(project.TaskCount) {
                /*buttons['keepTasks'] = {
                    text: 'Keep tasks',
                    value: 'keepTasks'
                }*/
                let wrapper = document.createElement('div');
                ReactDOM.render(<KeepTasksCheckbox />, wrapper);
                content = wrapper.firstChild;                
            }
            //buttons['ok'] = true;
            swal({
                title: 'Are you sure?',
                content,
                text: `Once deleted, you will not be able to recover this project. ${project.TaskCount ? `Select keep tasks to delete the project but keep the ${project.TaskCount} ${pluralise('task', project.TaskCount)}.` : ''}`,
                buttons: {
                    cancel: true,
                    confirm: true
                },
                dangerMode: true,
              })
              .then(val => {
                  debugger;
                if (val !== null) {
                    const deleteTasks = !val;
                    props.actions.deleteProject(activeProfile.ID, project.ID, deleteTasks);
                }
            });
        }, 'delete a project');        
    }

    return (
        <div className="home">
            <Header />
            <main className="content">
                <div className="container">
                    <ArtistNameHeader user={props.user} greeting={`Hey ${props.user.FirstName}, ${headerText}`}/>  
                    <div className="content-body">
                        <TaskList 
                            tasks={tasks} 
                            headerText="Tasks Due Today" 
                            history={props.history}
                            emptyText={noTasksDueTodayMessage}
                            showMenuForTask={showMenuForTask}
                            setShowMenuForTask={setShowMenuForTask}
                            headerLink="/tasks" />

                        <div className="list-head list-head--projects">
                            <h3 className="projects-title">PROJECTS</h3>
                            <div className="projects-header__right">
                                <div className="include-completed__wrapper">
                                    <div className="custom-control custom-checkbox mb-3">
                                        <input type="checkbox" className="custom-control-input" id="check-includecompleted" {...includeCompletedInput} />
                                        <label className="custom-control-label" htmlFor="check-includecompleted">Include completed</label>
                                    </div>  
                                </div>
                                <a href="#" className="btn-text btn-new" onClick={createNewProject}>
                                    <i className="mikeicon-pluscircle"></i>
                                    New project
                                </a>
                            </div>              
                        </div>   
                        <div className="projects__wrapper">
                            <div className="content-body">
                                {
                                    projects.length ?
                                        <div className="stats stats--social stats--projects">
                                            {                                            
                                                projects.map(project => 
                                                    <span key={project.ID} className={`stat stat-link stat-link--social project-item ${project.Completed ? 'project-item--completed': ''}`}>                                                    
                                                        <span className={`list-row__menu ${showMenuForProject === project.ID ? 'active' : ''}`}>
                                                            <span className="list-row__menu-btn" onClick={(event) => projectMenuClicked(event, project)}>
                                                                <span></span>
                                                                <span></span>
                                                                <span></span>
                                                            </span>
                                                            <span className="list-row__menu-dropdown">
                                                                <span className="list-row__menu-dropdown-item list-row__menu--edit" alt="" onClick={(event) => renameProject(event, project)}>
                                                                    <img src={require('../../images/icon-goals-edit.png')} />
                                                                    <span>Rename</span>
                                                                </span>  
                                                                <span className="list-row__menu-dropdown-item list-row__menu--delete" onClick={(event) => deleteProject(event, project)}>
                                                                    <img src={require('../../images/icon-goals-delete.png')} alt="" /> 
                                                                    <span>Delete</span> 
                                                                </span>                       
                                                            </span>                          
                                                        </span>  
                                                        <span className="social__item">
                                                            <span className="social-network">
                                                                <span className="social-item__name">
                                                                    <h3>
                                                                        {project.Name}
                                                                    </h3>
                                                                </span> 
                                                            </span>                 
                                                            <span className="social__item-icons-wrapper">
                                                                {project.CompletedTasksCount}/{project.TaskCount} tasks completed {project.Completed ? <img src={require('../../images/tick-green.svg')} alt="tick" /> : null}                                                
                                                            </span>
                                                            <span className="social__item-footer social__item-footer-active">                  
                                                                <Link to={`/tasks?projectID=${project.ID}`} className="connect btn view-social">View project</Link>        
                                                            </span>       
                                                        </span>
                                                    </span>
                                                )                                     
                                            }  
                                            {
                                                projects.length === 1 ?
                                                    <span className="stat stat-link stat-link--social project-item project-item--new">
                                                        <span className="social__item">                                                        
                                                            <span className="social-network">      
                                                                <span className="social-item__name">
                                                                    <h3>
                                                                        Create new project 
                                                                    </h3>
                                                                    <a href="#" className="list-row--empty__button" onClick={createNewProject}><span>+</span></a>                                            
                                                                </span> 
                                                            </span> 
                                                        </span>                                                
                                                    </span>
                                                : null
                                            }                              
                                        </div>
                                    :
                                    <EmptyView text={noProjectsMessage} buttonText="Create project" onButtonClick={createNewProject} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />           
        </div>
    )
}


function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user
    };
}


function mapDispatchToProps(dispatch) {
    return {
        //project actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, projectActions), dispatch)
    };
}

export default withModalContext(connect(mapStateToProps, mapDispatchToProps)(ProjectPage));