export function getNext(user, returnUrl) {
    //if we have an account but don't have a subscription, redirect to the payment page
    if(user.Artists.length) {
        if(!user.activeProfile.HasActiveSubscription) {
            return '/subscription'
        }
        else {
            return returnUrl || '/';
        }
    }
    else {
        return '/profilesetup'
    }
}

export function isAuthenticated(user) {
    //we need the ID set to be authenticated as we store the user object in local storage 
    //with just the email as we need it to refresh the token
    return user && user.ID ? true : false;
}