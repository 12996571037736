import ResourceService from '../services/resourceService';
import ToastrService from '../services/toastrService';
import { push } from 'connected-react-router';
import * as types from './actionTypes';
import * as actionUtils from '../utils/actionUtils';
import { normaliseResource } from '../utils/normaliseUtils';

export function addResource(artistID, resource) {
    return function(dispatch) {
        const toastrService = new ToastrService();
        return ResourceService.createResource(artistID, resource).then(resource => {
            normaliseResource(resource);
            dispatch({ type: types.RESOURCE_CREATE_SUCCESS, payload: { artistID, resource } });
            if(resource.Type === 'Text') {
                toastrService.showSuccess('', 'Note has been created!');
            } 
            else {
                toastrService.showSuccess('', `${resource.FileType} file has been uploaded!`)
            }
            return resource;
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function editResource(artistID, resource) {
    return function(dispatch) {
        const toastrService = new ToastrService();
        return ResourceService.editResource(artistID, resource).then(async resource => {
            normaliseResource(resource);
            dispatch({ type: types.RESOURCE_UPDATE_SUCCESS, payload: { artistID, resource } });
            if(resource.Type === 'Text') {
                toastrService.showSuccess('', 'Note has been updated!');
            } 
            else {
                toastrService.showSuccess('', `${resource.FileType} file has been updated!`)
            }
            return resource;
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function deleteResource(artistID, resource) {
    return function(dispatch) {
        const toastrService = new ToastrService();
        return ResourceService.deleteResource(artistID, resource.ID).then(_ => {
            dispatch({ type: types.RESOURCE_DELETE_SUCCESS, payload: { artistID, resourceID: resource.ID } });
            if(resource.Type === 'Text') {
                toastrService.showSuccess('', 'Note has been deleted!');
            } 
            else {
                toastrService.showSuccess('', `${resource.FileType} file has been deleted!`)
            }
        }).catch(error => {
            actionUtils.handleError(error);
        });
    };
}

export function getFile(artistID, resourceID) {
    return function(dispatch) {
        return ResourceService.getFile(artistID, resourceID).then(file => {
            return file;
        }).catch(error => {
            actionUtils.handleError(error);
        });
    };
}