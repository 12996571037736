export const goalTypes = {
    TwitterFollowers: {
        DisplayText: 'Twitter Followers',
        SocialPlatform: 'Twitter'
    },
    FacebookLikes: {
        DisplayText: 'Facebook Likes',
        SocialPlatform: 'Facebook'
    },
    InstagramFollowers: {
        DisplayText: 'Instagram Followers',
        SocialPlatform: 'Instagram'
    },
    MailingList: {
        DisplayText: 'Mailing List Subscribers',
        SocialPlatform: null
    },
    YouTubeViews: {
        DisplayText: 'YouTube Views',
        SocialPlatform: 'YouTube'
    },
    Gigs: {
        DisplayText: 'Gigs',
        SocialPlatform: null
    },
    Profit: {
        DisplayText: 'Profit',
        SocialPlatform: null
    },
    LoudUpConnections: {
        DisplayText: 'LoudUp Connections',
        SocialPlatform: 'LoudUp'
    },
    SoundCloudPlays: {
        DisplayText: 'SoundCloud Plays',
        SocialPlatform: 'SoundCloud'
    }
}

export const paymentCurrencies = ['USD','GBP', 'EUR'];

export const incomeTypes = {
    GigIncome: 'Gig Income',
    MusicSales: 'Music Sales',
    Royalties: 'Royalties',
    Tips: 'Tips',
    SessionWork: 'Session Work',
    Donation: 'Donation',
    Crowdfunding: 'Crowdfunding',
    MerchSales: 'Merch Sales',
    Other: 'Other'
}

export const expenseTypes = {
    Marketing: 'Marketing',
    Promotion: 'Promotion',
    PR: 'PR',
    Stationary: 'Stationary',
    StudioTime: 'Studio Time',
    MixingMastering: 'Mixing/Mastering',
    Distribution: 'Distribution',
    Merch: 'Merch',
    Payout: 'Payout',
    Equipment: 'Equipment',
    Styling: 'Styling',
    PhotoVideo: 'Photo/Video',
    LegalFees: 'Legal Fees',
    Accommodation: 'Accommodation',
    SessionMusicians: 'Session Musicians',
    VenueBooking: 'Venue Booking',
    Transportation: 'Transportation',
    FoodDrink: 'Food/Drink',
    Other: 'Other'
}