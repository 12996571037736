import React from 'react';
import * as taskUtils from '../../utils/taskUtils';
import TaskModal from './TaskModal';
import * as artistUtils from '../../utils/artistUtils';
import EmptyView from '../common/EmptyView';
import swal from 'sweetalert';
import ToastrService from '../../services/toastrService';
import * as taskActions from '../../actions/taskActions';
import { getResourceDescription } from '../../utils/resourceUtils';
import { bindActionCreators } from 'redux';
import { withModalContext } from '../../services/modalService';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

function TaskList({ 
    tasks, 
    headerText, 
    showModal, 
    resourceToLink, 
    history, 
    user, 
    actions, 
    projectID, 
    setLinkingResource, 
    showMenuForTask, 
    setShowMenuForTask, 
    emptyText, 
    headerLink, 
    headerLinkText="All tasks" 
}) {

    const activeProfile = user.activeProfile;

    const editTask = (event, task) => {
        event.preventDefault();
        event.stopPropagation();
        artistUtils.checkSubscription(activeProfile, () => {
            const taskModal = <TaskModal taskID={task.ID} />
            showModal(() => taskModal, { isOpen: true })
        }, 'edit a task');        
    }

    const createNewTask = (event) => {
        event.preventDefault();
        event.stopPropagation();
        artistUtils.checkSubscription(activeProfile, () => {
            const taskModal = <TaskModal defaultProjectID={projectID} />
            showModal(() => taskModal, { isOpen: true })
        }, 'create a task');        
    }

    const cloneTask = (event, task) => {
        event.preventDefault();
        event.stopPropagation();
        artistUtils.checkSubscription(activeProfile, () => {
            const taskModal = <TaskModal taskID={task.ID} clone={true} />
            showModal(() => taskModal, { isOpen: true })
        }, 'clone a task');        
    }

    const deleteTask = (event, task) => {
        event.preventDefault();        
        event.stopPropagation();
        artistUtils.checkSubscription(activeProfile, () => {
            swal({
                title: 'Are you sure?',
                text: 'Once deleted, you will not be able to recover this task!',
                buttons: true,
                dangerMode: true,
              })
              .then((willDelete) => {
                if (willDelete) {
                    actions.deleteTask(activeProfile.ID, task.ID);
                }
            });
        }, 'delete a task');        
    }

    const viewTask = (event, task) => {
        event.preventDefault();
        const taskModal = <TaskModal taskID={task.ID} viewMode={true} />
        showModal(() => taskModal, { isOpen: true })
    }

    const taskMenuClicked = (event, task) => {
        event.preventDefault();
        event.stopPropagation();
        setShowMenuForTask(showMenuForTask !== task.ID ? task.ID : null)
    }

    const resourceDescription = resourceToLink ? getResourceDescription(resourceToLink) : null;

    const linkResource = (event, task) => {
        event.preventDefault();
        artistUtils.checkSubscription(activeProfile, () => {
            if(!task.ResourceIDs.includes(resourceToLink.ID)) {
                setLinkingResource(true);
                actions.linkTaskResource(activeProfile.ID, task.ID, resourceToLink.ID)
                    .then(result => {
                        setLinkingResource(false);
                        history.push('/vault');
                    })
                    .catch(error => setLinkingResource(false));
            }
            else {
                const toastrService = new ToastrService();
                toastrService.showError('', `The ${resourceDescription} has already been linked to this task`);
            }
        }, `link the ${resourceDescription}`);        
    }

    const actionTask = (event, task) => {
        event.preventDefault();
        event.stopPropagation();
        artistUtils.checkSubscription(activeProfile, () => {
            task = {...task};
            if (task.Status === 'Completed') {
                task.Status = 'Pending';
                task.CompletedDate = null;
            }
            else
            {
                task.Status = 'Completed';
                task.CompletedDate = new Date();
            }   
            const target = window.$(event.currentTarget);     
            const successCallback = () => {            
                const anchorElement = target.parent().parent();
                if(task.Status === 'Completed') {
                    anchorElement.addClass('list-row-completed');
                    target.addClass('list-check-filled');
                }
                else {
                    anchorElement.removeClass('list-row-completed');
                    target.removeClass('list-check-filled');
                }
            }
            actions.editTask(activeProfile.ID, task, successCallback, 1000);
        }, 'update task statuses');        
    }

    return (
        <div className="tab-content">
            <div className="tab-pane fade show active" id="pending" role="tabpanel" aria-labelledby="pending-tab">
                <div className="list">
                    <div className="list-head">
                        <h3 className="m-0">
                            {headerText}
                        </h3>
                        {
                            headerLink ?
                                <Link to={headerLink} className="all-tasks-link btn-text btn-new">                          
                                    {headerLinkText}
                                </Link>
                                :
                                null
                        }                        
                    </div>
                    <div className="list-body">
                        {
                            tasks.length ?
                                tasks.map(task => {
                                    const {dueDateText, daysDiff} = taskUtils.getDueDateText(task);
                                    const overdue = daysDiff < 0;
                                    let anchorClass = '';
                                    let dueSpanClass = '';
                                    if(task.Status === 'Completed') {
                                        anchorClass += 'list-row-completed ';
                                    }
                                    else if(overdue) {
                                        anchorClass += 'list-row-overdue ';
                                        dueSpanClass += 'text-overdue';
                                    }
                                    else if(daysDiff === 0) {
                                        anchorClass += 'list-row-due-today ';
                                        dueSpanClass += 'text-due-today ';
                                    }
                                    if(task.Status !== 'Completed' && task.HighPriority) {
                                        anchorClass += 'list-row--priority ';
                                    }                                            
                                    return <a key={task.ID} href="#" className={`list-row list-row-link ${anchorClass}`} onClick={(event) => resourceToLink ? linkResource(event, task) : viewTask(event, task)}>
                                        {
                                            !resourceToLink ?
                                                <span className={`list-row__menu ${showMenuForTask === task.ID ? 'active' : ''}`}>
                                                    <span className="list-row__menu-btn" onClick={(event) => taskMenuClicked(event, task)}>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </span>
                                                    <span className="list-row__menu-dropdown">
                                                        <span className="list-row__menu-dropdown-item list-row__menu--view" onClick={(event) => viewTask(event, task)}>
                                                            <img src={require('../../images/eye.svg')} /> 
                                                            <span>View</span>
                                                        </span>
                                                        <span className="list-row__menu-dropdown-item list-row__menu--edit" onClick={(event) => editTask(event, task)}>
                                                            <img src={require('../../images/icon-goals-edit.png')} />
                                                            <span>Edit</span>
                                                        </span>  
                                                        <span className="list-row__menu-dropdown-item list-row__menu--clone" onClick={(event) => cloneTask(event, task)}>
                                                            <img src={require('../../images/sheet.svg')} />
                                                            <span>Clone</span>
                                                        </span>
                                                        <span className="list-row__menu-dropdown-item list-row__menu--delete" onClick={(event) => deleteTask(event, task)}>
                                                            <img src={require('../../images/icon-goals-delete.png')} />
                                                            <span>Delete </span>                                                            
                                                        </span>  
                                                    </span>                                                                                                
                                                </span>
                                                : null
                                        }
                                        
                                        <div className="list-col list-col-200">
                                            <p className="due">Due <span className={dueSpanClass}>{dueDateText}</span></p>
                                        </div>
                                        <div className="list-col">
                                            <p>{task.Name}</p>
                                        </div>
                                        <div className="list-col list-col-200 list-col-right">
                                            {
                                                task.UserIDs.length &&  task.UserIDs.length !== activeProfile.Members.length ?
                                                    task.UserIDs.map(userID =>
                                                        <span key={userID} className="badge badge-user">
                                                            <i className="mikeicon-user"></i> &nbsp;
                                                            {
                                                                artistUtils.getMemberName(user, activeProfile.Members, userID)
                                                            }
                                                        </span>
                                                    )
                                                : null
                                            }                                                        
                                        </div>
                                        {
                                            !resourceToLink ? 
                                                <div className="list-col list-col-100 text-center list-col-status">
                                                    <span className={`list-check ${task.Status === 'Completed' ? 'list-check-filled' : ''}`} onClick={(event) => actionTask(event, task)}>
                                                        <i className="mikeicon-checkmark"></i>
                                                    </span>
                                                </div>
                                                : null
                                        }
                                        
                                    </a>
                                })
                                :
                                <EmptyView text={emptyText} buttonText="Create task" onButtonClick={createNewTask} />
                        }                                       
                    </div>
                </div>
            </div>                           
        </div>
    )
}


function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, taskActions), dispatch)
    };
}

export default withModalContext(connect(mapStateToProps, mapDispatchToProps)(TaskList));