import EventService from '../services/eventService';
import ToastrService from '../services/toastrService';
import { push } from 'connected-react-router';
import * as types from './actionTypes';
import * as actionUtils from '../utils/actionUtils';
import { normaliseNote, normaliseEvent } from '../utils/normaliseUtils';
import { deleteArrayItem } from '../reducers/reducerUtils';

export function addEvent(artistID, event) {
    return function(dispatch) {
        const toastrService = new ToastrService();
        return EventService.createEvent(artistID, event).then(event => {
            normaliseEvent(event);
            dispatch({ type: types.EVENT_CREATE_SUCCESS, payload: { artistID, event } });
            if(window.location.pathname !== '/schedule') {
                dispatch(push('/schedule'));
            }
        }).catch(error => {
            actionUtils.handleError(error);
        });
    };
}

export function editEvent(artistID, event) {
    return function(dispatch) {
        const toastrService = new ToastrService();
        return EventService.editEvent(artistID, event).then(event => {    
            normaliseEvent(event);       
            dispatch({ type: types.EVENT_UPDATE_SUCCESS, payload: { artistID, event } });
            toastrService.showSuccess('', 'Event has been updated!');
        }).catch(error => {
            actionUtils.handleError(error);
        });
    };
}

export function deleteEvent(artistID, eventID) {
    return function(dispatch) {
        const toastrService = new ToastrService();
        return EventService.deleteEvent(artistID, eventID).then(_ => {
            dispatch({ type: types.EVENT_DELETE_SUCCESS, payload: { artistID, eventID } });
            toastrService.showSuccess('', 'Event has been deleted!');
        }).catch(error => {
            actionUtils.handleError(error);
        });
    };
}

export function linkEventResource(artistID, eventID, resourceID) {
    return function(dispatch, getState) {
        const toastrService = new ToastrService();
        return EventService.linkEventResource(artistID, eventID, resourceID).then(event => {
            normaliseEvent(event);
            dispatch({ type: types.EVENT_UPDATE_SUCCESS, payload: { artistID, event } });
            const state = getState();
            const resource = state.account.user.Artists.find(a => a.ID === artistID).Resources.find(r => r.ID === resourceID);
            toastrService.showSuccess('', `Item '${resource.Name}' has been linked to event '${event.Name}'`);
            return event;
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function deleteEventResourceLink(artistID, eventID, resourceID, showToast = true) {
    return function(dispatch, getState) {
        const toastrService = new ToastrService();
        return EventService.deleteEventResourceLink(artistID, eventID, resourceID).then(_ => {
            //we get a nocontent back so manually remove the link
            const state = getState();
            const event = {...state.account.user.activeProfile.Events.find(e => e.ID === eventID)};
            event.ResourceIDs = deleteArrayItem(event.ResourceIDs, r => r === resourceID);
            dispatch({ type: types.EVENT_UPDATE_SUCCESS, payload: { artistID, event } });
            if(showToast) {
                toastrService.showSuccess('', 'The link has been deleted successfully');
            }
            return event;
        }).catch(error => {
            actionUtils.handleError(error);
            return error;
        });
    };
}

export function setEventAttendance(artistID, eventID, attendance) {
    return function(dispatch, getState) {
        const toastrService = new ToastrService();
        return EventService.setEventAttendance(artistID, eventID, attendance).then(attendance => {
            dispatch({ type: types.EVENT_ATTENDANCE_UPDATE_SUCCESS, payload: { artistID, eventID, attendance } });
            const state = getState();
            const isBand = state.account.user.Artists.find(a => a.ID === artistID).ArtistType === 'Band';
            const status = attendance.Status;
            if (status === 'Attending')	{
				toastrService.showSuccess('', `Thanks for confirming your attendance${(isBand ? ', I\'ll let the others know' : '')}!`);
            }
            else if (status === 'NotAttending') {
                toastrService.showSuccess('', `Ahh...shame you can't make it${(isBand ? ', I\'ll let the others know' : '')}!`);
            }
            else {
                toastrService.showSuccess('', 'So I guess you\'re still deciding...');
            }
            return attendance;
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function addEventNote(artistID, eventID, note) {
    return function(dispatch) {
        return EventService.createEventNote(artistID, eventID, note).then(note => {
            normaliseNote(note);
            dispatch({ type: types.EVENT_NOTE_CREATE_SUCCESS, payload: { artistID, eventID, note } });
            return note;
        }).catch(error => {
            actionUtils.handleError(error);
            throw error;
        });
    };
}

export function deleteEventNote(artistID, eventID, noteID) {
    return function(dispatch) {
        return EventService.deleteEventNote(artistID, eventID, noteID).then(_ => {
            dispatch({ type: types.EVENT_NOTE_DELETE_SUCCESS, payload: { artistID, eventID, noteID } });
            const toastrService = new ToastrService();
            toastrService.showSuccess('', 'The note has been deleted!');
        }).catch(error => {
            actionUtils.handleError(error);
        });
    };
}