import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isAuthenticated } from '../../utils/userUtils';

const PrivateRoute = (props) => {            
    const { component: Component, authenticated, allowRoles, role, location, ...rest } = props;
    return <Route
        location={location}
        {...rest}
        render={props =>
            authenticated === true ? 
                allowRoles && allowRoles.length && allowRoles.find(r => r === role) == null ? //we're restricting the roles and the user doesn't have the required role, redirect to the home page
                    <Redirect to="/" />
                    : 
                    <Component {...props} /> 
                :
                //also pass in the page we were trying to get to as a redirect state?
                <Redirect to={{
                    pathname: '/login',
                    state: { from: location }
                    }} />
            }
    />;
}; 

PrivateRoute.propTypes = {
    authenticated: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    authenticated: isAuthenticated(state.account.user),
    role: state.account.user ? state.account.user.Role : null
});

export default connect(mapStateToProps)(PrivateRoute);