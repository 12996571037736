import React, { useLayoutEffect, useRef } from 'react';

function AnimateIn({ className, children }) {
  let control = useRef(null);
  useLayoutEffect(
    () => {
      const animObjects = window.$(control.current);
      let currobj = 0;        
      if (animObjects.length !== 0) {          
          animObjects.each(function(){
          const obj = window.$(this);            
          setTimeout(function() {
            obj.addClass('in'); 
            setTimeout(function() {
              obj.addClass('stop');
            }, 300)              
          }, 40 * currobj);
          currobj += 1;
        });
      }  
    },
    []
  );
  
  return (
      <div className={`${className} animatedin`} ref={control}>
          {children}
      </div>
  )
}

export default AnimateIn;