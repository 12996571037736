import urls from "../constants/urls";

export function isExternalUrl(url) {
    return ((url.indexOf(':') > -1 || url.indexOf('//') > -1 ) && checkDomain(window.location.href) !== checkDomain(url));
}

function checkDomain(url) {
    if (url.indexOf('//') === 0) { 
        url = window.location.protocol + url;
    }
    return url.toLowerCase().replace(/([a-z])?:\/\//,'$1').split('/')[0];
}

export function getReferralSource(referrer = document.referrer) {
    //check we're not on our site or on the marketing site
    if(referrer && referrer.indexOf(window.location.origin) !== 0 && referrer.indexOf(urls.MARKETING_URLS[window.location.origin]) !== 0) {
        if (referrer.search('https?://(.*)google.([^/?]*)') === 0) {
            return 'Google';
        } 
        else if (referrer.search('https?://(.*)bing.([^/?]*)') === 0) {
            return 'Bing';
        } 
        else if (referrer.search('https?://(.*)yahoo.([^/?]*)') === 0) {
            return 'Yahoo';
        } 
        else if (referrer.search('https?://(.*)facebook.([^/?]*)') === 0) {
            return 'Facebook';
        } 
        else if (referrer.search('https?://(.*)twitter.([^/?]*)') === 0) {
            return 'Twitter';
        } 
        else {
            return referrer;
        }
    }
    else {
        return '';
    }
}

export const saveReferrer = () => {
    //check if we have a referrer saved in the cookie so we can capture the original referrer (from the marketing site), else get the current referrer
    let referrerCookie = document.cookie
        .split('; ')
        .find(row => row.startsWith('referrer'));
    let referrer = referrerCookie ? referrerCookie.split('=')[1] : null;
    if(referrer) {
        referrer = getReferralSource(referrer);
    }
    if(!referrer) {
        //there's nothing in the cookie, check if we were currently referred
        const currentReferralSource = getReferralSource();
        //if we were currently referred, use that, else check if we were previously referred and use that
        referrer = currentReferralSource || localStorage.getItem('appReferrer');
    }
    if(referrer) {
        localStorage.setItem('appReferrer', referrer);
    }
}

export const getReferrer = () => {
    return localStorage.getItem('appReferrer');
}

export const clearReferrer = () => {
    localStorage.removeItem('appReferrer');
    document.cookie = 'referrer=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

export const getSocialPlatformUrl = (socialProfile, platform) => {
    if(platform === 'Facebook') {
        return `https://www.facebook.com/${socialProfile.Username || socialProfile.ID}`;
    }
    else if(platform === 'Twitter') {
        return `https://www.twitter.com/${socialProfile.Username}`;
    }
    else if(platform === 'Instagram') {
        return `https://www.instagram.com/${socialProfile.Username}`;
    }
    else if(platform === 'YouTube') {
        return `https://www.youtube.com/channel/${socialProfile.ID}`;
    }
    else if(platform === 'SoundCloud') {
        return `https://www.soundcloud.com/${socialProfile.Username}`;
    }
    else if(platform === 'LoudUp') {
        return `https://www.loudup.com/${socialProfile.Username}`;
    }
    return null;
}