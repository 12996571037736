import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';

function KeepTasksCheckbox() {
    const [keepTasks, setKeepTasks] = useState(false);

    useEffect(() => {
        swal.setActionValue({ confirm: keepTasks});
    }, [keepTasks]);
    
    return <div className="custom-control custom-checkbox mb-3">
        <input type="checkbox" className="custom-control-input" id="chkKeepTasks" onClick={(e) => {
            setKeepTasks(e.target.checked);
        } }  />
        <label className="custom-control-label" htmlFor="chkKeepTasks" style={{color: 'rgba(0,0,0,.64)', marginLeft: '5px', verticalAlign: 'middle'}}>Keep Tasks</label>
    </div>
}

export default KeepTasksCheckbox;