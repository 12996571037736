import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as accountActions from '../../actions/accountActions';
import * as currencyActions from '../../actions/currencyActions';
import * as greetingActions from '../../actions/greetingActions';
import '../../styles/style-ui.scss';
import Header from '../common/Header';
import * as taskUtils from '../../utils/taskUtils';
import * as artistUtils from '../../utils/artistUtils';
import * as financeUtils from '../../utils/financeUtils';
import * as goalUtils from '../../utils/goalUtils';
import { goalTypes } from '../../constants/types';
import Footer from '../common/Footer';
import EmptyView from '../common/EmptyView';
import { withModalContext } from '../../services/modalService';
import EventModal from '../events/EventModal';
import TaskModal from '../tasks/TaskModal';
import GoalModal from '../goals/GoalModal';
import ArtistNameHeader from '../common/ArtistNameHeader';
import AnalyticsService from '../../services/analyticsService';
import { noPendingTasksMessages, noUpcomingEventsMessages, welcomeGreetings } from '../../constants/appMessages';
import _ from 'lodash';

function HomePage(props) {   
    const [loadingQuote, setLoadingQuote] = useState(false);
    const [loadingQuoteHasErrored, setLoadingQuoteHasErrored] = useState(false);
    const [quote, setQuote] = useState();
    const greetingMessageTimer = useRef();
    const noPendingTasksMessage = useRef(_.sample(noPendingTasksMessages)).current;
    const noUpcomingEventsMessage = useRef(_.sample(noUpcomingEventsMessages)).current;

    useEffect(() => {
        if(!props.currencies.length) {
            props.actions.getCurrencies();
        }
        loadQuote();
        debugger;
        props.actions.getGreetingMessage();
        greetingMessageTimer.current = window.setInterval(props.actions.getGreetingMessage, 60000 * 15)
        return () => {
            window.clearInterval(greetingMessageTimer.current);
        }
    }, []);

    //initialise tasks
    const activeProfile = props.user.activeProfile;
    const isTaskMine = (task) => task.UserIDs.length === 0 || task.UserIDs.includes(props.user.ID);
    const pendingTasks = activeProfile.Tasks.filter(t => t.Status === 'Pending' && isTaskMine(t));
    const taskCount = 3;
    const displayTasks = pendingTasks.sort((a, b) => a.DueDate - b.DueDate).slice(0, taskCount);
    let tasksMore;
    let haveMoreTasks = false;
    if (pendingTasks.length > taskCount) {
        tasksMore = `${pendingTasks.length - taskCount} MORE`;
        haveMoreTasks = true;
    }
    //initialise events
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const isEventDeclined = (event) => event.Attendances.find(a => a.UserID === props.user.ID && a.Status === 'NotAttending') != null;
    const upcomingEvents = activeProfile.Events.filter(e => e.StartDate >= today && e.Status !== 'Cancelled' && !isEventDeclined(e));
    const eventCount = 3;
    const displayEvents = upcomingEvents.sort((a, b) => a.StartDate - b.StartDate).slice(0, eventCount);
    let eventsMore;
    let haveMoreEvents = false;
    if (upcomingEvents.length > eventCount) {
        eventsMore = `${upcomingEvents.length - eventCount} MORE`;
        haveMoreEvents = true;
    }
    const getEventClassAndImage = (event) => {
        let className = '';
        let imageSrc = '';
        if(event.Type === 'Rehearsal') {
            className = 'mikeicon-type-rehersal';
            imageSrc = require('../../images/icon-type-rehearsal.png');
        }
        else if(event.Type === 'Meeting') {
            className = 'mikeicon-type-meeting';
            imageSrc = require('../../images/icon-type-meeting.png');
        }
        else if(event.Type === 'Gig') {
            className = 'mikeicon-type-gig';
            imageSrc = require('../../images/icon-type-gig.png');
        }
        else {
            className = 'mikeicon-type-other';
            imageSrc = require('../../images/icon-type-other.png');
        }
        return { className, imageSrc };
    }

    //initialise balance
    const currency = props.currencies.find(c => c.ID === activeProfile.DefaultCurrencyID);
    const currentBalance = financeUtils.formatBalance(activeProfile, today);

    //initialise goals
    const goals = activeProfile.Goals.map(g => {
        const goal = {...g};
        goalUtils.addGoalCalculatedFields(goal);
        return goal;
    })
    const outstandingGoals = goals.filter(g => g.Percentage < 100);
	const goalCount = 3;
	const displayGoals = outstandingGoals.sort((a, b) => a.TargetDate - b.TargetDate).slice(0, goalCount);
    let goalsMore;
    let haveMoreGoals = false;
    if (outstandingGoals.length > goalCount) {
        goalsMore = `${outstandingGoals.length - goalCount} MORE`;
        haveMoreGoals = true;
    }

    const createNewTask = (event) => {
        event.preventDefault();
        artistUtils.checkSubscription(activeProfile, () => {
            const taskModal = <TaskModal />;
            props.showModal(() => taskModal, { isOpen: true });
        }, 'create a task');
    }

    const viewTask = (event, task) => {
        event.preventDefault();
        const taskModal = <TaskModal viewMode={true} taskID={task.ID} />
        props.showModal(() => taskModal, { isOpen: true })
    }

    const createNewEvent = (event) => {
        event.preventDefault();
        artistUtils.checkSubscription(activeProfile, () => {
            const eventModal = <EventModal />
            props.showModal(() => eventModal, { isOpen: true })
        }, 'create an event');
    }

    const createNewGoal = (event) => {
        artistUtils.checkSubscription(activeProfile, () => {
            const goalModal = <GoalModal />
            props.showModal(() => goalModal, { isOpen: true })
        }, 'create a goal');
    }

    const viewEvent = (e, event) => {
        e.preventDefault();
        const eventModal = <EventModal viewMode={true} eventID={event.ID} />
        props.showModal(() => eventModal, { isOpen: true })
    }

    const loadQuote = () => {
        setLoadingQuote(true);
        setLoadingQuoteHasErrored(false);
        props.actions.getQuote()
            .then(quote => {
                setQuote(quote);
                setLoadingQuote(false)
                AnalyticsService.logEvent('Quote Viewed');
            })
            .catch(error => {
                setLoadingQuote(false);
                setLoadingQuoteHasErrored(true);
            });
    }

    const getGreeting = () => {        
        const timeOfDay = props.greeting.timeOfDay;
        const firstName = props.user.FirstName;       
        let greeting = `${welcomeGreetings[timeOfDay]} ${firstName}!`;
        return greeting;
    }
           
    return (        
        <div className="home">
            <Header />
            <main className="content">
                <div className="container">
                    <ArtistNameHeader 
                        user={props.user} 
                        showInspiration={true} 
                        loadingQuote={loadingQuote} 
                        haveQuoteError={loadingQuoteHasErrored}
                        quote={quote}
                        greeting={getGreeting()}
                        welcomeText={props.greeting.text || ''}
                        reloadInspiration={loadQuote} />
                    <p className="home-text">Here's what you've got coming up:</p>
                    <div className="content-body">
                        {/*<div className="stats">
                            <Link to='/tasks' className="stat stat-link">
                                <p className="stat-val">{pendingTasks.length}</p>
                                <p className="stat-desc">Pending <br/>{pendingTasks.length === 1 ? 'Task' : 'Tasks'}</p>
                            </Link>
                            <a href="#" className="stat stat-link">
                                <p className="stat-val">{upcomingEvents.length}</p>
                                <p className="stat-desc">Upcoming <br/>{upcomingEvents.length === 1 ? 'Event' : 'Events'}</p>
                            </a>
                            <a href="#" className="stat stat-link">
                                <p className="stat-val">{currency ? currency.Symbol : ''}{currentBalance}</p>
                                <p className="stat-desc">Current <br/>Balance</p>
                            </a>
                        </div>*/}
                        <div className="list">
                            <div className="list-head">
                                <h3 className="m-0">
                                    <i className="mikeicon-tasks"></i>
                                    &nbsp;Tasks
                                </h3>
                                <div className="list-head-actions">
                                    <a href="#" className="btn-text btn-new mr-3" onClick={createNewTask}>
                                        <i className="mikeicon-pluscircle"></i>
                                        New Task
                                    </a>
                                    <p className="list-head-more mb-0">
                                        {
                                            haveMoreTasks ?
                                            <React.Fragment>{tasksMore}<span className="distancer-h">&middot;</span></React.Fragment>
                                            : null
                                        }
                                        <Link to="/tasks"><span className="text">See All</span><i className="mikeicon-caret-r"></i></Link>
                                    </p>
                                </div>
                            </div>

                            <div className="list-body">
                                {
                                    displayTasks.length ?
                                        displayTasks.map(task => {
                                            const {dueDateText, daysDiff} = taskUtils.getDueDateText(task);
                                            const overdue = daysDiff < 0;
                                            let anchorClass = '';
                                            if(overdue) {
                                                anchorClass += 'list-row-overdue ';
                                            }
                                            else if(daysDiff === 0) {
                                                anchorClass += 'list-row-due-today ';
                                            }
                                            return <a key={task.ID} href="#" className={`list-row list-row-link ${anchorClass}`} onClick={(event) => viewTask(event, task)}>
                                                <div className="list-col list-col-150">
                                                    <span className="badge badge-status">{dueDateText}</span>
                                                </div>
                                                <div className="list-col">
                                                    <p>{task.Name}</p>
                                                </div>
                                            </a>
                                        })
                                    :
                                    <EmptyView text={noPendingTasksMessage} buttonText="Create task" onButtonClick={createNewTask} />
                                }                                
                            </div>
                        </div>

                        <div className="list">
                            <div className="list-head">
                                <h3 className="m-0">
                                    <i className="mikeicon-schedule"></i>
                                    &nbsp;Schedule
                                </h3>
                                <div className="list-head-actions">
                                    <a href="#" className="btn-text btn-new mr-3" onClick={createNewEvent}>
                                        <i className="mikeicon-pluscircle"></i>
                                        New Event
                                    </a>
                                    <p className="list-head-more mb-0">
                                        {
                                            haveMoreEvents ?
                                            <React.Fragment>{eventsMore}<span className="distancer-h">&middot;</span></React.Fragment>
                                            : null
                                        }
                                        <Link to='/Schedule'><span className="text">See All</span><i className="mikeicon-caret-r"></i></Link>
                                    </p>
                                </div>
                            </div>
                            <div className="list-body">
                                {
                                    displayEvents.length ?
                                        displayEvents.map(event => {
                                            const startDate = new Date(event.StartDate);
                                            const { className, imageSrc } = getEventClassAndImage(event);
                                            return <a key={event.ID} href="#" className="list-row list-row-center list-row-link" onClick={(e) => viewEvent(e, event)}>
                                                <div className="list-col list-col-150">
                                                    <span className="date">
                                                        <p className="date-day">{startDate.getDate().toString().padStart(2, '0')}</p>
                                                        <p className="date-month">{startDate.toLocaleString('default', { month: 'short' }).toUpperCase()}<br/>{startDate.getFullYear()}</p>
                                                    </span>
                                                </div>
                                                <div className="list-col">
                                                    <p>{`${event.Name} @ ${event.Location}`}</p>
                                                </div>
                                                {
                                                    props.user.activeProfile.ArtistType === 'Band' ?
                                                    <div className="list-col list-col-200">
                                                        <p className="font-weight-bold">{event.Attendances.filter(a => a.Status === 'Attending').map(a => artistUtils.getMemberName(props.user, activeProfile.Members, a.UserID)).join(', ')}</p>
                                                    </div>
                                                    : null
                                                }              
                                                <div className="list-col list-col-100 list-col-center list-col-type">
                                                    <div className={className}>
                                                        <img src={imageSrc} alt="" />
                                                    </div> 
                                                </div>
                                            </a>
                                        })
                                        :
                                        <EmptyView text={noUpcomingEventsMessage} buttonText="Create event" onButtonClick={createNewEvent} />
                                    }                                
                            </div>
                        </div>

                        {/*<div className="list">
                            <div className="list-head">
                                <h3 className="m-0">
                                    <i className="mikeicon-goals"></i>
                                    &nbsp;Goals
                                </h3>
                                <div className="list-head-actions">
                                    <a href="#" className="btn-text btn-new mr-3" onClick={createNewGoal}>
                                        <i className="mikeicon-pluscircle"></i>
                                        New Goal
                                    </a>
                                    <p className="list-head-more mb-0">
                                        {
                                            haveMoreGoals ?
                                            <React.Fragment>{goalsMore}<span className="distancer-h">&middot;</span></React.Fragment>
                                            : null
                                        }
                                        <a href="#"><span className="text">See All</span><i className="mikeicon-caret-r"></i></a>
                                    </p>
                                </div>
                            </div>

                            <div className="list-body">
                                {
                                    displayGoals.length ?
                                        displayGoals.map(goal => 
                                            <div key={goal.ID} className="list-row">
                                                <div className="list-col">
                                                    <p>{`Get ${(goal.Initial !== 0 ? `from ${goal.Initial} ` : '')}to ${goal.Type === 'Profit' && currency ? currency.Symbol : ''}${goal.Target} ${(goalTypes[goal.Type] ? goalTypes[goal.Type].DisplayText : null) || ''}`}</p>
                                                    <div className="progress mt-2">
                                                        <div className={goal.OnTrack ? 'progress-bar--green': 'progress-bar'} role="progressbar" style={{width: goal.Percentage + '%'}} aria-valuenow={goal.Percentage} aria-valuemin="0" aria-valuemax="100">{goal.Current}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        :
                                        <EmptyView text="You don't have any active goals" buttonText="Create goal" onButtonClick={createNewGoal} />
    
                                }                                
                            </div>
                        </div>*/}
                        
                        <div className="more-pages">
                            <p className="home-text">Where should I take you next?</p>
                            <div className="row">

                                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                    <Link to="/projects" className="btn btn-primary">View my Projects</Link>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                    <Link to="/goals" className="btn btn-primary">View my Goals</Link>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                    <Link to="/schedule" className="btn btn-primary">Manage my Schedule</Link>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                    <Link to="/finances" className="btn btn-primary">Manage my Finances</Link>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                    <Link to="/social" className="btn btn-primary">Monitor my Social Profiles</Link>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                    <Link to="/vault" className="btn btn-primary">Take me to The Vault</Link>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}

HomePage.propTypes = {
    //myProp: PropTypes.string.isRequired
};


function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user,
        currencies: state.currencies,
        greeting: state.greeting
    };
}


function mapDispatchToProps(dispatch) {
    return {
        //account actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, accountActions, currencyActions, greetingActions), dispatch)
    };
}

export default withModalContext(connect(mapStateToProps, mapDispatchToProps)(HomePage));