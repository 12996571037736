import BaseService from '../services/baseService';
import * as types from '../actions/actionTypes';

export default function serviceMiddleware() {
    return ({ dispatch, getState }) => next => action => {
        if (action.type === types.LOGIN_SUCCESS || action.type === types.TOKEN_REFRESHED) {
            //const state = getState();
            BaseService.setToken(action.payload.access_token);
            if(action.payload.refresh_token) {
                //if it's a temporary token for a social login, we won't have a refresh token
                dispatch({ type: types.SCHEDULE_TOKEN_REFRESH, payload: { tokenExpiry: action.payload[".expires"], refreshToken: action.payload.refresh_token} });
            }
        }
        else if(action.type === types.LOGOUT) {
            BaseService.clearToken();
            BaseService.clearSessionID();
            dispatch({ type: types.CANCEL_TOKEN_REFRESH });
        }      
        return next(action);
    };
  }
