import BaseService from './baseService';

class CurrencyService {
    static getCurrencies() {
        return BaseService.getAndReturn('/Currencies', BaseService.returnAssignedArray, 'Unable to get currencies', false);
    }

    static getCurrency() {
        return BaseService.getAndReturn('/Currencies/Me', BaseService.returnAssignedObject, 'Unable to get currency', false);
    }
}
export default CurrencyService;