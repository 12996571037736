import React, { useState, useEffect, useLayoutEffect } from 'react';
import swal from 'sweetalert';
import 'select2/dist/js/select2';
import 'select2/dist/css/select2.min.css';

function SweetAlertSelectExternalAccount({accounts, artistType, ...rest}) {
    const [selectedAccountID, setSelectedAccountID] = useState();

    const selectAccount = (accountID) => {
        setSelectedAccountID(accountID);
        const account = accounts.find(a => a.ID === accountID);
        swal.setActionValue({ confirm: account });
    }

    return (
        <form className="form form-signup form-tightfields">
            <select className="form-control" value={selectedAccountID} onChange={e => selectAccount(e.target.value)}>
                <option value="" disabled selected>Select Facebook page</option>
                {
                    accounts.map(account =>
                        <option key={account.ID} value={account.ID}>{account.Name}</option>
                    )
                }
            </select>
            {
                artistType === 'Band' ?
                    <span className="text-muted">The account stats will be available to all members of this band</span>
                    : null
            }            
        </form>                                          
    );
}

SweetAlertSelectExternalAccount.propTypes = {
};

export default SweetAlertSelectExternalAccount;