import * as reducerUtils from '../reducerUtils';
import * as types from '../../actions/actionTypes';

export default function financeReduce(state, action) {
    const artistPredicate = (a, artistID) => a.ID === artistID;
    const financeIncomePredicate = (i, incomeID) => i.ID === incomeID;
    const financeExpensePredicate = (e, expenseID) => e.ID === expenseID;      
    const financeBalancePredicate = (b, balanceID) => b.ID === balanceID;
    const financeBudgetIncomePredicate = (i, incomeID) => i.ID === incomeID;
    const financeBudgetExpensePredicate = (e, expenseID) => e.ID === expenseID;
    
    switch(action.type) {            
        
        case types.INCOME_CREATE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const income = action.payload.income;
            const user = {...state.user};          
            user.Artists = reducerUtils.addNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Finances.Income', income);           
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }      
        case types.INCOME_UPDATE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const income = action.payload.income;
            const user = {...state.user};
            user.Artists = reducerUtils.updateNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Finances.Income', (i) => financeIncomePredicate(i, income.ID), income);    
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }
        case types.INCOME_DELETE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const incomeID = action.payload.incomeID;
            const user = {...state.user};
            user.Artists = reducerUtils.deleteNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Finances.Income', (i) => financeIncomePredicate(i, incomeID));
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }       
        case types.EXPENSE_CREATE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const expense = action.payload.expense;
            const user = {...state.user};            
            user.Artists = reducerUtils.addNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Finances.Expenses', expense);           
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }      
        case types.EXPENSE_UPDATE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const expense = action.payload.expense;
            const user = {...state.user};
            user.Artists = reducerUtils.updateNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Finances.Expenses', (e) => financeExpensePredicate(e, expense.ID), expense);    
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }
        case types.EXPENSE_DELETE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const expenseID = action.payload.expenseID;
            const user = {...state.user};
            user.Artists = reducerUtils.deleteNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Finances.Expenses', (e) => financeExpensePredicate(e, expenseID));
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        } 
        case types.BALANCE_CREATE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const balance = action.payload.balance;
            const user = {...state.user};  
            user.Artists = reducerUtils.addNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Finances.Balances', balance);           
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }      
        case types.BALANCE_UPDATE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const balance = action.payload.balance;
            const user = {...state.user};
            user.Artists = reducerUtils.updateNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Finances.Balances', (e) => financeBalancePredicate(e, balance.ID), balance);    
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }
        case types.BALANCE_DELETE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const balanceID = action.payload.balanceID;
            const user = {...state.user};
            user.Artists = reducerUtils.deleteNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Finances.Balances', (e) => financeBalancePredicate(e, balanceID));
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }   
        case types.BUDGET_INCOME_CREATE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const budgetIncome = action.payload.budgetIncome;
            const user = {...state.user};            
            user.Artists = reducerUtils.addNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Finances.Budget.Income', budgetIncome);           
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }      
        case types.BUDGET_INCOME_UPDATE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const budgetIncome = action.payload.budgetIncome;
            const user = {...state.user};
            user.Artists = reducerUtils.updateNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Finances.Budget.Income', (i) => financeBudgetIncomePredicate(i, budgetIncome.ID), budgetIncome);    
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }
        case types.BUDGET_INCOME_DELETE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const budgetIncomeID = action.payload.budgetIncomeID;
            const user = {...state.user};
            user.Artists = reducerUtils.deleteNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Finances.Budget.Income', (i) => financeBudgetIncomePredicate(i, budgetIncomeID));
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }       
        case types.BUDGET_EXPENSE_CREATE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const budgetExpense = action.payload.budgetExpense;
            const user = {...state.user};            
            user.Artists = reducerUtils.addNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Finances.Budget.Expenses', budgetExpense);           
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }      
        case types.BUDGET_EXPENSE_UPDATE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const budgetExpense = action.payload.budgetExpense;
            const user = {...state.user};
            user.Artists = reducerUtils.updateNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Finances.Budget.Expenses', (e) => financeBudgetExpensePredicate(e, budgetExpense.ID), budgetExpense);    
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }
        case types.BUDGET_EXPENSE_DELETE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const budgetExpenseID = action.payload.budgetExpenseID;
            const user = {...state.user};
            user.Artists = reducerUtils.deleteNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Finances.Budget.Expenses', (e) => financeBudgetExpensePredicate(e, budgetExpenseID));
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }               
        case types.DELETE_RECEIPT_SUCCESS: //do nothing for now - when we delete the receipt, we then need to make another call to update the item to set the receipt ID to null
        case types.GET_RECEIPT_SUCCESS://need to decide if we want to persist this due to it's size
        case types.UPLOAD_RECEIPT_SUCCESS:

        default:
            return state;
    }

    
}