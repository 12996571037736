import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as financeActions from '../../actions/financeActions';
import * as currencyActions from '../../actions/currencyActions';
import '../../styles/style-ui.scss';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { withModalContext } from '../../services/modalService';
import FinanceItemModal from './FinanceItemModal';
import FinancesOverview from './FinancesOverview';
import FinancesBudget from './FinancesBudget';
import ExportModal from './ExportModal';
import BudgetModal from './BudgetModal';
import { checkSubscription } from '../../utils/artistUtils';
import PageLoading from '../common/PageLoading';
import ArtistNameHeader from '../common/ArtistNameHeader';
import { financePageHeaders } from '../../constants/appMessages';
import _ from 'lodash';

function FinancesPage(props) {   

    const [selectedTab, setSelectedTab] = useState('Overview');
    //we have to store these here as they're needed for the export
    const [budgetSelectedTab, setBudgetSelectedTab] = useState('Income');
    const [budgetFilter, setBudgetFilter] = useState('Current Month');
    const [showBudgetModal, setShowBudgetModal] = useState(false);
    const [budgetItemEditing, setBudgetItemEditing] = useState();
    const headerText = useRef(_.sample(financePageHeaders)).current;

    const containerRef = useRef();
    useEffect(() => {
        if(!props.currencies.length) {
            props.actions.getCurrencies();
        }
    }, []);

    const updateSelectedTab = (tab) => {
        setSelectedTab(tab);
    }

    //initialise items
    const activeProfile = props.user.activeProfile;        
    const currency = props.currencies.find(c => c.ID === activeProfile.DefaultCurrencyID);

    const getActiveClass = (tabName) => {
        if(selectedTab === tabName) {
            return 'active';
        }
        return '';
    }
        
    const createNewItem = (event) => {
        event.preventDefault();
        event.stopPropagation();
        checkSubscription(activeProfile, () => {
            const financeItemModal = <FinanceItemModal />
            props.showModal(() => financeItemModal, { isOpen: true })
        }, 'create a new item');        
    }

    const requestExport = (event) => {
        event.preventDefault();
        const exportModal = <ExportModal user={props.user} actions={props.actions} type={selectedTab} itemType={selectedTab === 'Budget' ? budgetSelectedTab : null} budgetPeriod={budgetFilter ? budgetFilter.replace(' ', '') : null} />
        props.showModal(() => exportModal, { isOpen: true })
    }

    const showEditBudgetModal = (item) => {
        checkSubscription(activeProfile, () => {
            setBudgetItemEditing(item);
            setShowBudgetModal(true);
        }, 'edit your budget');        
    }
        
    const closeBudgetModal = () => {
        setBudgetItemEditing(null);
        setShowBudgetModal(false);
    }

    const createNewBudgetItem = (event) => {
        event.preventDefault();
        checkSubscription(activeProfile, () => setShowBudgetModal(true), 'create a new budget item');
    }

    return (   
        <div className="home">
            <Header />
            <main className="content">               
                <div className="container" ref={containerRef}>
                    <ArtistNameHeader user={props.user} greeting={`Hey ${props.user.FirstName}, ${headerText}`} />
                    {
                        currency ?
                            <div className="content-body">
                                <div className="tabs-header">
                                    <div className="actions float-right">
                                        <div className="finances__header-tabs">
                                            <div className="finances__header-tab">
                                                <div className="finances__header-tab-content">
                                                    <a href="#" className="btn-text btn-new" onClick={selectedTab === 'Overview' ? createNewItem : createNewBudgetItem}>
                                                        <i className="mikeicon-pluscircle"></i>
                                                        New item
                                                    </a>
                                                    <div className="dropdown ml-3">
                                                        <div className="export-budget" onClick={requestExport}>     
                                                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 496.486 496.486" style={{enableBackground: 'new 0 0 496.486 496.486'}} xmlSpace="preserve">
                                                                <g>
                                                                    <g>
                                                                        <path d="M349.12,249.785c-1.029-0.99-2.124-1.91-3.276-2.754c-13.03-8.6-30.296-6.934-41.44,4l-2.08,2.08
                                                                        c-4.382,4.398-8.101,9.411-11.04,14.88l-11.04,20.48V34.232c0.634-16.247-10.62-30.552-26.56-33.76
                                                                        c-17.416-3.006-33.971,8.675-36.977,26.09c-0.337,1.951-0.492,3.93-0.463,5.91v256l-16-28.64l-10.08-10.08
                                                                        c-12.492-12.501-32.753-12.509-45.255-0.017c-0.621,0.621-1.217,1.267-1.785,1.937c-10.731,13.47-9.413,32.903,3.04,44.8
                                                                        l78.56,78.56c12.49,12.504,32.751,12.515,45.255,0.025c0.008-0.008,0.017-0.017,0.025-0.025l80-80
                                                                        C362.254,282.293,361.858,262.036,349.12,249.785z"/>
                                                                    </g>
                                                                </g>
                                                                <g>
                                                                    <g>
                                                                        <path d="M488.244,416.472v-192c0,0,0-1.28,0-1.92c-3.04-48-30.88-75.84-80-77.92h-78.72c-17.673,0-32,14.327-32,32
                                                                        c0,17.673,14.327,32,32,32h77.28c13.6,0,16,1.92,17.44,17.12v189.28c0,12.16-3.04,16-17.12,17.44H89.364
                                                                        c-14.88-1.12-16-4.64-17.12-16v-190.56c0-17.44,5.44-17.44,16-17.44h80c17.673,0,32-14.327,32-32s-14.327-32-32-32h-80
                                                                        c-48,0-77.92,30.08-80,80v193.28c-1.245,42.22,31.972,77.456,74.193,78.701c1.242,0.037,2.485,0.043,3.727,0.019h324.16
                                                                        c42.945,0.106,77.846-34.622,77.952-77.568C488.278,418.093,488.267,417.282,488.244,416.472z"/>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>                                
                                    </div>
                                    <ul className="nav nav-tabs nav-tabs-simple nav-tabs-tasks" role="tablist">
                                        <li className="nav-item fin-tab">
                                            <a className={`nav-link ${getActiveClass('Overview')}`} id="overview-tab" href="#" onClick={(e => updateSelectedTab('Overview'))} role="tab" aria-controls="overview" aria-selected={selectedTab === 'Overview'}>Overview</a>
                                        </li>
                                        <li className="nav-item fin-tab">
                                            <a className={`nav-link ${getActiveClass('Budget')}`} id="budget-tab" href="#" onClick={(e => updateSelectedTab('Budget'))} role="tab" aria-controls="budget" aria-selected={selectedTab === 'Budget'}>Budget</a>
                                        </li>
                                    </ul>
                                    {
                                        selectedTab === 'Overview' ?
                                            <FinancesOverview user={props.user} currency={currency} actions={props.actions} balanceRenderNode={containerRef} />
                                            :
                                            <FinancesBudget 
                                                user={props.user} 
                                                currency={currency} 
                                                actions={props.actions} 
                                                budgetSelectedTab={budgetSelectedTab} 
                                                setBudgetSelectedTab={setBudgetSelectedTab} 
                                                budgetFilter={budgetFilter} 
                                                setBudgetFilter={setBudgetFilter} 
                                                showBudgetModal={showEditBudgetModal} />
                                    }
                                </div>                           
                            </div>
                            :
                        <PageLoading />
                    }
                </div>                  
                {
                    showBudgetModal ? 
                        <BudgetModal 
                            user={props.user} 
                            currency={currency} 
                            actions={props.actions} 
                            budgetItemEditing={budgetItemEditing} 
                            budgetSelectedTab={budgetSelectedTab} 
                            budgetFilter={budgetFilter}
                            closeBudgetModal={closeBudgetModal} /> 
                        : null
                }
            </main>
            <Footer />
        </div>
            
    );
}

FinancesPage.propTypes = {
    //myProp: PropTypes.string.isRequired
};


function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user,
        currencies: state.currencies
    };
}


function mapDispatchToProps(dispatch) {
    return {
        //account actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, financeActions, currencyActions), dispatch)
    };
}

export default withModalContext(connect(mapStateToProps, mapDispatchToProps)(FinancesPage));