import React from 'react'
import { getShortDate } from '../../utils/dateUtils';

function NoteViewContent({item}) {
    return (
        <div className="note-view">
            <div className="note-view__header">
                <div className="note-view__title">
                    {item.Name}
                </div>                                    
                <div className="note-view__date">
                    {getShortDate(item.CreatedDate)}
                </div>
            </div>
            <div className="note-view__text">
                {item.Text?.split('\n').map(line => <div>{line}</div>)}
            </div>
        </div>
    );
}

export default NoteViewContent;