import React from 'react';
import PropTypes from 'prop-types';
import urls from '../../constants/urls';
import { withRouter } from 'react-router-dom';

const Header = (props) =>
{
    const onLogoClick = (event) => {
        event.preventDefault();
        if(['/signup', '/login'].includes(window.location.pathname.toLowerCase())) {
            window.location = urls.MARKETING_URLS[window.location.origin];
        }
        else {
            props.history.push('/');
        }
    }

    return(
        <div {...props}>
            <a href="#" onClick={onLogoClick}><img src={require('../../images/logo-mikeapp.svg')} alt="Mike Logo" /></a>
        </div>
    );
};

Header.propTypes = {
};

export default withRouter(Header);