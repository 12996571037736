import React from 'react';
import { useFormInput } from '../../utils/customHooks';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as artistActions from '../../actions/artistActions';
import { trim } from 'lodash';
import Header from '../account/Header';
import { Redirect } from 'react-router-dom';
import { getNext } from '../../utils/userUtils';

function ViewProfilePage({user, actions, history}) {
    const artistIDInput = useFormInput('');

    const loadArtist = (event) => {
        event.preventDefault();
        if(artistIDInput.value) {
            actions.viewArtistAdmin(trim(artistIDInput.value));
        }       
    }

    if(user.Role !== 'Admin') {
        return <Redirect to={getNext(user)} />
    }
    return (
        <main className="">
            <button className="close-page" onClick={() => history.push('/')} />
            <div className="container account-setting__content">
                <Header className="page__branding" />
                <form className="form form--lined form--login" onSubmit={loadArtist}>
                    <p className="form-text text-center text-muted mb-4">Enter the artist ID to load</p>                              
                    <div className="form-group">
                        <label>Artist ID</label>
                        <input className="form-control" type="text" required {...artistIDInput} placeholder="Artist ID" />
                    </div>                    
                    <div className="form-group form-group--buttons">
                        <button type="submit" className="btn btn-block btn-primary">LOAD ARTIST</button>
                    </div>                            
                </form>
            </div>
        </main>
    )
}

function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //actions will now be available under props.actions
        actions: bindActionCreators(Object.assign({}, artistActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewProfilePage);