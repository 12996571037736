/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import SidePromo from './SidePromo.js';
import Header from './Header.js';
import Footer from './Footer.js';
import * as accountActions from '../../actions/accountActions';
import '../../styles/style-signup.css';
import AnimateIn from '../common/AnimateIn.js';
import { useFormInput } from '../../utils/customHooks.js';

const ForgotPasswordPage = ({user, actions}) =>
{
    const emailInput = useFormInput('');
    const [isBusy, setIsBusy] = useState(false);

    const forgotPassword = (event) => {
        event.preventDefault();
        if(emailInput.value) {
            setIsBusy(true);
            actions.forgotPassword(emailInput.value)
                .then(_ => setIsBusy(false))
                .catch(error => setIsBusy(false));
        }        
    }
    return(
        user ?
            <Redirect to='/' />
            :           
            <React.Fragment>
                <main className="login">
                    <SidePromo />
                    <div id="loginContent" className="login__content">
                        <Header className="login__branding" />
                        <AnimateIn className="login__form">
                            <div className="login__body">                                                                      
                                <form className="form form--lined form--password" onSubmit={forgotPassword}>
                                    <h5 className="text-center">Forgot password?</h5>
                                    <p className="form-text text-center text-muted mb-4">No worries, it happens. Please enter your
                                        email below and we'll send you a reset password code.</p>
                                    <div className="form-group">
                                        <div className="form-prefix">
                                            <i className="mikeicon-email form__picto"></i>
                                            <input className="form-control" type="email" placeholder="Email" required {...emailInput} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-block btn--outline btn-transparent-white">Request a reset code</button>
                                    </div>
                
                                    <div className="form-group text-center mt-5">
                                        <p className="text-muted small">Remembered? <Link to="/login" className="btn-text btn-backtologin">Back</Link>
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </AnimateIn>
                        <Footer />
                    </div>
                </main>
                {
                    isBusy ?
                        <div className="modal-window__loader modal-window__loader-task modal-window__loader-sign-up active">
                            <img src={require('../../images/loader.svg')} alt="loading" />
                            Sending password reset code...
                        </div>
                        : null
                }    
            </React.Fragment>
    );
};

ForgotPasswordPage.propTypes = {
    //myProp: PropTypes.string.isRequired
};


function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user
    };
}


function mapDispatchToProps(dispatch) {
    return {
        //account actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, accountActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
