import * as reducerUtils from '../reducerUtils';
import * as types from '../../actions/actionTypes';

export default function resourceReduce(state, action) {
    const artistPredicate = (a, artistID) => a.ID === artistID;
    const resourcePredicate = (r, resourceID) => r.ID === resourceID;

    switch(action.type) {            
        
        case types.RESOURCE_CREATE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const resource = action.payload.resource;
            const user = {...state.user};            
            user.Artists = reducerUtils.addNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Resources', resource);           
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }      
        case types.RESOURCE_UPDATE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const resource = action.payload.resource;
            const user = {...state.user};
            user.Artists = reducerUtils.updateNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Resources', (r) => resourcePredicate(r, resource.ID), resource);    
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }
        case types.RESOURCE_DELETE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const resourceID = action.payload.resourceID;
            const user = {...state.user};
            user.Artists = reducerUtils.deleteNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Resources', (r) => resourcePredicate(r, resourceID));
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }           
        default:
            return state;
    }
}