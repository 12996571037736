import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as taskActions from '../../actions/taskActions';
import { useFormInput, useFormCheckInput } from '../../utils/customHooks';
import { getMemberName, checkSubscription } from '../../utils/artistUtils';
import ToastrService from '../../services/toastrService';
import moment from 'moment';
import 'select2/dist/js/select2';
import 'select2/dist/css/select2.min.css'
import { isValidDate, isBrowserDateTypeSupported } from '../../utils/dateUtils';
import { trim } from 'lodash';
import LinkedItems from '../common/LinkedItems';
import AnalyticsService from '../../services/analyticsService';

function TaskModal({taskID, user, actions, defaultProjectID, clone = false, viewMode = false}) {
    const activeProfile = user.activeProfile;
    const task = activeProfile.Tasks.find(t => t.ID === taskID);
    const [projectID, setProjectID] = useState(task ? task.ProjectID : defaultProjectID);
    const nameInput = useFormInput(task ? task.Name : '');
    const descriptionInput = useFormInput(task ? (task.Description || '') : '');
    const dueDateInput = useFormInput(task ? task.DueDate.toISOString().split("T")[0] : '');
    const highPriorityInput = useFormCheckInput(task ? task.HighPriority : false);
    const recurrence = task ? task.Recurrence : null;
    const [isViewMode, setIsViewMode] = useState(viewMode);
    const [savingTask, setSavingTask] = useState(false);
    const [loadingFile, setLoadingFile] = useState(false);
    const [loadingPhoto, setLoadingPhoto] = useState(false);
    const [loadingAudio, setLoadingAudio] = useState(false);
    const [deletingLink, setDeletingLink] = useState(false);

    const members = activeProfile.Members
        .map(member => ({ ID: member.ID, Name: getMemberName(user, activeProfile.Members, member.ID) }))
        .sort((a, b) => {
            if(a.Name === 'Me') {
                return -1;
            }
            else if(b.Name === 'Me') {
                return 1;
            }
            else {
                return 0;
            }
        });
    if(activeProfile.ArtistType === 'Band') {
        members.unshift({ ID: '', Name: 'All' });
    }

    const completedProjects = activeProfile.Projects.filter(p => {
        var projectTasks = activeProfile.Tasks.filter(t => t.ProjectID === p.ID);
        var completedTasksCount = projectTasks.filter(t => t.Status === 'Completed').length;
        return projectTasks.length && projectTasks.length === completedTasksCount;
    });
    const openProjects = activeProfile.Projects.filter(p1 => !completedProjects.find(p2 => p2.ID === p1.ID));

    const projectRef = useRef();

    const projectChanged = (event) => {
        const data = event.params.data;
        if(data) {            
            setProjectID(data.id);
        }
    }

    const [assign, setAssign] = useState(task && task.UserIDs && task.UserIDs.length ? task.UserIDs[0] : '');
    const assignRef = useRef();

    const assignChanged = (event) => {
        const data = event.params.data;
        if(data) {            
            //const selectedValue = data.selected ? this.getValue(data.id) : '';           
            setAssign(data.id);
        }
    }

    const isEdit = task && !clone && !isViewMode;
    const browserDateTypeSupported = isBrowserDateTypeSupported();

    useEffect(() => {
        if(clone) {
            const toastrService = new ToastrService();
            toastrService.showSuccess('', 'This task has been cloned from your existing task');
        }
    }, []);

    useEffect(() => {        
        if(assignRef.current) {
            const assignControl = window.$(assignRef.current);
            assignControl.select2({
                theme : "classic" 
            })
            .on('select2:select', assignChanged);
            if(assign) {
                assignControl.val(assign).trigger('change');
            }
        }
        if(projectRef.current) {
            const projectControl = window.$(projectRef.current);
            projectControl.select2({
                theme : "classic" 
            })
            .on('select2:select', projectChanged);
            if(projectID) {
                projectControl.val(projectID).trigger('change');
            }
        }
    }, [isEdit]);

    useEffect(() => {
        let eventName = isEdit ? 'Edit Task Launched' : 'Add Task Launched';
        if(clone) {
            eventName += ' - cloning';
        }
        AnalyticsService.logEvent(eventName);
    }, []);

    const validateTask = () => {
        const toastrService = new ToastrService();
        if(!trim(nameInput.value)) {
            toastrService.showError('', 'Looks like you forgot to enter the name');
            return false;
        }
        if(!dueDateInput.value) {
            toastrService.showError('', 'Looks like you forgot to enter the due date');
            return false;
        }
        if(!isValidDate(dueDateInput.value)) {
            toastrService.showError('', `Looks like the due date isn't valid${!browserDateTypeSupported ? ', please enter the date in the format YYYY-MM-DD' : ''}`);
            return false;
        }
        if(!isEdit && moment(new Date(dueDateInput.value)) < moment(new Date()).startOf('day')) {
            toastrService.showError('', 'The due date cannot be a date in the past');
            return false;
        }
        return true;
    }

    const getTask = () => {
        const userIDs = [];
        if(assign) {
            userIDs.push(assign);
        }
        return {
            ID: isEdit ? task.ID : null,
            Name: trim(nameInput.value),
            Description: trim(descriptionInput.value) || '',
            CreatedDate: isEdit ? task.CreatedDate : new Date(),
            DueDate: new Date(dueDateInput.value).toISOString().split('T')[0] + 'T00:00:00+00:00', //set the due date with no time or time zone applied
            HighPriority: highPriorityInput.checked,
            Status: isEdit ? task.Status : 'Pending',
            CompletedDate: isEdit ? task.CompletedDate : null,
            CreatedFromID: isEdit ? task.CreatedFromID : null,
            Recurrence: recurrence,
            UserIDs: userIDs,
            ProjectID: projectID,
            Version: isEdit ? task.Version : 0
        };			
    }

    const createTask = (event) => {
        event.preventDefault();
        checkSubscription(activeProfile, () => {
            if(validateTask()) {
                setSavingTask(true);
                const newTask = getTask();
                actions.addTask(activeProfile.ID, newTask)
                    .then(task => {
                        window.$("#modal").modal("hide");
                        setSavingTask(false);
                    })
                    .catch(error => {
                        //do nothing, the action will show the error
                        setSavingTask(false);
                    });
            }   
        }, 'create a task');
    }

    const updateTask = (event) => {
        event.preventDefault();
        checkSubscription(activeProfile, () => {
            if(validateTask()) {
                setSavingTask(true);
                const updatedTask = getTask();
                actions.editTask(activeProfile.ID, updatedTask)
                    .then(task => {
                        window.$("#modal").modal("hide");
                        setSavingTask(false);
                    })
                    .catch(error => {
                        //do nothing, the action will show the error
                        setSavingTask(false);
                    });
            }
        }, 'update a task');
        
    }

    const getLoadingText = () => {
        if(savingTask) {
            return isEdit ? 'Saving task' : 'Creating task';
        }
        else {
            if(loadingPhoto) {
                return 'Loading photo...';
            }
            else if(loadingFile) {
                return 'Loading file...';
            }
            else if(loadingAudio) {
                return 'Loading audio...';
            }
            else if(deletingLink) {
                return 'Deleting Link...';
            }
        }
        return 'Loading...';
    }

    const taskAssignedToMember = assign ? members.find(m => m.ID === assign) : null;
    const project = projectID ? activeProfile.Projects.find(p => p.ID === projectID) : null;

    return (     
        <div className={`modal-dialog modal-lg modal-dialog--view-task ${!browserDateTypeSupported ? 'no-date-support' : ''}`}>
            {
                savingTask || loadingFile || loadingPhoto || loadingAudio || deletingLink ?
                    <div className="modal-window__loader modal-window__loader-task">
                        <img src={require('../../images/loader.svg')} alt="" />
                        {
                            getLoadingText()
                        }
                    </div>
                    : null
            }            
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">{isEdit ? 'Edit Task' : (isViewMode ? 'View Task' : 'New Task')}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i className="mikeicon-x" />
                    </button>
                </div>
                <div className="modal-body">
                    <form className="form form--lined form--inputbg modal-inner" onSubmit={isEdit ? updateTask : (!isViewMode ? createTask : null)}>
                        <div className="form-group mb-2">
                            <label>Project name</label>
                            {
                                isViewMode ?
                                    <input className="form-control" type="text" value={project ? project.Name : ''} readOnly /> 
                                    :
                                    <select className="form-control select" ref={projectRef}>
                                        <option key="" value="">Select a project</option>
                                        {
                                            openProjects.length ?
                                                <optgroup label="Open Projects">
                                                {
                                                    openProjects.map(p =>
                                                        <option key={p.ID} value={p.ID}>{p.Name}</option>
                                                    )
                                                }
                                                </optgroup>
                                                : null
                                        }
                                        {
                                            completedProjects.length ?
                                                <optgroup label="Completed Projects">
                                                {
                                                    completedProjects.map(p =>
                                                        <option key={p.ID} value={p.ID}>{p.Name}</option>
                                                    )
                                                }
                                                </optgroup>  
                                                : null
                                        }                                                                             
                                    </select>
                            }                                  
                        </div>
                        
                        <div className="form-group mb-2">
                            <label>Task name</label>
                            <input className="form-control" type="text" placeholder="Enter task name" {...nameInput} readOnly={isViewMode} />
                        </div>
                        {
                            !isViewMode ?
                                <div className="custom-control custom-checkbox mb-3">
                                    <input type="checkbox" className="custom-control-input" id="check-highpriority" {...highPriorityInput} />
                                    <label className="custom-control-label" htmlFor="check-highpriority">High priority</label>
                                </div>
                                : null
                        }                        
                        <div className="form-group">
                            <label>Task description</label>
                            <textarea className="form-control form-area" placeholder="Start typing task description..." {...descriptionInput} readOnly={isViewMode} />
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-2">
                                    <label>Due date</label>
                                    <div className="datapicker-wrapper" readOnly={isViewMode}>
                                       <input className="form-control form-date datepicker" type="date" min={!isEdit ? new Date().toISOString().split("T")[0] : null} placeholder={browserDateTypeSupported ? 'Select date' : 'yyyy-mm-dd'} {...dueDateInput} readOnly={isViewMode} /> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label>Assign</label>
                                    {
                                        isViewMode ?
                                            <input className="form-control" type="text" value={taskAssignedToMember ? taskAssignedToMember.Name : activeProfile.ArtistType === 'Band' ? 'All' : 'Me'} readOnly /> 
                                            :
                                            <select className="form-control select" ref={assignRef}>
                                                {
                                                    members.map(member =>
                                                        <option key={member.ID} value={member.ID}>{member.Name}</option>
                                                    )
                                                }
                                            </select>
                                    }                                    
                                </div>
                            </div>
                        </div>
                        {
                            isViewMode ?
                                <div className="custom-control custom-checkbox mb-3">
                                    <input type="checkbox" className="custom-control-input" id="check-highpriority" checked={task.HighPriority} readOnly />
                                    <label className="custom-control-label" htmlFor="check-highpriority">High priority</label>
                                </div>
                                : null
                        }
                        
                        {/*
                        <div className="custom-control custom-checkbox mb-1">
                            <input type="checkbox" className="custom-control-input" id="check-recurring" />
                            <label className="custom-control-label" htmlFor="check-recurring">Recurring</label>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <select className="form-control" name="" id="">
                                        <option value="" disabled selected>Select interval</option>
                                        <option value="">Every day</option>
                                        <option value="">Every week</option>
                                        <option value="">Every month</option>
                                        <option value="">Every year</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        */}
                        {
                            isViewMode && task.ResourceIDs.length ?
                                <LinkedItems type="Task" item={task} resourceIDs={task.ResourceIDs} setLoadingFile={setLoadingFile} setLoadingPhoto={setLoadingPhoto} setLoadingAudio={setLoadingAudio} setDeletingLink={setDeletingLink} />
                                : null
                        }
                        
                        <div className={`form-group text-center mt-4 modal-buttons ${isViewMode ? 'modal-buttons--view' : ''}`}>
                            {
                                isViewMode ? 
                                    <button type="button" className="btn btn-primary btn-wide m-1" onClick={() => checkSubscription(activeProfile, () => setIsViewMode(false), 'edit a task')}>Edit Task</button>
                                    :
                                    <React.Fragment>
                                        <button type="button" className="btn btn-outline btn-wide m-1" data-dismiss="modal">Cancel</button>
                                        <button type="submit" className="btn btn-primary btn-wide m-1">{isEdit ? 'Save Task' : 'Create Task'}</button>
                                    </React.Fragment>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state, ownProps) {
    //we pass the task ID instead of the object to this component so we can get the object from the store and re-render ourselves if we e.g. delete a linked item
    return { 
        user: state.account.user
    };
}


function mapDispatchToProps(dispatch) {
    return {
        //actions will now be available under props.actions
        actions: bindActionCreators(Object.assign({}, taskActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskModal);