import * as reducerUtils from '../reducerUtils';
import * as types from '../../actions/actionTypes';

export default function projectReduce(state, action) {
    const artistPredicate = (a, artistID) => a.ID === artistID;
    const projectPredicate = (p, projectID) => p.ID === projectID;

    switch(action.type) {            
        
        case types.PROJECT_CREATE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const project = action.payload.project;
            const user = {...state.user};            
            user.Artists = reducerUtils.addNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Projects', project);           
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }      
        case types.PROJECT_UPDATE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const project = action.payload.project;
            const user = {...state.user};
            user.Artists = reducerUtils.updateNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Projects', (p) => projectPredicate(p, project.ID), project);    
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }
        case types.PROJECT_DELETE_SUCCESS:
        {
            const artistID = action.payload.artistID;
            const projectID = action.payload.projectID;
            const deleteTasks = action.payload.deleteTasks;
            const user = {...state.user};            
            user.Artists = reducerUtils.deleteNestedArrayItem(user.Artists, (a) => artistPredicate(a, artistID), 'Projects', (p) => projectPredicate(p, projectID));
            //manually adjust the tasks list to match this change
            //we already cloned the Artists array and object with the call to delete the project so we shouldn't need to clone again as we haven't returned yet
            const artistIndex = user.Artists.findIndex((a) => artistPredicate(a, artistID));
            let artist = user.Artists[artistIndex];
            if(deleteTasks) {
                //we're also deleting any tasks for this project in the back end so remove them           
                artist.Tasks = artist.Tasks.filter(t => t.ProjectID !== projectID)
            }
            else {
                //any tasks for this project ID are now orphaned so clear the Project ID
                //increment the version as the API clearing the Project ID would have created a new version
                artist.Tasks = artist.Tasks.map(t => {
                    if(t.ProjectID === projectID) {
                        return { ...t, ProjectID: null, Version: t.Version + 1 }
                    }
                    else {
                        return t;
                    }
                })
            }
            user.Artists[artistIndex] = artist;            
            reducerUtils.updateActiveProfileAfterArtistUpdate(user, artistID);
            return {
                ...state,
                activeProfileID: user.activeProfile.ID,
                user
            }; 
        }            
        default:
            return state;
    }
}