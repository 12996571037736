import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as artistActions from '../../actions/artistActions';
import '../../styles/style-ui.scss';
import Header from '../common/Header';
import Footer from '../common/Footer';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import 'select2/dist/js/select2';
import 'select2/dist/css/select2.min.css'
import SocialMetricCard from './SocialMetricCard';
import SocialPosts from './SocialPosts';
import { getSocialPlatformUrl } from '../../utils/urlUtils';

function SocialInsightsPage(props) {   
    const activeProfile = props.user.activeProfile;
    const platform = props.match.params.platform;

    const [insights, setInsights] = useState();
    const [loading, setLoading] = useState(true);
    const yearAgo = moment().subtract(1, 'year');
    const periods = [{
        name: '7 days',
        chartLabelFormatString: 'DD MMM yy',
        minDate: moment().subtract(7, 'day').startOf('day'),
        prevPeriod: {
            minDate: moment().subtract(14, 'day').startOf('day'),
            maxDate: moment().subtract(8, 'day').endOf('day'),
        }
    }, {
        name: '30 days',
        chartLabelFormatString: 'DD MMM yy',
        minDate: moment().subtract(30, 'day').startOf('day'),
        prevPeriod: {
            minDate: moment().subtract(60, 'day').startOf('day'),
            maxDate: moment().subtract(31, 'day').endOf('day'),
        }
    }, {
        name: '90 days',
        chartLabelFormatString: 'DD MMM yy',
        minDate: moment().subtract(90, 'day'),
        prevPeriod: {
            minDate: moment().subtract(180, 'day').startOf('day'),
            maxDate: moment().subtract(91, 'day').endOf('day'),
        }
    }, {
        name: 'year',       
        chartLabelFormatString: 'DD MMM yy',
        minDate: yearAgo
    }];

    const [period, setPeriod] = useState(periods.find(p => p.name === '7 days'));
    const periodRef = useRef();
    const [chartData, setChartData] = useState();

    useEffect(() => {
        if(periodRef.current) {
            const periodControl = window.$(periodRef.current);
            periodControl.select2({
                theme : "classic" 
            })
            .on('select2:select', periodChanged);
            if(period) {
                periodControl.val(period.name).trigger('change');
            }
        }
    }, []);

    useEffect(() => {
        if(activeProfile.Social[platform] && activeProfile.Social[platform].IsConfigured) {
            props.actions.getSocialInsights(activeProfile.ID, platform)
            .then(insights => {
                setInsights(insights);
                setChartData(getChartData(insights));
                setLoading(false);
            });
        }
    }, [props.user.activeProfile]);

    useEffect(() => {
        if(insights) {
            setChartData(getChartData());
        }
    }, [period]);

    const periodChanged = (event) => {
        const data = event.params.data;
        if(data) {            
            setPeriod(periods.find(p => p.name === data.id));
        }
    }

    if(!activeProfile.Social[platform] || !activeProfile.Social[platform].IsConfigured) {
        return <Redirect to="/social" />
    }

    const getChartData = (socialInsights = insights) => {
        const chartData = {};
        if(socialInsights) {
            const keys = Object.keys(socialInsights.Insights);
            const labelFormatString = period.chartLabelFormatString;
            if(platform === 'Facebook') {
                chartData.Likes = {};
                chartData.Reach = {};
                chartData.ProfileViews = {};
                chartData.Engagement = {};
                chartData.PreviousLikes = {};
                chartData.PreviousReach = {};
                chartData.PreviousProfileViews = {};
                chartData.PreviousEngagement = {};
                keys.forEach(key => {
                    const insight = socialInsights.Insights[key];
                    const date = moment(insight.Date);
                    if(date >= period.minDate) {
                        const dateLabel = date.format(labelFormatString);
                        chartData.Likes[dateLabel] = { x: date, y: insight.Fans };
                        chartData.Reach[dateLabel] = { x: date, y: insight.Impressions };
                        chartData.ProfileViews[dateLabel] = { x: date, y: insight.Views };
                        chartData.Engagement[dateLabel] = { x: date, y: insight.PostEngagements };
                    }
                    else if(period.prevPeriod && date >= period.prevPeriod.minDate && date <= period.prevPeriod.maxDate) {
                        const dateLabel = date.format(labelFormatString);
                        chartData.PreviousLikes[dateLabel] = { x: date, y: insight.Fans };
                        chartData.PreviousReach[dateLabel] = { x: date, y: insight.Impressions };
                        chartData.PreviousProfileViews[dateLabel] = { x: date, y: insight.Views };
                        chartData.PreviousEngagement[dateLabel] = { x: date, y: insight.PostEngagements };
                    }
                });
            }
            else if(platform === 'Twitter') {
                chartData.Followers = {};
                chartData.TweetCount = {};
                chartData.Impressions = {};
                chartData.Engagement = {};
                chartData.PreviousFollowers = {};
                chartData.PreviousTweetCount = {};
                chartData.PreviousImpressions = {};
                chartData.PreviousEngagement = {};
                keys.forEach(key => {
                    const insight = socialInsights.Insights[key];
                    const date = moment(insight.Date);
                    if(date >= period.minDate) {
                        const dateLabel = date.format(labelFormatString);
                        chartData.Followers[dateLabel] = { x: date, y: insight.FollowedBy };
                        chartData.TweetCount[dateLabel] = { x: date, y: insight.TweetCount };
                        chartData.Impressions[dateLabel] = { x: date, y: insight.Impressions };
                        chartData.Engagement[dateLabel] = { x: date, y: insight.Engagement };
                    }
                    else if(period.prevPeriod && date >= period.prevPeriod.minDate && date <= period.prevPeriod.maxDate) {
                        const dateLabel = date.format(labelFormatString);
                        chartData.PreviousFollowers[dateLabel] = { x: date, y: insight.FollowedBy };
                        chartData.PreviousTweetCount[dateLabel] = { x: date, y: insight.TweetCount };
                        chartData.PreviousImpressions[dateLabel] = { x: date, y: insight.Impressions };
                        chartData.PreviousEngagement[dateLabel] = { x: date, y: insight.Engagement };
                    }
                });
            }
            else if(platform === 'Instagram') {
                chartData.Followers = {};
                chartData.Reach = {};
                chartData.PostCount = {};
                chartData.Engagement = {};
                chartData.PreviousFollowers = {};
                chartData.PreviousReach = {};
                chartData.PreviousPostCount = {};
                chartData.PreviousEngagement = {};
                keys.forEach(key => {
                    const insight = socialInsights.Insights[key];
                    const date = moment(insight.Date);
                    if(date >= period.minDate) {
                        const dateLabel = date.format(labelFormatString);
                        chartData.Followers[dateLabel] = { x: date, y: insight.Followers };
                        chartData.Reach[dateLabel] = { x: date, y: insight.Reach };
                        chartData.PostCount[dateLabel] = { x: date, y: insight.PostCount };
                        chartData.Engagement[dateLabel] = { x: date, y: insight.Engagement };
                    }
                    else if(period.prevPeriod && date >= period.prevPeriod.minDate && date <= period.prevPeriod.maxDate) {
                        const dateLabel = date.format(labelFormatString);
                        chartData.PreviousFollowers[dateLabel] = { x: date, y: insight.Followers };
                        chartData.PreviousReach[dateLabel] = { x: date, y: insight.Reach };
                        chartData.PreviousPostCount[dateLabel] = { x: date, y: insight.PostCount };
                        chartData.PreviousEngagement[dateLabel] = { x: date, y: insight.Engagement };
                    }
                });
            }
            else if(platform === 'YouTube') {
                chartData.Subscribers = {};
                chartData.ViewCount = {};
                chartData.VideoCount = {};
                chartData.Engagement = {};
                chartData.PreviousSubscribers = {};
                chartData.PreviousViewCount = {};
                chartData.PreviousVideoCount = {};
                chartData.PreviousEngagement = {};
                keys.forEach(key => {
                    const insight = socialInsights.Insights[key];
                    const date = moment(insight.Date);
                    if(date >= period.minDate) {
                        const dateLabel = date.format(labelFormatString);
                        chartData.Subscribers[dateLabel] = { x: date, y: insight.Subscribers };
                        chartData.ViewCount[dateLabel] = { x: date, y: insight.Views };
                        chartData.VideoCount[dateLabel] = { x: date, y: insight.Videos };
                        chartData.Engagement[dateLabel] = { x: date, y: insight.Engagement };
                    }
                    else if(period.prevPeriod && date >= period.prevPeriod.minDate && date <= period.prevPeriod.maxDate) {
                        const dateLabel = date.format(labelFormatString);
                        chartData.PreviousSubscribers[dateLabel] = { x: date, y: insight.Subscribers };
                        chartData.PreviousViewCount[dateLabel] = { x: date, y: insight.Views };
                        chartData.PreviousVideoCount[dateLabel] = { x: date, y: insight.Videos };
                        chartData.PreviousEngagement[dateLabel] = { x: date, y: insight.Engagement };
                    }
                });
            }
            else if(platform === 'SoundCloud') {
                chartData.Followers = {};
                chartData.PlayCount = {};
                chartData.TrackCount = {};
                chartData.Engagement = {};
                chartData.PreviousFollowers = {};
                chartData.PreviousPlayCount = {};
                chartData.PreviousTrackCount = {};
                chartData.PreviousEngagement = {};
                keys.forEach(key => {
                    const insight = socialInsights.Insights[key];
                    const date = moment(insight.Date);
                    if(date >= period.minDate) {
                        const dateLabel = date.format(labelFormatString);
                        chartData.Followers[dateLabel] = { x: date, y: insight.Followers };
                        chartData.PlayCount[dateLabel] = { x: date, y: insight.Plays };
                        chartData.TrackCount[dateLabel] = { x: date, y: insight.TrackCount };
                        chartData.Engagement[dateLabel] = { x: date, y: insight.Engagement };
                    }
                    else if(period.prevPeriod && date >= period.prevPeriod.minDate && date <= period.prevPeriod.maxDate) {
                        const dateLabel = date.format(labelFormatString);
                        chartData.PreviousFollowers[dateLabel] = { x: date, y: insight.Followers };
                        chartData.PreviousPlayCount[dateLabel] = { x: date, y: insight.Plays };
                        chartData.PreviousTrackCount[dateLabel] = { x: date, y: insight.TrackCount };
                        chartData.PreviousEngagement[dateLabel] = { x: date, y: insight.Engagement };
                    }
                });
            }
            else if(platform === 'LoudUp') {
                chartData.PluggedIn = {};
                chartData.PlayCount = {};
                chartData.Views = {};
                chartData.Rating = {};
                chartData.PreviousPluggedIn = {};
                chartData.PreviousPlayCount = {};
                chartData.PreviousViews = {};
                chartData.PreviousRating = {};
                keys.forEach(key => {
                    const insight = socialInsights.Insights[key];
                    const date = moment(insight.Date);
                    if(date >= period.minDate) {
                        const dateLabel = date.format(labelFormatString);
                        chartData.PluggedIn[dateLabel] = { x: date, y: insight.PluggedIn };
                        chartData.PlayCount[dateLabel] = { x: date, y: insight.Plays };
                        chartData.Views[dateLabel] = { x: date, y: insight.Views };
                        chartData.Rating[dateLabel] = { x: date, y: insight.Rating };
                    }
                    else if(period.prevPeriod && date >= period.prevPeriod.minDate && date <= period.prevPeriod.maxDate) {
                        const dateLabel = date.format(labelFormatString);
                        chartData.PreviousPluggedIn[dateLabel] = { x: date, y: insight.PluggedIn };
                        chartData.PreviousPlayCount[dateLabel] = { x: date, y: insight.Plays };
                        chartData.PreviousViews[dateLabel] = { x: date, y: insight.Views };
                        chartData.PreviousRating[dateLabel] = { x: date, y: insight.Rating };
                    }
                });
            }
        }
        return chartData;
    }

    const platformUrl = getSocialPlatformUrl(activeProfile.Social[platform], platform);

    const getPostUrl = (post) => {
        if(platform === 'Facebook') {
            return `https://www.facebook.com/${post.ID}`;
        }
        else if(platform === 'Twitter') {            
            const socialProfile = activeProfile.Social[platform];
            return `https://www.twitter.com/${socialProfile.Username}/status/${post.ID}`;
        }
        else if(platform === 'Instagram') {
            return post.Permalink;
        }
        else if(platform === 'YouTube') {
            return `https://www.youtube.com/v/${post.ID}`;
        }
        else if(platform === 'SoundCloud') {
            const socialProfile = activeProfile.Social[platform];
            return `https://www.soundcloud.com/${socialProfile.Username}/${post.Permalink}`;
        }
        else if(platform === 'LoudUp') {
            return `https://www.loudup.com/${post.MediaType === 'Music' ? 'Track' : 'Video'}/${post.ID}`;
        }
        return null;
    }

    const getInsightContent = () => {
        const content = [];
        if(platform === 'Facebook') {
            content.push(<SocialMetricCard key="likes" title="Likes" legendColour="pink" chartColour="#c64897" chartData={chartData['Likes']} previousChartData={chartData['PreviousLikes']} cumulative={false} period={period.name} />);   
            content.push(<SocialPosts key="popularPosts" posts={insights.PopularPosts} startDate={insights.StartDate} title="5 most popular posts" platformUrl={platformUrl} getPostUrl={getPostUrl} />);
            content.push(<SocialMetricCard key="reach" title="Reach" legendColour="blue" chartColour="#2e7692" chartData={chartData['Reach']} previousChartData={chartData['PreviousReach']} cumulative={true} period={period.name} />);
            content.push(<SocialPosts key="latestPosts" posts={insights.LatestPosts} startDate={insights.StartDate} title="5 latest posts" platformUrl={platformUrl} getPostUrl={getPostUrl} />);
            content.push(<SocialMetricCard key="profileViews" title="Profile Views" legendColour="yellow" chartColour="#e0ce67" chartData={chartData['ProfileViews']}  previousChartData={chartData['PreviousProfileViews']} cumulative={true} period={period.name} />);
            content.push(<SocialMetricCard key="engagement" title="Engagement" legendColour="purple" chartColour="#7646aa" chartData={chartData['Engagement']} previousChartData={chartData['PreviousEngagement']} cumulative={true} period={period.name} />);                         
        }
        else if(platform === 'Twitter') {
            content.push(<SocialMetricCard key="followers" title="Followers" legendColour="pink" chartColour="#c64897" chartData={chartData['Followers']} previousChartData={chartData['PreviousFollowers']} cumulative={false} period={period.name} />);   
            content.push(<SocialPosts key="popularTweets" posts={insights.PopularTweets} startDate={insights.StartDate} title="5 most popular tweets" platformUrl={platformUrl} getPostUrl={getPostUrl} itemName="tweet" engagementDescription="liked, retweeted, quoted, replied, or clicked" />);
            content.push(<SocialMetricCard key="tweetCount" title="Tweets" legendColour="blue" chartColour="#2e7692" chartData={chartData['TweetCount']} previousChartData={chartData['PreviousTweetCount']} cumulative={false} period={period.name} />);   
            content.push(<SocialPosts key="latestTweets" posts={insights.LatestTweets} startDate={insights.StartDate} title="5 latest tweets" platformUrl={platformUrl} getPostUrl={getPostUrl} itemName="tweet" engagementDescription="liked, retweeted, quoted, replied, or clicked" />);
            content.push(<SocialMetricCard key="impressions" title="Impressions" legendColour="yellow" chartColour="#e0ce67" chartData={chartData['Impressions']} previousChartData={chartData['PreviousImpressions']} cumulative={true} period={period.name} />);   
            content.push(<SocialMetricCard key="engagement" title="Engagement" legendColour="purple" chartColour="#7646aa" chartData={chartData['Engagement']} previousChartData={chartData['PreviousEngagement']} cumulative={true} period={period.name} />);   
        }
        else if(platform === 'Instagram') {
            content.push(<SocialMetricCard key="followers" title="Followers" legendColour="pink" chartColour="#c64897" chartData={chartData['Followers']} previousChartData={chartData['PreviousFollowers']} cumulative={false} period={period.name} />);   
            content.push(<SocialPosts key="popularPosts" posts={insights.PopularPosts} startDate={insights.StartDate} title="5 most popular posts" platformUrl={platformUrl} getPostUrl={getPostUrl} engagementDescription="liked or commented" />);
            content.push(<SocialMetricCard key="reach" title="Reach" legendColour="blue" chartColour="#2e7692" chartData={chartData['Reach']} previousChartData={chartData['PreviousReach']} cumulative={true} period={period.name} />);
            content.push(<SocialPosts key="latestPosts" posts={insights.LatestPosts} startDate={insights.StartDate} title="5 latest posts" platformUrl={platformUrl} getPostUrl={getPostUrl} engagementDescription="liked or commented" />);
            content.push(<SocialMetricCard key="postCount" title="Posts" legendColour="yellow" chartColour="#e0ce67" chartData={chartData['PostCount']}  previousChartData={chartData['PreviousPostCount']} cumulative={false} period={period.name} />);
            content.push(<SocialMetricCard key="engagement" title="Engagement" legendColour="purple" chartColour="#7646aa" chartData={chartData['Engagement']} previousChartData={chartData['PreviousEngagement']} cumulative={true} period={period.name} />);                         
        }
        //for YouTube, SoundCloud, and LoudUp, we set the start date for the popular posts to null as we show the most popular overall, we don't necessarily expect the user to have so much new content over the last year
        //unlike with normal social media posts where the quantity will be higher
        else if(platform === 'YouTube') {
            content.push(<SocialMetricCard key="subscribers" title="Subscribers" legendColour="pink" chartColour="#c64897" chartData={chartData['Subscribers']} previousChartData={chartData['PreviousSubscribers']} cumulative={false} period={period.name} />);   
            content.push(<SocialPosts key="popularVideos" posts={insights.PopularVideos} startDate={null} title="5 most popular videos" platformUrl={platformUrl} getPostUrl={getPostUrl} itemName="video" reachOrPlays="plays" reachText="Plays" reachDescription="The number of people who played your video" engagementDescription="liked, disliked, or commented on" />);
            content.push(<SocialMetricCard key="views" title="Views" legendColour="blue" chartColour="#2e7692" chartData={chartData['ViewCount']} previousChartData={chartData['PreviousViewCount']} cumulative={false} period={period.name} />);
            content.push(<SocialPosts key="latestPosts" posts={insights.LatestVideos} startDate={insights.StartDate} title="5 latest videos" platformUrl={platformUrl} getPostUrl={getPostUrl} itemName="video" reachOrPlays="plays" reachText="Plays" reachDescription="The number of people who played your video" engagementDescription="liked, disliked, or commented on" />);
            content.push(<SocialMetricCard key="videoCount" title="Videos" legendColour="yellow" chartColour="#e0ce67" chartData={chartData['VideoCount']}  previousChartData={chartData['PreviousVideoCount']} cumulative={false} period={period.name} />);
            content.push(<SocialMetricCard key="engagement" title="Engagement" legendColour="purple" chartColour="#7646aa" chartData={chartData['Engagement']} previousChartData={chartData['PreviousEngagement']} cumulative={false} period={period.name} />);                         
        }
        else if(platform === 'SoundCloud') {
            content.push(<SocialMetricCard key="followers" title="followers" legendColour="pink" chartColour="#c64897" chartData={chartData['Followers']} previousChartData={chartData['PreviousFollowers']} cumulative={false} period={period.name} />);   
            content.push(<SocialPosts key="popularTracks" posts={insights.PopularTracks} startDate={null} title="5 most popular tracks" platformUrl={platformUrl} getPostUrl={getPostUrl} itemName="track" reachOrPlays="plays" reachText="Plays" reachDescription="The number of people who played your track" engagementDescription="commented, reposted, downloaded, or favourited" />);
            content.push(<SocialMetricCard key="plays" title="Plays" legendColour="blue" chartColour="#2e7692" chartData={chartData['PlayCount']} previousChartData={chartData['PreviousPlayCount']} cumulative={false} period={period.name} />);
            content.push(<SocialPosts key="latestTracks" posts={insights.LatestTracks} startDate={insights.StartDate} title="5 latest tracks" platformUrl={platformUrl} getPostUrl={getPostUrl} itemName="track" reachOrPlays="plays" reachText="Plays" reachDescription="The number of people who played your track" engagementDescription="commented, reposted, downloaded, or favourited" />);
            content.push(<SocialMetricCard key="trackCount" title="Tracks" legendColour="yellow" chartColour="#e0ce67" chartData={chartData['TrackCount']}  previousChartData={chartData['PreviousTrackCount']} cumulative={false} period={period.name} />);
            content.push(<SocialMetricCard key="engagement" title="Engagement" legendColour="purple" chartColour="#7646aa" chartData={chartData['Engagement']} previousChartData={chartData['PreviousEngagement']} cumulative={false} period={period.name} />);                         
        }
        else if(platform === 'LoudUp') {
            content.push(<SocialMetricCard key="pluggedin" title="plugged in" legendColour="pink" chartColour="#c64897" chartData={chartData['PluggedIn']} previousChartData={chartData['PreviousPluggedIn']} cumulative={false} period={period.name} />);   
            content.push(<SocialPosts key="popularTracks" posts={insights.PopularTracks} startDate={null} title="5 most popular tracks" platformUrl={platformUrl} getPostUrl={getPostUrl} itemName="track" reachOrPlays="plays" reachText="Plays" reachDescription="The number of people who played your track" engagementDescription="turned up" />);
            content.push(<SocialMetricCard key="plays" title="Plays" legendColour="blue" chartColour="#2e7692" chartData={chartData['PlayCount']} previousChartData={chartData['PreviousPlayCount']} cumulative={false} period={period.name} />);
            content.push(<SocialPosts key="popularVideos" posts={insights.PopularVideos} startDate={null} title="5 most popular videos" platformUrl={platformUrl} getPostUrl={getPostUrl} itemName="video" reachOrPlays="plays" reachText="Plays" reachDescription="The number of people who played your video" engagementDescription="turned up" />);
            content.push(<SocialMetricCard key="views" title="Views" legendColour="yellow" chartColour="#e0ce67" chartData={chartData['Views']}  previousChartData={chartData['PreviousViews']} cumulative={false} period={period.name} />);
            content.push(<SocialMetricCard key="rating" title="Rating (dB)" legendColour="purple" chartColour="#7646aa" chartData={chartData['Rating']} previousChartData={chartData['PreviousRating']} cumulative={false} period={period.name} />);                         
        }
        return content;
    }

    return (        
        <div className="home">
            <Header />
            <main className="content">
                <div className="container">
                    <Link to="/social" className="social-back-button btn btn-outline">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                            viewBox="0 0 492 492" style={{ enableBackground: 'new 0 0 492 492' }}>
                            <g>
                                <g>
                                    <path d="M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12
                                        C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084
                                        c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864
                                        l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z" />
                                </g>
                            </g>
                        </svg>
                        Back 
                    </Link>
                    <div className="content-head content-head--social">
                        <h1>{platform}</h1>                        
                    </div>                    
                    <div className="social-page__header">  
                        <div className="statistics-select-wrapper">
                            <span>Show charts for the last</span>
                            <div className="statistics-select">
                                <select className="form-control select" ref={periodRef} value={period.name}>
                                    {
                                        periods.map(period =>
                                            <option key={period.name} value={period.name}>{period.name}</option>
                                        )
                                    }
                                </select>  
                            </div>
                        </div>
                        <div className="social-header-right-wrapper">
                            <a href={platformUrl} className="button btn btn-primary" target="_blank" rel="noopener noreferer">
                                Visit {platform} page
                            </a>    
                        </div>
                    </div>
                    {
                        !loading ?
                            <div className="content-body">
                                {
                                    platform === 'Instagram' && activeProfile.Social[platform].Current < 100 ?
                                        <div style={{paddingLeft: '10px', color: '#999', fontSize: '0.8em'}}>Note: Some data may only be available if you have over 100 followers</div>
                                        : null
                                }
                                <div className="social-page__items">       
                                    {
                                        getInsightContent()
                                    }
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
            </main>
            {
                loading ?
                    <div className="modal-window__loader modal-window__loader-task modal-window__loader-login active">
                        <img src={require('../../images/loader.svg')} alt="loading" />
                        Loading...
                    </div>
                    : null
            } 
            <Footer />
        </div>
    );
}

SocialInsightsPage.propTypes = {
    //myProp: PropTypes.string.isRequired
};


function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user
    };
}


function mapDispatchToProps(dispatch) {
    return {
         actions: bindActionCreators(Object.assign({}, artistActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialInsightsPage);