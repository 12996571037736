import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as eventActions from '../../actions/eventActions';
import ViewEvent from './ViewEvent';
import NewEditEvent from './NewEditEvent';
import settings from '../../constants/settings.js';

function EventModal({viewMode, eventID, clone, user, actions}) {
    const event = user.activeProfile.Events.find(e => e.ID === eventID);
    const [isViewMode, setIsViewMode] = useState(viewMode);
    const title = isViewMode ? 'View Event' : (event && !clone ? 'Edit Event' : 'New Event');
    const [savingEvent, setSavingEvent] = useState(false);    
    const [loadingFile, setLoadingFile] = useState(false);
    const [loadingPhoto, setLoadingPhoto] = useState(false);
    const [loadingAudio, setLoadingAudio] = useState(false);
    const [deletingLink, setDeletingLink] = useState(false);
    const googleMapsPromiseRef = useRef();

    if((!window.google || !window.google.maps) && !googleMapsPromiseRef.current) {
        googleMapsPromiseRef.current = new Promise((resolve) => {
            // Add a global handler for when the API finishes loading
            window.resolveGoogleMapsPromise = () => {
                resolve(window.google);
                // Tidy up
                delete window.resolveGoogleMapsPromise;
            };
            window.$.getScript(`https://maps.google.com/maps/api/js?key=${settings.GOOGLEPLACES_KEY}&libraries=places&sensor=false&callback=resolveGoogleMapsPromise`);
        })
     }
     else if(!googleMapsPromiseRef.current) {
         //we're already loaded google maps so resolve our promise with the instance
         googleMapsPromiseRef.current = Promise.resolve(window.google);
     }

    const getLoadingText = () => {
        if(savingEvent) {
            return eventID && !clone && !isViewMode ? 'Saving event' : 'Creating event';
        }
        else {
            if(loadingPhoto) {
                return 'Loading photo...';
            }
            else if(loadingFile) {
                return 'Loading file...';
            }
            else if(loadingAudio) {
                return 'Loading audio...';
            }
            else if(deletingLink) {
                return 'Deleting Link...';
            }
        }
        return 'Loading...';
    }

    return (
        <div className="modal-dialog modal-lg">
            {
                savingEvent || loadingFile || loadingPhoto || loadingAudio || deletingLink ?
                    <div className="modal-window__loader modal-window__loader-task">
                        <img src={require('../../images/loader.svg')} alt="" />
                        {
                            getLoadingText()
                        }
                    </div>
                    : null
            }     
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">{title}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i className="mikeicon-x" />
                    </button>
                </div>
                {
                    isViewMode ? 
                        <ViewEvent event={event} 
                            setIsViewMode={setIsViewMode} 
                            user={user} actions={actions} 
                            setLoadingFile={setLoadingFile} 
                            setLoadingPhoto={setLoadingPhoto} 
                            setLoadingAudio={setLoadingAudio}
                            setDeletingLink={setDeletingLink}
                            googleMapsPromise={googleMapsPromiseRef.current} /> 
                        : 
                        <NewEditEvent event={event} clone={clone} user={user} actions={actions} setSavingEvent={setSavingEvent} googleMapsPromise={googleMapsPromiseRef.current} />
                }
            </div>
        </div>
    )
}

function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //actions will now be available under props.actions
        actions: bindActionCreators(Object.assign({}, eventActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventModal);