import * as React from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Header from '../account/Header';

const NotFound = (props) => { 
    //debugger;
    return (
        <main className="not-found-page">
            <Header className="page__branding" />
            <div class="not-found-content">
                <h1>Page not found</h1>
                <p>Sorry, we couldn't find that page!</p>
                <div class="btn btn-brand">
                    <Link to="/">Return to homepage</Link>
                </div>
            </div>
           <Footer />
        </main>
    );
};

export default NotFound;