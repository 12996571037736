import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import '../../styles/style-ui.scss';
import Header from '../common/Header';
import * as artistUtils from '../../utils/artistUtils';
import Footer from '../common/Footer';
import moment from 'moment';
import { withModalContext } from '../../services/modalService';
import TaskModal from './TaskModal';
import { getResourceDescription } from '../../utils/resourceUtils';
import ArtistNameHeader from '../common/ArtistNameHeader';
import TaskList from './TaskList';
import * as qs from 'query-string';
import { Link } from 'react-router-dom';
import { noCompletedTasksMessages, noPendingTasksMessages, tasksCompletedPageHeaders, tasksPendingPageHeaders } from '../../constants/appMessages';
import _ from 'lodash';

function TasksPage(props) {   

    const [selectedTab, setSelectedTab] = useState('Pending');
    const [filter, setFilter] = useState('All Tasks');
    const [showMenuForTask, setShowMenuForTask] = useState();
    const resource = props.location.state && props.location.state.resource ? props.location.state.resource : null;
    const [resourceToLink, setResourceToLink] = useState(resource);
    const [linkingResource, setLinkingResource] = useState(false);
    const pendingTasksHeader = useRef(_.sample(tasksPendingPageHeaders)).current;
    const completedTasksHeader = useRef(_.sample(tasksCompletedPageHeaders)).current;
    const headerText = selectedTab === 'Pending' ? pendingTasksHeader : completedTasksHeader;
    const noPendingTasksMessage = useRef(_.sample(noPendingTasksMessages)).current;
    const noCompletedTasksMessage = useRef(_.sample(noCompletedTasksMessages)).current;
  
    const filters = {
        Pending: [{
            Name: 'All Tasks',
            Filter: (t) => true
        }, {
            Name: 'Tasks due in the next 7 days',
            Filter: (t) => moment(t.DueDate) <= moment().startOf('day').add(7, 'days')
        }, {
            Name: 'Tasks due in the next 30 days',
            Filter: (t) => moment(t.DueDate) <= moment().startOf('day').add(30, 'days')
        }],
        Completed: [{
            Name: 'All Tasks',
            Filter: (t) => true
        }, {
            Name: 'Tasks completed in the last 7 days',
            Filter: (t) => moment(t.CompletedDate) >= moment().startOf('day').add(-7, 'days')
        }, {
            Name: 'Tasks due in the last 30 days',
            Filter: (t) => moment(t.CompletedDate) >= moment().startOf('day').add(-30, 'days')
        }]
    }

    const updateFilter = (event, filter) => {
        event.preventDefault();
        setFilter(filter);
    }

    const updateSelectedTab = (tab) => {
        setSelectedTab(tab);
        setFilter('All Tasks');
        setShowMenuForTask(null);
    }

    //initialise tasks
    const activeProfile = props.user.activeProfile;
    const currentFilter = filters[selectedTab].find(f => f.Name === filter);
    const params = qs.parse(props.location.search);
    const projectID = params.projectID;
    let tasks = activeProfile.Tasks;
    let project;
    if(projectID) {
        tasks = tasks.filter(t => t.ProjectID === projectID);
        project = activeProfile.Projects.find(p => p.ID === projectID);
    }
    tasks = tasks.filter(t => t.Status === selectedTab && currentFilter.Filter(t));    
    if(selectedTab === 'Pending') {
        tasks = tasks.sort((a, b) => a.DueDate - b.DueDate);
    }
    else {
        tasks = tasks.sort((a, b) => b.CompletedDate - a.CompletedDate);
    }
    const getActiveClass = (tabName) => {
        if(selectedTab === tabName) {
            return 'active';
        }
        return '';
    }

    const createNewTask = (event) => {
        event.preventDefault();
        event.stopPropagation();
        artistUtils.checkSubscription(activeProfile, () => {
            const taskModal = <TaskModal defaultProjectID={projectID} />
            props.showModal(() => taskModal, { isOpen: true })
        }, 'create a task');        
    }

    useEffect(() => {
        window.$(document).on('click', '', closeDropDown);
        return () => {
            window.$(document).off('click', '', closeDropDown);
        }
    }, []);

    const closeDropDown = (e) => {
        const div = window.$('.list-row__menu');    
        if (!div.is(e.target) && div.has(e.target).length === 0) {
            div.removeClass('active');
        }
    }

    const getEmptyText = () => {        
        if(filter !== 'All Tasks') {
            return `You don't have any ${filter !== 'All Tasks' ? 'matching' : selectedTab.toLowerCase()} tasks`;
        }
        else {
            return selectedTab === 'Pending' ? noPendingTasksMessage : noCompletedTasksMessage;
        }
    }

    const resourceDescription = resourceToLink ? getResourceDescription(resourceToLink) : null;

    return (        
        <div className="home">
            <Header />
            <main className="content">
                {
                    resourceToLink ?
                        <div className="prompt">    
                            <div className="prompt__text">
                                Please select a task to link the {resourceDescription} to
                            </div>
                            <div className="prompt__close" onClick={() => setResourceToLink(null)}>+</div>
                        </div>
                    : null
                }
                <div className="container">
                    <Link to="/projects" className="social-back-button btn btn-outline">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                            viewBox="0 0 492 492" style={{ enableBackground: 'new 0 0 492 492' }}>
                            <g>
                                <g>
                                    <path d="M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12
                                        C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084
                                        c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864
                                        l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z" />
                                </g>
                            </g>
                        </svg>
                        Back 
                    </Link>                    
                    {
                        project ?
                            <div className="content-head">
                                <h1>{project.Name}</h1>                        
                            </div>
                        :                        
                            <ArtistNameHeader user={props.user} greeting={`Hey ${props.user.FirstName}, ${headerText}`}/>  
                    }
                    <div className="content-body">
                        <div className="tabs-header">
                            <div className="actions float-right">
                                {
                                    !resourceToLink ? 
                                        <a href="#" className="btn-text btn-new" onClick={createNewTask}>
                                            <i className="mikeicon-pluscircle"></i>
                                            New Task
                                        </a>
                                        : null
                                }                                
                                <div className="dropdown ml-3">
                                    <a className="btn-text-simple dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className={`mikeicon-filter ${filter !== 'All Tasks' ? 'active' : ''}`}></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-animated dropdown-menu-right dropdown-menu--filter" aria-labelledby="filterDropdown">
                                        {
                                            filters[selectedTab].map(f => 
                                                <a key={`${selectedTab}-${f.Name}`} className={`dropdown-item ${filter === f.Name ? 'choosen': ''}`} href="#" onClick={(event) => updateFilter(event, f.Name)}>{f.Name}</a>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <ul className="nav nav-tabs nav-tabs-simple nav-tabs-tasks" role="tablist">
                                <li className="nav-item">
                                    <a className={`nav-link ${getActiveClass('Pending')}`} id="pending-tab" href="#" onClick={(e => updateSelectedTab('Pending'))} role="tab" aria-controls="pending" aria-selected={selectedTab === 'Pending'}>Pending<span className="d-none d-lg-inline"> Tasks</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${getActiveClass('Completed')}`} id="completed-tab" href="#" onClick={(e => updateSelectedTab('Completed'))} role="tab" aria-controls="completed" aria-selected={selectedTab === 'Completed'}>Completed<span className="d-none d-lg-inline"> Tasks</span></a>
                                </li>
                            </ul>
                        </div>                        
                        <TaskList 
                            tasks={tasks} 
                            headerText={selectedTab === 'Pending' ?  'Up Next' : 'Completed Tasks'} 
                            resourceToLink={resourceToLink} 
                            setLinkingResource={setLinkingResource}
                            history={props.history}
                            emptyText={getEmptyText()}
                            projectID={projectID}
                            showMenuForTask={showMenuForTask}
                            setShowMenuForTask={setShowMenuForTask} />
                    </div>
                </div>
            </main>
            <Footer />
            {
                linkingResource ?
                    <div className="modal-window__loader modal-window__loader-task modal-window__loader-sign-up active">
                        <img src={require('../../images/loader.svg')} alt="loading" />
                        Linking {resourceDescription} to task...
                    </div>
                    : null
            }    
        </div>
    );
}

TasksPage.propTypes = {
    //myProp: PropTypes.string.isRequired
};


function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user
    };
}


function mapDispatchToProps(dispatch) {
    return {
        //account actions will now be available under this.props.actions
        //actions: bindActionCreators(Object.assign({}, taskActions), dispatch)
    };
}

export default withModalContext(connect(mapStateToProps, mapDispatchToProps)(TasksPage));