import _ from 'lodash';

export function updateObject(object, newValues) {
    return {...object, ...newValues};
}

export function addObjectToArray(array, object) {
    const newArray = [...array];
    newArray.push(object);
    return newArray;
}

export function updateArrayItem(array, findPredicate, newItem) {
    const index = array.findIndex(findPredicate);
    if(index !== -1) {
        array = [...array];
        array[index] = newItem;
    }
    return array;
}

export function addNestedArrayItem(array, findPredicate, propertyName, newItem) {    
    array = [...array];
    const nestedIndex = array.findIndex(findPredicate);
    let obj = {...array[nestedIndex]};
    copySubProperties(obj, propertyName);
    obj = _.set(obj, propertyName, addObjectToArray(_.get(obj, propertyName), newItem));
    array[nestedIndex] = obj;
    return array;
}

export function updateNestedArrayItem(array, findPredicate, propertyName, findPredicateChild, updatedItem) {    
    array = [...array];
    const nestedIndex = array.findIndex(findPredicate);
    let obj = {...array[nestedIndex]};
    copySubProperties(obj, propertyName);
    _.set(obj, propertyName, updateArrayItem(_.get(obj, propertyName), findPredicateChild, updatedItem));
    array[nestedIndex] = obj;
    return array;
}

export function deleteArrayItem(array, findPredicate) {
    const index = array.findIndex(findPredicate);
    if(index !== -1) {
        array = [...array];
        array.splice(index, 1);
    }
    return array;
}

function copySubProperties(obj, propertyName) {
    const propertyNames = propertyName.split('.');
    let tmpObj = obj;
    for(let i = 0; i < propertyNames.length - 1; i++) {
        const currentPropertyName = propertyNames[i];
        tmpObj[currentPropertyName] = {...tmpObj[currentPropertyName]};
        tmpObj = tmpObj[currentPropertyName];
    }
}

export function deleteNestedArrayItem(array, findPredicate, propertyName, findPredicateChild) {    
    array = [...array];
    const nestedIndex = array.findIndex(findPredicate);
    let obj = {...array[nestedIndex]};
    copySubProperties(obj, propertyName);
    _.set(obj, propertyName, deleteArrayItem(_.get(obj, propertyName), findPredicateChild));
    array[nestedIndex] = obj;
    return array;
}

export function updateActiveProfileAfterArtistUpdate(user, artistID) {
    if(user.activeProfile.ID === artistID) {
        user.activeProfile = user.Artists.find(a => a.ID === artistID);
    }
}