import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as resourceActions from '../../actions/resourceActions';
import { blobToBase64 } from '../../utils/fileUtils';
import FinaliseSave from './FinaliseSave';
import AudioViewContent from './AudioViewContent';
import { checkSubscription } from '../../utils/artistUtils';
import AnalyticsService from '../../services/analyticsService';

function AudioModal({item, blob, user, actions, allTags, viewMode = false}) {
    const activeProfile = user.activeProfile;
    const [savingAudio, setSavingAudio] = useState(false);
    const [loadingAudio, setLoadingAudio] = useState(false);
    const [file, setFile] = useState();
    const [proceed, setProceed] = useState(item && !viewMode);
    const isEdit = item && proceed;

    useEffect(() => {
        if(item && !blob && !file && !proceed) {
            setLoadingAudio(true);
            actions.getFile(activeProfile.ID, item.ID)
                .then(file => {
                    setFile(file);
                    setLoadingAudio(false);
                })
                .catch(error => setLoadingAudio(false));
        }        
    }, [isEdit])

    const noteRef = useRef();
    const finaliseRef = useRef();

    useEffect(() => {
        if(proceed) { 
            window.$(noteRef.current).slideUp();
            window.$(finaliseRef.current).slideDown();
        }
        else {            
            window.$(noteRef.current).slideDown();
            window.$(finaliseRef.current).slideUp();
        }
    }, [proceed])

    useEffect(() => {
        let eventName = isEdit ? 'Edit Vault Audio Launched' : 'Add Vault Audio Launched';
        AnalyticsService.logEvent(eventName);
    }, []);

    const finaliseCancelled = (event) => {
        if(event) {
            event.preventDefault();
        }
        setProceed(false);
    }

    const getItem = async (event) => {
        //if it's a new item, we'll have a blob and we need to send the file name and data string, else we send the file id as we don't allow the file to be updated
        return {
            ID: item ? item.ID : null,
            Type: 'File',
            FileType: 'Audio',
            Version: item ? item.Version : 0,
            Filename: blob ? `Audio.mp3` : null,
            DataString: blob ? (await blobToBase64(blob)).split(',')[1] : null,
            FileID: item ? item.FileID : null
        }
    }

    return ( 
        <div className="modal-dialog modal-lg modal-dialog--view-task">
            {
                savingAudio|| loadingAudio ?
                    <div className="modal-window__loader modal-window__loader-task">
                        <img src={require('../../images/loader.svg')} alt="" />
                        {
                            loadingAudio ? 
                                'Loading audio file...'
                                : 
                                isEdit ? 
                                    'Saving audio file' 
                                    : 
                                    'Creating audio file'
                        }
                    </div>
                    : null
            }            
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">{item && proceed ? 'Edit Audio File' : (item ? 'View Audio File' : 'New Audio File')}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i className="mikeicon-x" />
                    </button>
                </div>
                <div className="modal-body">
                    <div className="note-content">
                        <form className="form form--lined form--inputbg">
                            <div className="note-description-wrapper" ref={noteRef}>
                                <AudioViewContent item={item} file={file} blob={blob} />
                                <div className="form-group text-center mt-4 modal-buttons">
                                    <button type="button" className="btn btn-outline btn-wide m-1" data-dismiss="modal">Cancel</button>
                                    <button type="button" className="btn btn-primary btn-wide m-1 add-note-button" onClick={() => checkSubscription(activeProfile, () => setProceed(true), 'update this audio item')}>{item ? 'Edit' : 'Save Audio'}</button>
                                </div>
                            </div>
                            <FinaliseSave 
                                onCancel={finaliseCancelled} 
                                finaliseRef={finaliseRef}
                                user={user}
                                name={item ? item.Name : ''}
                                isPublic={item ? item.Visibility === 'Public' : true}
                                itemTags={item ? item.Tags : []}
                                allTags={allTags}
                                actions={actions}
                                getItem={getItem}
                                setSaving={setSavingAudio} />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user
    };
}


function mapDispatchToProps(dispatch) {
    return {
        //actions will now be available under props.actions
        actions: bindActionCreators(Object.assign({}, resourceActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AudioModal);