/* eslint-disable react/jsx-no-bind */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import Header from '../account/Header.js';
import * as currencyActions from '../../actions/currencyActions';
import * as pricingActions from '../../actions/pricingActions';
import * as artistActions from '../../actions/artistActions';
import '../../styles/style-onboarding.scss';
import AnimateIn from '../common/AnimateIn.js';
import ToastrService from '../../services/toastrService.js';
import { paymentCurrencies } from '../../constants/types';

function SubscriptionPage({user, currencies, pricingPlans, pricingPlan, actions, history, location}) {
    const [currency, setCurrency] = useState(null);

    useEffect(() => {
        if(!pricingPlans.length) {
            actions.getPricingPlans();
        }
        if(!currencies.length) {
            actions.getCurrencies();
        }        
        if(location.state && location.state.message) {
            const toastrService = new ToastrService();
            toastrService.showInfo('', `Sorry, you need a subscription in order to ${location.state.message}`);
        }
    }, []);

    useEffect(() => {
        if(currencies.length) {
            //if the profile default currency is a currency we use for payments (GBP, USD, EUR), we should default to it, 
            //else get a currency from the API which is based on the IP address
            const defaultCurrency = currencies.find(c => c.ID === user.activeProfile.DefaultCurrencyID);
            if(paymentCurrencies.includes(defaultCurrency.Code)) {
                setCurrency(defaultCurrency);
            }
            else {
                actions.getCurrency()
                    .then(currency => setCurrency(currency));
            }
        }
    }, [currencies]);

    const getCurrencySymbol = () => {
        if(currency) {
            return currency.Symbol;
        }
        return '$';
    }

    const getPrice = (frequency) => {
        let price = 0;
        if(pricingPlan && currency) {
            if(frequency === 'Monthly'){
                price = pricingPlan.Monthly.Prices[currency.Code];
            }
            else {
                price = pricingPlan.Annual.Prices[currency.Code];
            }
        }
        if(price === 0) {
            return '-';
        }
        else {
            return price;
        }
    }

    const getAnnualSaving = () => {
        if(pricingPlan && currency) {
            let saving = (pricingPlan.Monthly.Prices[currency.Code] * 12) - pricingPlan.Annual.Prices[currency.Code];
            //round to 2 decimal places by converting to cents, rounding, then converting back
            saving = Math.round(saving * 100)/100;
            return saving;
        }
        else {
            return '-';
        }
    } 

    const getLoginsText = () => {
        const activeProfile = user.activeProfile;
        const individual = activeProfile && activeProfile.ArtistType === 'Individual';
        if (individual) {
            return 'Single user login';
        }
        else {
            return 'Unlimited band members';
        }
    }

    const startTrial = (event, period) => {
        event.preventDefault();
        history.push('/subscription/create', { pricingPlan: pricingPlan[period], currency })
    }

    //make sure the user doesn't already have an active subscription
    if(user.activeProfile.HasActiveSubscription) {
        const toastrService = new ToastrService();
        toastrService.showInfo('', 'You already have an active subscription for this profile');
        return <Redirect to="/" />;
    }

    const close = () => {
        const redirectLocation = (location.state && location.state.returnUrl) ? location.state.returnUrl : '/';
        history.push(redirectLocation);
    }

    return (            
        <main className="onboarding">
            <button className="close-page" onClick={close} />
            <div className="onboarding__content">
                <Header className="page__branding" />
                <AnimateIn className="onboarding__body">
                    <form className="form form--lined form--login">
                        <h4 className="text-center">Choose a subscription plan</h4>
                        <p className="form-text text-center text-muted mb-4">To use all the application features, you will need to select a subscription plan below</p>
                        <div className="plans">
                            <a href="#" className="plan" onClick={(event) => startTrial(event, 'Monthly')}>
                                <div className="plan__head">
                                    <h5>Monthly</h5>
                                    <p className="plan__price">{getCurrencySymbol() + getPrice('Monthly')}<small>/month</small></p>
                                </div>
                                <div className="plan__body">
                                    <ul>
                                        <li>{getLoginsText()}</li>
                                        <li>Start with a 1 month free trial, followed by monthly payments</li>
                                    </ul>
                                </div>
                                <div className="plan__foot">
                                    <div className="btn btn--brand btn-block">
                                        Start 1 month free trial
                                    </div>
                                </div>
                            </a>
                            <a href="#" className="plan" onClick={(event) => startTrial(event, 'Annual')}>
                                <div className="plan__head">
                                    <div className="plan__save">
                                        <p>Save {getCurrencySymbol() + getAnnualSaving()}<small>/year</small></p>
                                    </div>
                                    <h5>Yearly</h5>
                                    <p className="plan__price">{getCurrencySymbol() + getPrice('Annual')}<small>/year</small></p>
                                </div>
                                <div className="plan__body">
                                    <ul>
                                        <li>{getLoginsText()}</li>
                                        <li>Start with a 1 month free trial, followed by annual payments</li>
                                    </ul>
                                </div>
                                <div className="plan__foot">
                                    <div className="btn btn--brand btn-block">
                                        Start 1 month free trial
                                    </div>
                                </div>
                            </a>
                        </div>
                        <p className="text-center mt-4 text-muted">You will not be charged until 1 month after you start the subscription. You can cancel anytime within the trial period if you do not wish to continue with the subscription.</p>
                    </form>
                </AnimateIn>
            </div>
        </main>    
    );   
}

SubscriptionPage.propTypes = {
    actions: PropTypes.object
    //myProp: PropTypes.string.isRequired
};

function getPricingPlan(pricingPlans, artistType, frequency) {
    const pricingPlan = pricingPlans.find(p => p.ArtistType === artistType && p.Frequency === frequency);
    return pricingPlan;
}

function mapStateToProps(state, ownProps) {
    let pricingPlan = null;
    if(state.pricingPlans && state.pricingPlans.length > 0) {
        const artistType = state.account.user.activeProfile.ArtistType;
        pricingPlan = {
            Monthly: getPricingPlan(state.pricingPlans, artistType, 'Monthly'),
            Annual: getPricingPlan(state.pricingPlans, artistType, 'Annual')
        };
    }
    return { 
        user: state.account.user,
        userLoading: state.account.userLoading,
        currencies: state.currencies,
        pricingPlans: state.pricingPlans,
        pricingPlan: pricingPlan
    };
}


function mapDispatchToProps(dispatch) {
    return {
        //actions will now be available under props.actions
        actions: bindActionCreators(Object.assign({}, artistActions, pricingActions, currencyActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage);
