import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import App from './components/App';
import HomePage from './components/home/HomePage';
import ScrollToTop from './components/common/ScrollToTop';
import NotFound from './components/common/NotFound';
import LoginPage from './components/account/LoginPage';
import SignupPage from './components/account/SignupPage';
import ForgotPasswordPage from './components/account/ForgotPasswordPage';
import PrivateRoute from './components/common/PrivateRoute';
import ProfileSetupPage from './components/accountSetup/ProfileSetupPage';
import SubscriptionPage from './components/accountSetup/SubscriptionPage';
import PageLoading from './components/common/PageLoading';
import TasksPage from './components/tasks/TasksPage';
import EventsPage from './components/events/EventsPage';
import ResetPasswordPage from './components/account/ResetPasswordPage';
import BandMembersPage from './components/additionalPages/BandMembersPage';
import GoalsPage from './components/goals/GoalsPage';
import CreateSubscriptionPage from './components/accountSetup/CreateSubscriptionPage';
//import InspirationPage from './components/additionalPages/InspirationPage';
import FinancesPage from './components/finances/FinancesPage';
import VaultPage from './components/vault/VaultPage';
import AccountSettingsPage from './components/account/AccountSettingsPage';
import ManageSubscriptionPage from './components/accountSetup/ManageSubscriptionPage';
import ViewProfilePage from './components/admin/ViewProfilePage';
import SocialPage from './components/social/SocialPage';
import SocialInsightsPage from './components/social/SocialInsightsPage';
import ProjectsPage from './components/projects/ProjectsPage';
import PartnersPage from './components/additionalPages/PartnersPage';
import DeleteAccountPage from './components/account/DeleteAccountPage';

export const routes = <ScrollToTop>
    <App>
        <Switch>
            <PrivateRoute exact path="/" component={HomePage} />
            <Route path="/login" component={LoginPage} />
            <Route path="/signup" component={SignupPage} />
            <Route path="/forgotpassword" component={ForgotPasswordPage} />
            <Route path="/resetpassword" component={ResetPasswordPage} />
            <PrivateRoute path="/profilesetup" component={ProfileSetupPage} />
            <PrivateRoute path="/editprofile" component={ProfileSetupPage} />
            <PrivateRoute exact path="/subscription" component={SubscriptionPage} />
            <PrivateRoute path="/subscription/create" component={CreateSubscriptionPage} />
            <PrivateRoute exact path="/projects" component={ProjectsPage} />         
            <PrivateRoute exact path="/tasks" component={TasksPage} />          
            <PrivateRoute exact path="/goals" component={GoalsPage} />       
            <PrivateRoute exact path="/schedule" component={EventsPage} /> 
            <PrivateRoute exact path="/finances" component={FinancesPage} />
            <PrivateRoute exact path="/social" component={SocialPage} />
            <PrivateRoute exact path="/social/:platform" component={SocialInsightsPage} />
            <PrivateRoute path="/bandmembers" component={BandMembersPage} />
            <PrivateRoute path="/partners" component={PartnersPage} />
            <PrivateRoute path="/vault" component={VaultPage} />            
            <PrivateRoute path="/accountsettings" component={AccountSettingsPage} />
            <PrivateRoute path="/subscription/manage" component={ManageSubscriptionPage} />
            <PrivateRoute path="/viewprofile" component={ViewProfilePage} />
            <PrivateRoute path="/deleteaccount" component={DeleteAccountPage} />
            <Route path="*" component={NotFound} />
        </Switch>
    </App>
</ScrollToTop>;

