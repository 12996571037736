import GoalService from '../services/goalService';
import ToastrService from '../services/toastrService';
import { push } from 'connected-react-router';
import * as types from './actionTypes';
import * as actionUtils from '../utils/actionUtils';
import { normaliseGoal } from '../utils/normaliseUtils';

export function addGoal(artistID, goal) {
    return function(dispatch, getState) {
        const toastrService = new ToastrService();
        return GoalService.createGoal(artistID, goal).then(goal => {
            normaliseGoal(goal);
            dispatch({ type: types.GOAL_CREATE_SUCCESS, payload: { artistID, goal } });
            const state = getState();
            const artist = state.account.user.Artists.find(a => a.ID === artistID);
            if(!artist.Goals.length && artist.Tasks.length === 1) {
                toastrService.showSuccess('', 'Congratulations on creating your 1st goal, you are well on your way to world domination!');
            }
            else {
                toastrService.showSuccess('', 'Goal has been created!')
            }
            if(window.location.pathname !== '/goals') {
                dispatch(push('/goals'));
            }
        }).catch(error => {
            actionUtils.handleError(error);
        });
    };
}

export function editGoal(artistID, goal) {
    return function(dispatch, getState) {
        const toastrService = new ToastrService();
        const state = getState();
        const existingGoal = state.account.user.Artists.find(a => a.ID === artistID).Goals.find(g => g.ID === goal.ID);
        return GoalService.editGoal(artistID, goal).then(async goal => {
            normaliseGoal(goal);
            dispatch({ type: types.GOAL_UPDATE_SUCCESS, payload: { artistID, goal } });
            //check if the goal has just been completed
            const goalCompleted = existingGoal.Current < existingGoal.Target && goal.Current >= goal.Target;
            if (goalCompleted) {
                toastrService.showSuccess('', `Congratulations on ${(goal.Current == goal.Target ? 'meeting' : 'exceeding')} your goal!`);
            }		
            else {
                toastrService.showSuccess('', 'Goal has been updated!');
            }
        }).catch(error => {
            actionUtils.handleError(error);
        });
    };
}

export function deleteGoal(artistID, goalID) {
    return function(dispatch) {
        const toastrService = new ToastrService();
        return GoalService.deleteGoal(artistID, goalID).then(_ => {
            dispatch({ type: types.GOAL_DELETE_SUCCESS, payload: { artistID, goalID } });
            toastrService.showSuccess('', 'Goal has been deleted!');
        }).catch(error => {
            actionUtils.handleError(error);
        });
    };
}
