import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as vaultActions from '../../actions/resourceActions';
import * as taskActions from '../../actions/taskActions';
import * as eventActions from '../../actions/eventActions';
import { showFile, showInlineContent, showImage } from '../../utils/widgetUtils';
import NoteModal from '../vault/NoteModal';
import { getBase64Url } from '../../utils/fileUtils';
import { getVaultType } from '../../utils/resourceUtils';
import AudioModal from '../vault/AudioModal';
import { getShortDate } from '../../utils/dateUtils';
import NoteViewContent from '../vault/NoteViewContent';
import AudioViewContent from '../vault/AudioViewContent';
import swal from 'sweetalert';

function LinkedItems({user, type, item, resourceIDs, setLoadingFile, setLoadingPhoto, setLoadingAudio, setDeletingLink, actions}) {
    const activeProfile = user.activeProfile;
    const magnificContainerRef = useRef();
    const [modalType, setModalType] = useState();
    const [selectedResource, setSelectedResource] = useState();
    const [resourceFile, setResourceFile] = useState();

    const showNote = (event, resource) => {        
        //render note into magnific container
        setModalType('Note');
        setSelectedResource(resource);  
        showInlineContent(window.$(magnificContainerRef.current), event.target, () => setSelectedResource(null))
    }

    const showAudio = (resource, target) => {
        //update the react component in the magnific container        
        setModalType('Audio');
        setSelectedResource(resource);  
        showInlineContent(window.$(magnificContainerRef.current), target, () => {
            setSelectedResource(null);
            setResourceFile(null);
        })
    }

    const itemClicked = (event, resource) => {
        if(resource.VaultType === 'Text') {
            showNote(event, resource);            
        }
        else if(resource.VaultType === 'PDF') {            
            event.preventDefault();
            loadAndShowItem(event, resource, setLoadingFile, (file, target) => {
                const src =  `data:${file.MetaData.ContentType};base64,${file.Data}`;
                showFile(src, target);  
            })    
        }
        else if(resource.VaultType === 'Image') {
            event.preventDefault();
            loadAndShowItem(event, resource, setLoadingPhoto, (file, target) => {
                const imageSrc = getBase64Url(file.Data);
                showImage(imageSrc, target);     
            });
        }
        else {
            event.preventDefault();
            loadAndShowItem(event, resource, setLoadingAudio, (file, target) => {
                setResourceFile(file);
                showAudio(resource, target);     
            });
        }
    }

    const getItemImage = (resource) => {
        if(resource.VaultType === 'Audio') {
            return require('../../images/vault-icon-record-small.png');
        }
        else if(resource.VaultType === 'Text') {
            return require('../../images/vault-icon-write-small.png');
        }
        else if(resource.VaultType === 'Image') {
            return require('../../images/vault-icon-photo-small.png');
        }
        else {
            return require('../../images/vault-icon-attach-small.png');
        }        
    }

    const loadAndShowItem = (event, resource, setLoading, showFileCallback) => {
        const target = event.currentTarget;  
        setLoading(true);  
        actions.getFile(activeProfile.ID, resource.ID)
            .then(file => {                
                showFileCallback(file, target);     
                setLoading(false);
            })
            .catch(error => setLoading(false));      
    }     

    const deleteLink = (event, resource) => {
        event.preventDefault();
        event.stopPropagation();
        swal({
            title: 'Please confirm',
            text: 'Are you sure you want to delete this link?',
            buttons: true,
            dangerMode: true,
        })
        .then(async (willDelete) => {
            if (willDelete) {
                setDeletingLink(true);
                const action = type === 'Task' ? actions.deleteTaskResourceLink : actions.deleteEventResourceLink;
                action(activeProfile.ID, item.ID, resource.ID)
                    .then(result => setDeletingLink(false))
                    .catch(error => setDeletingLink(false));
            }
        })
    }

    const linkedResources = activeProfile.Resources
            .filter(r => resourceIDs.includes(r.ID))
            .map(r => ({...r, VaultType: getVaultType(r)}));

    return (
        <div className="form-group">
            <div className="linked-items">
                <label htmlFor="" className="modal-label">LINKED ITEMS</label>  
                {
                    linkedResources.map(resource =>                                                
                        <div key={resource.ID} className="linked-item">
                            <div className="linked-item__content" onClick={(event) => itemClicked(event, resource)}>
                                <div className="vault-item__img">
                                    <img src={getItemImage(resource)} alt="" /> 
                                </div>
                                <div className="linked-item__title">
                                    {resource.Name}
                                </div>                               
                            </div>
                            <div className="linked-item__delete" onClick={(event) => deleteLink(event, resource)}>
                                +
                            </div>
                        </div>
                    )
                }  
            </div>
            <div id="magnificContainer" ref={magnificContainerRef}>
                {
                    modalType && selectedResource ?
                        modalType === 'Note' ?
                            <div className="text-note-modal" id="text-note-modal">
                                <NoteViewContent item={selectedResource} />
                            </div>                            
                            :
                            resourceFile ?
                                <div className="text-note-modal" id="audio-note-modal">
                                    <AudioViewContent item={selectedResource} file={resourceFile} />
                                </div>
                                : null
                        : null
                }
            </div>
        </div>
    )
}


function mapStateToProps(state, ownProps) {
    return { 
        user: state.account.user
    };
}


function mapDispatchToProps(dispatch) {
    return {
        //actions will now be available under props.actions
        actions: bindActionCreators(Object.assign({}, taskActions, eventActions, vaultActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkedItems);